
<div id='newsimulation-modal' class='modal-wrap'>
    <div class='modal-header'>
        <div>
            <h3>{{'editSimulationModal.titlesectionsims' | translate}}</h3>
        </div>
        <button type="button" class="btn-modal-header" (click)="onClose()" data-dismiss="modal">
            <span>&times;</span>
        </button>
    </div>
    <div class='modal-content'>
        <div id='loading-modal-edit' class='d-none'>
            <div>
                <img src="../../../../assets/img/interface/loading.gif" alt="loading" height="50">
                <span class='d-block mt-4'>Calculating results...</span>
            </div>
        </div>
        <form id='step-form' #formSimulation="ngForm" (ngSubmit)="onSubmit(formSimulation)" (keydown.enter)="$event.preventDefault()">
            <div *ngIf='simulation?.allocation'>
                <div class='warning-alert'>WARNING : You have an allocation created, if you change the section parameters the allocation will be deleted.</div>
            </div>
            <div class='step'>
                <div class='row'>
                    <div class='col-6 pl-0'>
                        <span class='data-content'>{{'editSimulationModal.venue' | translate}}: <b>{{venueName}}</b></span>
                        <span class='data-content'>{{'editSimulationModal.configuration' | translate}}: <b>{{simulation?.configuration?.name}}</b></span>
                        <span class='data-content'>
                            <div class='row align-items-center'>
                                <div class='col-auto pr-0'>
                                    {{'editSimulationModal.simulation' | translate}}: 
                                </div>
                                <div class='col-auto p-0 d-flex align-items-center'>
                                    <span id='sim-name' class='color-blue' [ngClass]="nameInEdit ? 'd-none' : ''" ><b>{{simulation?.name}}</b></span>
                                    <!-- 
                                    <input id="simulationName" type="text" *ngIf="nameInEdit" class='d-flex text-input' name="simulationName" [(ngModel)]="simulationName" /> 
                                        <div class='data-content-ico' [ngClass]="nameInEdit ? 'd-none' : ''" >
                                        <button type='button' class='btn-round' (click)="enableEditName()">
                                            <div class='icon-container-round'>
                                                <div class='icon edit'></div>
                                            </div>
                                        </button>
                                    </div> -->
                                </div>
                                <div class='col p-0'>
                                    <div class='data-content-ico' [ngClass]="!nameInEdit ? 'd-none' : ''">
                                        <button type='button' class='btn-round' (click)="updateSimulationName()"  [disabled]="nameIsDisabled()">
                                        <div class='icon-container-round'>
                                            <div class='icon check'></div>
                                        </div>
                                        </button>
                                    </div>
                                    <div id='data-content-ico-last' class='data-content-ico' [ngClass]="!nameInEdit ? 'd-none' : ''">
                                        <button type='button' class='btn-round'  (click)="disableEditName()">
                                            <div class='icon-container-round'>
                                                <div class='icon cancel'></div>
                                            </div>
                                        </button>
                                    </div>

                                </div>
                            </div>
                            
                            <!-- <div id='editName-icons' class="row">
                                <div class='col' (click)="enableEditName()" [ngClass]="nameInEdit ? 'd-none' : ''">
                                </div>

                                <div class='editnameIcons col' (click)="updateSimulationName()" [ngClass]="!nameInEdit ? 'd-none' : ''">
                                </div>
                                <div class='editnameIcons col' (click)="disableEditName()" [ngClass]="!nameInEdit ? 'd-none' : ''">
                                </div>
                            </div> -->
                        </span>
                        <span class='data-content'>
                            Sections selected: <b class='color-blue'>{{sections_selected_value}}</b>
                        </span>
                    </div>
                    <div class='col-6 pr-0'>
                        <span class='data-content'>{{'editSimulationModal.venuecapacity' | translate}}: <b>{{formatNumber(simulation?.configuration.capacity)}}</b></span>
                        <span class='data-content'>{{'editSimulationModal.configseats' | translate}}: <b style='margin-right:10px;'>{{formatNumber(simulation?.configuration.capacity-nlocked)}}</b> <small>({{ formatNumber(nlocked) }} locked seats)</small></span>
                        <span class='data-content'>
                            {{'editSimulationModal.simresult' | translate}}: 
                            <b class='color-blue'>{{formatNumber(simulationcapacity)}} </b>
                            <small class='color-blue'> ({{availability}}%)</small>
                        </span>
                        <span id='sectionsselected' class='data-content'>
                            Sections selected new capacity: <b class='color-blue'>{{subsimcapacity}}</b> / <b class='ml-0'>{{selection_capacity}}</b>
                        </span>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-5 p-0'>
                        <label><b>1. {{'editSimulationModal.chooseSocialDistancing' | translate}}</b></label>
                    </div>
                    <div class='col-auto p-0'>
                        <div class='sd-btns'>
                            <button id='btn-less-distance' type='button' class='btn-round d-inline' (click)="onSetDistancing('minus')">
                                <div class='icon-container-round'>
                                    <div class='icon minus'></div>
                                </div>
                            </button>
                            <span class='sd-value d-inline-block'>{{formatSocialDistancing(socialDistancing)}}</span>
                            <span class='sd-unit d-inline-block'>{{units}}</span>
                            <button id='btn-more-distance' type='button' class=' d-inline btn-round' (click)="onSetDistancing('sum')">
                                <div class='icon-container-round'>
                                    <div class='icon plus'></div>
                                </div>
                            </button>
                        </div>
                        <input id="securityDistance" type="hidden" name="socialDistancing" [(ngModel)]="socialDistancing" /> 
                        
                    </div>
                </div>
                <div class='row custom-padding-row'>
                    
                    <div class='col-5 p-0'>
                        <label><b>2. {{'editSimulationModal.aisle_note' | translate}}</b></label>
                    </div>
                    <div class='col d-flex align-items-center p-0 opt-col'>
                        <div class='sd-btns'>
                            <button id='btn-less-aisle' type='button' class='btn-round d-inline' (click)="onChangeAisleSeat('minus')">
                                <div class='icon-container-round'>
                                    <div class='icon minus'></div>
                                </div>
                            </button>
                            <span class='sd-value d-inline-block'>{{aisleSeats}}</span>
                            <span class='sd-unit d-inline-block'>{{'editSimulationModal.seats' | translate}}</span>
                            <button id='btn-more-aisle' type='button' class=' d-inline btn-round' (click)="onChangeAisleSeat('sum')">
                                <div class='icon-container-round'>
                                    <div class='icon plus'></div>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class='col p-0'>
                        <div class="dropdown-wrap" dropdown>
                            <button dropdownToggle type="button" class="btn-dropdown dropdown-toggle" [disabled]='disabledKillSide' aria-controls="dropdown-animated-configs">
                                <span *ngIf="aisleSeats > 0">
                                    {{current_side_tokill}}
                                </span>
                                <span *ngIf="aisleSeats === 0">
                                    Select aisle seats to kill
                                </span>
                            </button>

                            <ul id="dropdown-animated-configs" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dropdown-animated-configs">
                                <li (click)="setAisleSideSelected(null)" class='dropdown-item' role="menuitem">
                                    <span>Kill seats from both sides of the section</span>
                                </li>
                                <li (click)="setAisleSideSelected(1)" class='dropdown-item' role="menuitem">
                                    <span>Kill seats from the begining of the row</span>
                                </li>
                                <li (click)="setAisleSideSelected(2)" class='dropdown-item' role="menuitem">
                                    <span>Kill seats from the ending of the row</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class='row no-border'>
                    <div class='col-5 p-0'>
                        <label><b>3. {{'editSimulationModal.editGroups' | translate}}</b></label>
                    </div>
                    <div id='warning-col' class='text-center' *ngIf="isDisabled('percent')">
                        <span class='warning-percentage'>The total desired is {{totalPercentage}}%, must be exactly 100%. </span>
                    </div>
                </div>
                <div class='row'>
                    <!-- HALF 1 -->
                    <div class='group-options col pl-0' style='border-right:1px solid #ccc'>
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th class='text-right'>{{'editSimulationModal.desired' | translate}}</th>
                                    <th class='text-center d-none'>{{'editSimulationModal.calculated' | translate}}</th>
                                    <th class='text-right d-none'>{{'editSimulationModal.availableseats' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let option of paginatedSimulationParameters[0]; let i = index">
                                    <td><span class='opt-lab'>{{'editSimulationModal.optionparam.'+option.name | translate}}</span></td>
                                    <td class='text-right'>
                                        <input id="{{option.name}}" type="number" min="0" max="100" [(ngModel)]="inputHash[option.id].percentage" name="{{option.name}}" (input)="onChangeNumber($event, 100, option.id)" value="0" />
                                    </td>
                                    <td class='text-center d-none'>
                                        {{(inputHash[option.id].computed_per).toFixed(2)}}
                                    </td>
                                    <td class='text-right d-none'>
                                        <span class='color-blue' *ngIf="inputHash[option.id].computed_nseats else nocomputed_nseats">
                                            <b>{{formatNumber(inputHash[option.id].computed_nseats)}} </b><small>({{computedTotalsStringsHash[option.id]}}%)</small>
                                        </span>
                                        <ng-template #nocomputed_nseats>
                                            <span class='color-blue'><b>0</b></span>
                                        </ng-template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- HALF 2 -->
                    <div class='group-options col pr-0'>

                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th class='text-center'>{{'editSimulationModal.desired' | translate}}</th>
                                    <th class='text-right d-none'>{{'editSimulationModal.calculated' | translate}}</th>
                                    <th class='text-center d-none'>{{'editSimulationModal.availableseats' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let option of paginatedSimulationParameters[1]; let i = index">
                                    <td><span class='opt-lab'>{{'editSimulationModal.optionparam.'+option.name | translate}}</span></td>
                                    <td class='text-center'>
                                        <input id="{{option.name}}" type="number" min="0" max="100" [(ngModel)]="inputHash[option.id].percentage" name="{{option.name}}" (input)="onChangeNumber($event, 100, option.id)" value="0" />
                                    </td>
                                    <td class='text-center d-none'>
                                        {{(inputHash[option.id].computed_per).toFixed(2)}}
                                    </td>
                                    <td class='text-right d-none'>
                                        <span class='color-blue' *ngIf="inputHash[option.id].computed_nseats else nocomputed_nseats">
                                            <b>{{formatNumber(inputHash[option.id].computed_nseats)}} </b> <small>({{computedTotalsStringsHash[option.id]}}%)</small>
                                        </span>
                                        <ng-template #nocomputed_nseats>
                                            <span class='color-blue'><b>0</b></span>
                                        </ng-template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-5 p-0'>
                        <label for='limit2groups'><b>4. {{'editSimulationModal.limit2groups' | translate}}</b></label>
                    </div>
                    <div class='col-auto p-0'>
                        <input id='limit2groups' class="css-checkbox" type='checkbox' name="limit2groups" [(ngModel)]="limit2groups">
                        <label class='css-label' (click)='checkLimit2groups()'></label>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-5 p-0'>
                        <label for='fallback'><b>5. {{'editSimulationModal.fallback' | translate}}</b></label>
                    </div>
                    <div class='col p-0'>
                        <input id='fallback' class="css-checkbox" type='checkbox' name="fallback" [(ngModel)]="fallback">
                        <label class='css-label' (click)='checkFallback()'></label>
                    </div>
                </div>
            </div>
            <div class='modal-footer'>
                <button id='cancelsimulation-btn' class='btn' type="button" (click)="onClose()">{{'editSimulationModal.buttonCancel' | translate}}</button>
                <button id='newsimulation-btn' class='btn' type="submit" [disabled]="isDisabled()">{{'editSimulationModal.buttonConfirm' | translate}}</button>
            </div>
        </form>
    </div>
</div>