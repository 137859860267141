import { Inject, Injectable, OnDestroy } from "@angular/core";
import { parseCSVToJSON } from "../../allocator/utils/parse";
import { CategoriesService } from "../../../services/categories.service";
import { cleanId, LaligaVenueDataRow } from "../../allocator/services/parsers/laliga-parser.service";
import { Inventory, InventoryService } from "../../../services/inventory.service";
import { Subscription } from "rxjs";
import { DVMService } from "../../../services/dvm.service";
import { CUSTOM_CATEGORIES_BY_VENUE } from "../configuration/categories.configuration";
import { AuthenticationService } from "../../../../auth";
import { CustomCategoriesPlugin } from "../custom-categories.module";
import { DataService } from "../../../services/data.service";
import { Configuration } from "../../../shared/models/configuration-data.model";
import { CustomCategoriesByVenue } from "../models/custom-categories.model";
import { Simulation } from "../../../shared/models/simulation-data.model";
import { GroupsService } from "../../../services/groups.service";
import { MapViewer, MapViewerStylesState } from "@3ddv/dvm-internal";

export interface CustomCategory {
  name: string;
  group: string;
  color: string;
}

@Injectable({
  providedIn: "root",
})
export class CustomCategoriesService implements OnDestroy {
  public get allowCustomCategories(): boolean {
    return this._allowCustomCategories;
  }

  public get configurationHasCustomCategories(): boolean {
    return this._configurationHasCustomCategories;
  }

  public customCategories: Map<string, CustomCategory> = new Map();

  private _allowCustomCategories = false;
  private _configurationHasCustomCategories = false;
  private readonly subscriptions: Subscription[] = [];

  constructor(
    @Inject(CUSTOM_CATEGORIES_BY_VENUE) private customCategoriesByVenue: CustomCategoriesByVenue,
    private categoriesService: CategoriesService,
    private inventoryService: InventoryService,
    private authService: AuthenticationService,
    private dvmService: DVMService,
    private dataService: DataService,
    private groupsService: GroupsService
  ) {
    this.subscriptions.push(
      this.authService.getUserLoggedSubject().subscribe((user) => {
        if (user) {
          const plugin = user.user.plugins.find(
            (pl) => pl.type === "customcategories"
          ) as CustomCategoriesPlugin | null;
          if (plugin) {
            this._allowCustomCategories = true;
            return;
          }
        }
        this._allowCustomCategories = false;
      })
    );

    this.subscriptions.push(
      this.dataService.getCurrentConfiguration$().subscribe((configuration: Configuration) => {
        this.setConfigurationHasCustomCategories(configuration);
      })
    );

    this.subscriptions.push(
      this.inventoryService.inventoryObservable$.subscribe((inventory) => {
        this.parseCustomCategoriesFromInventory();
      })
    );

    this.subscriptions.push(
      this.categoriesService.printCategoriesObservable$.subscribe((viewer) => {
        this.setCustomCategoriesNodeGroups(viewer);
        this.setCustomCategoriesStyles(viewer);
      })
    );

    this.subscriptions.push(
      this.inventoryService.preInventoryObservable$.subscribe((inventory) => {
        if (this.dvmService.isSimulation) {
          this.cleanCustomCategoriesFromInventory(inventory);
        }
      })
    );

    this.subscriptions.push(
      this.inventoryService.preComparativeInventoryObservable$.subscribe((inventory) => {
        if (this.dvmService.isSimulation) {
          this.cleanCustomCategoriesFromInventory(inventory);
        }
      })
    );

    this.subscriptions.push(
      this.groupsService.resetGroupBehaviors$.subscribe((inventory) => {
        if (this.dvmService.isSimulation) {
          this.parseSimulationCustomCategories();
        }
      })
    );

    this.subscriptions.push(
      this.dataService.editSimulation$.subscribe((body: any) => {
        if (this.allowCustomCategories) {
          const sim = this.dataService.simulationData;
          if (sim && sim.configuration) {
            const obj = this.dataService.simulationData?.custom_categories;
            if (obj && obj.selected_custom_categories) {
              obj.statistics = {};
              body.custom_categories = obj;
              this.inventoryService.iterateInventoryElements((element) => {
                const customcategory = element.customcategory;
                if (customcategory) {
                  const value = obj.statistics[customcategory];
                  obj.statistics[customcategory] = value ? value + 1 : 1;
                }
              });
            }
          }
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getCustomCategories(): string[] {
    const set: Set<string> = new Set();
    this.inventoryService.iterateInventoryElements((elementData) => {
      const customcategory = elementData.customcategory;
      if (customcategory) {
        set.add(customcategory);
      }
    });
    return Array.from(set);
  }

  addCustomCategory(groupId: string, name: string, color: string): boolean {
    if (!this.customCategories.has(groupId)) {
      this.customCategories.set(groupId, { group: groupId, name, color });
      return true;
    }
    return false;
  }

  getCategoriesList(): CustomCategory[] {
    return Array.from(this.customCategories.values());
  }

  getCategory(categoryId: string): CustomCategory | null {
    return this.customCategories.get(categoryId) ?? null;
  }

  // Recibe seatmanifest con custom categories y el manifest actual
  // devuelve un seatmanifest resultante de la union
  buildManiestWithCustomCategories(
    csvUser: string,
    oldManifestObject: Inventory
  ): { inventory: Inventory; categories: string[] } {
    const missingSeats = [];
    const csvUserRows = parseCSVToJSON<LaligaVenueDataRow>(csvUser, ";");
    const categories = new Set<string>();

    for (const row of csvUserRows) {
      const { ESTADO_AVET } = row;
      const sectionId = row.IDZONA.toString();
      const rowId = cleanId(row.FILA);
      const seatId = cleanId(row.ASIENTO);
      const data = oldManifestObject[sectionId]?.elements[rowId]?.elements[seatId];
      if (data) {
        const seatData = data.data;
        seatData.category = "regular";
        seatData.customcategory = ESTADO_AVET;
        data.data = seatData;
        categories.add(ESTADO_AVET);
      } else {
        // si la silla no existe la guardamos en la lista de sillas perdidas
        missingSeats.push(`S_${sectionId}-${rowId}-${seatId}`);
      }
    }

    this.setDefaultLockedCategoryToInventory(oldManifestObject, "locked");

    if (missingSeats.length > 0) {
      console.warn("Seats with errors", missingSeats);
    }
    return { inventory: oldManifestObject, categories: Array.from(categories) };
  }

  public parseSimulationCustomCategories(): void {
    const sim: Simulation = this.dataService.simulationData as Simulation;
    const conf = sim.configuration;
    this.setConfigurationHasCustomCategories(conf);
    if (this._configurationHasCustomCategories) {
      const selectedCustomCategories = sim.custom_categories?.selected_custom_categories ?? [];
      const set = new Set(selectedCustomCategories);
      conf.custom_categories?.custom_categories?.forEach((c) => {
        if (!set.has(c)) {
          this.groupsService.addGroupsToBehaviors("validated", c);
        }
      });
    }
  }

  public cleanCustomCategoriesFromInventory(inventory: Inventory): void {
    const sim = this.dataService.simulationData;
    if (sim && this._configurationHasCustomCategories) {
      const selectedCustomCategories = sim.custom_categories?.selected_custom_categories ?? [];
      const set = new Set(selectedCustomCategories);
      this.inventoryService.iterateInventoryElements((elementData) => {
        const customcategory = elementData.customcategory;
        if (set.has(customcategory)) {
          elementData.customcategory = null;
        }
      }, inventory);
    }
  }

  public setDefaultLockedCategoryToInventory(inventory: Inventory, category: string): void {
    this.inventoryService.iterateInventoryElements((elementData) => {
      if (!elementData.customcategory) {
        elementData.category = category;
      }
    }, inventory);
  }

  private setConfigurationHasCustomCategories(configuration: Configuration): void {
    this._configurationHasCustomCategories =
      this.allowCustomCategories && !!configuration.custom_categories?.custom_categories?.length;
  }

  private parseCustomCategoriesFromInventory(): void {
    this.customCategories.clear();

    if (!this.allowCustomCategories) {
      return;
    }

    const viewer = this.dvmService.viewer as MapViewer;
    const venue = viewer.getVenueId();
    const cats = this.customCategoriesByVenue?.[venue];
    this.getCustomCategories().forEach((customcategory) => {
      const color = cats?.[customcategory]?.color ?? "white";
      const title = cats?.[customcategory]?.title ?? customcategory;
      this.addCustomCategory(customcategory, title, color);
    });
  }

  private setCustomCategoriesNodeGroups(viewer: MapViewer): void {
    if (!this.allowCustomCategories) {
      return;
    }

    this.inventoryService.iterateInventoryElements((elementData) => {
      const customcategory = elementData.customcategory;
      if (customcategory) {
        viewer.addNodesToGroup(elementData.code, customcategory);
      }
    });
  }

  private setCustomCategoriesStyles(viewer: MapViewer): void {
    if (!this.allowCustomCategories) {
      return;
    }

    const styles = viewer.getStyles();
    for (const category of this.customCategories.values()) {
      const { color, group } = category;
      (styles[1].seat.available as MapViewerStylesState).normal[group] = { fillStyle: color };
      (styles[1].seat.unavailable as MapViewerStylesState).normal[group] = { fillStyle: color };
    }
    viewer.setStyles(styles);
  }

  public getCutomCategoriesByVenue(venueId: string) {
    return this.customCategoriesByVenue?.[venueId];
  }

  /**
   * Same endpoint simulations / configurations
   * Status : raw or parsed
   * - POST | GET /customcategories/configuration/:id/venuedata/:status`
   * - POST | GET /customcategories/simulation/:id/venuedata/:status`
   */
  public postVenueDataFile(model: string, id: number, status: "raw" | "parsed", csv: string, filename?: string) {
    let mime = "application/vnd.ms-excel";
    let extension = "csv";
    if (status === "parsed") {
      mime = "application/json";
      extension = "json";
    }
    const blob = new Blob([csv], { type: mime });
    const body = new FormData();
    if (!filename) {
      body.append("venuedata", blob, `venuedata.${extension}`);
    } else {
      filename = filename.split(".csv")[0] + "." + extension;
      body.append("venuedata", blob, filename);
    }
    const api = this.dataService.getHttp();
    return api.http.post(`${api.apiRoot}/customcategories/${model}/${id}/venuedata/${status}`, body);
  }

  public getVenueDataFile(model: string, id: number, type: "raw" | "parsed") {
    const api = this.dataService.getHttp();
    return api.http.get(`${api.apiRoot}/customcategories/${model}/${id}/venuedata/${type}`);
  }
}
