import { Injectable } from '@angular/core';
import { InventoryService } from './inventory.service';
import { DataService } from './data.service';
import { FormatNumberService } from './format-number.service';

@Injectable({
  providedIn: 'root'
})
export class PricingService {

    currentSelectionPricing = [];
    //currencySymbol;
    getRevenueBySection(areaId:string,rowId?:string){
        return this.inventory.getInventoryRevenue(areaId,rowId);
        
    }
    getCurrentPricing(nodeType: string, nodes: any[], update?: boolean, returnNumber?: boolean) {
        const pricingHash = {};
        if (nodeType === 'seat') {
            nodes.forEach((node) => {
                const split = node.id.split('-');
                const areaId = split[0].split('_')[1];
                const rowId = split[1];
                const invProps = this.inventory.getInventoryProperty('price', areaId, rowId);
                console.log(invProps)
                invProps.forEach(price => {
                    pricingHash[price] = true;
                });
            });
            
        } else if (nodeType === 'section') {
            nodes.forEach((node) => {
                const split = node.id.split('-');
                const areaId = split[0].split('_')[1];
                const invProps = this.inventory.getInventoryProperty('price', areaId);
                invProps.forEach(price => {
                    pricingHash[price] = true;
                });
            });
        }
        if (update) {
            this.currentSelectionPricing = Object.keys(pricingHash);
        }

        const keys = Object.keys(pricingHash);
        if (keys.length > 1) {
            return Object.keys(pricingHash);
        } else if (keys.length === 1) {
            if (returnNumber) {
                return this.formatNumber(Object.keys(pricingHash)[0]);
            } else {
                return this.formatPrice(Object.keys(pricingHash)[0]);
            }
        } else {
            return null;
        }
    }

  constructor(private inventory: InventoryService,
              private dataService: DataService,
              private formatService: FormatNumberService) {
                // this.formatPrice(0).split('0').forEach(
                //     c => {
                //         if (c && c !== '.') {
                //         this.currencySymbol = c;
                //         }
                //     }
                // );
  }

  setPricing(nodeType: 'section' | 'seat', nodes: any[], value: number) {
      const setPricingPromise = new Promise((s, r) => {
          if (nodeType === 'seat') {
            nodes.forEach((node) => {
                const split = node.id.split('-');
                this.inventory.setInventoryProperty('price', value, split[0].split('_')[1], split[1], s);
            });

        } else if (nodeType === 'section') {
            nodes.forEach((node) => {
                const split = node.id.split('-');
                this.inventory.setInventoryProperty('price', value, split[0].split('_')[1], null, s);
            });
        }
      }).then(() => {
          console.log('Set pricing completed.');
      });
  }

  formatPrice(price) {
    // const formatter = new Intl.NumberFormat(this.dataService.getUser().user.client.language, {
    //         style: 'currency',
    //         currency: this.dataService.getUser().user.client.currency,
    //       });
    return this.formatService.formatCurrencyNumber(price);
  }

  formatNumber(price) {
      return this.formatService.formatNumber(price);
  }

}
