import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCategoriesComponent } from './components/custom-categories/custom-categories.component';
import { TranslateModule } from '@ngx-translate/core';
import {DVM_CONFIG, DVMConfig} from '../../../../assets/configuration/digitalvenue.configuration';
import {CUSTOM_CATEGORIES_BY_VENUE, customCategoriesbyVenue} from './configuration/categories.configuration';
import {SDEPlugin} from '../../shared/models/user-data.model';

export interface CustomCategoriesPlugin extends SDEPlugin {
  type: 'customcategories';
}

export interface CustomCategoriesConfigurationObject {
  custom_categories?: string[];
}

export interface CustomCategoriesSimulationObject {
  selected_custom_categories?: string[];
  statistics?: {
    [key: string]: number;
  };
}


declare module '../../services/data.service' {
  interface CloneConfigurationBody {
    custom_categories?: CustomCategoriesConfigurationObject;
  }
}

declare module '../../services/inventory.service' {
  // MERGE
  interface InventoryElementData {
    customcategory?: string;
  }
}

declare module '../../shared/models/configuration-data.model' {
  // MERGE
  interface Configuration {
    custom_categories?: CustomCategoriesConfigurationObject;
  }
}

declare module '../../shared/models/simulation-data.model' {
  interface Simulation {
    custom_categories?: CustomCategoriesSimulationObject;
  }
}

declare module '../../shared/models/user-data.model' {
  // MERGE
  export interface Plugins {
    customcategories: CustomCategoriesPlugin;
  }
}

@NgModule({
  declarations: [CustomCategoriesComponent],
  exports: [
    CustomCategoriesComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  providers: [
    {provide: DVM_CONFIG, useValue: DVMConfig},
    {provide: CUSTOM_CATEGORIES_BY_VENUE, useValue: customCategoriesbyVenue},
  ]
})
export class CustomCategoriesModule { }
