import { Component, OnInit, Input } from '@angular/core';
import { SelectionService } from 'src/app/social-distance-editor/services/selection.service';
import { GroupsService } from 'src/app/social-distance-editor/services/groups.service';
import { CategoriesService } from 'src/app/social-distance-editor/services/categories.service';
import { PricingService } from 'src/app/social-distance-editor/services/pricing.service';
import { InventoryService } from 'src/app/social-distance-editor/services/inventory.service';

import { FormatNumberService } from 'src/app/social-distance-editor/services/format-number.service';
import { DataService } from 'src/app/social-distance-editor/services/data.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { User } from 'src/app/social-distance-editor/shared/models/user-data.model';
import { formatPercent } from '@angular/common';

@Component({
  selector: 'app-selectiondata',
  templateUrl: './selectiondata.component.html',
  styleUrls: ['./selectiondata.component.scss']
})
export class SelectiondataComponent implements OnInit {
  // @Input()
  // set open(val) {
  //   this._open = val;
  //   if (val && this.lastSelection) {
  //     this.processSelection(this.lastSelection);
  //   }
  // }
  @Input() page: 'setup' | 'simulation' | 'shared';

  constructor(
    private selection: SelectionService,
    private inventory: InventoryService,
    private numberFormat: FormatNumberService,
    private dataService: DataService,
    private authService: AuthenticationService
    ) { }

  nodeType;
  currentSelectionData = {};
  //_open;
  lastSelection;
  currencySymbol;
  totalRevenue;
  totalRevenueString;
  totalSeats;
  totalRows;
  totalSections;
  displayInfo = false;

  objectKeysOf(object) {
    return Object.keys(object);
  }

  ngOnInit(): void {
    if (this.page !== 'shared') {
      if (this.dataService.getUser()){
        this.currencySymbol = this.dataService.getUser().user.client.currency;
      }else{
        this.authService.getUserLogged$().subscribe(
          response => {
            this.currencySymbol = (response as User).user.client.currency;
          }
        );
      }
    } else {
      if (this.dataService.shareData) {
        this.currencySymbol = this.dataService.shareData.data.currency;
      } else {
        this.dataService.shareDataSubject.subscribe(
          response => {
            this.currencySymbol = this.dataService.shareData.data.currency;
          }
        );
      }
    }

    this.selection.selectionSubject.subscribe(
      selection => {
        if (this.displayInfo) {
          this.processSelection(selection);
        }
        this.lastSelection = selection;
      }
    );
  }

  processSelection(selection) {
    this.currentSelectionData = {};
    this.totalRevenue = 0;
    this.totalSections = 0;
    this.totalRows = 0;
    this.totalSeats = 0;
    if (selection.length) {
      this.nodeType = selection[0].type;
      if (this.nodeType === 'section') {
        selection.forEach(element => {
          const section = element.id.split('_')[1].split('-')[0];
          this.currentSelectionData[section] = JSON.parse(JSON.stringify(this.inventory.getInventoryData()[section]));
        });
      } else if (this.nodeType === 'seat') {
        selection.forEach(element => {
          const split = element.id.split('_')[1].split('-');
          const section = split[0];
          const row = split[1];
          const seat = split[2];
          if (!this.currentSelectionData[section]) {
            this.currentSelectionData[section] = { elements: {}, data: {} };
          }
          if (!this.currentSelectionData[section].elements[row]) {
            this.currentSelectionData[section].elements[row] = { elements: {}, data: {} };
          }
          this.currentSelectionData[section].elements[row].elements[seat] =
          JSON.parse(JSON.stringify(this.inventory.getInventoryData()[section].elements[row].elements[seat]));
        });
      }


      for (const key in this.currentSelectionData) {
        if (this.currentSelectionData.hasOwnProperty(key)) {
          const element = this.currentSelectionData[key];
          this.recursiveDeepSearch(element);
          this.totalRevenue += parseFloat(element.data.price);
          this.totalSections += 1;
          this.totalRows += element.data.count;
          this.totalSeats += element.data.subcount;

        }
      }
      this.totalSections = this.formatNumber(this.totalSections);
      this.totalRows = this.formatNumber(this.totalRows);
      this.totalSeats = this.formatNumber(this.totalSeats);

      this.totalRevenueString = this.formatNumber(this.totalRevenue);
    }
    const selectionMap = selection.map(x => x.id);
  }

  /*
  * Joan añadiendo comentarios solo en las cosas nice:
  * Recursive Depth-first Search algorithm that goes through the child elements until it finds the lowest level.
  * It returns the data of the lowest level and computes its totals, and then proceeds to compute the inmediate higher level
  * until all element levels are covered.
  */
  recursiveDeepSearch(element) {
    if (Object.entries(element.elements).length) {
      for (const key in element.elements) {
        if (element.elements.hasOwnProperty(key)) {
          const newElement = element.elements[key];
          const elementData = JSON.parse(JSON.stringify(this.recursiveDeepSearch(newElement)));

          if (!element.data.price) {
            element.data.price = 0;
          }
          if (!element.data.unitprice) {
            element.data.unitprice = element.data.price;
          }
          if (!element.data.categories) {
            element.data.categories = {};
          }
          if (!element.data.count) {
            element.data.count = 0;
          }
          if (!element.data.avcount) {
            element.data.avcount = 0;
          }
          if (!element.data.subcount) {
            element.data.subcount = 0;
          }
          if (!element.data.unitprice) {
            element.data.unitprice = {};
          }

          if (Object.entries(elementData).length) {
            if (elementData.category) {
              element.data.categories[elementData.category] = true;
            } else if (Object.keys(elementData.categories).length) {
              Object.keys(elementData.categories).forEach(cat => {
                element.data.categories[cat] = true;
              });
            }
            if (typeof elementData.price !== 'undefined') {
              const floatPrice = parseFloat(elementData.price);

              if (elementData.unitprice && Object.keys(elementData.unitprice).length) {
                Object.keys(elementData.unitprice).forEach(up => {
                  element.data.unitprice[up] = true;
                });
              } else {
                element.data.unitprice[this.formatNumber(floatPrice)] = true;
              }
              element.data.price += floatPrice;
              element.data.priceString = this.formatNumber(element.data.price);
            }
            element.data.count ++;
            element.data.countString = this.formatNumber(element.data.count);
            if (elementData.count) {
              element.data.subcount += elementData.count;
              element.data.subcountString = this.formatNumber(element.data.subcount);
            }
            if (elementData.avcount) {
              element.data.avcount += elementData.avcount;
              element.data.avcountString = this.formatNumber(element.data.avcount);
            } else if (elementData.status && elementData.status === 'available') {
              element.data.avcount ++;
              element.data.avcountString = this.formatNumber(element.data.avcount);
            }
          }
        }
      }
    }
    if (Object.entries(element.data).length) {
      return element.data;
    }
  }

  public toggleChildrens(domid,event){
    document.getElementById("domid_"+domid).classList.toggle("closed");
    document.getElementById("tetilla_"+domid).classList.toggle("closed");
  }
  public closeChildrens(domid,event){
    document.getElementById("domid_"+domid).classList.add("closed");
    document.getElementById("tetilla_"+domid).classList.add("closed");
  }
  public toggleSectiondata(domid,event){
    this.closeChildrens(domid,event);
    document.getElementById("domid_parent_"+domid).classList.toggle("closed");
    document.getElementById("tetilla_parent_"+domid).classList.toggle("closed");
  }
  
  public formatNumber(price: number): string {
    return this.numberFormat.formatNumber(price);
  }

  public displayInfoTab(){
    this.displayInfo = !this.displayInfo;
    //console.log(this.displayInfo);
    if(this.displayInfo){
      this.processSelection(this.lastSelection);
    }
    return this.displayInfo;
  }
}
