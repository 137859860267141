import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PlatformService } from '../services/platform.service';
import { AuthenticationService } from './authentication.service';


@Injectable({
    providedIn: 'root'
})
export class AllowWorkspace implements CanActivateChild{
    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private platformService: PlatformService) {
    }
    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            return this.authService.getUserLogged().then(resp=>{
              let allowed = false;
              if(resp){
                // este if no escala - refactor v2: my venue workspace
                const p = resp.user.plugins.filter(plugin=>plugin.type === 'scv');
                if(!p.length){
                  this.router.navigate(['/home']);
                  allowed = false;
                }else{
                  allowed = true;
                }

                this.platformService.setConfigurationWorkspace(allowed);

              }
              return allowed
            });
        }
}