
<div id='topbardata' [ngClass]="{'simulation': isSimulation}" class="topbar-container asrow align-items-center">
    <div class='col-auto pl-0'>
        <h2>{{venueData?.name | titlecase}}</h2>
    </div>
    <!-- <div class='col-auto pl-0'>
        <span class='topbardata-item'>{{'setup.topbar.location' | translate}}: <b class='color-darkgray'>{{clientData?.client.city}}, {{clientData?.client.country}}</b></span>
    </div> -->
    <div class='col-auto pl-0'>
        <span *ngIf="!isSimulation; else simulation" class='topbardata-item'>{{'setup.topbar.capacity' | translate}}: <b class='color-darkgray'>{{formatNumber(venueData?.capacity)}}</b></span>
        <ng-template #simulation>
            <span class='topbardata-item'>{{'setup.topbar.capacity' | translate}}:  <span class='color-darkgray'>{{formatNumber(venueData?.capacity)}}</span> / <b class='color-blue'> {{formatNumber(availability)}}</b> <span class='color-blue'> ( {{(availabilityPercentage)}}% )</span></span>
        </ng-template>
    </div>
    <!-- <div class='col-auto'>
        <span class='topbardata-item'>{{'setup.topbar.currency' | translate}}: <b class='color-darkgray'>{{clientData?.client.currency | uppercase}}</b></span>
    </div>
    <div class='col-auto'>
        <span class='topbardata-item'>{{'setup.topbar.units' | translate}}: <b class='color-darkgray'>{{units | titlecase}}</b></span>
    </div> -->
            
    <div class='col-auto ml-auto p-0 text-right asrow align-items-center'>
        <div *ngIf="levels && levels.length" id='dropdown-wrap-levels' class="dropdown-wrap header-right-item mr-0" dropdown>
            <button id="button-levels" dropdownToggle type="button" class="btn btn-system dropdown-toggle"
                    aria-controls="dropdown-animated-levels">
                    <span id='currentlevel'>Levels</span> <span class="caret"></span>
            </button>
            <ul id="dropdown-animated-levels" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dropdown-animated-levels">
                <li role="menuitem" *ngFor="let level of levels; let i = index">
                    <a class="dropdown-item" href="javascript:;" (click)="loadMap(level['map_id'],level['name'])"
                    [ngClass]="{'active': isCurrentMap(level['map_id'])}">{{level['name']}}</a>
                </li>
            </ul>
        </div>
        <button *ngIf="isSimulation" type="button" class='ml-2 btn btn-system' (click)="onComputeResults()">SIMULATION RESULTS</button>
        <button type="button" class='ml-2 btn btn-system' (click)="onCategoriesPrices()">INFO</button>

    </div>
       
</div>     