<div>
    <div class='modal-header'>
        <h3>Share simulation</h3>
        <button type="button" class="btn-modal-header" (click)="onDismiss('cancel')" data-dismiss="modal">&times;</button>
    </div>
    
    <div class='modal-content'>
        <div id='btns-rows'>
            <div>
                <p class='guide'>Selecting 'yes', everybody in knowledge of the URL can access to the simulation as a viewers: Edition parameters, will not be accesible for them.</p>
                <div class='d-inline-flex'>
                    <span>Share:</span>
                </div>
                <div id='switcher-btns' class='d-inline-flex'>
                    <button (click)="disableSharing(!isSharingEnabled)" class='btn color-black border-gray'
                        [ngClass]="{'active': !isSharingEnabled}">
                        No
                    </button>
                    <button (click)="enableSharing(isSharingEnabled)" class='btn color-black border-gray'
                    [ngClass]="{'active': isSharingEnabled}">
                    Yes
                </button>
                </div>
            </div>
            <div *ngIf="isSharingEnabled">
                <div class='d-flex align-items-center justify-content-start'>
                    <span>Link:</span>
                    <input type="text" id="shareKeyInput" class='d-flex' (click)="newTab(shareUrl)" name="shareKeyInput" [ngModel]="shareUrl" readonly>
                </div>
            </div>
            <div *ngIf="!isSharingEnabled">
                <div class='d-flex align-items-center justify-content-start'>
                    <p class='color-blue'>Simulation accessible via login only.</p>
                </div>
            </div>
        </div>
        <div class='modal-footer'>
            <button id='cancelsimulation-btn' class='btn' type="button" (click)="onDismiss('success')">Close</button>
            <button *ngIf="isSharingEnabled" type="button" class="btn border-blue" title='Copy URL' (click)="copy()">
                Copy to clipboard
            </button>
        </div>
    </div>

</div>