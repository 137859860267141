import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormatNumberService } from "../../../../../services/format-number.service";
import { DataService } from "../../../../../services/data.service";
import { Form, NgForm } from "@angular/forms";
import { UploadbuttonComponent } from "../../../../../shared/buttons/uploadbutton/uploadbutton.component";
import { DVMService } from "../../../../../services/dvm.service";
import { AllocatorService } from "../../../services/allocator.service";
import { InventoryService } from "../../../../../services/inventory.service";
import { Router } from "@angular/router";
import { LaligaRepeatedError, LaligaParserService } from "../../../services/parsers/laliga-parser.service";
import { SocialdistancingService } from "../../../../../services/socialdistancing.service";
import { PlatformLocation } from "@angular/common";
import { MapViewer } from "@3ddv/dvm-internal";

interface Result {
  membersAssigned: number;
  membersLeft: number;
  computedPercentage: number;
}

// XXX_YY_AAASUBSCRIBER_DATA.csv
const subscriberFileNameExp =
  /^(?<clubId>\d{3})_(?<eventId>\d+)_(?<clubInitials>\w{3})SUBSCRIBER_DATA\.(?<fileExtension>csv$)/i;
// XXX_YY_AAAVENUE_DATA.csv
const venueDataFileNameExp =
  /^(?<clubId>\d{3})_(?<eventId>\d+)_(?<clubInitials>\w{3})VENUE_DATA\.(?<fileExtension>csv$)/i;

@Component({
  selector: "app-laliga-modal",
  templateUrl: "./laliga-modal.component.html",
  styleUrls: ["./laliga-modal.component.scss"],
})
export class LaligaModalComponent implements OnInit, OnDestroy {
  @ViewChild("uploadClientsCsv") clientsCsv!: UploadbuttonComponent;
  @ViewChild("uploadCategoriesCsv") categoriesCsv!: UploadbuttonComponent;

  @ViewChild("step1") modalStep1!: ElementRef<HTMLDivElement>;
  @ViewChild("step2") modalStep2!: ElementRef<HTMLDivElement>;
  @ViewChild("step3") modalStep3!: ElementRef<HTMLDivElement>;
  @ViewChild("stepError") modalStepError!: ElementRef<HTMLDivElement>;
  @ViewChild("stepErrorRepeated") modalStepErrorRepeated!: ElementRef<HTMLDivElement>;
  @ViewChild("repeatedFile") repeatedFile!: ElementRef<HTMLAnchorElement>;

  simulation;
  currentAvailability: number;
  currentVenue: string;
  configuration;
  aisleSeats = 0;
  socialDistancing = 1.5;

  maxClientsEnabled = false;
  upgradeOrDowngrade = true;
  maxClientsAllowed = 0;
  result: Result | undefined;

  toCurrent = true;

  errorMsg = "";
  error = false;
  errorFile: Blob | null = null;
  errorFileName: string | null = null;

  get venueName() {
    if (this.simulation) {
      return this.dataService.venueData[this.simulation.configuration.venueId].name;
    }
  }

  get availability() {
    return this.currentAvailability;
  }
  get availabilityPercentage() {
    if (this.simulation) {
      return ((this.simulation.capacity / this.simulation.configuration.capacity) * 100).toFixed(2);
    }
  }

  constructor(
    private bsModalRef: BsModalRef,
    private dataService: DataService,
    private numberFormatService: FormatNumberService,
    private dvmService: DVMService,
    private allocatorService: AllocatorService,
    private csvParserService: LaligaParserService,
    private inventoryService: InventoryService,
    private socialdistancingService: SocialdistancingService,
    private router: Router,
    private location: PlatformLocation
  ) {
    this.location.onPopState(() => {
      this.onClose();
    });
  }

  ngOnInit(): void {
    this.socialDistancing = this.socialdistancingService.securityDistance ?? 1.5;
    this.aisleSeats = this.socialdistancingService.isleSeats ?? 0;
  }

  ngOnDestroy() {}

  public onClose(): void {
    this.bsModalRef.hide();
  }

  public hasAllocation() {
    return this.simulation.allocation;
  }
  public formatNumber(price: number): string {
    return this.numberFormatService.formatNumber(price);
  }

  get units() {
    if (this.dataService.getUser()) {
      return this.dataService.getUnits(this.dataService.getUser().user.client.metric_system);
    }
  }

  public onSetDistancing(action: "minus" | "sum"): void {
    if (this.numberFormatService.getUnits() === "ft") {
      if (action === "minus" && this.socialDistancing > 0.5) {
        this.socialDistancing -= 0.0305;
      }
      if (action === "sum") {
        this.socialDistancing += 0.0305;
      }
    } else {
      if (action === "minus" && this.socialDistancing > 0.5) {
        this.socialDistancing -= 0.1;
      }
      if (action === "sum") {
        this.socialDistancing += 0.1;
      }
    }
  }

  public formatSocialDistancing(num: number): number {
    return Number(num.toFixed(2));
  }

  public onChangeAisleSeat(action: "minus" | "sum") {
    if (action === "minus" && this.aisleSeats > 0) {
      this.aisleSeats -= 1;
    }
    if (action === "sum" && this.aisleSeats < 4) {
      this.aisleSeats += 1;
    }
  }

  public onSubmit(form: NgForm): void {
    if (!this.isDisabled()) {
      this.hideError();

      const csvSubscribersFile = this.clientsCsv.getFile();
      const csvVenueDataFile = this.categoriesCsv.getFile();
      const viewer = this.dvmService.viewer;
      const checkUpdagreDowngrade: boolean = form.value.upgradeOrDowngradeCheckbox;

      if (csvSubscribersFile && csvVenueDataFile && viewer) {
        const subscriberResult = csvSubscribersFile.name.match(subscriberFileNameExp);
        const venueDataResult = csvVenueDataFile.name.match(venueDataFileNameExp);

        if (subscriberResult == null) {
          this.showError(`Subscribers file does not match name template: 'XXX_Y_AAASUBSCRIBER_DATA.csv'`);
          return;
        }

        if (venueDataResult == null) {
          this.showError(`Venue data file does not match name template: 'XXX_Y_AAAVENUE_DATA.csv'`);
          return;
        }

        const obj1 = subscriberResult.groups;
        const obj2 = subscriberResult.groups;

        const { clubId, eventId, clubInitials } = obj1;
        const { clubId: clubId2, eventId: eventId2, clubInitials: clubInitials2 } = obj2;

        if (clubId !== clubId2) {
          this.showError(` File names club ID do not match`);
          return;
        }

        if (eventId !== eventId2) {
          this.showError(` File names event ID do not match`);
          return;
        }

        if (clubInitials !== clubInitials2) {
          this.showError(` File names club initials do not match`);
          return;
        }

        const outputPrefix = `${clubId}_${eventId}_${clubInitials}`;

        this.showStep2();

        function readText(file: Blob, encoding?: string): Promise<string> {
          return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              if (typeof reader.result === "string") {
                resolve(reader.result);
              } else {
                reject(new Error("Cannot read file"));
              }
            };
            reader.onerror = () => reject(new Error("Cannot read file"));

            reader.readAsText(file, encoding);
          });
        }

        Promise.all([
          readText(csvSubscribersFile, "iso-8859-1"),
          readText(csvVenueDataFile, "iso-8859-1"),
          this.delay(100),
        ])
          .then((results) => {
            const [csvClients, csvCategories] = results;

            const maps = [];
            if (this.configuration.tier_list) {
              this.configuration.tier_list.forEach((level) => {
                maps.push(level.map_id);
              });
            }

            const p: Promise<any> = this.toCurrent
              ? this.assignToCurrent(
                  viewer,
                  csvClients,
                  csvCategories,
                  outputPrefix,
                  checkUpdagreDowngrade,
                  checkUpdagreDowngrade,
                  maps
                )
              : this.assign(
                  viewer,
                  csvClients,
                  csvCategories,
                  outputPrefix,
                  checkUpdagreDowngrade,
                  checkUpdagreDowngrade,
                  maps
                );

            // return Promise.all<any>([p, this.delay(1000)]);
            return p;
          })
          .then(() => {
            this.showStep3(false);
          })
          .catch((err) => {
            this.showError(err.message);
            if (err instanceof LaligaRepeatedError) {
              this.errorFile = err.file;
              this.errorFileName = err.filename;
              this.repeatedFile.nativeElement.href = URL.createObjectURL(this.errorFile);
              this.repeatedFile.nativeElement.download = this.errorFileName;
              this.repeatedFile.nativeElement.innerText = "Download repeated subscribers.";
            }
            this.allocatorService.clearAllocationFromSimulation().finally(() => {
              this.showStep3(true);
            });
          });
      }
    }
  }

  private showError(errorMessage: string) {
    // TODO
    this.errorMsg = errorMessage;
    this.error = true;
    console.error(errorMessage);
  }

  private hideError() {
    this.errorMsg = "";
    this.error = false;
    this.errorFile = null;
    this.errorFileName = null;
  }

  // private startAssign(viewer: MapViewer, csvSubscribers: string, csvVenueData: string, outputPrefix: string) {
  //   const maps = [];
  //   if (this.configuration.tier_list) {
  //     this.configuration.tier_list.forEach(level => {
  //       maps.push(level.map_id);
  //     });
  //   }
  //
  //   const p = this.toCurrent ?
  //     this.assignToCurrent(viewer, csvSubscribers, csvVenueData, outputPrefix, maps) :
  //     this.assign(viewer, csvSubscribers, csvVenueData, outputPrefix, maps);
  //
  //   return Promise.all<any>([p, this.delay(1000)])
  //     .then(() => {
  //       this.showStep3(false);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       this.allocatorService.clearAllocationFromSimulation()
  //         .finally(() => {
  //           this.showStep3(true);
  //         });
  //     });
  // }

  private assign(
    viewer: MapViewer,
    csvClients: string,
    csvCategories: string,
    outputPrefix: string,
    upgrade: boolean,
    downgrade: boolean,
    maps?: string[]
  ) {
    return this.csvParserService
      .fullAssign(
        viewer,
        csvClients,
        csvCategories,
        this.socialDistancing,
        this.aisleSeats,
        outputPrefix,
        upgrade,
        downgrade,
        maps
      )
      .then((result) => {
        const assignedClients =
          result.groups.length > 0 ? result.groups.map((g) => g.members.length).reduce((acc, curr) => acc + curr) : 0;
        const unassignedClients =
          result.unassigned.length > 0
            ? result.unassigned.map((g) => g.members.length).reduce((acc, curr) => acc + curr)
            : 0;
        const percentage = assignedClients ? (assignedClients / (assignedClients + unassignedClients)) * 100 : 0;
        this.result = {
          computedPercentage: percentage,
          membersLeft: unassignedClients,
          membersAssigned: assignedClients,
        };
        if (!percentage && !assignedClients && !unassignedClients) {
          return Promise.reject(new Error("Computation could not be done"));
        }
        return result;
      });
  }
  private assignToCurrent(
    viewer: MapViewer,
    csvClients: string,
    csvCategories: string,
    outputPrefix: string,
    upgrade: boolean,
    downgrade: boolean,
    maps?: string[]
  ) {
    const maxAssignedClients: number | undefined = this.maxClientsEnabled
      ? this.maxClientsAllowed ?? undefined
      : undefined;
    return this.csvParserService
      .fullAssignToCurrent(
        viewer,
        csvClients,
        csvCategories,
        outputPrefix,
        upgrade,
        downgrade,
        maps,
        maxAssignedClients
      )
      .then((result) => {
        let membersAssigned = 0;
        let membersLeft = 0;
        result.assigned.forEach((group) => (membersAssigned += group.assigned.length));
        result.unassigned.forEach((group) => (membersLeft += group.members.length));
        const percentage = (membersAssigned / (membersAssigned + membersLeft)) * 100;
        this.result = { computedPercentage: percentage, membersLeft, membersAssigned };
        if (!percentage && !result.unassigned.size) {
          this.result = { computedPercentage: 100, membersLeft: 0, membersAssigned: 0 };
        }
        return result;
      });
  }

  private delay(time: number): Promise<void> {
    return new Promise<void>((resolve) => setTimeout(resolve, time));
  }

  public isDisabled(): boolean {
    return (!this.clientsCsv?.hasFile() ?? true) || (!this.categoriesCsv?.hasFile() ?? true);
  }

  public onViewAllocation() {
    this.onClose();
    this.router.navigate(["/allocation/" + this.dataService.currentSimulationId]);
  }

  private showStep1() {
    this.modalStep1.nativeElement.classList.remove("d-none");
    this.modalStep2.nativeElement.classList.add("d-none");
    this.modalStep3.nativeElement.classList.add("d-none");
    this.modalStepError.nativeElement.classList.add("d-none");
    this.modalStepErrorRepeated.nativeElement.classList.add("d-none");
  }

  private showStep2() {
    this.modalStep1.nativeElement.classList.add("d-none");
    this.modalStep2.nativeElement.classList.remove("d-none");
    this.modalStep3.nativeElement.classList.add("d-none");
    this.modalStepError.nativeElement.classList.add("d-none");
    this.modalStepErrorRepeated.nativeElement.classList.add("d-none");
  }

  private showStep3(error: boolean) {
    this.modalStep1.nativeElement.classList.add("d-none");
    this.modalStep2.nativeElement.classList.add("d-none");
    if (!error) {
      this.modalStep3.nativeElement.classList.remove("d-none");
    } else {
      this.modalStep3.nativeElement.classList.add("d-none");
      if (this.errorFile) {
        this.modalStepErrorRepeated.nativeElement.classList.remove("d-none");
      } else {
        this.modalStepError.nativeElement.classList.remove("d-none");
      }
    }
  }
}
