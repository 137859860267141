import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/social-distance-editor/services/data.service';
import {AllocatorService} from '../../../services/allocator.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {TiersService} from "../../../../../services/tiers.service";
import {PlatformLocation} from "@angular/common";

@Component({
  selector: 'app-confirm-delete-allocation',
  templateUrl: './confirm-delete-allocation.component.html',
  styleUrls: ['./confirm-delete-allocation.component.scss']
})

export class ConfirmDeleteAllocationComponent implements OnInit {
  simulation;
  closeReason;
  constructor(private bsModalRef: BsModalRef,
              private dataService: DataService,
              private allocatorService: AllocatorService,
              private router: Router,
              private toastr: ToastrService,
              private location: PlatformLocation)  {
    this.location.onPopState(()=> {
      this.onDismiss('cancel');
    });
  }

  ngOnInit(): void { }

  public onDismiss(reason: 'success' | 'cancel'): void {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onDeleteAllocation(): void {
    this.allocatorService.clearAllocationFromSimulation().then(res=>{
      this.bsModalRef.hide();
      this.toastr.success('','Allocation deleted');
      this.router.navigate(['/simulation/'+this.simulation.id]);
    }).catch(err=>{
      this.toastr.error('',"Allocation not deleted, please try again.");
      this.bsModalRef.hide();
    });
  }

}
