import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { GroupsService } from 'src/app/social-distance-editor/services/groups.service';
import { Subscription } from 'rxjs';
import { CategoriesService } from 'src/app/social-distance-editor/services/categories.service';
import { SelectionService } from 'src/app/social-distance-editor/services/selection.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  @Input() page: 'setup' | 'simulation' | 'allocation';

  get isSimulation() {
    return this.page === 'simulation';
  }

  nodeType: 'section' | 'seat' = 'section';
  currentCategories: string | string[] = [];
  currentSelection = [];
  currentSelectionRows: number;
  currentCategoriesWithColors = [];

  get currentSelectionCount() {
    if (this.nodeType === 'section') {
      return this.currentSelection.length;
    } else if (this.nodeType === 'seat') {
      return this.currentSelectionRows;
    }
    return 0;
  }

  get groupsNumber() {
    const groupsHash = {};
    this.currentSelection.forEach(element => {
      if (this.groups.groupsHash.nodes[element.id]) {
        groupsHash[this.groups.groupsHash.nodes[element.id]] = true;
      }
    });
    return Object.keys(groupsHash).length;
  }

  get categoriesNumber() {
    return this.currentCategories.length;
  }

  get currentSelectionType() {
    if (this.nodeType === 'section') {
      return 'section';
    } else if (this.nodeType === 'seat') {
      return 'row';
    }
    return '';
  }

  constructor(private groups: GroupsService,
              public categories: CategoriesService,
              private selection: SelectionService
              ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.selection.nodeType.subscribe(
      type => {
        this.nodeType = type;
      }
    ));
    this.subscriptions.push(this.selection.selectionSubject.subscribe(
      selection => {
        const categoriesWeights = {
          regular: 1,
          vip: 2,
          disabled: 3,
          locked: 4
        };
        this.currentCategoriesWithColors = [];

        this.currentSelection = selection;
        if (this.nodeType === 'seat') {
          const rowsHash = {};
          selection.forEach(seat => {
            const split = seat.id.split('_')[1].split('-');
            const section = split[0];
            const row = split[1];
            rowsHash[section + row] = true;
          });
          this.currentSelectionRows = Object.keys(rowsHash).length;
        }
        const currentCategories = this.categories.getCurrentCategories(this.nodeType, selection);
        currentCategories.sort((a, b) => categoriesWeights[a] - categoriesWeights[b]);
        if (currentCategories) {
          const index = currentCategories.indexOf('none');
          if (index > -1) {
            currentCategories.splice(index, 1);
          }
          
          if (typeof currentCategories === 'string') {
            this.currentCategories = [currentCategories];
          } else {
            this.currentCategories = currentCategories;
          }
        } else {
          this.currentCategories = [];
        }

        for(let i =0;i<this.currentCategories.length;i++){
          const group = this.currentCategories[i];
          const color = this.categories.getCategoryColor(group)
          let cat = { group, color };
          this.currentCategoriesWithColors.push(cat);
        }

      }
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
