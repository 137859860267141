<div>
    <div class='modal-header'>
        <h3>{{'confirm-edit-multiseat.title' | translate}}</h3>
        <button type="button" class="btn-modal-header" (click)="onDismiss('cancel')" data-dismiss="modal">&times;</button>
    </div>
    
    <div class='modal-content' >
        <div *ngIf="!error else errortemplate">
            <p class='text-left'>
                You are about to lose the social distance simulation in the selected area.<br>
                Once you perform this action, it cannot be undone.
            </p>
            <br>
            <p class='text-left'>
                Your selection affects <b>{{affected_seats?.length}}</b> seats.<br>
                Their status will change to <b class='color-blue'>{{status_tochange}}</b>.
            </p>
        </div>
        <ng-template #errortemplate>
            <p *ngIf="affected_seats.length===1 else multiple">
                This seat belongs to a newest version of the map. Please create a new simulation.
            </p>
            <ng-template #multiple>
                These seats belongs to a newest version of the map. Please create a new simulation.
            </ng-template>
        </ng-template>
    </div>
    <div class='modal-footer'>
        <div *ngIf="!error else errortemplatebtn"> 
            <button (click)="onDismiss('cancel')" class='btn d-inline-block'>
                {{'confirm-edit-multiseat.cancel' | translate}}
            </button>
            <button (click)="onConfirm()" class='btn d-inline-block'>
                {{'confirm-edit-multiseat.confirm'  | translate}}
            </button>
        </div>
        <ng-template #errortemplatebtn>
            <button (click)="onDismiss('cancel')" class='btn d-inline-block'>
               OK
            </button>
        </ng-template>
    </div>
</div>