<div>
    <div class='modal-header'>
        <h3>{{"confirmDeleteConfigModal.title" | translate}}</h3>
        <button type="button" class="btn-modal-header" (click)="onDismiss('cancel')" data-dismiss="modal">&times;</button>
    </div>
    
    <div class='modal-content'>
        <p>
            Are you sure do you want to delete the configuration <b>{{configuration.name}}</b>?
        </p>
        
        <div *ngIf='configSims.length'>
            <p>
                <b>{{configSims.length}} {{"confirmDeleteConfigModal.associated" | translate}}</b> {{"confirmDeleteConfigModal.willberemoved" | translate}}</p>
<!--            
            <ul>
                <li *ngFor="let simulation of configSims">
                    {{simulation.name}}
                </li>
            </ul> -->
        </div>
    </div>
    <div class='modal-footer'>
        <button id='cancelsimulation-btn' class='btn' type="button" (click)="onDismiss('cancel')">Cancel</button>
        <button (click)="onConfirm()" class='btn btn-danger'>{{"confirmDeleteConfigModal.buttonConfirm" | translate}}</button>
    </div>    
</div>