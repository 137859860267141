import { Component, OnInit,HostListener,Inject  } from '@angular/core';
import { PublicVideosComponent } from '../social-distance-editor/shared/modals/public-videos/public-videos.component';
import { LoginModalComponent } from '../social-distance-editor/shared/modals/login-modal/login-modal.component';
import { ModalOptions, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { youtubeVideo } from '../social-distance-editor/shared/models/video.model';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from "@angular/router";
import { LaligaContactCauModalComponent } from '../social-distance-editor/shared/modals/laliga-contact-cau-modal/laliga-contact-cau-modal.component';


@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss','../header/header.component.scss']
})
export class DocumentationComponent implements OnInit {

  constructor(
    private modalService: BsModalService,
    private translate: TranslateService,
    private route : ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) { }
  bsModalRef: BsModalRef;
  ua = navigator.userAgent;

  versions = [
    {version: '2.0'},
    {version: '1.9'},{version: '1.8'},{version: '1.7'},{version: '1.6'},{version: '1.5'},
    {version: '1.4'},{version: '1.3'},{version: '1.2'},{version: '1.1'},{version: '1.0'}
  ];

  faqs = [
    {elementid:'faqs1',title: this.translate.instant('documentation.faqs.faqs1.title')},
    {elementid:'faqs2',title: this.translate.instant('documentation.faqs.faqs2.title')},
    {elementid:'faqs3',title: this.translate.instant('documentation.faqs.faqs3.title')},
    {elementid:'faqs4',title: this.translate.instant('documentation.faqs.faqs4.title')}
  ]
  //@HostListener("scroll", ['$event'])
  @HostListener('window:scroll', ['$event']) onWindowScroll(e){

    let offset= document.getElementById('faqs1').offsetTop+2700;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(this.ua)){
      offset = document.getElementById('faqs1').offsetTop+3500;
    }
    //on scroll set headers btns active
    if (document.documentElement.scrollTop < offset) {
      document.getElementById('faqsbtn').classList.remove('active');
      document.getElementById('changelogbtn').classList.add('active');
    }else{
      document.getElementById('faqsbtn').classList.add('active');
      document.getElementById('changelogbtn').classList.remove('active');
    }
  }

  ngAfterViewInit(): void{
    for(let i = 0;i<this.versions.length;i++){
      const element_id = "v"+this.versions[i].version.replace(".","-");
      this.versions[i]['elementId'] = element_id;
      this.versions[i]['offsetTop'] = document.getElementById(element_id).offsetTop;
    }

    let offset1 = 3000;
    let offset2 = 3350;
    let offset3 = 3700;
    let offset4 = 4200;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(this.ua)){
      offset1 = 3500;
      offset2 = 4400;
      offset3 = 5300;
      offset4 = 5500;
    }
    this.faqs[0]['offsetTop'] = document.getElementById('faqs1').offsetTop+offset1;
    this.faqs[1]['offsetTop'] = document.getElementById('faqs2').offsetTop+offset2;
    this.faqs[2]['offsetTop'] = document.getElementById('faqs3').offsetTop+offset3;
    this.faqs[3]['offsetTop'] = document.getElementById('faqs4').offsetTop+offset4;
  }

  client;
  ngOnInit(): void {
    this.client = this.route.snapshot.params.client;

    if(this.client && this.client.toLowerCase()==='laliga'){
      this.translate.use('es');
    }
  }

  public openVideo(url, title){
    const videoData : youtubeVideo ={
      raw : url,
      title : title
    }
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered modal-dialog-video',
      backdrop: false,
      ignoreBackdropClick: false,
      initialState: {
        video : videoData
      }
    };
    this.bsModalRef = this.modalService.show(PublicVideosComponent, modalConfig);
  }

  public onOpenLoginModal(): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered',
      backdrop: false,
      ignoreBackdropClick: false,
      initialState: {}
    };
    this.bsModalRef = this.modalService.show(LoginModalComponent, modalConfig);
  }

  public onOpenContactCau(): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered',
      backdrop: false,
      ignoreBackdropClick: false,
      initialState: {}
    };
    this.bsModalRef = this.modalService.show(LaligaContactCauModalComponent, modalConfig);
  }
}
