<div>
    <div class='modal-header'>
        <h3>Contacto</h3>
        <button type="button" class="btn-modal-header" (click)="onDismiss()" data-dismiss="modal">
            <span>&times;</span>
        </button>
    </div>
    
    <div class='modal-content'>
        <div class='form-wrapper'>
            <p>Puede solicitar más información y soporte técnico a través del portal CAU de LaLiga.
            </p>
            <p class='text-center'>
                <a target="_blank" href='http://soporteclubes.laliga.es'>soporteclubes.laliga.es</a><br>
            </p>
            <div>
                <div class='modal-footer'>
                    <button class='btn' type='button' (click)='onDismiss()'>OK</button>
                </div>
            </div>
        </div>
    </div>
</div>