<div id='newsimulation-modal' class='modal-wrap'>
    <div class='modal-header'>
        <h3>{{'computeResultModal.title' | translate}}</h3>
        <button type="button" class="btn-modal-header" (click)="onDismiss()" data-dismiss="modal">
            <span>&times;</span>
        </button>
    </div>
    <div class='modal-content'>
        <div id='loading-modal'>
            <div>
                <img src="../../../../assets/img/interface/loading.gif" alt="loading" height="50">
                <span class='d-block mt-4'>Calculating results...</span>
            </div>
        </div>
        <div id='step-content' class='d-none'>
            <div class='step'>
                <div class='row'>
                    
                    <div class='col-6 p-0'>
                        
                        <span class='data-content'>{{'computeResultModal.venue' | translate}}: <b>{{venueName}}</b></span>
                        <span class='data-content'>{{'computeResultModal.configuration' | translate}}: <b>{{simulation?.configuration?.name}}</b></span>
                        <span class='data-content'>{{'computeResultModal.simulationname' | translate}}: <b class='color-blue'>{{simulation?.name}}</b></span>

                    </div>
                    <div class='col-6 p-0'>
                        <span class='data-content'>{{'computeResultModal.venuecapacity' | translate}}: <b>{{formatNumber(simulation?.configuration.capacity)}}</b></span>
                        <span class='data-content'>
                            {{'computeResultModal.configseats' | translate}}: <b style='margin-right:10px;'>{{formatNumber(simulation?.configuration.capacity-nlocked)}}</b><small>({{ formatNumber(nlocked) }} locked seats)</small>
                        </span>
                        <span class='data-content a' *ngIf="!customGroups">
                            {{'computeResultModal.simresult' | translate}}: 
                            <b class='color-blue'>{{formatNumber(simulation?.capacity)}} </b>
                        </span>
                        <span class='data-content a' *ngIf="!customGroups">
                            <p class='data-content p-0'>Availability percentage of :</p>
                            <ul class='m-0'>
                                <li><span>Venue capacity:</span><b class='color-blue'>{{availability}}%</b></li>
                                <li><span>Config. capacity:</span><b class='color-blue'>{{configAvailabilityPercent}}%</b></li>
                            </ul> 
                        </span>
                        <span class='data-content' *ngIf="customGroups">
                            {{'computeResultModal.simresult' | translate}}: 
                            <b class='color-blue'>{{formatNumber(totalCustomSeats)}} </b>
                        </span>
                        <span class='data-content a' *ngIf="customGroups">
                            <p class='data-content p-0'>Availability percentage of :</p>
                            <ul class='m-0'>
                                <li><span>Venue capacity:</span><b class='color-blue'>{{customAvailabilityPercentage}}%</b></li>
                                <li><span>Config. capacity:</span><b class='color-blue'>{{customConfigAvailabilityPercentage}}%</b></li>
                            </ul> 
                        </span>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-auto p-0'>
                        <label><b>{{'computeResultModal.chooseSocialDistancing' | translate}}</b></label>
                    </div>
                    <div class='col-auto p-0'>
                        <div class='sd-btns text-left'>
                            <span class='sd-value d-inline-block'>{{formatSocialDistancing(simulation?.social_distancing)}}</span>
                            <span class='sd-unit d-inline-block'>{{units}}</span>
                        </div>
                        
                    </div>
                </div>
                <div class='row'>
                    <div class='col-6 p-0'>
                        <div class='asrow'>
                            <div class='col-auto p-0'>
                                <label><b>{{'computeResultModal.aisle_note' | translate}}</b></label>
                            </div>
                            <div class='col-auto p-0 opt-col'>
                                <div class='sd-btns text-left'>
                                    <span class='sd-value d-inline-block'>{{simulation?.isle_seats}}</span>
                                    <span class='sd-unit d-inline-block'>{{'computeResultModal.seats' | translate}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='col-6 p-0 opt-col' *ngIf='totalAisleSeats'>
                        <span class="data-content">{{'computeResultModal.totalaisleseats' | translate}}: <b class="color-blue">{{formatNumber(totalAisleSeats)}} </b></span>
                    </div>

                </div>
                <div *ngIf='!customGroups' class='row'>
                    <div class='col-12 p-0'>
                        <label><b>{{'computeResultModal.seatgroups' | translate}}</b></label>
                    </div>
                    <div class='parentgroupoption-col asrow p-0'>
                        <!-- HALF 1 -->
                        <div *ngIf="paginatedSimulationParameters[0] && paginatedSimulationParameters[0].length" class='group-options col-6 pl-0'>
                            <table>
                                <thead>
                                    <tr>
                                        <th class='text-left'></th>
                                        <th class='text-center'>{{'computeResultModal.desired' | translate}}</th>
                                        <th class='text-center'>{{'computeResultModal.computed_percentage' | translate}}</th>
                                        <th class='text-right'>{{'computeResultModal.availableseats' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let option of paginatedSimulationParameters[0]; let i = index">
                                        <td><span class='opt-lab'>{{'computeResultModal.optionparam.'+option.parameter.name | translate}}</span></td>
                                        <td class='text-center'>{{(option.percentage * 100).toFixed(0)}}</td>
                                        <td class='text-center'>{{(option.computed_percentage * 100).toFixed(2)}}</td>
                                        <td class='text-right'>
                                            <span class='color-blue' *ngIf="option.computed_nseats else nocomputed_nseats">
                                                <!-- <b>{{formatNumber(option.computed_nseats)}} </b> <small>({{(option.computed_percentage * 100).toFixed(2)}}%)</small> -->
                                                <b>{{formatNumber(option.computed_nseats)}} </b> <small>({{(option.nseats_percentage * 100).toFixed(2)}}%)</small>
                                            </span>
                                            <ng-template #nocomputed_nseats>
                                                <span class='color-blue'><b>0</b></span>
                                            </ng-template>
                                        </td>
<!--                                         <td class='text-right'>
                                            <span>{{(option.percentage_config * 100).toFixed(2)}}%</span>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- HALF 2 -->
                        <div *ngIf="paginatedSimulationParameters[1] && paginatedSimulationParameters[1].length" class='group-options col-6 pr-0'>
                            <table>
                                <thead>
                                    <tr>
                                        <th class='text-left'></th>
                                        <th class='text-center'>{{'computeResultModal.desired' | translate}}</th>
                                        <th class='text-center'>{{'computeResultModal.computed_percentage' | translate}}</th>
                                        <th class='text-right'>{{'computeResultModal.availableseats' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let option of paginatedSimulationParameters[1]; let i = index">
                                        <td><span class='opt-lab'>{{'computeResultModal.optionparam.'+option.parameter.name | translate}}</span></td>
                                        <td class='text-center'>{{(option.percentage * 100).toFixed(0)}}</td>
                                        <td class='text-center'>{{(option.computed_percentage * 100).toFixed(2)}}</td>
                                        <td class='text-right'>
                                            <span class='color-blue' *ngIf="option.computed_nseats else nocomputed_nseats">
                                                <!-- <b>{{formatNumber(option.computed_nseats)}} </b> <small>({{(option.computed_percentage * 100).toFixed(2)}}%)</small> -->
                                                <b>{{formatNumber(option.computed_nseats)}} </b> <small>({{(option.nseats_percentage * 100).toFixed(2)}}%)</small>
                                            </span>
                                            <ng-template #nocomputed_nseats>
                                                <span class='color-blue'><b>0</b></span>
                                            </ng-template>
                                        </td>
<!--                                         <td class='text-right'>
                                            <span>{{(option.percentage_config * 100).toFixed(2)}}%</span>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class='row' *ngIf='customGroups'>
                    <div class='col-12 p-0'>
                        <label><b>{{'computeResultModal.groupcount' | translate}}</b></label>
                    </div>
                    <!-- HALF 1 -->
                    <div class='parentgroupoption-col asrow p-0'>
                        <div class='group-options col-6 pl-0'>
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class='text-center'>{{'computeResultModal.ngroups' | translate}}</th>
                                        <th class='text-right'>{{'computeResultModal.availableseats' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor='let group of paginatedCustomParameters[0]'>
                                        <td class='text-left'>
                                            <span class='opt-lab'>{{group.name}}</span></td>
                                        <td class='text-center'>
                                            <span>{{group.ngroups}}</span>
                                        </td>
                                        <td class='text-right'>
                                            <span class='color-blue'><b>{{group.available_seats}} </b> <small>({{group.percentage}}%)</small></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- HALF 2 -->
                        <div class='group-options col-6 pr-0' *ngIf="paginatedCustomParameters[1] && paginatedCustomParameters[1].length">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class='text-center'>{{'computeResultModal.ngroups' | translate}}</th>
                                        <th class='text-right'>{{'computeResultModal.availableseats' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor='let group of paginatedCustomParameters[1]'>
                                        <td class='text-left'>
                                            <span class='opt-lab'>{{group.name}}</span></td>
                                        <td class='text-center'>
                                            <span>{{group.ngroups}}</span>
                                        </td>
                                        <td class='text-right'>
                                            <span class='color-blue'><b>{{group.available_seats}} </b> <small>({{group.percentage}}%)</small></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class='row' *ngIf='simulation?.limit2groups'>
                    <div class='col p-0'>
                        <b>{{'computeResultModal.confirmlimit2groups' | translate}}</b>
                    </div>
                </div>
            </div>
            <div class='modal-footer'>
                <button id='cancelsimulation-btn' class='btn' type="button" (click)="onDismiss()">{{'computeResultModal.buttonClose' | translate}}</button>
                <button class='btn' type="button" (click)="closeToEdit()" *ngIf="!page">{{'computeResultModal.buttonEdit' | translate}}</button>
            </div>
        </div>
    </div>
</div>