
<div id='newsimulation-modal' class='modal-wrap newconfigmodal'>
    <div class='modal-header'>
        <h3>{{'createConfigModal.title' | translate}}</h3>
        <button type="button" class="btn-modal-header" (click)="onClose('cancel')" data-dismiss="modal">
            <span>&times;</span>
        </button>
    </div>
    <div class='modal-content'>
        <form #formSimulation="ngForm" (ngSubmit)="onSubmit(formSimulation)" (keydown.enter)="$event.preventDefault()">
            <div class='step step-title'>
                <div class='row'>
                    <div class='col text-center p-0'>
                        <span class='data-content'>{{'createConfigModal.venue' | translate}}: <b>{{currentVenue.name}}</b></span>
                        <span class='data-content'>{{'createConfigModal.venuecapacity' | translate}}: <b>{{venuecapacity}}</b></span>
                    </div>
                </div>
            </div>
            <div class='step'>
                <div class='row align-items-center'>
                    <div class='col pl-0'>
                        <span class='data-content'>1. Choose configuration to copy</span>
                    </div>
                    <div class='col pr-0'>
                        <div id='configs-dropdown' class='ascol'>
                            <div class="dropdown-wrap" dropdown>
                                <button dropdownToggle type="button" class="btn-dropdown dropdown-toggle" aria-controls="dropdown-animated-configs">
                                    <span *ngIf="configSelectedKey == null else configNameTemplate">SELECT CONFIGURATION</span>
                                    <ng-template #configNameTemplate>
                                        <span>{{configSelectedName | uppercase}}</span>
                                    </ng-template>
                                </button>
                                <ul id="dropdown-animated-configs" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dropdown-animated-configs">
                                    <li (click)="setConfigSelected(config.key)" class='dropdown-item' role="menuitem" *ngFor="let config of configurations | keyvalue">
                                        <span>{{config.value?.name}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div class='row align-items-center'>
                    <div class='col pl-0'>
                        <label class='data-content m-0' for="newconfigname">2. Enter configuration name</label>
                    </div>
                    <div class='col pr-0'>
                        <input type="text" id="newconfigname" (keyup)="disableConfirmbtn()" name="newconfigname" [(ngModel)]="newconfigname">
                    </div>
                </div>
                <div class='row align-items-center' *ngIf="hasPlugin('customcategories')">
                    <div class='col pl-0'>
                        <label class='data-content m-0'>3. Importar fichero VenueData</label>
                    </div>
                    <div class='col pr-0'>
                        <app-uploadbutton #uploadVenueData [disabled]="false" name="Subir fichero"></app-uploadbutton>
                    </div>
                </div>
            </div>
            <div class='modal-footer'>
                <div *ngIf="cloningconfig else defaultsubmit" class='d-flex align-items-center'>
                    <i class="fa fa-spinner fa-spin fa-2x fa-fw"></i> Processing data ... 
                    <span class="sr-only">Loading...</span>
                </div>
                <ng-template #defaultsubmit>
                    <button id='cancelsimulation-btn' class='btn' type="button" (click)="onClose('cancel')">{{'createConfigModal.buttonCancel' | translate}}</button>
                    <button id='newsimulation-btn' class='btn' [disabled]="disableConfirmbtn()" type="submit">{{'createConfigModal.buttonConfirm' | translate}}</button>
                </ng-template>
            </div>
        </form>
    </div>
</div>
