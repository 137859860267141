<div id='currentselection-column' class='asrow justify-content-center' [ngClass]="{'currentselection-column-simulation': isSimulation}">
    <div class='ascol'>
        <span class='title-selection text-uppercase'>
            {{'setup.footer.currentselection' | translate}}: 
        </span>
    </div>
    <div *ngIf="!currentSelectionCount" class='ascol'>

        <span class='value-selection'>0</span>
        <span class='label-selection'>
            {{'setup.footer.'+currentSelectionType | translate}}s, 
        </span>
    </div>
    <div *ngIf="currentSelectionCount" class='ascol'>
        <span class='value-selection'>{{currentSelectionCount}}</span>
        <span class='label-selection' *ngIf="currentSelectionCount>1; else elementsingle">
                {{'setup.footer.'+currentSelectionType | translate}}s, 
        </span>
        <ng-template #elementsingle>
            <span class='value-selection'>
                {{'setup.footer.'+currentSelectionType | translate}}, 
            </span>
        </ng-template>
    </div>
    <div *ngIf="!groupsNumber && !isSimulation" class='ascol'>
        <div class='ngroup-selected'>
            <span class='value-selection'>0</span>
            <span class='label-selection'>{{'setup.footer.groups' | translate}}s, </span>
        </div>
    </div>
    <div *ngIf="groupsNumber && !isSimulation" class='ascol'>
        <span>
            <span class='value-selection'>{{groupsNumber}}</span>
            <span class='label-selection' *ngIf="groupsNumber>1; else groupsingle">
                {{'setup.footer.groups' | translate}}s, 
            </span>
        </span>
        <ng-template #groupsingle>
            <span class='label-selection'>{{'setup.footer.group' | translate}}, </span>
        </ng-template>
    </div>
    <div *ngIf="!categoriesNumber" class='ascol'>
        <div class='selection-section'>
            <span class='value-selection'>0</span>
            <span class='label-selection'>{{'setup.footer.categories' | translate}}</span>
        </div>
    </div>
    <div *ngIf="currentSelection.length;" class='ascol'>
        <div class='selection-section'>
            <div *ngIf="categoriesNumber>0">
                <span class='mr-10'>
                    <span class='value-selection'>{{categoriesNumber}}</span>
                    <span class='label-selection' *ngIf="categoriesNumber == 1; else categoriesmultiple">
                        {{'setup.footer.category' | translate}}: 
                    </span>
                </span>
                <ng-template #categoriesmultiple>
                    <span class='label-selection'>
                        {{'setup.footer.categories' | translate}}
                    </span>
                </ng-template>
                <a *ngFor="let category of currentCategories" href='javascript:;' class='removelink icon-container-crop' title='{{category}}'>
                    <!--
                        No borrar clase icon.
                        Clases de iconos posibles: general - vip - locked - disabled
                    -->
                    <div class='icon' [ngClass]="category"></div>
                </a>
            </div>
        </div>
    </div>
</div>