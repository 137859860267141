<div>
    <div class='modal-header'>
        <h3>{{"confirmLogoutModal.title" | translate}}</h3>
        <button type="button" class="btn-modal-header" (click)="onDismiss('cancel')" data-dismiss="modal">&times;</button>
    </div>
    
    <div class='modal-content'>
        <p>
            {{"confirmLogoutModal.body" | translate}}
        </p>
    </div>
    <div class='modal-footer'>
        <button (click)="onDismiss('cancel')" class='btn d-inline-block'>{{"confirmLogoutModal.buttonClose" | translate}}</button>
        <button (click)="onConfirm()" class='btn d-inline-block'>{{"confirmLogoutModal.buttonConfirm" | translate}}</button>
    </div>
</div>