import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/auth';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { DataService } from 'src/app/social-distance-editor/services/data.service';


@Component({
  selector: 'app-confirm-logout-modal',
  templateUrl: './confirm-logout-modal.component.html',
  styleUrls: ['./confirm-logout-modal.component.scss']
})

export class ConfirmLogoutModalComponent implements OnInit {

  clientData;
  closeReason: 'success' | 'cancel';

  constructor(private authService: AuthenticationService,
              private bsModalRef: BsModalRef,
              private router: Router,
              private dataService: DataService) { }

  ngOnInit(): void {
  }

  public onConfirm(): void {
    const s = this.authService.logout().subscribe(
      response => {
        s.unsubscribe();
        if (localStorage.getItem('token') !== null) {
          localStorage.removeItem('token');
        }
        this.onDismiss('success');
      },
      error => {
        this.onDismiss('cancel');
        console.log(error);
      }
    );
  }

  public onDismiss(reason: 'success' | 'cancel'): void {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

}
