<div>
    <div class='modal-header'>
        <h3>Issues encountered</h3>
        <button type="button" class="btn-modal-header" (click)="onDismiss()" data-dismiss="modal">
            <span>&times;</span>
        </button>
    </div>
    
    <div class='modal-content'>
        <p>{{errorMessage}}</p>
    </div>
</div>