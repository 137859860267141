<div>
    <div class='modal-header'>
        <h3>{{'changePasswordModal.title' | translate}}</h3>
        <button type="button" class="btn-modal-header" (click)="onDismiss()" data-dismiss="modal">
            <span>&times;</span>
        </button>
    </div>
    
    <div class='modal-content'>

        <div id='passwordform'>
            <form>
                <div class='passwordform-item'>
                    <label><b>{{'changePasswordModal.currentpass' | translate}}</b></label>
                    <input type='password' name='current' [(ngModel)]="_passdata.current">
                </div>
                <div *ngIf='_passdata.errorcurrent'>
                    <div class='error-alert'>{{_passdata.errorcurrent}}</div>
                </div>
                <hr>
                <div class='passwordform-item'>
                    <label><b>{{'changePasswordModal.newpass' | translate}}</b></label>
                    <input type='password' name='new' [(ngModel)]="_passdata.password">
                </div>
                <div class='passwordform-item last-item'>
                    <label><b>{{'changePasswordModal.repeatpass' | translate}}</b></label>
                    <input type='password' name='repeat' [(ngModel)]="_passdata.repeatpass">
                </div>
                <div *ngIf='_passdata.errorrepeat'>
                    <div class='error-alert'>{{_passdata.errorrepeat}}</div>
                </div>
                <div class='modal-footer'>
                    <button type='button' (click)='onDismiss()' class='btn'>Cancel</button>
                    <button id='btnconfirm' type='submit' (click)='onSubmit()' class='btn'>Confirm</button>
                </div>
            </form>
        </div>
    </div>
</div>