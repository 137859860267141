import { Injectable } from "@angular/core";
import { InventoryService } from "../../../../services/inventory.service";
import { AllocatorService } from "../allocator.service";
import { DataService } from "../../../../services/data.service";
import JSZip from "jszip";
import { parseCSVToJSON } from "../../utils/parse";
import { SocialdistancingService } from "../../../../services/socialdistancing.service";
import {
  MapViewer,
  SocialDistancingAssignGroup,
  SocialDistancingAssignGroupResult,
  SocialDistancingAssignToCurrentResult,
  SocialDistancingGetGroupsInput,
} from "@3ddv/dvm-internal";

/* GENERIC */
export interface CsvParserGetGroupsInput {
  csvClients: string;
  csvCategories: string;
  upgrade: boolean;
  downgrade: boolean;
}
export interface CsvParserGetGroupsOutput {
  groups: SocialDistancingAssignGroup[];
}

export interface CsvParser {
  getGroups(input: CsvParserGetGroupsInput): CsvParserGetGroupsOutput;
}

/* SPORTS ALLIANCE */
interface CsvJson {
  [key: string]: string;
}

interface HashAreas {
  [key: string]: string[];
}

export interface ParsedCsvs {
  groups: SocialDistancingAssignGroup[];
  hashAreas: HashAreas;
  sortedAreas: SortedAreas;
}

export interface SportsAllianceClientsRow {
  ClusterID: string; // ClusterID - in popover (group ID)
  UniqueID: string; // UniqueID - in popover (client ID)
  CustomerID: string; // CustomerID
  Category: string; // Category
  Priority: string;
  SecondaryCategory?: string;
}

export interface SportsAllianceAssignedClientsRow extends SportsAllianceClientsRow {
  "Assigned Area": string;
  "Assigned Row": string;
  "Assigned Seat": string;
  fullId: string;
}

interface SportsAllianceAreasRow {
  SectionName: string;
  Category: string;
  Section: string;
  SecondaryCategory?: string;
}

interface ParseOutput {
  groups: SocialDistancingAssignGroup[];
  parsedClients: SportsAllianceClientsRow[];
  sortedAreas: SortedAreas;
}

type SortedAreas = string[];

interface CsvParserSportsAllianceGetGroupsOutput extends CsvParserGetGroupsOutput {
  parsedClients: SportsAllianceClientsRow[];
}

@Injectable({
  providedIn: "root",
})
export class SportsAllianceParserService {
  constructor(
    private inventoryService: InventoryService,
    private allocatorService: AllocatorService,
    private dataService: DataService,
    private socialdistancingService: SocialdistancingService
  ) {}

  public full(
    viewer: MapViewer,
    csvClients: string,
    csvCategories: string,
    upgrade: boolean,
    downgrade: boolean,
    maps?: string[]
  ): Promise<SocialDistancingAssignToCurrentResult> {
    const obj: CsvParserGetGroupsInput = {
      csvClients,
      csvCategories,
      upgrade,
      downgrade,
    };
    const { groups, parsedClients } = this.getGroups(obj);

    const getGroupsInput: SocialDistancingGetGroupsInput = {
      neighbor_distance: this.socialdistancingService.neighborDistance,
    };

    return this.allocatorService.assignGroupsToCurrent(viewer, { groups, maps }).then((result) => {
      const filledClients = this.fillClientRows(parsedClients, result.assigned);
      const bySeatId = this.getRowsBySeat(filledClients);

      const [csvResultAssigned, csvResultUnassigned] = this.parseJSONToCSV(filledClients);

      const inventory = this.inventoryService.getInventoryData();
      this.allocatorService.clearInventoryAllocation(inventory);

      return this.fillInventoryAllocation(inventory, bySeatId).then(() => {
        const zip = new JSZip();
        const blob1 = new Blob([csvResultAssigned], { type: "text/csv" });
        const blob2 = new Blob([csvResultUnassigned], { type: "text/csv" });
        zip.file("assigned.csv", blob1);
        zip.file("unassigned.csv", blob2);

        return zip
          .generateAsync({ type: "blob", mimeType: "application/zip" })
          .then((zipFile) => {
            this.allocatorService.resultFile = zipFile;
            return this.dataService
              .sendAllocationResult(this.dataService.currentSimulationId, this.allocatorService.ref, zipFile)
              .toPromise();
          })
          .then(() => {
            return result;
          });
      });
    });
  }

  public getGroups(input: CsvParserGetGroupsInput): CsvParserSportsAllianceGetGroupsOutput {
    const { csvClients, csvCategories, upgrade, downgrade } = input;
    const parsedClients = parseCSVToJSON<SportsAllianceClientsRow>(csvClients);
    const { groups } = this.parseCSVs(parsedClients, csvCategories, upgrade, downgrade);
    return { groups, parsedClients };
  }

  public getRowsBySeat(
    parsedClients: Iterable<SportsAllianceAssignedClientsRow>
  ): Map<string, SportsAllianceAssignedClientsRow> {
    const map = new Map<string, SportsAllianceAssignedClientsRow>();
    for (const client of parsedClients) {
      const seatId = `S_${client["Assigned Area"]}-${client["Assigned Row"]}-${client["Assigned Seat"]}`;
      map.set(seatId, client);
    }
    return map;
  }

  public getRowsByClient(
    parsedClients: Iterable<SportsAllianceAssignedClientsRow>
  ): Map<string, SportsAllianceAssignedClientsRow> {
    const map = new Map<string, SportsAllianceAssignedClientsRow>();
    for (const client of parsedClients) {
      map.set(client.UniqueID, client);
    }
    return map;
  }

  public fillClientRows(
    parsedClients: Iterable<SportsAllianceClientsRow>,
    groups: Iterable<SocialDistancingAssignGroupResult>
  ): SportsAllianceAssignedClientsRow[] {
    // Primero, creamos los grupos por cliente
    const clientsStruct = new Map<string, SocialDistancingAssignGroupResult>();
    const seatsStruct = new Map<string, SocialDistancingAssignGroupResult>();
    const clientsMap = new Map<string, string>();

    for (const group of groups) {
      if (group.assigned.length) {
        for (let i = 0; i < group.assigned.length; ++i) {
          const seatId = group.assigned[i];
          const clientId = group.members[i];
          seatsStruct.set(seatId, group);
          clientsStruct.set(clientId, group);
          clientsMap.set(clientId, seatId);
        }
      }
    }

    const assignedRows: SportsAllianceAssignedClientsRow[] = [];
    // Segundo, añadimos los datos a las rows
    for (const row of parsedClients) {
      const { ClusterID, UniqueID, CustomerID, Category, Priority } = row;
      const group = clientsStruct.get(UniqueID);
      const seatId = clientsMap.get(UniqueID);
      const obj: SportsAllianceAssignedClientsRow = {
        ClusterID,
        UniqueID,
        CustomerID,
        Category,
        Priority,
        "Assigned Area": "",
        "Assigned Row": "",
        "Assigned Seat": "",
        fullId: "",
      };

      if (seatId) {
        const split = seatId.split("-");
        const partialSectionId = split[0].split("S_")[1];
        const partialRowId = split[1];
        const partialSeatId = split[2];
        obj.fullId = seatId;
        obj["Assigned Area"] = partialSectionId;
        obj["Assigned Row"] = partialRowId;
        obj["Assigned Seat"] = partialSeatId;
      }
      assignedRows.push(obj);
    }

    return assignedRows.sort((a, b) => parseInt(a.Priority, 10) - parseInt(b.Priority, 10));
  }

  public parseJSONToCSV(assignedRows: SportsAllianceAssignedClientsRow[]): [string, string] {
    assignedRows.sort((r1, r2) => parseInt(r1.Priority, 10) - parseInt(r2.Priority, 10));

    const outputAssigned: string[][] = [
      ["ClusterID", "UniqueID", "CustomerID", "Category", "Priority", "Assigned Area", "Assigned Row", "Assigned Seat"],
    ];
    const outputUnassigned: string[][] = [
      ["ClusterID", "UniqueID", "CustomerID", "Category", "Priority", "Assigned Area", "Assigned Row", "Assigned Seat"],
    ];

    for (const row of assignedRows) {
      const areaId = row["Assigned Area"];
      const rowId = row["Assigned Row"];
      const seatId = row["Assigned Seat"];
      const outut = areaId && rowId && seatId ? outputAssigned : outputUnassigned;
      outut.push([row.ClusterID, row.UniqueID, row.CustomerID, row.Category, row.Priority, areaId, rowId, seatId]);
    }

    return [
      outputAssigned.map((arr) => arr.join(";")).join("\n"),
      outputUnassigned.map((arr) => arr.join(";")).join("\n"),
    ];
  }

  private parseCSVs(
    parsedClients: SportsAllianceClientsRow[],
    inputAreas: string,
    upgrade: boolean,
    downgrade: boolean
  ): ParsedCsvs {
    const { hash: parsedAreas, sorted: sortedAreas } = this.parseCSVAreas(inputAreas);

    const hash: { [key: string]: SocialDistancingAssignGroup } = {};
    for (const row of parsedClients) {
      const groupId: string = row.ClusterID;
      const clientId: string = row.UniqueID;
      const preferredArea: string = row.Category;
      const secondaryArea: string | null = row.SecondaryCategory || null;
      const priority: number = parseInt(row.Priority, 10);

      if (groupId == null || clientId == null) {
        continue;
      }

      if (hash[groupId] == null) {
        const sdmGroupInput: SocialDistancingAssignGroup = hash[groupId] || {
          group_id: groupId,
          priority,
          members: [],
          preferred: this.parsePreferredAreas(preferredArea, secondaryArea, parsedAreas, upgrade, downgrade),
        };
        hash[groupId] = sdmGroupInput;
      }
      const obj = hash[groupId] as SocialDistancingAssignGroup;
      obj.members.push(clientId);
    }

    return {
      groups: Object.values(hash).sort((a, b) => a.priority - b.priority),
      hashAreas: parsedAreas,
      sortedAreas,
    };
  }

  private parseCSVAreas(input: string): { hash: HashAreas; sorted: string[] } {
    const parsed = parseCSVToJSON<SportsAllianceAreasRow>(input);
    const hash: HashAreas = {};
    const sorted: string[] = [];
    for (const row of parsed) {
      const SectionName = row.SectionName || row.Section;
      const Category = row.Category;
      const SecondaryCategory = row.SecondaryCategory || null;
      const sectionId = "S_" + SectionName;

      if (hash[Category] == null) {
        hash[Category] = [];
      }
      hash[Category].push(sectionId);

      if (hash[SecondaryCategory] == null) {
        hash[SecondaryCategory] = [];
      }
      hash[SecondaryCategory].push(sectionId);

      sorted.push(sectionId);
    }
    return { hash, sorted };
  }

  private parsePreferredAreas(
    category1: string,
    category2: string | null,
    areas: HashAreas,
    upgrade: boolean,
    downgrade: boolean
  ): string[] {
    const set = new Set(areas[category1]);

    if (category2) {
      areas[category2]?.forEach((n) => set.add(n));
    }

    if (downgrade) {
      const keys = Object.keys(areas).sort((a, b) => parseInt(a, 10) - parseInt(b, 10));
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < keys.length; ++i) {
        const cat = keys[i];
        if (cat > category1) {
          areas[cat].forEach((n) => set.add(n));
        }
      }
    }

    if (upgrade) {
      const keys = Object.keys(areas).sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < keys.length; ++i) {
        const cat = keys[i];
        if (cat < category1) {
          areas[cat].forEach((n) => set.add(n));
        }
      }
    }

    return Array.from(set);
  }

  protected fillInventoryAllocation(inventory, bySeatId: Map<string, SportsAllianceAssignedClientsRow>) {
    for (const [fullId, row] of bySeatId) {
      const sectionId = row["Assigned Area"];
      const rowId = row["Assigned Row"];
      const seatId = row["Assigned Seat"];
      if (sectionId && rowId && seatId) {
        const data = inventory[sectionId]?.elements[rowId]?.elements[seatId]?.data;
        if (data) {
          data.allocation = Object.assign({}, row);
          data.allocation.allocated = true;
          data.allocation.popup = {
            "Group ID": row.ClusterID,
            "Client ID": row.UniqueID,
          };
        }
      }
    }

    return this.allocatorService.setSimulationAsAllocation(inventory);
  }
}

// *** Test funcs ***

function countGroups(hash: { [p: string]: SocialDistancingAssignGroup }) {
  let total = 0;
  const countHash: any = {};
  const percentHash: any = {};
  for (const i in hash) {
    if (hash.hasOwnProperty(i)) {
      const group = hash[i];
      total++;
      countHash[group.members.length] != null
        ? countHash[group.members.length]++
        : (countHash[group.members.length] = 1);
    }
  }
  for (const i in countHash) {
    if (countHash.hasOwnProperty(i)) {
      percentHash[i] = ((countHash[i] / total) * 100).toFixed(2) + "%";
    }
  }

  return [countHash, percentHash];
}

function generateGroups(maxGroups: number, groupsProbability: { [key: number]: number }) {
  const entries = Object.entries(groupsProbability);
  // const sum = Object.values(porcentajes).reduce((acc, curr) => acc + curr);
  let nextGroup = 1;
  let nextClient = 10000;
  let nextPriority = 1000;
  const groups: SportsAllianceClientsRow[] = [];
  for (let i = 0; i < maxGroups; ++i) {
    const groupId = nextGroup++;
    const numGroup = getRandomGroup(entries);
    const priority = nextPriority++;
    for (let j = 0; j < numGroup; ++j) {
      const clientId = nextClient++;
      const row: SportsAllianceClientsRow = {
        ClusterID: groupId.toString(),
        UniqueID: clientId.toString(),
        CustomerID: clientId.toString(),
        Category: "1",
        Priority: priority.toString(),
      };
      groups.push(row);
    }
  }

  const result: string[][] = [["ClusterID", "UniqueID", "CustomerID", "Category", "Priority"]];
  for (const group of groups) {
    result.push([group.ClusterID, group.UniqueID, group.CustomerID, group.Category, group.Priority]);
  }

  return result.map((arr) => arr.join(";")).join("\n");

  function getRandomGroup(probabilities: [string, number][]): number {
    const probs: number[] = [];
    let total = 0;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < probabilities.length; ++i) {
      const value = probabilities[i][1];
      probs.push(total + value);
      total += value;
    }

    const r = Math.random() * total;

    for (let i = 0; i < probs.length; ++i) {
      if (r < probs[i]) {
        return parseInt(probabilities[i][0], 10);
      }
    }

    return -1;
  }
}
