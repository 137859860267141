import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PlatformService } from '../services/platform.service';
import { AuthenticationService } from './authentication.service';



@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild{

    constructor(private authService: AuthenticationService,
        private router: Router,
        private platformService: PlatformService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            let allowed = false;
            if (this.authService.isTokenExpired()) {
                this.router.navigate(['/']);
                allowed = false;
            } else {
                allowed = true;
                this.authService.getUserLogged().then(res=>{
                    if(res){
                        this.platformService.setAuth(res);
                    }
                })
            }
            this.platformService.setConfigurationPrivate(allowed)
            return allowed;
            // if (localStorage.getItem('token') === null) {
            // } else {
            //     const token = JSON.parse(localStorage.getItem('token'));
            //     const date = Date.now();
            //     if (date > token.expires_in) {
            //         localStorage.removeItem('token');
            //         this.router.navigate(['/login']);
            //         return false;
            //     }
            // }

            // return true;
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            // if (localStorage.getItem('token') === null) {
            //     this.router.navigate(['/login']);
            //     return false;
            // } else {
            //     const token = JSON.parse(localStorage.getItem('token'));
            //     const date = Date.now();
            //     if (date > token.expires_in) {
            //         console.log('date > token expire time');
            //         localStorage.removeItem('token');
            //         this.router.navigate(['/login']);
            //         return false;
            //     }
            // }
            return true;
            let allowed = false;
            if (this.authService.isTokenExpired()) {
                this.router.navigate(['/']);
                allowed = false;
            } else {
                this.authService.getUserLogged().then(res=>{
                    if(res){
                        this.platformService.setAuth(res);
                    }
                })
                allowed = true;
            }

            this.platformService.setConfigurationPrivate(allowed)
            
            return allowed;
    }
}
