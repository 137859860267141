import { Component, OnInit } from '@angular/core';
import { DVMService } from '../services/dvm.service';
import { ClickHandler } from '../services/handlers/click.handler';
import { EnterHandler } from '../services/handlers/enter.handler';
import { LeaveHandler } from '../services/handlers/leave.handler';
import { PopoverHandler } from '../services/handlers/popover.handler';
import { AuthenticationService } from '../../auth';
import { DataService } from '../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialdistancingService } from '../services/socialdistancing.service';
import { Subject } from 'rxjs';
import { LoadShareSimulationHandler } from '../services/handlers/load-share-simulation.handler';
import { FormatNumberService } from '../services/format-number.service';
import { Platform, PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'app-simulation',
  templateUrl: './share-simulation.component.html',
  styleUrls: ['./share-simulation.component.scss'],
  providers: [
    LoadShareSimulationHandler,
    ClickHandler,
    EnterHandler,
    LeaveHandler,
    PopoverHandler
  ]
})
export class ShareSimulationComponent implements OnInit {

  configurationSubject = new Subject<any>();

  shareSimulationKey;
  simulation;

  constructor(private dvmService: DVMService,
              private router: Router,
              private loadShareSimulationHandler: LoadShareSimulationHandler,
              private clickHandler: ClickHandler,
              private enterHandler: EnterHandler,
              private leaveHandler: LeaveHandler,
              private popoverHandler: PopoverHandler,
              private authService: AuthenticationService,
              private dataService: DataService,
              private activatedRoute: ActivatedRoute,
              private socialDistancing: SocialdistancingService,
              private platformService: PlatformService,
              private formatNumber: FormatNumberService) { }

  ngOnInit(): void {
    this.shareSimulationKey = this.activatedRoute.snapshot.params.key;
    this.dataService.currentShareKey = this.shareSimulationKey;
    this.platformService.refreshPlatform();
    this.dataService.getSharedSimulation(this.shareSimulationKey).subscribe(
      response => {
        if (response.error) {
          console.log(response.error);
          this.router.navigate(['/home']);
        } else {
          this.formatNumber.setCurrency(response.data.currency);
          this.formatNumber.setUnits(response.data.metric_system);
          // this.formatNumber.setCurrentLocale(response.data.currency);
          this.dataService.shareDataAllParams = response.all_params;
          this.dataService.shareData = response;

          this.simulation = response.simulation;
          this.dataService.venueData = response.venue;
          this.dataService.shareDataSubject.next();
          console.log(this.shareSimulationKey);
          this.dataService.currentSimulationId = this.simulation.id;
          this.dataService.simulationData = this.simulation;
          this.dataService.setCurrentSimulation$(this.simulation);
          // this.configurationSubject.next(this.simulation['configuration']);
          this.configurationSubject.next(response);
          this.dataService.currentConfigurationId = this.simulation['configuration'].id;
          this.dataService.setCurrentVenue$(this.simulation['configuration']['venueId']);
          this.dvmService.restartDVM(this.simulation['configuration']['venue_code'], this.simulation['configuration']['map_id']);
          this.dvmService.isConfiguration = false;
          this.dvmService.isSimulation = true;
          this.dataService.inputHash = {};
          const socialDistancingConfig = {
            securityDistance: this.simulation.social_distancing,
            isleSeats: this.simulation.isle_seats,
            parameters: this.simulation.parameters
          };
          this.socialDistancing.setSocialDistancingConfig(socialDistancingConfig);
          this.dataService.configCategories = response.categories;
        }
      }
    );
  }

}
