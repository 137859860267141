import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, DoCheck } from '@angular/core';
import { DVMService } from 'src/app/social-distance-editor/services/dvm.service';
import { CategoriesService } from 'src/app/social-distance-editor/services/categories.service';
import { DataService } from 'src/app/social-distance-editor/services/data.service';
import { saveAs } from 'file-saver';
import { GroupsService } from 'src/app/social-distance-editor/services/groups.service';
import { PricingService } from 'src/app/social-distance-editor/services/pricing.service';
import { InventoryService } from 'src/app/social-distance-editor/services/inventory.service';
import { SelectionService } from 'src/app/social-distance-editor/services/selection.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormatNumberService } from 'src/app/social-distance-editor/services/format-number.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { EditSimulationModalComponent } from 'src/app/social-distance-editor/shared/modals/edit-simulation-modal/edit-simulation-modal.component';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Subject } from 'rxjs';
import { ConfirmDeleteAllocationComponent } from 'src/app/social-distance-editor/plugins/allocator/components/modals/confirm-delete-allocation/confirm-delete-allocation.component';
import { ModalService } from 'src/app/social-distance-editor/services/modal.service';
import { SocialdistancingService } from 'src/app/social-distance-editor/services/socialdistancing.service';
import { AuthenticationService } from 'src/app/auth';
import { CategoryPriceModalComponent } from 'src/app/social-distance-editor/shared/modals/category-price-modal/category-price-modal.component';
import { AllocatorService } from '../../plugins/allocator/services/allocator.service';
import JSZip from 'jszip';
import { TiersService } from 'src/app/social-distance-editor/services/tiers.service';
import {AllocatorModalService} from '../../plugins/allocator/services/allocator-modal.service';
import {CustomCategoriesService} from '../../plugins/custom-categories/services/custom-categories.service';
import * as buffer from 'buffer';
import { Console } from 'console';
import { escapeRegExp } from '@angular/compiler/src/util';
(window as any).Buffer = buffer.Buffer;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  // @ViewChild('switcher-section') buttonSection: ElementRef;
  // @ViewChild('switcher-row') buttonRow: ElementRef;
  // @ViewChild('switcher-seat') buttonSeat: ElementRef;
  displayBtnText: boolean;

  //cutom categories
  ondownloadCustomcategories = false;

  private subscriptions: Subscription[] = [];

  @Input() page: 'setup' | 'simulation' | 'shared' | 'allocation';
  @Input() configurationSubject: Subject<any>;

  get isSimulation() {
    return this.page === 'simulation' || this.page === 'shared' || this.page === 'allocation';
  }

  get isAllocation() {
    return this.page === 'allocation';
  }

  get allowAllocation(): boolean {
    return this.allocatorService.allowAllocation;
  }

  get hasAllocation(): boolean {
    return this.currentSimulation?.allocation === 1;
  }

  get allowCustomCategories(): boolean {
    return this.customCategoriesService.allowCustomCategories;
  }

  get configurationHasCustomCategories(): boolean {
    return this.customCategoriesService.configurationHasCustomCategories;
  }


  get isMultiSelection(): boolean {
    return this.selection.isMultiSelection;
  }

  public onNewAllocation(): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered newsimulation-modal med-modal',
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {
        simulation: this.currentSimulation,
        currentVenue: this.currentVenue,
        configuration: this.configuration
      }
    };

    this.bsModalRef = this.allocatorModalService.showModal(modalConfig);
    // this.bsModalRef = this.allocatorService.showModal(modalConfig);
  }

  public onViewSimulation(): void {
    this.router.navigate(['/simulation/' + this.currentSimulation.id]);
  }

  public onViewAllocation(): void {
    this.router.navigate(['/allocation/' + this.currentSimulation.id]);
  }


  public onDownloadAllocation(): void {
    const file = this.allocatorService.resultFile;
    const fileName = this.allocatorService.resultFileName;
    if (file) {
      saveAs(file, fileName);
    } else {
      this.dataService.getAllocationResult(this.currentSimulation.id, this.allocatorService.ref).toPromise()
        .then((result) => {
          const zip = new JSZip();
          return zip.loadAsync(result)
            .then(() => {
              if (zip.files) {
                let count = 0;
                let key = null;
                for (const i in zip.files) {
                  if (zip.files.hasOwnProperty(i) && zip.files[i].name !== 'undefined') {
                    key = i;
                    count++;
                  }
                }
                if (count > 1) {
                  const blob = new Blob([result], { type: 'application/zip'});
                  const name = 'result.zip';
                  this.allocatorService.resultFile = blob;
                  this.allocatorService.resultFileName = name;
                  saveAs(blob, name);
                } else {
                  zip.file(key).async('blob')
                    .then((fileBlob) => {
                      this.allocatorService.resultFile = fileBlob;
                      this.allocatorService.resultFileName = key;
                      saveAs(fileBlob, key);
                    });
                }
              }
            });
        });
    }
  }

  public confirmDeleteAllocation(){
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered simulation-modal',
      backdrop: true,
      ignoreBackdropClick: true,
      initialState:{
        simulation: this.currentSimulation
      }
    };
    this.bsModalRef = this.modalService.show(ConfirmDeleteAllocationComponent, modalConfig);
  }

  currentLayer = 'section';
  isRowsActive = false;
  nodeType: 'section' | 'seat' = 'section';
  uploadSeatmanifest: File;
  isUploadActive = false;
  alerts: Array<any> = [];
  newPricing;
  currentCategories: string | string[] = [];
  currentSelection = [];
  isGroupSelected = false;
  isStrictlyGrouped = false;


  // currentSelectionRows: number;
  currentVenue;
  @ViewChild('inputFile') inputFile: ElementRef;
  displaySidebar = false;
  bsModalRef: BsModalRef;
  _clientData;
  configId: number;
  currentSimulation;
  configuration;
  currentyear = new Date().getFullYear();

  currentAvailability = 0;
  //currentAvailabilityPercentage = '';
  levels: Array<string> = [];
  currentLevelName;
  currentLevelAvailability = 0;
  hasMapChanged = false;
  sharedData;

  currentMapId;
  displayAllocationBtn;

  get currentSelectionPricing() {
    if (typeof this.pricing.currentSelectionPricing[0] === 'string') {
      return this.pricing.currentSelectionPricing[0];
    } else {
      return '';
    }
  }

  get currentSelectionCount() {
    if (this.currentSelection) {
      return this.currentSelection.length;
    }
    return 0;
  }

  get currency() {
    return this.numberFormat.getCurrency();
  }

  get venueData() {
    if (this.page !== 'shared') {
      if (this.dataService.venueData && this.currentVenue) {
        return this.dataService.venueData[this.currentVenue];
      }
    } else {
      if (this.sharedData) {
        return this.sharedData.simulation.configuration;
      }
    }
  }

  get availability() {
    return this.currentAvailability;
  }

  // get availabilityPercentage() {
  //   return this.currentAvailabilityPercentage;
  // }

  get isGroupsSelection() {
    return this.selection.isGroupsSelection;
  }

  get isSinglesSelection(){
    return this.selection.isSingleSelection;
  }

  constructor(private dvmService: DVMService,
              public categories: CategoriesService,
              private dataService: DataService,
              private groups: GroupsService,
              private inventory: InventoryService,
              private selection: SelectionService,
              private pricing: PricingService,
              private activatedRoute: ActivatedRoute,
              private numberFormat: FormatNumberService,
              private modalService: BsModalService,
              private ownModalService: ModalService,
              private socialDistancing: SocialdistancingService,
              private toastr: ToastrService,
              private authService: AuthenticationService,
              private activateRoute: ActivatedRoute,
              private allocatorService: AllocatorService,
              private allocatorModalService: AllocatorModalService,
              private customCategoriesService: CustomCategoriesService,
              private router: Router,
              private tiersService: TiersService) { }

  toggleMoveGroups() {
    if(this.hasAllocation){
      this.toastr.error("","Moving groups is not allowed due to the allocation");
      return;
    }
    this.selection.isGroupsSelection = !this.selection.isGroupsSelection;
    this.selection.isSingleSelection = false;
    this.unselectNodes();
    if (this.selection.isGroupsSelection){
      this.dvmService.viewer.addNodesToGroup(this.dvmService.getLockedseats(), "lockedborderfilled");
      this.toastr.success('', 'Enabled group edition. Click any group of seats to move them.',{ timeOut: 4000 });
    }else{
      this.dvmService.viewer.removeNodesFromGroup(this.dvmService.getLockedseats(), "lockedborderfilled");
      this.toastr.info('', 'Disabled group edition.');
    }

  }
  toggleSingleSelection() {
    if(this.hasAllocation){

      this.toastr.error("","Editing single seats is not allowed due to the allocation");
      return;
    }
    this.selection.isSingleSelection = !this.selection.isSingleSelection;
    this.selection.isGroupsSelection = false;
    this.selection.isMultiSelection = false;
    this.unselectNodes();
    if(this.selection.isSingleSelection){
      this.dvmService.viewer.addNodesToGroup(this.dvmService.getLockedseats(), "lockedborderfilled");
      this.toastr.success('', 'Enabled single seat edition. Click any seat to switch the availability.',{ timeOut: 5000 });
    }else{
      this.dvmService.viewer.removeNodesFromGroup(this.dvmService.getLockedseats(), "lockedborderfilled");

      this.toastr.info('', 'Disabled single seat edition.');
    }

  }

  // ngDoCheck(): void {
  //   if (this.buttonSection.nativeElement.classList.contains('active')) {

  //   }
  // }

  ngOnInit(): void {
    if (this.isSimulation){
      this.currentSimulation = this.dataService.simulationData;
    }else{
      this.resetSimulationFlags();
    }
    // this.toastr.info('', 'Price successfully assigned'),{ timeOut: 999999999 };
    this.configurationSubject.subscribe(
      configuration => {
        if (this.page === 'shared') {
          this.sharedData = configuration;
          this.configuration = configuration.simulation.configuration;
          this.currentVenue = configuration.venue.id;
          const venueHash = {
            [configuration.venue.id]: configuration.venue
          };
          this.dataService.venueData = venueHash;
        } else {
          this.configuration = configuration;
        }
        if (this.configuration.tier_list && this.configuration.tier_list.length) {
          this.levels = this.configuration.tier_list;
          const setInitialMap = () => {
            this.currentMapId = this.dvmService.viewer.getMapId();
            if (this.levels.length) {
              this.levels.forEach(level => {
                if (level['map_id'] === this.currentMapId) {
                  this.currentLevelName = level['name'];
                }
              });
            }
          };
          // if (this.dvmService.isViewerSubjectInitialized) {
          //   setInitialMap();
          // } else {
          const s = this.dvmService.mapSubject.subscribe(
            r => {
              s.unsubscribe();
              setInitialMap();
            }
          );
          // }
        }
      }
    );
    this.subscriptions.push(this.dataService.getCurrentSimulation$().subscribe(
      simulation => {
        //console.log(simulation);
        this.currentSimulation = simulation;
        if (this.currentSimulation.allocation){
          this.displayAllocationBtn = true;
          this.allocatorService.showAllocatedSeats = true;
        }
        this.currentAvailability = simulation.capacity;
      }
    ));
    this.inventory.availabilityLoadedSubject.subscribe(
      r => {
        this.currentLevelAvailability = this.dvmService.viewer.getNodesByState('seat', 'available').length;
        if(this.currentSimulation){
          this.currentAvailability = this.currentSimulation.capacity;
        }
      }
    );
    this.configId = this.activateRoute.snapshot.params.id;
    if (this.page !== 'shared') {
      this.subscriptions.push(this.authService.getUserLogged$().subscribe(
        client => {
          if (typeof client !== 'boolean') {
            this._clientData = client.user;
            this.getClientConfigs(this._clientData.client.id);
          }
        },
        error => {
          console.log('error landing get user logged');
          console.log(error);
        }
      ));
    } else {
      if (this.dataService.shareData) {
        this._clientData = {
          client: this.dataService.shareData.data
        };
      } else {
        this.dataService.shareDataSubject.subscribe(
          response => {
            this._clientData = {
              client: this.dataService.shareData.data
            };
          }
        );
      }
    }
    this.subscriptions.push(this.socialDistancing.calculationFinishedsubject.subscribe(
      result => {
        this.currentAvailability = this.currentSimulation.capacity;

      }
    ));
    this.subscriptions.push(this.dataService.getCurrentVenue$().subscribe(
      currentVenue => {
        this.currentVenue = currentVenue;
      }
    ));
    this.subscriptions.push(this.selection.selectionSubject.subscribe(
      selection => {
        this.currentSelection = selection;
        if (document.getElementById("btnsaveprice")) {
          document.getElementById("btnsaveprice").classList.add("disabled");
        }
        if (this.currentSelection) {
          this.isGroupSelected = false;
          this.isStrictlyGrouped = true;
          this.currentSelection.forEach(node => {
            if (this.groups.groupsHash.nodes[node.id]) {
              this.isGroupSelected = true;
            } else {
              this.isStrictlyGrouped = false;
            }
          });
        }
      }
    ));

    if (this.isSimulation) {
      const s = this.dvmService.mapSubject.subscribe(
        viewer => {
          s.unsubscribe();
          //this.toggleRows(false);
          this.switchLayers('seat',false);
          this.subscriptions.push(this.dvmService.mapSubject.subscribe(
            mapChanged => {
              this.dvmService.viewer.layers.flags.automatic_control_level = false;
              this.unselectNodes();
              if (this.currentLayer==='section') {
                this.dvmService.viewer.layers.setLayerLevel(0);
                this.nodeType = 'section';
              } else {
                this.dvmService.viewer.layers.setLayerLevel(1);
                this.nodeType = 'seat';
              }
            }
          ));
        }
      );
    }
  }

  priceValueChange(value) {
    if (this.selection.lastSelection && this.selection.lastSelection.length) {
      const currentSelectionCategories = this.categories.getCurrentCategories(this.nodeType, this.selection.lastSelection);
      if (currentSelectionCategories.length === 1 && currentSelectionCategories[0] === 'locked') {
        if (typeof value === 'string' && parseFloat(value) < 1000000 && parseFloat(value) >= 0) {
          this.newPricing = value;
          document.getElementById("btnsaveprice").classList.remove("disabled")
        } else {
          this.newPricing = 0;
          document.getElementById("btnsaveprice").classList.add("disabled")
        }
      } else {
        if (typeof value === 'string' && parseFloat(value) < 1000000 && parseFloat(value) > 0) {
          this.newPricing = value;
          document.getElementById("btnsaveprice").classList.remove("disabled")
        } else {
          this.newPricing = 0;
          document.getElementById("btnsaveprice").classList.add("disabled")
        }
      }
    }
  }

  restartPricing() {
    if (document.getElementById('priceinput')) {
      (document.getElementById('priceinput') as HTMLInputElement).value = this.pricing.currentSelectionPricing[0];
      document.getElementById("btnsaveprice").classList.add("disabled");
    }
  }

  setNewPricing() {
    if (this.dvmService.viewer.getNodesByGroups(this.nodeType, 'selected').length) {
      // console.log(typeof this.newPricing);
      // console.log(parseFloat(this.newPricing));
      // console.log(this.nodeType);
      // console.log(this.currentLayer);
      if (typeof this.newPricing === 'string' && parseFloat(this.newPricing) < 1000000 && parseFloat(this.newPricing) >= 0) {
        if (this.currentLayer === 'seat') {
          const inventory = this.inventory.getInventoryData();
          const selection = this.dvmService.viewer.getNodesByGroups(this.nodeType, 'selected');
          selection.forEach(node => {
            const split = node.id.split('_')[1].split('-');
            const section = split[0];
            const row = split[1];
            const seat = split[2];
            inventory[section].elements[row].elements[seat].data.price = this.newPricing;
          });
        } else {
          this.pricing.setPricing(this.nodeType, this.dvmService.viewer.getNodesByGroups(this.nodeType, 'selected'),
            parseFloat(this.newPricing));
        }
        this.subscriptions.push(this.inventory.sendInventoryData(this.isSimulation).subscribe(
          response => {
            this.toastr.success('', 'Price successfully assigned');
          }
        ));
        // En caso que en el futuro se puedan cambiar precios o categorias por simulación, comprobar si
        // estamos en una simulación y llamar con 'all'.
        this.inventory.updateTotalsHash('configurations');
        document.getElementById("btnsaveprice").classList.add("disabled");
        this.selection.selectionSubject.next(this.selection.lastSelection);
      }
    }
  }

  switchLayers(layer: string, quit: boolean){
    const layers_levels = {
      section : 0,
      row: 1,
      seat: 1
    }

    if(quit || (layer===this.currentLayer && layers_levels[layer]===this.dvmService.viewer.layers.getLayerLevel())) return null;

    this.dvmService.viewer.layers.flags.automatic_control_level = false;
    this.unselectNodes();
    this.restartPricing();

    if (layer === 'section') {
      this.dvmService.viewer.layers.setLayerLevel(0);
      this.selection.nodeType.next('section');
      this.nodeType = 'section';

    } else {
      this.dvmService.viewer.layers.setLayerLevel(1);
      this.selection.nodeType.next('seat');
      this.nodeType = 'seat';
    }
    if(layer=='row'){
      this.selection.isGroupsSelection = false;
      this.selection.isSingleSelection = false;
    }else if(layer=='seat'){
      this.selection.isMultiSelection = false;
    }
    this.currentLayer = layer;
    this.dvmService.activeVisualizationLayer = this.currentLayer;
    this.toastr.clear();
    this.toastr.success('', 'Select '+layer+'s.');

  }
  toggleRows(quit: boolean) {
    if(quit) return null;

    this.dvmService.viewer.layers.flags.automatic_control_level = false;
    this.unselectNodes();
    this.restartPricing();

    if (this.isRowsActive) {
      this.dvmService.viewer.layers.setLayerLevel(0);
      this.selection.nodeType.next('section');
      this.nodeType = 'section';
    } else {
      this.dvmService.viewer.layers.setLayerLevel(1);
      this.selection.nodeType.next('seat');
      this.nodeType = 'seat';
    }
    this.isRowsActive = !this.isRowsActive;
  }

  removeCategories(unselect?: boolean) {
    const selection = this.dvmService.viewer.getNodesByGroups(this.nodeType, 'selected');
    this.categories.getCategoriesList().forEach(
      (category) => {
        this.dvmService.viewer.removeNodesFromGroup(selection, category.group);
      }
    );
    this.categories.setCategory(this.nodeType, selection, 'none');
    if (unselect) {
      this.unselectNodes();
    }
    this.restartPricing();
  }

  setCategory(category: string) {
    if (this.dvmService.viewer.getNodesByGroups(this.nodeType, 'selected').length) {
      //this.removeCategories();
      //console.log(this.nodeType);
      const selection = this.dvmService.viewer.getNodesByGroups(this.nodeType, 'selected');
      this.dvmService.viewer.addNodesToGroup(selection, category);
      if (this.nodeType === 'section') {
        selection.forEach(section => {
          const seats = this.dvmService.viewer.getNodesByParent(section);
          this.dvmService.viewer.addNodesToGroup(seats, category);
        });
      }
      //to delete if no errors
      //if (this.currentLayer === 'seat') {
        // const inventory = this.inventory.getInventoryData();
        // const selection = this.dvmService.viewer.getNodesByGroups(this.nodeType, 'selected');
        // selection.forEach(node => {
        //   const split = node.id.split('_')[1].split('-');
        //   const section = split[0];
        //   const row = split[1];
        //   const seat = split[2];
        //   inventory[section].elements[row].elements[seat].data.category = category;
        // });
        // this.inventory.updateTotalsHash('configurations');
        // this.toastr.success('', 'Category successfully assigned');
        // this.subscriptions.push(this.inventory.sendInventoryData(this.isSimulation).subscribe(
        //   response => {
        //     console.log(response);
        //   }
        // ));
      //} else {
        this.categories.setCategory(this.nodeType, selection, category, (response) => {
          if(response.capacity_updated){

            //warning: carga todos los tiers : no abusar
            this.tiersService.setTotalTiersAV(this.configuration.tier_list).then(
              result=>{
                //console.log(result);
                const body = {capacity:result.capacity};

                const s1 = this.dataService.editConfiguration(this.configId,body).subscribe(
                  response=>{
                    this.dataService.setCurrentConfiguration$(response.result);
                    this.configurationSubject.next(response.result);
                    s1.unsubscribe();
                  }
                )
              }
            )
          }



              
          const s2 = this.inventory.sendInventoryData(this.isSimulation).subscribe(
            response => {
              s2.unsubscribe();
            }
          );
          // En caso que en el futuro se puedan cambiar precios o categorias por simulación, comprobar si
          // estamos en una simulación y llamar con 'all'.
          this.inventory.updateTotalsHash('configurations');
          this.toastr.success('', 'Category successfully assigned');
        });
      //}
      this.unselectNodes();
    }else{
      this.toastr.error("","Please select a "+this.nodeType+" to change its category");
    }
    this.restartPricing();
  }

  public btnUnselectNodes(){
    const selection = this.dvmService.viewer.getNodesByGroups(this.nodeType, 'selected');
    if(!selection.length){
      this.toastr.error("","Nothing selected");
      return;
    }
    this.unselectNodes();
  }

  unselectNodes() {

    const selection = this.dvmService.viewer.getNodesByGroups(this.nodeType, 'selected');

    this.dvmService.viewer.removeNodesFromGroup(selection, 'selected');
    this.selection.selectionSubject.next(this.dvmService.viewer.getNodesByGroups(this.nodeType, 'selected'));
    this.restartPricing();
  }

  /**
   * Subscribe to getSeatManifest and dDownloads the CSV file to the client
   */
  public onDownloadManifest(): void {
    if (this.isSimulation) {
      // TODO: Definir como se exportará finalmente las sillas en onhold. Abriendo este modal preguntara al usuario si quiere exportarlo con onhold
      // de momento se exportara por defecto sin onhold.
      // this.ownModalService.openExportOnHold(this.configuration, this.activatedRoute.snapshot.params.id);

      // Export normal. Por el momento se dejara este export hasta que se defina cómo se va a descargar el seatmanifest con el status onhold
      const getSSM = () => {
        this.subscriptions.push(this.dataService.getSimulationSeatManifest(this.activatedRoute.snapshot.params.id,
            'csv').subscribe(
            data => {
              const csv = data.response;
              const date = new Date().toDateString();
              const blob = new Blob([csv], {type: 'text/csv'});
              saveAs(blob, `seatmanifest_${date}.csv`);
            }
        ));
      };
      // if (this.dataService.simulationData.custom_groups && "dirty" in this.dataService.simulationData.custom_groups) {
      const maps = [];
      if (this.configuration.tier_list) {
        this.configuration.tier_list.forEach(level => {
          maps.push(level.map_id);
        });
      }
      this.socialDistancing.getGroupsFullExport(this.dvmService.viewer, maps).then(
          response => {
            response['subscribe'](
                data => {
                  getSSM();
                }
            );
          }
      );
      // } else {
      //   getSSM();
      // }
    } else {
      this.subscriptions.push(this.dataService.getSeatManifest(this.activatedRoute.snapshot.params.id, 'csv').subscribe(
        data => {
          const csv = data.response;
          const date = new Date().toDateString();
          const blob = new Blob([csv], {type: 'text/csv'});
          saveAs(blob, `seatmanifest_${date}.csv`);
        }
      ));
    }
  }

  /**
   * Subscribes to uploadSeatManifest and uploads the CSV file to the server
   */
  public onUploadManifest(): void {
    this.subscriptions.push(this.dataService.uploadSeatManifest(this.activatedRoute.snapshot.params.id, this.uploadSeatmanifest).subscribe(
      upload => {
        this.isUploadActive = false;
        console.log(upload);
        // TODO: parsear el contenido de momento sale un null
      },
      error => {
        console.log(error);
        this.isUploadActive = false;
      }
    ));
  }


  /**
   * Handles the input file and shows the input submit
   * @param event to handle the file
   */
  public handleFileInput(event: any): void {
    if (event.length > 0) {
      this.isUploadActive = true;
      this.uploadSeatmanifest = event[0];
    }
  }

  public onCancelUpload(): void {
    this.isUploadActive = false;
    this.uploadSeatmanifest = null;
    this.inputFile.nativeElement.value = '';
  }

  public onTriggerUploadManifest(): void {
    document.getElementById('uploadInput').click();
  }

  public onDisplaySidebar(): void {
    this.displaySidebar = !this.displaySidebar;
  }

  createGroup() {
    if(this.currentLayer==='seat') return;

    if (this.dvmService.viewer.getNodesByGroups(this.nodeType, 'selected').length) {
      const selection = this.dvmService.viewer.getNodesByGroups(this.nodeType, 'selected');
      this.groups.createGroup(selection, this.isSimulation);
      this.unselectNodes();
      this.toastr.success('', 'Group successfully created');
    }
    this.restartPricing();
  }

  deleteGroup() {
    if(this.currentLayer==='seat') return;
    if (this.dvmService.viewer.getNodesByGroups(this.nodeType, 'selected').length) {
      const selection = this.dvmService.viewer.getNodesByGroups(this.nodeType, 'selected');
      this.groups.deleteGroup(selection, this.isSimulation);
      this.unselectNodes();
      this.toastr.success('', 'Group successfully removed');
    }
    this.restartPricing();
  }

  public onComputeSectionResultsSubSimulation(): void{
    let sdParentNodes = this.dvmService.viewer.getNodesByGroups("section","selected");
    if(!sdParentNodes.length){
      this.toastr.error("","Please select a section");
      return;
    }
    let simulationParameters;
    this.socialDistancing.isSectionSocialDistancing = true;
    const inputHash = {};
    this.subscriptions.push(this.dataService.getAllParameters().subscribe(
      parameters => {
        //console.log(parameters);
        simulationParameters = parameters['params'];
        simulationParameters.splice(0, 1);
        simulationParameters.forEach(
          p => {
            inputHash[p.id] = {percentage: 0, computed_per: 0};
          }
        );
        // inputHash[3] = {percentage: 100, computed_per: 0};
        this.dataService.inputHash = inputHash;
        this.dataService.setInputHash$(inputHash);
      }
    ).add(
      success => {
        this.ownModalService.openComputedSectionResultsModal(simulationParameters, inputHash, sdParentNodes);
      }
    ));
  }
  public onEditSimulation(): void {
    let simulationParameters;
    this.socialDistancing.isSectionSocialDistancing = false;

    const inputHash = {};
    this.subscriptions.push(this.dataService.getAllParameters().subscribe(
      parameters => {
        //console.log(parameters);
        simulationParameters = parameters['params'];
        simulationParameters.splice(0, 1);
        simulationParameters.forEach(
          p => {
            inputHash[p.id] = {percentage: 0, computed_per: 0};
          }
        );
        // inputHash[3] = {percentage: 100, computed_per: 0};
        this.dataService.inputHash = inputHash;
        this.dataService.setInputHash$(inputHash);
      }
    ).add(
      success => {
        const modalConfig: ModalOptions = {
          animated: true,
          class: 'modal-dialog-centered simulation-modal',
          backdrop: true,
          ignoreBackdropClick: true,
          initialState: {
            simulationParameters,
            inputHash
          }
        };
        this.bsModalRef = this.modalService.show(EditSimulationModalComponent, modalConfig);
        const subscription = this.modalService.onHidden.subscribe(
          reason => {
            subscription.unsubscribe();
            // this.ownModalService.openComputedResultsModal();
          }
        );
      }
    ));
  }

  public formatNumber(price: number): string {
    return this.numberFormat.formatNumber(price);
  }

  public onCategoriesPrices(): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-start info-modal',
      backdrop: false,
      ignoreBackdropClick: false,
      initialState: {
        currency: this._clientData.client.currency,
        configId: this.configId,
        isSimulation: this.isSimulation,
        simulation: this.currentSimulation
      }
    };
    this.bsModalRef = this.modalService.show(CategoryPriceModalComponent, modalConfig);
    this.categories.setCategorySubject();
  }

  private getClientConfigs(clientId: number): void {
    this.subscriptions.push(this.dataService.getClientConfigs(clientId).subscribe(
      configs => {
        //console.log(configs);
        const venueHash = {};
        configs.forEach(config => {
          venueHash[config.id] = config;
        });
        this.dataService.venueData = venueHash;
        //console.log(this.venueData);
      },
      error => {
        console.log(error);
      }
    ));
  }

  public loadMap(mapId, levelName) {
    if (mapId !== this.dvmService.viewer.getMapId()) {
      this.toastr.info('', 'Loading '+levelName);
      this.dvmService.changeMapConfiguration(this.configuration['venue_code'], mapId);
      const s = this.dvmService.mapSubject.subscribe(
        r => {
          console.log(this.currentLayer);
          this.toastr.clear();
          s.unsubscribe();
          const availability = this.inventory.getAvailableInventory(this.isSimulation);
          this.dvmService.viewer.setAvailability('seat', availability.seats);
          this.dvmService.viewer.setAvailability('section', availability.sections);
          this.inventory.availabilityLoadedSubject.next();
          this.categories.printInventoryCategories();
          this.currentMapId = mapId;
          this.currentLevelName = levelName;
          this.hasMapChanged = true;
          //this.toggleRows(false);
          this.switchLayers(this.currentLayer,false);
          this.toastr.success('', 'Now watching: '+levelName);
          }
        );
    }else{
      this.toastr.info('', levelName+" already loaded");
    }
  }

  isCurrentMap(mapId) {
    if (mapId && this.currentMapId) {
      return mapId === this.currentMapId;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.isGroupSelected = false;
    this.selection.isSingleSelection = false;
    this.selection.isGroupsSelection = false;
  }

  public onComputeResults(frompage?:any) {
    // const modalConfig: ModalOptions = {
    //   animated: true,
    //   class: 'modal-dialog-centered simulation-modal',
    //   backdrop: true,
    //   ignoreBackdropClick: true,
    //   initialState: {
    //   }
    // };
    // this.bsModalRef = this.modalService.show(ComputeResultsModalComponent, modalConfig);
    console.log("Opening Computed Results Modal, with this configuration:", this.configuration, frompage);
    this.ownModalService.openComputedResultsModal(frompage,this.configuration);
  }

  public toggleAllocatedSeats(){
    this.allocatorService.showAllocatedSeats =  !this.allocatorService.showAllocatedSeats;
    this.displayAllocationBtn = this.allocatorService.showAllocatedSeats;
    if(this.displayAllocationBtn){
      this.toastr.success("","Showing allocated seats");
    }else{
      this.toastr.success("","Showing seats by categories only");
    }
  }

  mouseOverFunction(event: MouseEvent): void {
    if (event.type === 'mouseover') {
      (event.target as HTMLElement).firstElementChild.classList.add('none');
      console.log((event.target as HTMLElement).firstElementChild) ;
      // event.target.firstElementChild;
    }
    if (event.type === 'mouseout') {

    }
    console.log(event);
  }

  public selectToEnable(){
    this.selection.isSingleSelection = false;
    this.selection.isGroupsSelection = false;
    this.dvmService.viewer.removeNodesFromGroup(this.dvmService.viewer.getNodesByGroups("section","selected"),"selected");
    this.dvmService.viewer.removeNodesFromGroup(this.dvmService.viewer.getNodesByGroups("seat","selected"),"selected");
    if (this.currentLayer==='seat'){
      this.selection.isMultiSelection = false;
      this.toastr.success("","To set seats as available, use edit single seat button");
    }else{
      this.selection.isMultiSelection = !this.selection.isMultiSelection;
      this.dvmService.viewer.removeNodesFromGroup(this.dvmService.getLockedseats(), "lockedborderfilled");
      if(this.selection.isMultiSelection){

        if (this.currentLayer==='row'){
          this.dvmService.viewer.addNodesToGroup(this.dvmService.getLockedseats(), "lockedborderfilled");
          this.toastr.success("","Select rows to set as available");
        }else if(this.currentLayer==='section'){
          this.toastr.success("","Select sections to set as available");
        }

      }
    }
    if(!this.selection.isMultiSelection){
      this.toastr.info('', 'Disabled edit multi seat.');
    }
  }

  private resetSimulationFlags(){
    this.selection.isMultiSelection = false;
    this.selection.isSingleSelection = false;
  }

  public downloadCustomCategoriesVenueData(){
    this.ondownloadCustomcategories = true;

    let model = "configuration";
    let id = this.configId;
    if(this.isSimulation){
      id = this.currentSimulation.configuration.id;
    }

    this.customCategoriesService.getVenueDataFile(model,id,'raw').toPromise().then(file=>{
      let config = JSON.parse(file['CustomData']);
      let rawcsv = Buffer.from(file['Body'], 'utf8').toString();
      let filename = config.ids+'_'+config.club.toUpperCase()+'EXPORT_DATA.csv';
      if(!this.isSimulation){
        this.createDownloadFile(rawcsv,filename);
        this.ondownloadCustomcategories = false;
      }else{
        let csv = rawcsv.split('\n');
  /* 
        0 IDZONA;
        1 ZONA;
        2 ZONA_ABREVIADO;
        3 PUERTA;
        4 IDASIENTO;
        5 POS_FILA;
        6 POS_ASIENTO;
        7 FILA;
        8 ASIENTO;
        9 SLOT;
        10 ESTADO_AVET;
        11 PATH_DESC;
        12 IDEVENT;
        13 IDGRUPOZONA;
        14 GRUPOZONA
  */
        const inventory = this.inventory.getInventoryData();

        let parsed_csv = "";
        parsed_csv+=csv[0];


        //Get un available nodes
        //let test = [];
        for(let i = 1;i<csv.length;i++){
          let rowcsv = csv[i].split(";");
          const section = rowcsv[0].replace(/\s/g,'');
          const section_id = "S_"+rowcsv[0].replace(/\s/g,'');
          const row = rowcsv[7].replace(/\s/g,'');
          const seat = rowcsv[8].replace(/\s/g,'');
          const id = `${section_id}-${row}-${seat}`;
          const data = inventory[section]?.elements[row]?.elements[seat]?.data;

          if(data){
          //if(section === '100'){
            if( (!data.status || data.status != 'available') && !data.customcategory){
              rowcsv[10]= 'Bloqueado';
            }else if(data.status && data.status === 'available'){

              if(!data.customcategory){
                // FREE SEAT
                rowcsv[10] = 'Libre';
/*                 if(!test['L']) test['L'] = [];
                test['L'].push(rowcsv); */
              }
            }
            let stringRow = JSON.stringify(rowcsv);
            stringRow = this.replaceAll(stringRow,'[','');
            stringRow = this.replaceAll(stringRow,']','');
            stringRow = this.replaceAll(stringRow,'"','');
            stringRow = this.replaceAll(stringRow,',',';');
            stringRow = stringRow.replace(/(?:\\[r])+/g, "");
            parsed_csv+=stringRow+"\n";
          //}
          }else{
            console.warn("Seat detected in the inventory but not in the map: "+id);
           
          }
        }// end for
        this.createDownloadFile(parsed_csv,filename);
        this.ondownloadCustomcategories = false;

      }
      return;
    }).catch(e=>{
      console.log(e);
    });
  }

  private replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
  }

  private createDownloadFile(data, fileName) {
    const a = document.createElement("a");
    document.body.appendChild(a);
    const blob =  new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = fileName; 
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();

  };
}
