import { Injectable, Inject } from "@angular/core";
import { GeneralHandler } from "./general.handler";
import { DVMService } from "../dvm.service";
import { DVM_CONFIG } from "src/assets/configuration/digitalvenue.configuration";
import { DVMConfiguration } from "src/app/social-distance-editor/shared/models/dvm-configuration.model";
import { InventoryService } from "../inventory.service";
import { GroupsService } from "../groups.service";
import { PricingService } from "../pricing.service";
import { CategoriesService } from "../categories.service";
import { SelectionService } from "../selection.service";
import { SocialdistancingService } from "../socialdistancing.service";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/social-distance-editor/services/data.service";
import { ParametersService } from "../parameters.service";
import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { ConfirmEditMultiSeatComponent } from "src/app/social-distance-editor/shared/modals/confirm-edit-multi-seat/confirm-edit-multi-seat.component";

@Injectable()
export class ClickHandler extends GeneralHandler {
  private removeWarningNodes = false;
  private nodes_tomove;
  constructor(
    protected dvmService: DVMService,
    @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
    private inventoryService: InventoryService,
    private groups: GroupsService,
    private categories: CategoriesService,
    private selection: SelectionService,
    private pricing: PricingService,
    private toastr: ToastrService,
    private parameters: ParametersService,
    private socialDistancing: SocialdistancingService,
    private dataService: DataService,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService
  ) {
    super("click", dvmService);
  }

  sectionClickHandler(node, viewer) {
    if (viewer.layers.getLayerLevel() === 0) {
      if (node.state !== "unavailable" || this.dvmService.isConfiguration) {
        let isGrouped = false;
        node.groups.forEach((element) => {
          if (parseInt(element, 10)) {
            isGrouped = true;
            if (node.groups.includes("selected")) {
              viewer.removeNodesFromGroup(this.groups.groupsHash.groups[element], "selected");
            } else {
              viewer.addNodesToGroup(this.groups.groupsHash.groups[element], "selected");
            }
          }
        });
        if (!isGrouped) {
          if (node.groups.includes("selected")) {
            viewer.removeNodesFromGroup(node, "selected");
          } else {
            viewer.addNodesToGroup(node, "selected");
          }
        }

        const selection = viewer.getNodesByGroups("section", "selected");
        this.pricing.getCurrentPricing("section", selection, true);
        this.selection.selectionSubject.next(selection);
      }
      if (this.selection.isMultiSelection) {
        viewer.removeNodesFromGroup(node, "selected");
        let nodes = viewer.getNodesByParent(node.id);
        let nodes_available = [];
        let nodes_unavailable = [];
        let all_nodes = [];
        let nodes_affected = [];
        let exists_iniventory = this.inventoryService.getInventoryBySection(node.id.split("_")[1]);
        for (let i = 0; i < nodes.length; i++) {
          let exists = exists_iniventory.indexOf(nodes[i].id);
          if (exists > -1) {
            if (nodes[i].groups.indexOf("locked") === -1 && nodes[i].state === "unavailable") {
              nodes_unavailable.push(nodes[i]);
            } else {
              nodes_available.push(nodes[i]);
            }
          }
          all_nodes.push(nodes[i]);
        }

        let status_tochange = "available";
        let error = false;
        if (!nodes_unavailable.length && !nodes_available.length) {
          error = true;
        } else {
          if (!nodes_unavailable.length && nodes_available.length) {
            status_tochange = "unavailable";
            nodes_affected = nodes_available;
          } else {
            status_tochange = "available";
            nodes_affected = nodes_unavailable;
          }
        }
        const confirmMultiSeatSelectionOpt: ModalOptions = {
          animated: true,
          class: "modal-dialog-centered",
          backdrop: true,
          ignoreBackdropClick: true,
          initialState: {
            affected_seats: nodes_affected,
            status_tochange: status_tochange,
            error: error,
          },
        };
        this.bsModalRef = this.modalService.show(ConfirmEditMultiSeatComponent, confirmMultiSeatSelectionOpt);
      }
    }
  }

  /**Seat click:
   * 1. if: Gestión del selected, quitar asientos single/multi seat
   * 2. if: Gestión del mover asientos
   * 3. if: Añadir asientos del edit single/multi seat
   * **/
  seatClickHandler(node, viewer) {
    console.log(node);
    let toastrOpt = {
      msg: "Availability and Seatmanifest data successfully updated",
      timeOut: 4000,
    };
    if (node.state !== "unavailable" || this.dvmService.isConfiguration) {
      //deprecated
      //let isGrouped = this.toggleSelection(node,viewer);

      if (/*!isGrouped && */ !this.selection.isSingleSelection && !this.selection.isMultiSelection) {
        const section = node.id.split("_")[1].split("-")[0];
        const row = node.id.split("-")[1];
        const seat = node.id.split("-")[2];
        if (node.groups.includes("selected")) {
          if (this.selection.isGroupsSelection) {
            this.selection.selectionSubscriptionsArray.push(
              this.socialDistancing.getNeighbors(viewer, node.id).subscribe((response) => {
                this.selection.selectionSubscriptionsArray.forEach((e) => {
                  e.unsubscribe();
                });
                viewer.removeNodesFromGroup(response, "selected");
                this.selection.selectionSubject.next(viewer.getNodesByGroups("seat", "selected"));
              })
            );
          } else {
            let nodes_toselect = [];
            if (this.dvmService.activeVisualizationLayer === "row") {
              nodes_toselect = this.inventoryService.getInventoryByRow(section, row);
              let all_nodes = viewer.getNodesByParent("S_" + section);
              let nodes_toselect_from_map = [];

              for (let i = 0; i < all_nodes.length; i++) {
                let row_toadd = all_nodes[i].id.split("_")[1].split("-")[1];
                if (row_toadd === row) {
                  nodes_toselect_from_map.push(all_nodes[i].id);
                }
              }
              if (nodes_toselect_from_map.length != nodes_toselect.length) {
                nodes_toselect = nodes_toselect_from_map;
              }
            } else {
              nodes_toselect = this.inventoryService.getInventoryBySeat(section, row, seat);
              if (!nodes_toselect.length) {
                nodes_toselect.push(node);
              }
            }
            viewer.removeNodesFromGroup(nodes_toselect, "selected");
          }
        } else {
          if (this.selection.isGroupsSelection) {
            this.nodes_tomove = [];
            this.selection.selectionSubscriptionsArray.push(
              this.socialDistancing.getNeighbors(viewer, node.id).subscribe((response: any) => {
                this.selection.selectionSubscriptionsArray.forEach((e) => {
                  e.unsubscribe();
                });
                viewer.addNodesToGroup(response, "selected");
                this.selection.selectionSubject.next(viewer.getNodesByGroups("seat", "selected"));
                for (let i = 0; i < response.length; i++) {
                  const [section, row, seat] = response[i].split("_")[1].split("-");
                  const seat_inv_data =
                    this.inventoryService.getInventoryData()[section].elements[row].elements[seat].data;
                  this.nodes_tomove.push(seat_inv_data);
                }
              })
            );
          } else {
            let nodes_toselect = [];
            if (this.dvmService.activeVisualizationLayer === "row") {
              nodes_toselect = this.inventoryService.getInventoryByRow(section, row);
              let all_nodes = viewer.getNodesByParent("S_" + section);
              let nodes_toselect_from_map = [];

              for (let i = 0; i < all_nodes.length; i++) {
                let row_toadd = all_nodes[i].id.split("_")[1].split("-")[1];
                if (row_toadd === row) {
                  nodes_toselect_from_map.push(all_nodes[i].id);
                }
              }
              if (nodes_toselect_from_map.length != nodes_toselect.length) {
                nodes_toselect = nodes_toselect_from_map;
              }
            } else {
              nodes_toselect = this.inventoryService.getInventoryBySeat(section, row, seat);
              if (!nodes_toselect.length) {
                nodes_toselect.push(node);
              }
            }
            viewer.addNodesToGroup(nodes_toselect, "selected");
          }
        }
        this.selection.selectionSubject.next(viewer.getNodesByGroups("seat", "selected"));
      } else {
        let nodes_tosetunavailable = [];
        let [sect, r, ss] = node.id.split("_")[1].split("-");
        if (this.selection.isMultiSelection) {
          let row_seats = this.inventoryService.getInventoryByRow(sect, r);
          for (let i = 0; i < row_seats.length; i++) {
            let aux_node = viewer.getNodeById(row_seats[i]);
            if (aux_node.groups.indexOf("locked") === -1 && aux_node.state === "available") {
              nodes_tosetunavailable.push(aux_node);
            }
          }
          const confirmMultiSeatSelectionOpt: ModalOptions = {
            animated: true,
            class: "modal-dialog-centered",
            backdrop: true,
            ignoreBackdropClick: true,
            initialState: {
              affected_seats: nodes_tosetunavailable,
              status_tochange: "unavailable",
            },
          };
          this.bsModalRef = this.modalService.show(ConfirmEditMultiSeatComponent, confirmMultiSeatSelectionOpt);
        } else {
          // remove seat
          nodes_tosetunavailable.push(node);
          viewer.setUnavailable("seat", nodes_tosetunavailable);

          this.inventoryService.controlDirty(nodes_tosetunavailable, "remove");

          this.inventoryService.updateInventory(nodes_tosetunavailable, "unavailable", toastrOpt);
        }
      }
    } else if (
      this.selection.isGroupsSelection &&
      viewer.getNodesByGroups("seat", "selected").length &&
      viewer.getNodesByGroups("seat", "tomove").length
    ) {
      const inventoryData = this.inventoryService.getInventoryData();
      const newSeats = { ids: [] };
      //on move group

      let tomove_lockeddetected = false;
      viewer.getNodesByGroups("seat", "tomove").forEach((seatNode) => {
        if (seatNode.groups.indexOf("locked") > -1) {
          tomove_lockeddetected = true;
        }
      });
      if (tomove_lockeddetected) {
        this.toastr.error("Locked seats detected, is not possible to set locked seats as available.");
        return;
      }

      viewer.getNodesByGroups("seat", "selected").forEach((seatNode) => {
        const split = seatNode.id.split("_")[1].split("-");
        const section = split[0];
        const row = split[1];
        const seat = split[2];
        delete inventoryData[section].elements[row].elements[seat].data.status;
      });
      let i = 0;
      let allocation_tomove = false;
      viewer.getNodesByGroups("seat", "tomove").forEach((seatNode) => {
        const split = seatNode.id.split("_")[1].split("-");
        const section = split[0];
        const row = split[1];
        const seat = split[2];
        inventoryData[section].elements[row].elements[seat].data.status = "available";
        newSeats.ids.push(seatNode.id);
        if (this.nodes_tomove[i].allocation) {
          allocation_tomove = true;
          inventoryData[section].elements[row].elements[seat].data.allocation = this.nodes_tomove[i].allocation;
          let [old_sect, old_row, old_seat] = this.nodes_tomove[i].code.split("_")[1].split("-");
          delete inventoryData[old_sect].elements[old_row].elements[old_seat].data.allocation;
        }
        i++;
      });
      if (allocation_tomove) {
        this.inventoryService.setInventoryData(inventoryData);
      }
      this.socialDistancing.filterNearest(viewer, newSeats.ids).subscribe((result) => {
        // TODO: En este punto se debería mostrar el popup y esperar el input del usuario antes de ejecutar el resto del código.
        const seatsToLock = result as string[];
        // TODO: Sustituir con el resultado del popup. true: los filterNearest se eliminan. false: los filterNearest se mantienen
        if (this.removeWarningNodes) {
          seatsToLock.forEach((seatNode) => {
            const split = (seatNode as string).split("_")[1].split("-");
            const section = split[0];
            const row = split[1];
            const seat = split[2];
            delete inventoryData[section].elements[row].elements[seat].data.status;
          });
        }

        // TODO: Esto antes estaba dentro del forEach. Creo que con hacer esto 1 vez en vez de n veces es suficiente, pero repasar por si hay alguna llamada que haya que hacer n veces
        const inventory = this.inventoryService.getAvailableInventory(true);
        this.inventoryService.updateTotalsHash();
        viewer.setAvailability("section", inventory.sections);
        viewer.setAvailability("seat", inventory.seats);
        this.inventoryService.availabilityLoadedSubject.next();
        this.categories.printInventoryCategories();
        this.dvmService.viewer.removeNodesFromGroup(viewer.getNodesByGroups("seat", "selected"), "selected");
        this.selection.selectionSubject.next(this.dvmService.viewer.getNodesByGroups("seat", "selected"));
        this.inventoryService.sendInventoryData(true).subscribe(
          (response) => {
            console.log(response);
          },
          (error) => {
            console.error(error);
          }
        );
      });
    } else if (this.selection.isSingleSelection || this.selection.isMultiSelection) {
      //new seat

      if (node.groups.indexOf("locked") > -1) {
        this.toastr.error("This seat is configured as locked. Is not possible to set as available.");
      } else {
        let nodes_tosetavailable = [];
        let [sect, r, ss] = node.id.split("_")[1].split("-");
        if (this.selection.isMultiSelection) {
          let row_seats = this.inventoryService.getInventoryByRow(sect, r);
          let error = false;
          if (!row_seats.length) {
            error = true;
            //seats no existen en el inventory
            /* row_seats = this.dvmService.viewer.getNodeById(node.id).row;
                        if(!row_seats){
                            //error no hay row attributr
                            row_seats = this.dvmService.getLogicRowSeats(node);
                            if(!row_seats.length){
                                error = true;
                            }
                        } */
          }
          let all_available_nodes = [];
          if (!error) {
            for (let i = 0; i < row_seats.length; i++) {
              let aux_node = viewer.getNodeById(row_seats[i]);
              if (aux_node.groups.indexOf("locked") === -1 && aux_node.state === "unavailable") {
                nodes_tosetavailable.push(aux_node);
              }
              all_available_nodes.push(aux_node);
            }
          }
          let status = "available";
          if (!nodes_tosetavailable.length && !error) {
            nodes_tosetavailable = all_available_nodes;
            status = "unavailable";
          }
          const confirmMultiSeatSelectionOpt: ModalOptions = {
            animated: true,
            class: "modal-dialog-centered",
            backdrop: true,
            ignoreBackdropClick: true,
            initialState: {
              affected_seats: nodes_tosetavailable,
              status_tochange: status,
              error: error,
            },
          };
          this.bsModalRef = this.modalService.show(ConfirmEditMultiSeatComponent, confirmMultiSeatSelectionOpt);
        } else {
          nodes_tosetavailable.push(node);

          if (!this.inventoryService.getInventoryBySeat(sect, r, ss).length) {
            const confirmMultiSeatSelectionOpt: ModalOptions = {
              animated: true,
              class: "modal-dialog-centered",
              backdrop: true,
              ignoreBackdropClick: true,
              initialState: {
                affected_seats: nodes_tosetavailable,
                status_tochange: "available",
                error: true,
              },
            };
            this.bsModalRef = this.modalService.show(ConfirmEditMultiSeatComponent, confirmMultiSeatSelectionOpt);
          } else {
            viewer.setAvailable("seat", nodes_tosetavailable);

            let section_affected = nodes_tosetavailable[0].id.split("-")[0];
            let n_av = viewer.getNodesByState("seat", "available", section_affected).length;
            if (n_av) {
              viewer.setAvailable("section", section_affected);
            }
            //setting dirty true and update sim db
            this.inventoryService.controlDirty(nodes_tosetavailable, "add");

            //update inventory y manifest
            this.inventoryService.updateInventory(nodes_tosetavailable, "available", toastrOpt);
          }
        }
      }
    }
  }

  //deprecated
  private ____toggleSelection(node, viewer) {
    let isGrouped = false;
    node.groups.forEach((element) => {
      console.log(parseInt(element, 10));
      if (parseInt(element, 10)) {
        isGrouped = true;
        if (node.groups.includes("selected")) {
          viewer.removeNodesFromGroup(this.groups.groupsHash.groups[element], "selected");
        } else {
          viewer.addNodesToGroup(this.groups.groupsHash.groups[element], "selected");
        }
      }
    });
    return isGrouped;
  }
  protected handle(obj) {
    // Close popovers
    const tooltip: HTMLElement = document.querySelector("#tooltip");
    tooltip.removeAttribute("data-show");

    // Handle
    const viewer = obj.instance;
    const nodes = obj.nodes;
    if (nodes.length) {
      if (nodes[0].type === "section") {
        this.sectionClickHandler(nodes[0], viewer);
      } else if (nodes[0].type === "seat") {
        this.seatClickHandler(nodes[0], viewer);
      }
    }
  }

  private ____updateParams(node, viewer, group?) {
    //node = viewer.getNodeById(node.id);
    const options = { count: Infinity, isolated_groups: this.socialDistancing.buildIsolatedGroups(viewer) };
    let that = this;
    viewer.social_distancing
      .getNeighbors(options, node.id)
      .then(function (result) {
        let nseats = result.length;
        let increase = true;
        if (!nseats) {
          increase = false;
          nseats = group.length;
        }
        if (!that.dataService.listParamsHashed[nseats]) {
          that.dataService.getParameterOrCreate(result.length).subscribe((response) => {
            that.dataService.listParamsHashed[nseats] = response;

            //let params = that.getParamsByNseats(nseats,increase);
          });
        } else {
          //let params = that.getParamsByNseats(nseats,increase);
        }
      })
      .catch(function (err) {
        console.error(err);
      });
  }
  /* private ____getParamsByNseats(current_nseats,increase){
        let original_params =  this.dataService.simulationData.parameters;
        let hashedNseats = {};
        for(let i =0;i<original_params.length;i++){
            let computed_nseats = original_params[i].computed_nseats;
            let nseats = original_params[i].parameter.nseats;
            hashedNseats[nseats]=computed_nseats;
        }

        if(!hashedNseats[current_nseats]){
            console.log("no existe en la hash")
            if(increase){
                console.log("suma cuando no existe en la hash");
                hashedNseats[current_nseats] = current_nseats;
                
                if(current_nseats-1 && (current_nseats-1)>0 && !hashedNseats[current_nseats-1]){
                    console.log("nseats menor que el current mayor que 0")
                    hashedNseats[current_nseats-1] = current_nseats-1;
                }
            }else{
                console.log("resta cuando no existe en la hash");
                
                hashedNseats[current_nseats] = 0;
                if(hashedNseats[current_nseats-1]){
                    console.log("resta al menor cuando existe en la hash");
                    hashedNseats[current_nseats-1] += current_nseats-1;
                }
                
            }
        }else{
            console.log("existe en la hash")
            if(increase){
                console.log("existe y se incrementa")
                hashedNseats[current_nseats]+=current_nseats;
                if(current_nseats-1 && (current_nseats-1) > 0){
                    if(!hashedNseats[current_nseats-1]){
                        console.log("existe en la hash, pero no el menor, se reduce el menor")
                    
                        hashedNseats[current_nseats-1] = 0;
                        
                    }else{
                        console.log("existe en la hash, el menor se reduce")
                        hashedNseats[current_nseats-1]-=current_nseats;
                    }
                }else{
                    console.log("existe el menor en la hash")
                    hashedNseats[current_nseats-1] = 0;
                }
            }else{
                console.log("existe y se resta")
                hashedNseats[current_nseats]-=current_nseats;
                if(hashedNseats[current_nseats-1]){
                    console.log("el menor existe y se resta")
                    hashedNseats[current_nseats-1]+=current_nseats-1;
                }else{
                    console.log("el menor no existe y se iguala")
                    hashedNseats[current_nseats-1]=current_nseats-1
                }

                
            }
        }
        console.log(hashedNseats);
    }*/
}
