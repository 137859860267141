import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { youtubeVideo } from '../../models/video.model';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-public-videos',
  templateUrl: './public-videos.component.html',
  styleUrls: ['./public-videos.component.scss']
})
export class PublicVideosComponent implements OnInit {

  constructor(
    private bsModalRef: BsModalRef,
    private sanitizer: DomSanitizer) {

  }

  video : youtubeVideo;

  ngOnInit(): void {
    this.video.url  = this.sanitizer.bypassSecurityTrustResourceUrl(this.video.raw);
  }
  public onDismiss() {
    this.bsModalRef.hide();
  }
}
