import { Injectable } from '@angular/core';
import { AuthorizationData } from '../social-distance-editor/shared/models/authorization-data.model';
import { User } from '../social-distance-editor/shared/models/user-data.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class AuthenticationService {

  authRoot = '/auth';

    private currentUser: any;
    private crsfToken = null;
    private readonly userLogged$: BehaviorSubject<User | false>;


    constructor(private http: HttpClient) {
        this.userLogged$ = new BehaviorSubject<User | undefined>(undefined);
     }

    private tapSetUser = tap((result: User) => {
        const retValue = this.setUser(result);
        this.currentUser = retValue;
        this.userLogged$.next(retValue);
    });

    private setUser(result: User): User | false {
        const retValue: User | false = result;
        if (result.token) {
            this.crsfToken = result.token;
            const expiresIn = Math.round(Date.now() + result.expires_in * 1000);
            localStorage.setItem('token', JSON.stringify({ token: result.token, expires_in: expiresIn}));
        }
        return retValue;
    }

    private _getUserLogged(): Observable<User> {
        return this.http.get(`${this.authRoot}/user_logged/`).pipe(
            this.tapSetUser, ) as Observable<User>;
    }

    public getUserLogged(): Promise<User | false> {
        const promise = new Promise<User | false>((resolve, reject) => {
            if (this.currentUser === undefined) {
                this._getUserLogged().toPromise().then(data => {
                    resolve(this.setUser(data));
                }).catch(e=>{
                    console.log("error")
                });
            } else {
                resolve(this.currentUser);
            }
        });
        return promise;
    }

    public getUserLogged$(): Observable<User | false> {
        return this._getUserLogged();
        // return this.userLogged$.asObservable();
    }
    public getUserLoggedSubject(): Observable<User | false> {
        return this.userLogged$.asObservable();
    }

    public login(loginData: AuthorizationData): Observable<User> {
        const data: AuthorizationData = {
            username: loginData.username,
            password: loginData.password,
            'grant-type': 'password'
        };
        return this.http.post(`${this.authRoot}/login/`, data).pipe(
            this.tapSetUser,
          ) as Observable<User>;
    }

    public logout(): Observable<any> {
        this.userLogged$.next(undefined);
        this.currentUser = undefined;
        return this.http.get(`${this.authRoot}/logout`);
    }

    public getCsrfToken() {
        return this.crsfToken;
    }

    public isTokenExpired(): boolean {
        if (localStorage.getItem('token') === null) {
            return true;
        } else {
            const token = JSON.parse(localStorage.getItem('token'));
            const date = Date.now();
            if (date > token.expires_in) {
                localStorage.removeItem('token');
                return true;
            }
            return false;
        }
    }

    public getUser(){
        return this.currentUser;
    }
}
