import { Injectable } from "@angular/core";
import { InventoryService } from "../../../../services/inventory.service";
import { AllocatorService } from "../allocator.service";
import { DataService } from "../../../../services/data.service";
import JSZip from "jszip";
import { parseCSVToJSON } from "../../utils/parse";
import { SocialdistancingService } from "../../../../services/socialdistancing.service";
import {
  MapViewer,
  SocialDistancingAssignGroup,
  SocialDistancingAssignGroupResult,
  SocialDistancingAssignToCurrentResult,
  SocialDistancingGetGroupsInput,
} from "@3ddv/dvm-internal";

/* GENERIC */
export interface CsvParserGetGroupsInput {
  csvClients: string;
  csvCategories: string;
  upgrade: boolean;
  downgrade: boolean;
}
export interface CsvParserGetGroupsOutput {
  groups: SocialDistancingAssignGroup[];
}

export interface CsvParser {
  getGroups(input: CsvParserGetGroupsInput): CsvParserGetGroupsOutput;
}

interface CsvJson {
  [key: string]: string;
}

interface HashAreas {
  [key: string]: string[];
}

export interface ParsedCsvs {
  groups: SocialDistancingAssignGroup[];
  hashAreas: HashAreas;
  sortedAreas: SortedAreas;
}

export interface MlbClientsRow {
  FINANCIAL_ACCOUNT_ID: string;
  FINANCIAL_ACCOUNT_NAME: string;
  FINANCIAL_FIRST_NAME: string;
  FINANCIAL_LAST_NAME: string;
  FINANCIAL_ADDRESS_1: string;
  FINANCIAL_ADDRESS_2: string;
  FINANCIAL_CITY: string;
  FINANCIAL_POSTAL_CODE: string;
  FINANCIAL_SUBCOUNTRY_CODE: string;
  FINANCIAL_EMAIL: string;
  FINANCIAL_PRI_PHONE_DISPLAY: string;
  PACKAGE_DESC: string;
  PRICE_SCALE_DESC: string;
  BUYER_TYPE_CODE: string;
  BUYER_TYPE_DESC: string;
  SECTION_DESC: string;
  ROW: string;
  SEAT_NUMBER: string;
  SEAT_ID: string;

  TICKET_PRICE: string;
  TENURE: string;
  Concat: string;
  "Sec Priority": string;
  "Row Priority": string;
  "Tenure Priority": string;
  "Spend Priority": string;
  Priority: string;

  UniqueID: string;
}

const HEADER = [
  "FINANCIAL_ACCOUNT_ID",
  "FINANCIAL_ACCOUNT_NAME",
  "FINANCIAL_FIRST_NAME",
  "FINANCIAL_LAST_NAME",
  "FINANCIAL_ADDRESS_1",
  "FINANCIAL_ADDRESS_2",
  "FINANCIAL_CITY",
  "FINANCIAL_POSTAL_CODE",
  "FINANCIAL_SUBCOUNTRY_CODE",
  "FINANCIAL_EMAIL",
  "FINANCIAL_PRI_PHONE_DISPLAY",
  "PACKAGE_DESC",
  "PRICE_SCALE_DESC",
  "BUYER_TYPE_CODE",
  "BUYER_TYPE_DESC",
  "SECTION_DESC",
  "ROW",
  "SEAT_NUMBER",
  "SEAT_ID",
  "TICKET_PRICE",
  "TENURE",
  "Concat",
  "Sec Priority",
  "Row Priority",
  "Tenure Priority",
  "Spend Priority",
  "Priority",
];

export interface MlbAssignedClientsRow extends MlbClientsRow {
  "Assigned Area": string;
  "Assigned Row": string;
  "Assigned Seat": string;
  fullId: string;
}

interface MlbAreasRow {
  SectionName: string;
  Category: string;
}

interface ParseOutput {
  groups: SocialDistancingAssignGroup[];
  parsedClients: MlbClientsRow[];
  sortedAreas: SortedAreas;
}

type SortedAreas = string[];

interface CsvParserMlbGetGroupsOutput extends CsvParserGetGroupsOutput {
  parsedClients: MlbClientsRow[];
}

@Injectable({
  providedIn: "root",
})
export class MlbParserService {
  constructor(
    private inventoryService: InventoryService,
    private allocatorService: AllocatorService,
    private dataService: DataService,
    private socialdistancingService: SocialdistancingService
  ) {}

  public full(
    viewer: MapViewer,
    csvClients: string,
    csvCategories: string,
    upgrade: boolean,
    downgrade: boolean,
    maps?: string[]
  ): Promise<SocialDistancingAssignToCurrentResult> {
    const obj: CsvParserGetGroupsInput = {
      csvClients,
      csvCategories,
      upgrade,
      downgrade,
    };
    const { groups, parsedClients } = this.getGroups(obj);

    const getGroupsInput: SocialDistancingGetGroupsInput = {
      neighbor_distance: this.socialdistancingService.neighborDistance,
    };

    return this.allocatorService.assignGroupsToCurrent(viewer, { groups, maps }).then((result) => {
      const filledClients = this.fillClientRows(parsedClients, result.assigned);
      const bySeatId = this.getRowsBySeat(filledClients);

      const [csvResultAssigned, csvResultUnassigned] = this.parseJSONToCSV(filledClients);

      const inventory = this.inventoryService.getInventoryData();
      this.allocatorService.clearInventoryAllocation(inventory);

      return this.fillInventoryAllocation(inventory, bySeatId).then(() => {
        const zip = new JSZip();
        const blob1 = new Blob([csvResultAssigned], { type: "text/csv" });
        const blob2 = new Blob([csvResultUnassigned], { type: "text/csv" });
        zip.file("assigned.csv", blob1);
        zip.file("unassigned.csv", blob2);

        return zip
          .generateAsync({ type: "blob", mimeType: "application/zip" })
          .then((zipFile) => {
            this.allocatorService.resultFile = zipFile;
            return this.dataService
              .sendAllocationResult(this.dataService.currentSimulationId, this.allocatorService.ref, zipFile)
              .toPromise();
          })
          .then(() => {
            return result;
          });
      });
    });
  }

  public getGroups(input: CsvParserGetGroupsInput): CsvParserMlbGetGroupsOutput {
    const { csvClients, csvCategories, upgrade, downgrade } = input;
    const parsedClients = parseCSVToJSON<MlbClientsRow>(csvClients);

    // assign fake id
    let uniqueId = 1;
    parsedClients.forEach((r) => (r.UniqueID = `${uniqueId++}`));
    const { groups } = this.parseCSVs(parsedClients, csvCategories, upgrade, downgrade);
    return { groups, parsedClients };
  }

  public getRowsBySeat(parsedClients: Iterable<MlbAssignedClientsRow>): Map<string, MlbAssignedClientsRow> {
    const map = new Map<string, MlbAssignedClientsRow>();
    for (const client of parsedClients) {
      const seatId = `S_${client["Assigned Area"]}-${client["Assigned Row"]}-${client["Assigned Seat"]}`;
      map.set(seatId, client);
    }
    return map;
  }

  public getRowsByClient(parsedClients: Iterable<MlbAssignedClientsRow>): Map<string, MlbAssignedClientsRow> {
    const map = new Map<string, MlbAssignedClientsRow>();
    for (const client of parsedClients) {
      map.set(client.UniqueID, client);
    }
    return map;
  }

  public fillClientRows(
    parsedClients: Iterable<MlbClientsRow>,
    groups: Iterable<SocialDistancingAssignGroupResult>
  ): MlbAssignedClientsRow[] {
    // Primero, creamos los grupos por cliente
    const clientsStruct = new Map<string, SocialDistancingAssignGroupResult>();
    const seatsStruct = new Map<string, SocialDistancingAssignGroupResult>();
    const clientsMap = new Map<string, string>();

    for (const group of groups) {
      if (group.assigned.length) {
        for (let i = 0; i < group.assigned.length; ++i) {
          const seatId = group.assigned[i];
          const clientId = group.members[i];
          seatsStruct.set(seatId, group);
          clientsStruct.set(clientId, group);
          clientsMap.set(clientId, seatId);
        }
      }
    }

    const assignedRows: MlbAssignedClientsRow[] = [];
    // Segundo, añadimos los datos a las rows
    for (const row of parsedClients) {
      const { UniqueID } = row;
      const group = clientsStruct.get(UniqueID);
      const seatId = clientsMap.get(UniqueID);
      const obj1 = {
        "Assigned Area": "",
        "Assigned Row": "",
        "Assigned Seat": "",
        fullId: "",
      };

      const obj: MlbAssignedClientsRow = Object.assign(obj1, row);

      if (seatId) {
        const split = seatId.split("-");
        const partialSectionId = split[0].split("S_")[1];
        const partialRowId = split[1];
        const partialSeatId = split[2];
        obj.fullId = seatId;
        obj["Assigned Area"] = partialSectionId;
        obj["Assigned Row"] = partialRowId;
        obj["Assigned Seat"] = partialSeatId;
      }
      assignedRows.push(obj);
    }

    return assignedRows.sort((a, b) => parseInt(a.Priority, 10) - parseInt(b.Priority, 10));
  }

  public parseJSONToCSV(assignedRows: MlbAssignedClientsRow[]): [string, string] {
    assignedRows.sort((r1, r2) => parseInt(r1.Priority, 10) - parseInt(r2.Priority, 10));

    const first = HEADER.slice();
    first.push("Assigned Area", "Assigned Row", "Assigned Seat");
    const outputAssigned: string[][] = [first.slice()];
    const outputUnassigned: string[][] = [first.slice()];

    for (const row of assignedRows) {
      const outputRow = [];
      for (const prop of HEADER) {
        outputRow.push(row[prop]);
      }
      const areaId = row["Assigned Area"];
      const rowId = row["Assigned Row"];
      const seatId = row["Assigned Seat"];
      const output = areaId && rowId && seatId ? outputAssigned : outputUnassigned;
      outputRow.push(areaId, rowId, seatId);
      output.push(outputRow);
    }

    return [
      outputAssigned.map((arr) => arr.join(";")).join("\n"),
      outputUnassigned.map((arr) => arr.join(";")).join("\n"),
    ];
  }

  private parseCSVs(
    parsedClients: MlbClientsRow[],
    inputAreas: string,
    upgrade: boolean,
    downgrade: boolean
  ): ParsedCsvs {
    const { hash: parsedAreas, sorted: sortedAreas } = this.parseCSVAreas(inputAreas);

    let UNIQUEID = 1;
    const rowsByUniqueId: Map<string, MlbClientsRow> = new Map();
    const hash: { [key: string]: SocialDistancingAssignGroup } = {};
    for (const row of parsedClients) {
      const groupId: string = row.FINANCIAL_ACCOUNT_ID;
      const clientId = `${UNIQUEID++}`;
      const preferredArea: string = row["Sec Priority"];
      const priority: number = parseInt(row.Priority, 10);

      rowsByUniqueId.set(clientId, row);

      if (groupId == null || clientId == null) {
        continue;
      }

      if (hash[groupId] == null) {
        const sdmGroupInput: SocialDistancingAssignGroup = hash[groupId] || {
          group_id: groupId,
          priority,
          members: [],
          preferred: this.parsePreferredAreas(preferredArea, parsedAreas, upgrade, downgrade),
        };
        hash[groupId] = sdmGroupInput;
      }
      const obj = hash[groupId] as SocialDistancingAssignGroup;
      obj.members.push(clientId);
    }

    const result = {
      groups: Object.values(hash).sort((a, b) => a.priority - b.priority),
      hashAreas: parsedAreas,
      sortedAreas,
    };

    return result;
  }

  private parseCSVAreas(input: string): { hash: HashAreas; sorted: string[] } {
    const parsed = parseCSVToJSON<MlbAreasRow>(input);
    const hash: HashAreas = {};
    const sorted: string[] = [];
    for (const row of parsed) {
      const { SectionName, Category } = row;
      if (hash[Category] == null) {
        hash[Category] = [];
      }
      const sectionId = "S_" + SectionName;
      hash[Category].push(sectionId);
      sorted.push(sectionId);
    }
    return { hash, sorted };
  }

  private parsePreferredAreas(category: string, areas: HashAreas, upgrade: boolean, downgrade: boolean): string[] {
    const set = new Set(areas[category]);

    if (downgrade) {
      const keys = Object.keys(areas).sort((a, b) => parseInt(a, 10) - parseInt(b, 10));
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < keys.length; ++i) {
        const cat = keys[i];
        if (cat > category) {
          areas[cat].forEach((n) => set.add(n));
        }
      }
    }

    if (upgrade) {
      const keys = Object.keys(areas).sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < keys.length; ++i) {
        const cat = keys[i];
        if (cat < category) {
          areas[cat].forEach((n) => set.add(n));
        }
      }
    }

    return Array.from(set);
  }

  protected fillInventoryAllocation(inventory, bySeatId: Map<string, MlbAssignedClientsRow>) {
    for (const [fullId, row] of bySeatId) {
      const sectionId = row["Assigned Area"];
      const rowId = row["Assigned Row"];
      const seatId = row["Assigned Seat"];
      if (sectionId && rowId && seatId) {
        const data = inventory[sectionId]?.elements[rowId]?.elements[seatId]?.data;
        if (data) {
          data.allocation = Object.assign({}, row);
          data.allocation.allocated = true;
          // TODO
          data.allocation.popup = {
            "Account ID": row.FINANCIAL_ACCOUNT_ID,
            // Client: row.FINANCIAL_ACCOUNT_NAME,
          };
        }
      }
    }

    return this.allocatorService.setSimulationAsAllocation(inventory);
  }
}

// *** Test funcs ***

function countGroups(hash: { [p: string]: SocialDistancingAssignGroup }) {
  let total = 0;
  const countHash: any = {};
  const percentHash: any = {};
  for (const i in hash) {
    if (hash.hasOwnProperty(i)) {
      const group = hash[i];
      total++;
      countHash[group.members.length] != null
        ? countHash[group.members.length]++
        : (countHash[group.members.length] = 1);
    }
  }
  for (const i in countHash) {
    if (countHash.hasOwnProperty(i)) {
      percentHash[i] = ((countHash[i] / total) * 100).toFixed(2) + "%";
    }
  }

  return [countHash, percentHash];
}
