import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DataService } from 'src/app/social-distance-editor/services/data.service';
import { AuthenticationService } from 'src/app/auth';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { CategoryPriceModalComponent } from 'src/app/social-distance-editor/shared/modals/category-price-modal/category-price-modal.component';
import { GroupsService } from 'src/app/social-distance-editor/services/groups.service';
import { SelectionService } from 'src/app/social-distance-editor/services/selection.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CategoriesService } from 'src/app/social-distance-editor/services/categories.service';
import { FormatNumberService } from 'src/app/social-distance-editor/services/format-number.service';
import { DVMService } from 'src/app/social-distance-editor/services/dvm.service';
import { SocialdistancingService } from 'src/app/social-distance-editor/services/socialdistancing.service';
import { Subscription, Subject } from 'rxjs';
import { ComputeResultsModalComponent } from 'src/app/social-distance-editor/shared/modals/compute-results-modal/compute-results-modal.component';
import { InventoryService } from 'src/app/social-distance-editor/services/inventory.service';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})

export class TopbarComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  @Input() page: 'setup' | 'simulation';
  @Input() configurationSubject: Subject<any>;

  get isSimulation() {
    return this.page === 'simulation';
  }

  // tslint:disable-next-line: variable-name
  _clientData;
  currentVenue;
  bsModalRef: BsModalRef;
  configId: number;
  levels: Array<string> = [];
  currentSimulation = null;
  configuration;

  currentAvailability = 0;
  currentAvailabilityPercentage = '';
  currentMapId;

  get availability() {
    return this.currentAvailability;
  }

  get availabilityPercentage() {
    return this.currentAvailabilityPercentage;
  }

  constructor(private dataService: DataService,
              private authService: AuthenticationService,
              private modalService: BsModalService,
              private router: Router,
              private activateRoute: ActivatedRoute,
              private categories: CategoriesService,
              private dvmService: DVMService,
              private inventoryService: InventoryService,
              private socialDistancing: SocialdistancingService,
              private numberFormatService: FormatNumberService) { }

  ngOnInit(): void {
    const s = this.dvmService.mapSubject.subscribe(
      response => {
        s.unsubscribe();
        this.currentMapId = this.dvmService.viewer.getMapId();
      }
    );
    this.currentSimulation = this.dataService.simulationData;
    this.configurationSubject.subscribe(
      configuration => {
        console.log(configuration);
        this.configuration = configuration;
        if (configuration.tier_list && configuration.tier_list.length) {
          this.levels = configuration.tier_list;
        }
      }
    );
    if (this.currentSimulation == null) {
    this.subscriptions.push(this.dataService.getCurrentSimulation$().subscribe(
      simulation => {
        this.currentSimulation = simulation;
        console.log(this.currentSimulation);
      }
    ));
    }
    this.configId = this.activateRoute.snapshot.params.id;
    this.subscriptions.push(this.dataService.getCurrentVenue$().subscribe(
      currentVenue => {
        this.currentVenue = currentVenue;
        console.log(currentVenue);
      }
    ));
    this.subscriptions.push(this.authService.getUserLogged$().subscribe(
      client => {
        if (typeof client !== 'boolean') {
          this._clientData = client.user;
          this.getClientConfigs(this._clientData.client.id);
        }
      },
      error => {
        console.log('error landing get user logged');
        console.log(error);
      }
    ));
    this.subscriptions.push(this.socialDistancing.calculationFinishedsubject.subscribe(
      result => {
        const availability = this.dvmService.viewer.getNodesByState('seat', 'available');
        if (availability.length) {
          const totalSeats = this.dvmService.viewer.getNodesByType('seat');
          this.currentAvailability = availability.length;
          this.currentAvailabilityPercentage = (100 * availability.length / totalSeats.length).toFixed(2);
        } else {
          this.currentAvailability = 0,
          this.currentAvailabilityPercentage = '';
        }
      }
    ));
  }

  private getClientConfigs(clientId: number) {
    return this.dataService.getClientConfigs(clientId).subscribe(
      configs => {
        const venueHash = {};
        configs.forEach(config => {
          venueHash[config.id] = config;
        });
        this.dataService.venueData = venueHash;
        console.log(venueHash);
      },
      error => {
        console.log(error);
      }
    );
  }

  public onCategoriesPrices(): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-start info-modal',
      backdrop: false,
      ignoreBackdropClick: false,
      initialState: {
        currency: this._clientData.client.currency,
        configId: this.configId,
        isSimulation: this.isSimulation,
        simulation: this.currentSimulation
      }
    };
    this.bsModalRef = this.modalService.show(CategoryPriceModalComponent, modalConfig);
    this.categories.setCategorySubject();
  }

  get clientData() {
    if (this._clientData) {
      return this._clientData;
    }
  }

  get venueData() {
    if (this.dataService.venueData && this.currentVenue) {
      return this.dataService.venueData[this.currentVenue];
    }
  }

  get units() {
    if (this.dataService.getUser()) {
      return this.dataService.getUnits(this.dataService.getUser().user.client.metric_system);
    }
  }

  public onBack(): void {
    this.router.navigate(['/home']);
  }

  public formatNumber(price: number): string {
    return this.numberFormatService.formatNumber(price);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public onComputeResults(): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {
      }
    };
    this.bsModalRef = this.modalService.show(ComputeResultsModalComponent, modalConfig);
  }

  public loadMap(mapId,level_name) {
    if (mapId !== this.dvmService.viewer.getMapId()) {
    this.dvmService.changeMapConfiguration(this.configuration['venue_code'], mapId);
    this.dvmService.mapSubject.subscribe(
      r => {
          const availability = this.inventoryService.getAvailableInventory(this.isSimulation);
          this.dvmService.viewer.setAvailability('seat', availability.seats);
          this.dvmService.viewer.setAvailability('section', availability.sections);
          this.categories.printInventoryCategories();
          this.currentMapId = mapId;
          document.getElementById("currentlevel").textContent = level_name;
        }
      );
    }
  }

  isCurrentMap(mapId) {
    if (mapId && this.currentMapId) {
      return mapId === this.currentMapId;
    }
  }
}
