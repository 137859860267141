import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthenticationService } from 'src/app/auth';
import { Observable } from 'rxjs';


@Injectable()
export class HttpCsrfInterceptor implements HttpInterceptor {

    constructor(private auth: AuthenticationService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headerName = '';
        let token;
        if (localStorage.getItem('token') !== null) {
            token = JSON.parse(localStorage.getItem('token'));
            if (token.token !== null && !req.headers.has(headerName)) {
                // console.log(req)
                if(req.url!="https://s3.eu-central-1.amazonaws.com/test-dev.sdm.staticresources.pre-3ddvapi.com/nam-us-10177-royals/scv/view_4/seating.json"){
                    req = req.clone({
                        setHeaders: {
                            Authorization: `Bearer ${token.token}`
                        }
                    });
                }
            }
        }
        return next.handle(req);
    }
}
