import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'LockFilter'
})

export class SearchPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) return [];
        if (!searchText) return items;
        searchText = searchText.toLowerCase();
        const values = Object.values(items);
        // return items.filter(it => {
        //     console.log(it);
        //     console.log(property);
        //     return it[property].toLowerCase().includes(searchText);
        // });

        // return items.filter(o => {
        //     console.log(o);
        //     Object.keys(o).some(k => {
        //         console.log(k);
        //         console.log(o[k]);
        //         o[k].includes(searchText.toLowerCase());
        //     });
        // });

        return items.filter(o => {
            return Object.keys(o).some(k => {
                if (typeof o[k] === 'string') {
                    return o[k].toLowerCase().includes(searchText.toLowerCase());
                } else {
                    return String(o[k]).toLowerCase().includes(searchText.toLowerCase());
                }
            });
        });

    }
}
