import { Injectable } from "@angular/core";
import { InventoryService } from "../../../services/inventory.service";
import { DataService } from "../../../services/data.service";
import { SocialdistancingService } from "../../../services/socialdistancing.service";
import { DVMService } from "../../../services/dvm.service";
import { AuthenticationService } from "../../../../auth";
import { Subscription } from "rxjs";
import { SDEPlugin } from "../../../shared/models/user-data.model";
import { CategoriesService } from "../../../services/categories.service";
import { AllocatorRefs } from "../allocator.module";
import { GroupsService } from "../../../services/groups.service";
import {
  MapViewer,
  MapViewerInitializerOptions,
  MapViewerLoadMapOptions,
  MapViewerNode,
  SocialDistancingAssignGroup,
  SocialDistancingAssignGroupResult,
  SocialDistancingAssignInput,
  SocialDistancingAssignOutput,
  SocialDistancingAssignToCurrentResult,
} from "@3ddv/dvm-internal";

interface AssignGroupsInput {
  groups: SocialDistancingAssignGroup[];
  maps?: string[];
}

interface AssignGroupsFromCurrentInput {
  groups: SocialDistancingAssignGroup[];
  // getGroupsInput?: SocialDistancingGetGroupsInput;
  maps?: string[];
}

@Injectable({
  providedIn: "root",
})
export class AllocatorService {
  get allowAllocation(): boolean {
    return this._allowAllocation;
  }

  private _allowAllocation = false;
  public ref: AllocatorRefs = "";
  public resultFile: Blob | null = null;
  public resultFileName: string | null = null;
  private userSubscription: Subscription;

  private showAllocatedSeatsFlag = false;

  get showAllocatedSeats(): boolean {
    return this.showAllocatedSeatsFlag;
  }

  set showAllocatedSeats(value: boolean) {
    const old = this.showAllocatedSeatsFlag;
    this.showAllocatedSeatsFlag = value;

    if (old !== value) {
      const inventory = this.inventoryService.getInventoryData();
      const viewer = this.dvmService.viewer;
      if (inventory && viewer) {
        this.setAllocatedGroup(viewer, inventory);
      }
    }
  }

  constructor(
    private dvmService: DVMService,
    private inventoryService: InventoryService,
    private socialdistancingService: SocialdistancingService,
    private dataService: DataService,
    private authService: AuthenticationService,
    private categoriesService: CategoriesService,
    private groupsService: GroupsService
  ) {
    this.userSubscription = this.authService.getUserLoggedSubject().subscribe((user) => {
      if (user) {
        // TODO: plugin debería tener un nombre génerico (allocator?) o ser dos plugins (allocator + parser)
        const plugin = user.user.plugins.find((pl) => pl.type === "allocation") as SDEPlugin | null;
        if (plugin) {
          this._allowAllocation = true;
          this.ref = (plugin.ref ?? "").toLowerCase() as AllocatorRefs;
          return;
        }
      }
      this._allowAllocation = false;
      this.ref = "";
    });
    this.categoriesService.printCategoriesObservable$.subscribe((viewer) => {
      const inventory = this.inventoryService.getInventoryData();
      this.setAllocatedGroup(viewer, inventory);
    });
    this.inventoryService.inventoryObservable$.subscribe((inventory) => {
      const viewer = this.dvmService.viewer;
      this.setAllocatedGroup(viewer, inventory);
    });
  }

  // public showModal(modalConfig?: ModalOptions): BsModalRef {
  //     switch (this.ref) {
  //         case 'sportsallianceallocator':
  //             return this.modalService.show(SportsAllianceModalComponent, modalConfig);
  //         case 'laligaallocator':
  //             return this.modalService.show(LaligaModalComponent, modalConfig);
  //         case 'mlballocator':
  //             return this.modalService.show(MlbModalComponent, modalConfig);
  //     }
  //
  //     throw new Error('Invalid allocator ref');
  // }

  public reset() {
    this.resultFile = null;
    this.resultFileName = null;
  }

  public assignGroups(
    viewer: MapViewer,
    input: AssignGroupsInput,
    socialDistancing?: number,
    aisleSeats?: number
  ): Promise<SocialDistancingAssignOutput> {
    if (!viewer.social_distancing) {
      return Promise.reject(new Error(`Missing 'social_distancing' plugin`));
    }

    if (!this._allowAllocation) {
      return Promise.reject(new Error("Allocations not allowed"));
    }

    const { groups, maps } = input;

    groupsReport(groups);

    const currentMapId = viewer.getMapId();
    const venueId = viewer.getVenueId();

    const mainPromise = this.assignGroups2(viewer, groups, socialDistancing, aisleSeats).then((result) => {
      // TODO: do stuff only for main viewer
      return result;
    });
    const promiseArray: Promise<SocialDistancingAssignOutput>[] = [mainPromise];

    if (maps) {
      const index = maps.indexOf(currentMapId);
      if (index > -1) {
        maps.splice(index, 1);
      }

      maps.forEach((mapId) => {
        const viewerConfig = this.dvmService.getDVMConfig() as MapViewerInitializerOptions & MapViewerLoadMapOptions;
        viewerConfig.venue_id = this.dvmService.viewer.getVenueId();
        viewerConfig.map_id = mapId;
        viewerConfig.container = "dummy-viewer-container";

        const promise = (window as any).DVM.loadModule("map_viewer", viewerConfig).then((localViewer) => {
          return localViewer
            .loadMap(viewerConfig)
            .then((response) => {
              const availableInventory = this.inventoryService.getAvailableInventory(true).seats;
              localViewer.setAvailability("seat", availableInventory);
              return this.assignGroups2(localViewer, groups, aisleSeats);
            })
            .then((result) => {
              // TODO: do stuff only for secondary viewers
              localViewer.reset();
              localViewer.close();

              return result;
            });
        });

        promiseArray.push(promise);
      });
    }

    return Promise.all(promiseArray).then((resultArray) => {
      this.reset();
      const merge = this.mergeAssignResults(resultArray);
      return merge;
    });
  }

  public assignGroupsToCurrent(
    viewer: MapViewer,
    input: AssignGroupsFromCurrentInput,
    maxAssignedClients?: number
  ): Promise<SocialDistancingAssignToCurrentResult> {
    if (!viewer.social_distancing) {
      return Promise.reject(new Error(`Missing 'social_distancing' plugin`));
    }

    if (!this._allowAllocation) {
      return Promise.reject(new Error("Allocations not allowed"));
    }

    const { groups, maps } = input;

    groupsReport(groups);

    const currentMapId = viewer.getMapId();
    const venueId = viewer.getVenueId();

    const inventoryMapMissmatches: string[] = [];
    const inventory = this.inventoryService.getInventoryData();

    let total = 0;
    const s1 = viewer.getNodesByType("seat");
    s1.forEach(checkMissmatch);
    total += s1.length;

    function checkMissmatch(node: MapViewerNode) {
      const id: string = node.id;
      const split: string[] | null = id.split("S_")?.[1]?.split("-") ?? null;
      let success = false;
      if (split) {
        const [sectionId, rowId, seatId] = split;
        const data = inventory[sectionId]?.elements[rowId]?.elements[seatId]?.data;
        if (data) {
          success = true;
        }
      }
      if (!success) {
        inventoryMapMissmatches.push(node.id);
      }
    }
    const mainPromise = this.assignGroupsToCurrent2(viewer, groups, maxAssignedClients).then((result) => {
      // TODO: do stuff only for main viewer
      return result;
    });
    const promiseArray: Promise<SocialDistancingAssignToCurrentResult>[] = [mainPromise];

    if (maps) {
      const index = maps.indexOf(currentMapId);
      if (index > -1) {
        maps.splice(index, 1);
      }

      maps.forEach((mapId) => {
        const viewerConfig = this.dvmService.getDVMConfig() as MapViewerInitializerOptions & MapViewerLoadMapOptions;
        viewerConfig.venue_id = this.dvmService.viewer.getVenueId();
        viewerConfig.map_id = mapId;
        viewerConfig.container = "dummy-viewer-container";

        const promise = (window as any).DVM.loadModule("map_viewer", viewerConfig).then((localViewer) => {
          return localViewer
            .loadMap(viewerConfig)
            .then((response) => {
              const availableInventory = this.inventoryService.getAvailableInventory(true).seats;
              localViewer.setAvailability("seat", availableInventory);
              const s2 = localViewer.getNodesByType("seat");
              s2.forEach(checkMissmatch);
              total += s2.length;
              return this.assignGroupsToCurrent2(localViewer, groups, maxAssignedClients);
            })
            .then((result) => {
              // TODO: do stuff only for secondary viewers
              localViewer.reset();
              localViewer.close();

              return result;
            });
        });

        promiseArray.push(promise);
      });
    }

    return Promise.all(promiseArray).then((resultArray) => {
      // console.log(`TOTAL SEATS: ${total}`);
      if (inventoryMapMissmatches.length) {
        console.warn(`INVENTORY - MAP MISSMATCHES:\n${inventoryMapMissmatches.join(", ")}`);
      }
      this.reset();
      const merge = this.mergeAssignToCurrentResults(resultArray, maxAssignedClients);
      return merge;
    });
  }

  private assignGroups2(
    viewer: MapViewer,
    groups: SocialDistancingAssignGroup[],
    socialDistancing?: number,
    aisleSeats?: number
  ): Promise<SocialDistancingAssignOutput> {
    if (!viewer.social_distancing) {
      return Promise.reject(`Missing 'social_distancing' plugin`);
    }

    const obj: SocialDistancingAssignInput = {
      groups: filterOwnParents(viewer, groups),
      neighbor_distance: this.socialdistancingService.neighborDistance,
      security_distance: socialDistancing ?? this.socialdistancingService.securityDistance,
      from_edges: true,
      only_from_edges: this.socialdistancingService.limit2groups,
      kill_edges: aisleSeats ?? this.socialdistancingService.isleSeats,
      invalidated: viewer
        .getNodesByGroups("seat", this.groupsService.getGroupBehaviors("invalidated"))
        .map((n) => n.id),
      validated: viewer.getNodesByGroups("seat", this.groupsService.getGroupBehaviors("validated")).map((n) => n.id),
      isolated_groups: this.socialdistancingService.buildIsolatedGroups(viewer),
    };

    return viewer.social_distancing.assignGroups(obj);
  }
  private assignGroupsToCurrent2(
    viewer: MapViewer,
    groups: SocialDistancingAssignGroup[],
    maxAssignedClients?: number
  ): Promise<SocialDistancingAssignToCurrentResult> {
    if (!viewer.social_distancing) {
      return Promise.reject(`Missing 'social_distancing' plugin`);
    }

    // assignGroupsToCurrent asigna sillas sobre la disponibilidad actual (se asume que ya se ha hecho la simulación)
    // - No se ponen validated porque matarían sillas, y en este caso no intersa.
    // - En invalidated se ponen los nodos que forman parte del behavior 'validated' porque representa que en esa silla ya una persona,
    //   por lo que no se debe hacer un allocation en esa silla, y sin embargo NO debe matar sillas (por eso no se pone en 'validated')
    const obj = {
      neighbor_distance: this.socialdistancingService.neighborDistance,
      invalidated: viewer
        .getNodesByGroups("seat", this.groupsService.getGroupBehaviors("validated" /* NO es una errata! */))
        .map((n) => n.id),
      isolated_groups: this.socialdistancingService.buildIsolatedGroups(viewer),
    };

    return viewer.social_distancing.assignGroupsToCurrent(obj, filterOwnParents(viewer, groups), maxAssignedClients);
  }

  private mergeAssignResults(resultArray: SocialDistancingAssignOutput[]): SocialDistancingAssignOutput {
    // const output: SocialDistancingAssignToCurrentResult = {
    //     total_nodes
    //     groups: [],
    //     unassigned: [],
    // }

    return resultArray.reduce((acc, curr) => {
      acc.total_nodes += curr.total_nodes;
      acc.computed_nodes += curr.computed_nodes;
      acc.total_searches += curr.total_searches;
      acc.build_time += curr.build_time;
      acc.compute_time += curr.compute_time;
      acc.availability = acc.availability.concat(curr.availability);
      acc.groups = acc.groups.concat(curr.groups);
      acc.unassigned = acc.unassigned.concat(curr.unassigned);
      acc.unavailable.killed_edges = acc.unavailable.killed_edges.concat(curr.unavailable.killed_edges);
      acc.unavailable.security = acc.unavailable.security.concat(curr.unavailable.security);
      // TODO: acc.grouped
      return acc;
    });
  }

  private mergeAssignToCurrentResults(
    resultArray: SocialDistancingAssignToCurrentResult[],
    maxAssignedClients: number = Infinity
  ): SocialDistancingAssignToCurrentResult {
    const assigned: Set<SocialDistancingAssignGroupResult> = new Set();
    const unassigned: Set<SocialDistancingAssignGroupResult> = new Set();
    const surplus: string[] = [];
    const available: string[] = [];

    const assignedMap: Map<string, SocialDistancingAssignGroupResult> = new Map();
    const unassignedMap: Map<string, SocialDistancingAssignGroupResult> = new Map();

    for (const r of resultArray) {
      r.assigned.forEach((g) => {
        if (!assignedMap.has(g.group_id)) {
          assignedMap.set(g.group_id, g);
          // Asumimos que esto es correcto porque estamos asumiendo que un grupo solo se ha asignado una vez
          available.push(...g.assigned);
        } else {
          // TODO: borrar/comentar
          console.warn(`Group assigned more than once: ${g.group_id}`);
        }
      });

      // Asumimos que esto es correcto porque estamos asumiendo que un grupo solo se ha asignado una vez
      surplus.push(...r.surplus);
    }

    for (const r of resultArray) {
      r.unassigned.forEach((g) => {
        if (!assignedMap.has(g.group_id) && !unassignedMap.has(g.group_id)) {
          unassignedMap.set(g.group_id, g);
        } else {
          // TODO: borrar/comentar
          // console.warn(`Group unassigned more than once: ${g.group_id}`);
        }
      });
    }

    assignedMap.forEach((group, groupId) => {
      available.push(...group.assigned);
    });

    let total = 0;

    const assignedArr: Array<SocialDistancingAssignGroupResult> = Array.from(assignedMap.values());
    const unassignedArr: Array<SocialDistancingAssignGroupResult> = Array.from(unassignedMap.values());

    assignedArr
      .sort((a, b) => a.priority - b.priority)
      .forEach((a) => {
        if (total < maxAssignedClients) {
          total += a.assigned.length;
          assigned.add(a);
        } else {
          a.assigned.length = 0;
          unassignedArr.push(a);
        }
      });

    unassignedArr.sort((a, b) => a.priority - b.priority).forEach((x) => unassigned.add(x));

    const result: SocialDistancingAssignToCurrentResult = {
      assigned,
      unassigned,
      surplus,
      available,
    };

    return result;
  }

  /*private _mergeAssignToCurrentResults(
      resultArray: SocialDistancingAssignToCurrentResult[],
      maxAssignedClients: number = Infinity
    ): SocialDistancingAssignToCurrentResult {
        const assigned: Set<SocialDistancingAssignGroupResult> = new Set();
        const unassigned: Set<SocialDistancingAssignGroupResult> = new Set();
        const surplus: string[] = [];
        const available: string[] = [];

        const assignedArr: SocialDistancingAssignGroupResult[] = [];
        const unassignedArr: SocialDistancingAssignGroupResult[] = [];
        for (const r of resultArray) {
            // r.assigned.forEach(x => assigned.add(x));
            // r.unassigned.forEach(x => unassigned.add(x));
            r.assigned.forEach(x => assignedArr.push(x));
            r.unassigned.forEach(x => unassignedArr.push(x));
            surplus.push(...r.surplus);
            available.push(...r.available);
        }

        let total = 0;
        assignedArr
          .sort((a, b) => a.priority - b.priority)
          .forEach((a) => {
            if (total < maxAssignedClients) {
                total += a.assigned.length;
                assigned.add(a);
            } else {
                a.assigned.length = 0;
                unassignedArr.push(a);
            }
        });

        unassignedArr
          .sort((a, b) => a.priority - b.priority)
          .forEach(x => unassigned.add(x));

        const result: SocialDistancingAssignToCurrentResult = {
            assigned,
            unassigned,
            surplus,
            available,
        };

        return result;
    }*/

  public clearAllocationInventoryAndResult(): Promise<void> {
    const inventory = this.inventoryService.getInventoryData();
    this.clearInventoryAllocation(inventory);
    this.inventoryService.setInventoryData(inventory);
    const p1 = this.inventoryService.sendInventoryData(true).toPromise();
    const p2 = this.dataService.deleteAllocationResult(this.dataService.currentSimulationId, this.ref).toPromise();

    return Promise.all([p1, p2]).then((success2) => {
      return Promise.resolve();
    });
  }

  public setSimulationAsAllocation(inventory): Promise<void> {
    this.inventoryService.setInventoryData(inventory);
    // TODO: hacer next para que el resto de componentes sepan que ha cambiado
    // this.inventoryService.availabilityLoadedSubject.next();

    const body = { allocation: true };
    const p1 = this.inventoryService.sendInventoryData(true).toPromise();
    const p2 = this.dataService.editSimulation(this.dataService.currentSimulationId, body).toPromise();
    return Promise.all([p1, p2]).then((r) => {
      this.dataService.simulationData = r[1].result;
      this.dataService.setCurrentSimulation$(r[1].result);
      return Promise.resolve();
    });
  }

  public clearAllocationFromSimulation(): Promise<void> {
    const inventory = this.inventoryService.getInventoryData();
    this.clearInventoryAllocation(inventory);
    this.inventoryService.setInventoryData(inventory);
    const p1 = this.inventoryService.sendInventoryData(true).toPromise();

    const body = { allocation: false };
    // console.log(this.dataService.currentSimulationId);
    const p2 = this.dataService
      .editSimulation(this.dataService.currentSimulationId, body)
      .toPromise()
      .then((success) => {
        this.dataService.setCurrentSimulation$(success.result);
        return this.dataService.deleteAllocationResult(this.dataService.currentSimulationId, this.ref).toPromise();
      });
    return Promise.all([p1, p2]).then((success2) => {
      return Promise.resolve();
    });
  }

  public clearInventoryAllocation(inventory) {
    for (const sectionKey in inventory) {
      if (inventory.hasOwnProperty(sectionKey)) {
        const sectionObj = inventory[sectionKey];
        for (const rowKey in sectionObj.elements) {
          if (sectionObj.elements.hasOwnProperty(rowKey)) {
            const rowObj = sectionObj.elements[rowKey];
            for (const seatKey in rowObj.elements) {
              if (rowObj.elements.hasOwnProperty(seatKey)) {
                const seatObj = rowObj.elements[seatKey];
                const data = seatObj.data;
                if (data && data.allocation) {
                  delete data.allocation;
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Añade el grupo 'allocation' a todas las sillas con datos de allocation en el inventory, de forma
   * que se pueda pintar con estilo propio
   */
  public setAllocatedGroup(viewer: MapViewer, inventory) {
    const old = viewer.getNodesByGroups("seat", "allocation");
    viewer.removeNodesFromGroup(old, "allocation");
    if (this.showAllocatedSeats) {
      for (const sectionKey in inventory) {
        if (inventory.hasOwnProperty(sectionKey)) {
          const sectionObj = inventory[sectionKey];
          for (const rowKey in sectionObj.elements) {
            if (sectionObj.elements.hasOwnProperty(rowKey)) {
              const rowObj = sectionObj.elements[rowKey];
              for (const seatKey in rowObj.elements) {
                if (rowObj.elements.hasOwnProperty(seatKey)) {
                  const seatObj = rowObj.elements[seatKey];
                  const data = seatObj.data;
                  if (data && data.allocation && data.allocation.allocated) {
                    const seatId = `S_${sectionKey}-${rowKey}-${seatKey}`;
                    viewer.addNodesToGroup(seatId, "allocation");
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

function groupsReport(groups: SocialDistancingAssignGroup[]): void {
  let total = 0;
  const groupsObj = {};
  groups.forEach((g) => {
    const len = g.members.length;
    if (groupsObj[len] == null) {
      groupsObj[len] = 0;
    }
    groupsObj[len]++;
    total++;
  });

  console.log("----------------------");
  console.log("File groups report:");
  for (const i in groupsObj) {
    if (groupsObj.hasOwnProperty(i)) {
      console.log(`Groups of ${i}: ${groupsObj[i]} (${((groupsObj[i] / total) * 100).toFixed(2)}%)`);
    }
  }
  console.log("----------------------");
}

function filterOwnParents(viewer: MapViewer, groups: SocialDistancingAssignGroup[]): SocialDistancingAssignGroup[] {
  const groupsCopy = JSON.parse(JSON.stringify(groups)) as SocialDistancingAssignGroup[];
  return groupsCopy.filter((group) => {
    const preferred = group.preferred[0];
    if (preferred) {
      // If the first preferred is not in this map, the entire group is removed
      if (viewer.getNodeById(preferred)) {
        // If the first preferred is in the map, we clean the preferred ids with only the ones that are in this map
        group.preferred = group.preferred.filter((nodeId) => !!viewer.getNodeById(nodeId));
        return true;
      }
    }
    return false;
  });
}
