import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Parameter } from '../shared/models/parameter-data.model';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {
    constructor(private dataService: DataService) {}

    private parameter:Parameter;
    public parametersHashed = {};
    public paramsList = [];

    public getSampleParam(nseats){
        this.parameter = {
            percentage : 0,
            computed_nseats : nseats,
            nseats_percentage : '0',
            computed_percentage : '0',
            parameter:{
                name : 'Sample',
                id:0,
                nseats : nseats,
                editable : false
            }
        }

        return this.parameter;
    }


    public setParametersList(){
        this.dataService.getParameters().subscribe(
            result => { this.paramsList = result; }
        );
    }
}