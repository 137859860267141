import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/social-distance-editor/services/data.service';

@Component({
  selector: 'app-confirm-delete-config',
  templateUrl: './confirm-delete-config.component.html',
  styleUrls: ['./confirm-delete-config.component.scss']
})

export class ConfirmDeleteConfigComponent implements OnInit {

  configuration;
  configSims;

  closeReason;
  constructor(private bsModalRef: BsModalRef,
              private dataService: DataService) { }

  ngOnInit(): void {
    console.log(this.configSims);
    console.log(this.configuration);
  }

  public onDismiss(reason: 'success' | 'cancel'): void {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onConfirm(): void {
    
    const s = this.dataService.deleteConfiguration(this.configuration.id).subscribe(
      success => {
        s.unsubscribe();
        this.onDismiss('success');
      }
    );
  }

}
