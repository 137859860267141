import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SelectionService {

    nodeType = new Subject<'section' | 'seat'>();
    selectionSubject = new Subject<any[]>();
    lastSelection;

    isGroupsSelection = false;
    selectionSubscriptionsArray = [];
    isSingleSelection = false;

    isMultiSelection = false;
    constructor() {
        this.selectionSubject.subscribe(
            selection => {
                this.lastSelection = selection;
            }
        );
    }

}
