import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/social-distance-editor/services/data.service';
import { Form } from '@angular/forms';
import { Router } from '@angular/router';

import { SocialdistancingService } from 'src/app/social-distance-editor/services/socialdistancing.service';
import { InventoryService } from 'src/app/social-distance-editor/services/inventory.service';
import { CategoriesService } from 'src/app/social-distance-editor/services/categories.service';
import { FormatNumberService } from 'src/app/social-distance-editor/services/format-number.service';
import { ModalService } from 'src/app/social-distance-editor/services/modal.service';
import { Subscription } from 'rxjs';
import { Simulation } from '../../models/simulation-data.model';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-copy-simulation-modal',
  templateUrl: './copy-simulation-modal.component.html',
  styleUrls: ['./copy-simulation-modal.component.scss']
})
export class CopySimulationModalComponent implements OnInit {

  private subscriptions: Subscription[] = [];

  //on init data
  originalSimulation_id;
  originalSimulation;
  currentVenueId;
  currentVenue;
  originalCategories;
  configAvailability;
  simPercentAv;
  currentSDMValue;
  multiSDMValues;
  //groups percentages
  paramsHashed = {};
  paginatedSimulationParameters = [];
  simulation;
  paramsList = [];

  //form
  copySimulationName;

  //customgroups
  customGroups = false;
  customCapacity = 0;
  customCapacityPercent;
  //modal
  closeReason;
  constructor(private bsModalRef: BsModalRef,
              private dataService: DataService,
              private inventoryService: InventoryService,
              private categories: CategoriesService,
              private socialDistancingService: SocialdistancingService,
              private numberFormatService: FormatNumberService,
              private modalService: ModalService,
              private router: Router,
              private toastr: ToastrService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.currentVenue = this.dataService.venueData[this.currentVenueId];
    let p  =this.dataService.getParameters().subscribe(
      result => {
        this.paramsList = result.params;
        this.getSimulation();
      });

  }

  paginateSimulationParameters() {
    let sp = [];
    if(!this.customGroups){
        sp = JSON.parse(JSON.stringify(this.originalSimulation.parameters));
        sp.sort((a, b) => (a.parameter.nseats > b.parameter.nseats) ? 1 : -1);
    }else{

      for (const group_size in this.originalSimulation.custom_groups.groups){
        let name = '';
        if(group_size === '1'){
          name =  'Single Seats';
        }else if(group_size === '2'){
          name = 'Pairs';
        }else{
          name = 'Groups Of '+group_size+' Seats';
        }
        let group = {
          name : name,
          group_size : group_size,
          ngroups :  this.originalSimulation.custom_groups.groups[group_size]/parseInt(group_size),
          available_seats :  this.originalSimulation.custom_groups.groups[group_size],
          percentage: ( 100 * this.originalSimulation.custom_groups.groups[group_size] / this.customCapacity ).toFixed(2)
        }
        sp.push(group);
      }
      sp.sort((a, b) => (parseInt(a.group_size) > parseInt(b.group_size)) ? 1 : -1);
    }

    if (sp.length > 4) {
      const half = Math.ceil(sp.length / 2);
      this.paginatedSimulationParameters[0] = sp.splice(0, half);
      this.paginatedSimulationParameters[1] = sp;
    } else {
      this.paginatedSimulationParameters[0] = sp;
      this.paginatedSimulationParameters[1] = [];
    }
    console.log(this.paginatedSimulationParameters);
    console.log(this.originalSimulation);
  }


  private getSimulation(): any {
    this.dataService.getSimulationParameters(this.originalSimulation_id).subscribe(
      simulation => {
        this.originalSimulation = simulation;
        this.configAvailability = this.originalSimulation.configuration.capacity-this.originalCategories.locked.total;        
        this.simPercentAv  = ((this.originalSimulation.capacity * 100) / this.configAvailability).toFixed(2);
        this.paramsHashed = {};
        let simparams = [];
        
        for (let i=0;i<simulation.parameters.length;i++){
          simulation.parameters[i].percentage = (parseFloat(simulation.parameters[i].percentage)*100).toFixed(0)
          simulation.parameters[i].computed_percentage = (parseFloat(simulation.parameters[i].computed_percentage)*100).toFixed(2)
          this.paramsHashed[simulation.parameters[i].parameter.id] = simulation.parameters[i];
          if (simulation.parameters[i].percentage) {
            simparams.push(simulation.parameters[i].parameter.id);
          }
        }
        this.addAllParams(simparams);
        this.copySimulationName = simulation.name;
        this.currentSDMValue = this.numberFormatService.formatSocialDistancing(simulation.social_distancing);
        console.log(simulation);
        this.currentSDMValue = parseFloat(this.currentSDMValue).toFixed(1);
        this.multiSDMValues = this.checkMultiSDvalues();
        
        if(this.originalSimulation.custom_groups?.groups){
          this.customGroups = true;
          this.customCapacity = parseInt(this.originalSimulation.capacity);
          this.customCapacityPercent = (100 * this.customCapacity / this.originalSimulation.configuration.capacity).toFixed(2);

        }
        this.paginateSimulationParameters();
      });
  }


  private checkMultiSDvalues(){
    let subsims = this.originalSimulation.subsimulations;
    let resp = {
      sd_values : [this.originalSimulation.social_distancing],
      aisle_seats : [this.originalSimulation.isle_seats],
    }
    if(subsims.length){
      for(let i = 0;i< subsims.length;i++){
        if(resp.sd_values.indexOf(subsims[i].social_distancing)==-1){
          resp.sd_values.push(subsims[i].social_distancing);
        }
        if(resp.aisle_seats.indexOf(subsims[i].isle_seats)==-1){
          resp.aisle_seats.push(subsims[i].isle_seats);
        }
      }
    }
    return resp;
  }
  private addAllParams(simparams){
    for(let i = 0; i<this.paramsList.length;i++){
      if(simparams.indexOf(this.paramsList[i].id) === -1 && this.paramsList[i].nseats>0){
        let default_param = {
          percentage: 0,
          computed_nseats: 0,
          computed_percentage: 0,
          parameter : {},
        }
        default_param.parameter = this.paramsList[i];
        this.originalSimulation.parameters.push(default_param);
      }
    }
    return true;
  }
  public onClose(reason : 'success' | 'cancel'): void {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onSubmit(ngForm: Form): void {
    if (!this.isDisabled()) {
      const simulation: Simulation = {
        name: this.copySimulationName,
        capacity: this.originalSimulation.capacity,
        config_id: this.originalSimulation.configuration.id,
        social_distancing: this.originalSimulation.social_distancing,
        isle_seats: this.originalSimulation.social_distancing,
        custom_groups: JSON.stringify(this.originalSimulation.custom_groups),
      };
      const s = this.dataService.createSimulation(simulation).subscribe(
        response => {
          s.unsubscribe();
          const data =  {
            original : this.originalSimulation.id,
            cloned : response.result.id
          };
          this.dataService.copySimulation(data).subscribe(
            subresponse => {
              s.unsubscribe();
              if(subresponse.message){
                this.onClose('success');
                //this.router.navigate(['/simulation/' + response.result.id]);
                this.toastr.success('', 'New simulation successfully saved');
              }
            }
          );
        }
      );
    }

  }

  get units() {
    if (this.dataService.getUser()) {
      return this.dataService.getUnits(this.dataService.getUser().user.client.metric_system);
    }
  }



  public isDisabled(): boolean {
    if(!this.copySimulationName){
      return true;
    }
    return false;
  }

  public formatNumber(price: number): string {
    return this.numberFormatService.formatNumber(price);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
