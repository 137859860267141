import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DataService } from 'src/app/social-distance-editor/services/data.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CategoriesService } from 'src/app/social-distance-editor/services/categories.service';
import { InventoryService } from 'src/app/social-distance-editor/services/inventory.service';
import { FormatNumberService } from 'src/app/social-distance-editor/services/format-number.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-category-price-modal',
  templateUrl: './category-price-modal.component.html',
  styleUrls: ['./category-price-modal.component.scss']
})
export class CategoryPriceModalComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  // tslint:disable-next-line: variable-name
  clientData;
  categoriesList: any;
  categoriesHash = {};
  totalsHash = {};
  totalsAvailableHash = {};
  categories: Array<any> = [];
  configId: number;
  configuration;
  totalSections: number;
  totalSectionsString: string;
  totalSeats: number;
  totalSeatsAvailable: number;
  totalSeatsString: string;
  totalSeatsAvailableString: string;
  totalRevenue: number;
  totalRevenueAvailable: number;
  totalRevenueString: string;
  totalRevenueAvailableString: string;
  currentVenue: number;
  currency;
  currencySymbol;
  simulation;
  isSimulation;

  get units() {
    if (this.dataService.getUser()) {
      return this.dataService.getUnits(this.dataService.getUser().user.client.metric_system);
    }
  }

  constructor(private inventory: InventoryService,
              private dataService: DataService,
              private categoryService: CategoriesService,
              private bsModalRef: BsModalRef,
              private modalService: BsModalService,
              private numberFormatService: FormatNumberService) { }

  ngOnInit(): void {
    // this.formatPrice(0).split('0').forEach(
    //   c => {
    //     if (c && c !== '.') {
    //       this.currencySymbol = c;
    //     }
    //   }
    // );
    console.log(this.simulation);
    if (!this.isSimulation) {
      this.configuration = this.dataService.getConfig(this.configId).subscribe(
        (response: any) => {
          this.currentVenue = response.venueId;
        }
      );
    } else {
      this.currentVenue = this.simulation.configuration.venueId;
    }

    // this.clientData = this.dataService.getUser().user;
    if (this.dataService.currentShareKey) {
      if (this.dataService.shareData) {
        this.currencySymbol = this.dataService.shareData.data.currency;
        this.clientData = {
          client: this.dataService.shareData.data
        };
      } else {
        this.dataService.shareDataSubject.subscribe(
          response => {
            this.currencySymbol = this.dataService.shareData.data.currency;
            this.clientData = {
              client: this.dataService.shareData.data
            };
          }
        );
      }
    } else {
      this.currencySymbol = this.dataService.getUser().user.client.currency;
      this.clientData = this.dataService.getUser().user;
    }
    this.categoriesList = this.categoryService.getCategoriesList();
    this.categoriesList.forEach(category => {
      this.categoriesHash[category.group] = category;
    });
    this.subscriptions.push(this.categoryService.getCategorySubject().subscribe(
      response => {
        this.inventory.updateTotalsHash();
        this.totalsHash = this.inventory.getTotalsHash();
        this.totalsAvailableHash = this.inventory.totalsAvailableHash;
        this.checkCategories();
      }
    ));
  }

  get venueData() {
    if (this.dataService.venueData) {
      if (this.dataService.venueData[this.currentVenue] !== undefined) {
        return this.dataService.venueData[this.currentVenue];
      } else {
        // console.error('Venue undefined!');
        return null;
      }
    }
  }

  private checkCategories(): void {
    this.categories = [];
    const categoriesCountHash = {};
    if (Object.keys(this.totalsHash).length === 0) {
      for (const c of this.categoriesList) {
        const category = { name: c.name, nseats: 0, revenue_total: 0, unit_price: 0};
        this.categories.push(category);
      }
    } else {
      for (const c in this.totalsHash) {
        if (this.totalsHash[c]) {
          for (const p in this.totalsHash[c]) {
            if (this.totalsHash[c][p]) {
              let name;
              if (c === 'none') {
                name = 'Uncategorized';
              } else {
                name = this.categoriesHash[c].name;
              }
              if (categoriesCountHash[name]) {
                categoriesCountHash[name]++;
                name = name + ' ' + categoriesCountHash[name];
              } else {
                categoriesCountHash[name] = 1;
              }
              let category;
              let totalsAvailable = 0;
              if (this.totalsAvailableHash[c] && this.totalsAvailableHash[c][p]) {
                totalsAvailable = this.totalsAvailableHash[c][p].seats;
              }
              if (!p || !parseFloat(p)) {
                category = {name,
                  nsections: this.totalsHash[c][p].sections,
                  nseats: this.totalsHash[c][p].seats,
                  nseats_available: totalsAvailable,
                  nsections_string: this.formatPrice(this.totalsHash[c][p].sections),
                  nseats_string: this.formatPrice(this.totalsHash[c][p].seats),
                  nseats_available_string: this.formatPrice(totalsAvailable),
                  revenue_total: '-', unit_price: '-',
                  revenue_total_string: '-',
                  group: this.categoriesHash[c].group
                };
              } else {
                category = {name,
                  nsections: this.totalsHash[c][p].sections,
                  nseats: this.totalsHash[c][p].seats,
                  nseats_available: totalsAvailable,
                  nsections_string: this.formatPrice(this.totalsHash[c][p].sections),
                  nseats_string: this.formatPrice(this.totalsHash[c][p].seats),
                  nseats_available_string: this.formatPrice(totalsAvailable),
                  revenue_total: this.totalsHash[c][p].seats * parseFloat(p), unit_price: this.formatPrice(p),
                  revenue_total_available: totalsAvailable * parseFloat(p),
                  revenue_total_string: this.formatPrice(this.totalsHash[c][p].seats * parseFloat(p)),
                  revenue_total_available_string: this.formatPrice(totalsAvailable * parseFloat(p)),
                  group: this.categoriesHash[c].group
                };
              }
              this.categories.push(category);
            }
          }
        }
      }
    }
    this.sumCategoriesTotal();

  }

  private sumCategoriesTotal(): void {
    this.totalSections = 0;
    this.totalSeats = 0;
    this.totalSeatsAvailable = 0;
    this.totalRevenue = 0;
    this.totalRevenueAvailable = 0;
    for (const c of Object.values(this.categories)) {
      if (c && parseInt(c['nsections'], 10)) {
        this.totalSections += parseInt(c['nsections'], 10);
      }
      if (c && parseInt(c['nseats'], 10)) {
        this.totalSeats += parseInt(c['nseats'], 10);
      }
      if (c && parseInt(c['nseats_available'], 10)) {
        this.totalSeatsAvailable += parseInt(c['nseats_available'], 10);
      }
      if (c && parseInt(c['revenue_total'], 10)) {
        this.totalRevenue += parseFloat(c['revenue_total']);
      }
      if (c && parseInt(c['revenue_total_available'], 10)) {
        this.totalRevenueAvailable += parseFloat(c['revenue_total_available']);
      }
    }
    this.totalSectionsString = this.formatPrice(this.totalSections);
    this.totalSeatsString = this.formatPrice(this.totalSeats);
    this.totalSeatsAvailableString = this.formatPrice(this.totalSeatsAvailable);
    this.totalRevenueString = this.formatPrice(this.totalRevenue);
    this.totalRevenueAvailableString = this.formatPrice(this.totalRevenueAvailable);
  }

  public onDismiss(): void {
    this.bsModalRef.hide();
  }

  formatPrice(price) {
    return this.numberFormatService.formatNumber(price);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
