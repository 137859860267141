import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/auth';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/social-distance-editor/services/data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-share-simulation-modal',
  templateUrl: './share-simulation-modal.component.html',
  styleUrls: ['./share-simulation-modal.component.scss']
})
export class ShareSimulationModalComponent implements OnInit {

  simulationId;
  publicKey;
  closeReason: 'success' | 'cancel';
  isSharingEnabled = false;
  shareUrl;
  shareLocation = '/share-simulation/';

  constructor(private authService: AuthenticationService,
              private bsModalRef: BsModalRef,
              private router: Router,
              private dataService: DataService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    console.log(this.publicKey);
    if (this.publicKey) {
      this.isSharingEnabled = true;
      this.shareUrl = window.location.origin + this.shareLocation + this.publicKey;
    }
  }

  public onConfirm(): void {
    this.onDismiss('success');
  }
  public newTab(url):void{
    window.open(url, '_blank');
  }
  public onDismiss(reason: 'success' | 'cancel'): void {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }
  public copy(){
      /* Select the text field */
      var copyText = document.getElementById("shareKeyInput") as HTMLInputElement;

      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.toastr.success('', 'Copied to clipboard'),{ timeOut: 999999999 };
  }
  enableSharing(ret) {
    if (ret) {
      return;
    }
    if (this.simulationId) {
      this.dataService.getSimulationShareKey(this.simulationId).subscribe(
        response => {
          if (response.public_key) {
            this.isSharingEnabled = true;
            this.publicKey = response.public_key;
            this.shareUrl = window.location.origin + this.shareLocation + this.publicKey;
          }
        }
      );
    }
  }

  disableSharing(ret) {
    if (ret) {
      return;
    }
    if (this.simulationId) {
      this.dataService.stopSharingSimulation(this.simulationId).subscribe(
        response => {
          this.isSharingEnabled = false;
          this.publicKey = null;
          this.shareUrl = null;
        }
      );
    }
  }

  // toggleSharing(ret) {
  //   if (ret) {
  //     return;
  //   }
  //   this.isSharingEnabled = !this.isSharingEnabled;
  // }

}
