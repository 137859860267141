import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareSimulationComponent } from './share-simulation.component';
import { TopbarComponent } from '../interface/topbar/topbar.component';
import { SidebarComponent } from '../interface/sidebar/sidebar.component';
import { MapInterfaceComponent } from '../interface/map-interface/map-interface.component';
import { FooterComponent } from '../interface/footer/footer.component';
import { FormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap/alert';
import { DVM_CONFIG, DVMConfig } from 'src/assets/configuration/digitalvenue.configuration';
import { InterfaceModule } from '../interface/interface.module';



@NgModule({
  declarations: [
    ShareSimulationComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    InterfaceModule,
    AlertModule.forRoot()
  ],
  exports: [
  ],
  providers: [
    {provide: DVM_CONFIG, useValue: DVMConfig},
  ]
})
export class ShareSimulationModule { }
