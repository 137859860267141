import Papa from 'papaparse';

export function parseCSVToJSON<T>(input: string,
                                  separator = ';',
                                  mapFunc?: (key: string, value: string) => any,
                                  keyValidator?: (key: string) => boolean,
                                  keysValidator?: (keys: string[]) => boolean): T[]
{
    mapFunc = mapFunc || ((key: string, value: string) => value.trim());

    const parsed = Papa.parse<string[]>(input);
    const rows =  parsed.data;
    const first = rows[0];

    if (keyValidator) {
        if (!first.every(keyValidator)) {
            throw new Error('Invalid key found');
        }
    }

    if (keysValidator) {
        if (!keysValidator(first)) {
            throw new Error('Missing keys');
        }
    }

    const result: T[] = [];
    for (let i = 1; i < rows.length; ++i) {
        const row = rows[i];
        if (row && row.length === first.length) {
            const obj: T = {} as T;
            for (let j = 0; j < first.length; ++j) {
                const key = first[j];
                const value = row[j];
                obj[key] = mapFunc(key, value);
            }
            result.push(obj);
        }
    }
    return result;
}
