import { Component, HostListener , OnInit } from '@angular/core';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { PublicVideosComponent } from '../social-distance-editor/shared/modals/public-videos/public-videos.component';
import { ModalOptions, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { youtubeVideo } from '../social-distance-editor/shared/models/video.model';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss','../documentation/documentation.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]

})

export class ChangelogComponent implements OnInit {

  constructor(
    private modalService: BsModalService
  ) { }
  bsModalRef: BsModalRef;
  versions = [
    {version: '2.0'},
    {version: '1.9'},{version: '1.8'},{version: '1.7'},{version: '1.6'},{version: '1.5'},
    {version: '1.4'},{version: '1.3'},{version: '1.2'},{version: '1.1'},{version: '1.0'}
  ];

  ngOnInit(): void {
    //add div tags
    for(let i = 0;i<this.versions.length;i++){
      const element_id = "v"+this.versions[i].version.replace(".","-");
      this.versions[i]['elementId'] = element_id;
      this.versions[i]['offsetTop'] = document.getElementById(element_id).offsetTop-74;
      
    }
  }




  public openVideo(url, title){
    const videoData : youtubeVideo ={
      raw : url,
      title : title
    }
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered modal-dialog-video',
      backdrop: false,
      ignoreBackdropClick: false,
      initialState: {
        video : videoData
      }
    };
    this.bsModalRef = this.modalService.show(PublicVideosComponent, modalConfig);
  }


}
