<div class='modal-wrap'>
    <div #step1>
        <div class='modal-header'>
            <h3>{{'newAllocationModal.newAllocationTitle' | translate}}</h3>
            <button type="button" class="btn-modal-header" (click)="onClose()" data-dismiss="modal">
                <span>&times;</span>
            </button>
        </div>
        <div class='modal-content'>
            <form #formSimulation="ngForm" (ngSubmit)="onSubmit(formSimulation)" (keydown.enter)="$event.preventDefault()">
                <div class='step'>
                    <div class='row'>
                        <div class='col-6 pl-0'>
                            <span class='data-content'>{{'editSimulationModal.venue' | translate}}: <b>{{venueName}}</b></span>
                            <span class='data-content'>{{'editSimulationModal.configuration' | translate}}: <b>{{simulation?.configuration?.name}}</b></span>
                        </div>
                        <div class='col-6 pr-0'>
                            <span class='data-content'>{{'editSimulationModal.simulation' | translate}}: <b>{{simulation?.name}}</b></span>
                            <span class='data-content'>{{'editSimulationModal.venuecapacity' | translate}}: <b>{{formatNumber(simulation?.configuration?.capacity)}}</b></span>
                            <span class='data-content'>
                            {{'editSimulationModal.simresult' | translate}}:
                            <b class='color-blue'>{{formatNumber(simulation?.capacity)}} </b>
                            <small class='color-blue'> ({{availabilityPercentage}}%)</small>
                        </span>
                        </div>
                    </div>
                    <div class='row'>
                        <div class='col-6 pr-0'>
                            <label for='upgradeCheckbox'>Upgrade</label>
                            <input id="upgradeCheckbox" #upgradeCheckbox class="css-checkbox upgradeCheckbox" type='checkbox' name="upgradeCheckbox" [(ngModel)]="upgrade">
                        </div>
                        <div class='col-6 pr-0'>
                            <label for='downgradeCheckbox'>Downgrade</label>
                            <input id="downgradeCheckbox" #downgradeCheckbox class="css-checkbox downgradeCheckbox" type='checkbox' name="downgradeCheckbox" [(ngModel)]="downgrade">
                        </div>
                    </div>
                    <div class='row'>
                        <div class='col-6 pr-0'>
                            {{'newAllocationModal.uploadClientsFile' | translate}}
                        </div>
                        <div class='col-6 pr-0'>
                            <app-uploadbutton #uploadClientsCsv [disabled]="false" name="{{'newAllocationModal.uploadCsv' | translate}}"></app-uploadbutton>
                        </div>
                    </div>
                    <div class='row'>
                        <div class='col-6 pr-0'>
                            {{'newAllocationModal.uploadCategoriesFile' | translate}}
                        </div>
                        <div class='col-6 pr-0'>
                            <app-uploadbutton #uploadCategoriesCsv [disabled]="false" name="{{'newAllocationModal.uploadCsv' | translate}}"></app-uploadbutton>
                        </div>
                    </div>
                </div>
                <div class='modal-footer'>
                    <button class='btn' type="button" (click)="onClose()">{{'newAllocationModal.buttonCancel' | translate}}</button>
                    <button class='btn' type="submit" [disabled]="isDisabled()">{{'newAllocationModal.buttonConfirm' | translate}}</button>
                </div>
            </form>
        </div >
    </div>

    <div class="d-none" #step2>
        <div class='modal-header'>
            <h3>{{'newAllocationModal.loadingAllocationTitle' | translate}}</h3>
            <button type="button" class="btn-modal-header" (click)="onClose()" data-dismiss="modal">
                <span>&times;</span>
            </button>
        </div>
        <div class='modal-content'>
            <div class='step'>
                <div class='row'>
                    <div class='col-12 pr-0'>
                        {{'newAllocationModal.loadingAllocationContent1' | translate}}<br>
                        {{'newAllocationModal.loadingAllocationContent2' | translate}}
                    </div>
                </div>
            </div>
            <div class='modal-footer'>
                <button class='btn' type="button" (click)="onClose()">{{'newAllocationModal.buttonCancel' | translate}}</button>
            </div>
        </div>
    </div>
    <div class="d-none" #step3>
        <div class='modal-header'>
            <h3>{{'newAllocationModal.allocationCompletedTitle' | translate}}</h3>
            <button type="button" class="btn-modal-header" (click)="onClose()" data-dismiss="modal">
                <span>&times;</span>
            </button>
        </div>
        <div class='modal-content'>
            <div class='step'>
                <div class='row'>
                    <div class='col-12 pr-0'>
                        {{'newAllocationModal.allocationCompletedContent1' | translate}}
                        <b class="color-blue">{{this.result?.computedPercentage.toFixed(1) || '??'}}%</b>.<br>
                        <b class="color-blue">{{this.result?.membersAssigned || '0'}}</b>
                        {{'newAllocationModal.allocationCompletedContent2' | translate}}
                        <b class="color-blue">{{this.result?.membersLeft || '0'}}</b>
                        {{'newAllocationModal.allocationCompletedContent3' | translate}}
                    </div>
                </div>
            </div>
            <div class='modal-footer'>
                <button class='btn' type="button" (click)="onClose()">{{'newAllocationModal.buttonOk' | translate}}</button>
                <button class='btn' [ngClass]="{'d-none' : hasAllocation()}" type="button" (click)="onViewAllocation()">{{'newAllocationModal.goallocation' | translate}}</button>

            </div>
        </div>
    </div>
    <div class="d-none" #step4>
        <div class='modal-header'>
            <h3>{{'newAllocationModal.allocationErrorTitle' | translate}}</h3>
            <button type="button" class="btn-modal-header" (click)="onClose()" data-dismiss="modal">
                <span>&times;</span>
            </button>
        </div>
        <div class='modal-content'>
            <div class='step'>
                <div class='row'>
                    <div class='col-12 pr-0'>
                        <div class='data-content'>
                            <p>Please check if the uploaded files are valid.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class='modal-footer'>
            <button class='btn' type="button" (click)="onClose()">{{'newAllocationModal.buttonOk' | translate}}</button>
        </div>
    </div>
</div>
