import { Injectable } from "@angular/core";
import { InventoryService } from "./inventory.service";
import { DVMService } from "./dvm.service";
import { Subject, Observable } from "rxjs";
import { MapViewer } from "@3ddv/dvm-internal";

@Injectable({
  providedIn: "root",
})
export class CategoriesService {
  constructor(private inventory: InventoryService, private dvmService: DVMService) {}

  private categorySuject$ = new Subject<any>();

  /** Se emite cuando se hace un printInventoryCategories */
  public printCategoriesSubject$ = new Subject<MapViewer>();

  /** Se emite cuando se hace un printInventoryCategories */
  get printCategoriesObservable$(): Observable<MapViewer> {
    return this.printCategoriesSubject$.asObservable();
  }

  private categoriesList = [
    {
      name: "General",
      group: "regular",
      color: "rgb(0, 255, 69)",
    },
    {
      name: "VIP",
      group: "vip",
      color: "rgb(255, 202, 13)",
    },
    {
      name: "Disabled",
      group: "disabled",
      color: "#32B9FF",
    },
    {
      name: "Locked",
      group: "locked",
      color: "rgb(0, 0, 0)",
    },
    {
      name: "Limited view",
      group: "restricted",
      color: "#C34DFF",
    },
  ];

  // categories de menos a más importante
  private priorities = ["selected", "locked", "regular", "restricted", "disabled", "vip"];

  currentSelectionCategories = [];

  public getCategorySubject(): Observable<any> {
    return this.categorySuject$.asObservable();
  }

  public setCategorySubject(): void {
    this.categorySuject$.next(null);
  }

  public getCatPriority(category: string) {
    return this.priorities.indexOf(category);
  }

  public getMostImportantCat(categories: string[]) {
    let cat_val = -1;
    let cat_name = "";
    for (let i = 0; i < categories.length; i++) {
      let aux_cat_val = this.priorities.indexOf(categories[i]);
      if (aux_cat_val > cat_val) {
        cat_val = aux_cat_val;
        cat_name = categories[i];
      }
    }
    return { priority: cat_val, category: cat_name };
  }

  getCurrentCategories(nodeType: string, nodes: any[], update?: boolean) {
    const categoriesHash = {};
    if (nodeType === "seat") {
      nodes.forEach((node) => {
        const split = node.id.split("-");
        const areaId = split[0].split("_")[1];
        const rowId = split[1];
        const invProps = this.inventory.getInventoryProperty("category", areaId, rowId);
        invProps.forEach((price) => {
          categoriesHash[price] = true;
        });
      });
    } else if (nodeType === "section") {
      nodes.forEach((node) => {
        const split = node.id.split("-");
        const areaId = split[0].split("_")[1];
        const invProps = this.inventory.getInventoryProperty("category", areaId);
        invProps.forEach((price) => {
          categoriesHash[price] = true;
        });
      });
    }
    if (update) {
      this.currentSelectionCategories = Object.keys(categoriesHash);
    }

    const keys = Object.keys(categoriesHash);
    if (keys && keys.length) {
      return Object.keys(categoriesHash);
    } else {
      return [];
    }
  }

  getCategoriesList(): Array<{ name: string; group: string; color: string }> {
    return this.categoriesList;
  }
  private removeSelectionCategoryOnViewer(section_id) {
    this.getCategoriesList().forEach((category) => {
      this.dvmService.viewer.removeNodesFromGroup(section_id, category.group);
    });
  }
  private getSectionCategoryPriority(section: string) {
    let section_id = "S_" + section;
    let all_seats = this.dvmService.viewer.getNodesByParent(section_id);
    let all_seats_groups = [];
    for (let i = 0; i < all_seats.length; i++) {
      let groups = all_seats[i].groups;
      for (let j = 0; j < groups.length; j++) {
        let g = groups[j];
        if (g && this.getCatPriority(g) > -1 && all_seats_groups.indexOf(g) == -1) {
          all_seats_groups.push(g);
        }
      }
    }
    //most_priorized_category_value_already_existing
    let mpcae_value = -1;
    let mpcae_name;

    for (let i = 0; i < all_seats_groups.length; i++) {
      let aux = this.getCatPriority(all_seats_groups[i]);
      if (aux > mpcae_value) {
        mpcae_value = aux;
        mpcae_name = all_seats_groups[i];
      }
    }

    return {
      priority: mpcae_value,
      category: mpcae_name,
      section: section,
      section_id: section_id,
    };
  }
  getCategoryColor(group: string) {
    for (let i = 0; i < this.categoriesList.length; i++) {
      const category = this.categoriesList[i];
      if (category.group === group) {
        //console.log('RETURN', category.color);
        return category.color;
      }
    }
    // this.categoriesList.forEach(
    //     (category) => {
    //         if (category.group === group) {
    //             console.log('RETURN', category.color);
    //             return category.color;
    //         }
    //     }
    // );
  }

  setCategory(nodeType: "section" | "seat", nodes: any[], category: string, callback?) {
    let flags = {
      capacity_updated: false,
    };
    const setCategoryPromise = new Promise((s, r) => {
      let section;
      let sections_cats;
      let sections_parsed;

      if (nodeType === "seat") {
        let row, seat;
        if (this.dvmService.activeVisualizationLayer === "row") {
          const rowsHash = {};
          sections_cats = [];
          sections_parsed = [];
          let add_row_hash = false;
          nodes.forEach((node) => {
            let split = node.id.split("_")[1].split("-");
            let aux_section = split[0];
            let aux_row = split[1];
            let aux_seat = split[2];
            if (sections_parsed.indexOf(section) == -1) {
              sections_cats.push(this.getSectionCategoryPriority(aux_section));
              sections_parsed.push(aux_section);
            }
            if (!rowsHash[aux_section + aux_row]) {
              rowsHash[section + row] = true;
            }
            if (!this.inventory.getInventoryBySeat(aux_section, aux_row, aux_seat).length) {
              this.inventory.createInventoryItem(node.id);
              this.dvmService.viewer.setAvailable("seat", node.id);
            }
            this.inventory.setInventoryProperty("category", category, aux_section, aux_row);
          });
        } else if (this.dvmService.activeVisualizationLayer === "seat") {
          sections_cats = [];
          sections_parsed = [];
          nodes.forEach((node) => {
            const split = node.id.split("_")[1].split("-");
            section = split[0];
            row = split[1];
            seat = split[2];

            if (sections_parsed.indexOf(section) == -1) {
              sections_cats.push(this.getSectionCategoryPriority(section));
              sections_parsed.push(section);
            }
            if (!this.inventory.getInventoryBySeat(section, row, seat).length) {
              this.inventory.createInventoryItem(node.id);
              this.dvmService.viewer.setAvailable("seat", node.id);
            }
            this.inventory.setInventoryProperty("category", category, section, row, null, seat);
          });
        }

        let current_priority = this.getCatPriority(category);

        if (category != "none" && current_priority > -1) {
          for (let i = 0; i < sections_cats.length; i++) {
            if (current_priority >= sections_cats[i].priority) {
              this.removeSelectionCategoryOnViewer(sections_cats[i].section_id);
              this.dvmService.viewer.addNodesToGroup(sections_cats[i].section_id, category);
            } else {
              this.dvmService.viewer.addNodesToGroup(sections_cats[i].section_id, sections_cats[i].category);
            }
          }
        }
      } else if (nodeType === "section") {
        nodes.forEach((node) => {
          const split = node.id.split("_")[1].split("-");
          let section = split[0];
          //create seats if not exists (last flag on create inventory item)
          let new_nodes = this.dvmService.viewer.getNodesByParent("S_" + section);
          for (let i = 0; i < new_nodes.length; i++) {
            let [aux_sect, aux_row, aux_seat] = new_nodes[i].id.split("_")[1].split("-");
            if (!this.inventory.getInventoryBySeat(aux_sect, aux_row, aux_seat).length) {
              this.inventory.createInventoryItem(new_nodes[i].id);
              flags.capacity_updated = true;
            }
          }

          this.inventory.setInventoryProperty("category", category, section, null);
          this.dvmService.viewer.setAvailable("section", "S_" + section);
          this.dvmService.viewer.setAvailable("seat", this.dvmService.viewer.getNodesByParent("S_" + section));
        });
      }
      s(flags);
    }).then(() => {
      if (callback) {
        callback(flags);
      }
    });
  }

  printInventoryCategories(viewer?: MapViewer) {
    viewer = viewer ?? this.dvmService.viewer;
    const sections = viewer.getNodesByType("section");
    const inventoryData = this.inventory.getInventoryData();

    sections.forEach((section) => {
      const sectionCategories = this.getCurrentCategories("section", [section]);
      const seats = viewer.getNodesByParent(section);
      if (sectionCategories.length > 1) {
        const category = this.getMostImportantCat(sectionCategories);
        //viewer.addNodesToGroup(section, 'none');
        //viewer.addNodesToGroup(section, sectionCategories[0]);
        viewer.addNodesToGroup(section, category.category);
        seats.forEach((seat) => {
          //const seatCategories = this.getCurrentCategories('seat', [seat]);
          // viewer.addNodesToGroup(seat, seatCategories[0]);
          const split = seat.id.split("-");
          const areaId = split[0].split("_")[1];
          const rowId = split[1];
          const seatId = split[2];
          if (
            inventoryData[areaId] &&
            inventoryData[areaId].elements[rowId] &&
            inventoryData[areaId].elements[rowId].elements[seatId]
          ) {
            viewer.addNodesToGroup(seat, inventoryData[areaId].elements[rowId].elements[seatId].data.category);
          }
        });
      } else if (sectionCategories.length === 1) {
        viewer.addNodesToGroup(section, sectionCategories[0]);
        viewer.addNodesToGroup(seats, sectionCategories[0]);
      }

      // Guarda las sillas con status onhold en el grupo de onhold
      seats.forEach((seat) => {
        const split = seat.id.split("-");
        const areaId = split[0].split("_")[1];
        const rowId = split[1];
        const seatId = split[2];
        if (
          inventoryData[areaId] &&
          inventoryData[areaId].elements[rowId] &&
          inventoryData[areaId].elements[rowId].elements[seatId]
        ) {
          // Comprobar si el estado de la silla esta en onhold
          const seatStatus = inventoryData[areaId].elements[rowId].elements[seatId].data.status;
          // console.log(seatStatus);
          if (seatStatus && seatStatus === "onhold") {
            viewer.addNodesToGroup(seat, "onhold");
          }
        }
      });
    });
    this.printCategoriesSubject$.next(viewer);
  }
}
