import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { LoginComponent } from './login/login.component';
/* import { LandingComponent } from './social-distance-editor/landing/landing.component';
import { VenueSetupModule } from './social-distance-editor/venue-setup/venue-setup.module'; */
// import { LegendComponent } from './interface/map-interface/legend/legend.component';
//import { SimulationModule } from './social-distance-editor/simulation/simulation.module';
// import { UserOptionsComponent } from './header/user-options/user-options.component';
import { SharedModule } from './social-distance-editor/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { AuthenticationService } from './auth';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { DataService } from './social-distance-editor/services/data.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { AddApiUrlInterceptor } from './social-distance-editor/shared/interceptors/add-api-url.interceptor';
import { HttpCsrfInterceptor } from './social-distance-editor/shared/interceptors/csrf-token.interceptor';
import { FooterComponent } from './footer/footer.component';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { HelpComponent } from './help/help.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AuthInterceptor } from './social-distance-editor/shared/interceptors/auth.interceptor';
import { SelectionFooterComponent } from './footer/selection-footer/selection-footer.component';
import { ToastrModule } from 'ngx-toastr';
import { ShareSimulationModule } from './social-distance-editor/share-simulation/share-simulation.module';
import { ChangelogComponent } from './changelog/changelog.component';
import { FaqsComponent } from './faqs/faqs.component';
import { HomeComponent } from './home/home.component';
import { LegalComponent } from './legal/legal.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ScrollToModule } from 'ng2-scroll-to';
import { DocumentationComponent } from './documentation/documentation.component';
import { HeaderPublicComponent } from './header/header-public/header-public.component';
import { isolatedGroups, ISOLATED_GROUPS } from 'src/assets/data/isolated-groups.configuration';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HelpComponent,
    SelectionFooterComponent,
    ChangelogComponent,
    FaqsComponent,
    HomeComponent,
    LegalComponent,
    DocumentationComponent,
    HeaderPublicComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ShareSimulationModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    SharedModule,
    SlickCarouselModule,
    ScrollToModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-left',
      preventDuplicates: true,
    }),
  ],
  exports: [
    TranslateModule,
    BsDropdownModule,
    TranslateModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AddApiUrlInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpCsrfInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    AuthenticationService,
    DataService,
    BsModalRef,
    {provide: ISOLATED_GROUPS, useValue: isolatedGroups},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
