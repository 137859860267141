import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopbarComponent } from './topbar/topbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MapInterfaceComponent } from './map-interface/map-interface.component';
//import { LegendComponent } from './map-interface/legend/legend.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastrModule } from 'ngx-toastr';
import { FooterComponent } from './footer/footer.component';
import { SelectiondataComponent } from './sidebar/selectiondata/selectiondata.component';
import {CustomCategoriesModule} from '../plugins/custom-categories/custom-categories.module';



@NgModule({
  declarations: [
    TopbarComponent,
    SidebarComponent,
    MapInterfaceComponent,
    FooterComponent,
    SelectiondataComponent
    //LegendComponent
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
    FormsModule,
    TranslateModule,
    BsDropdownModule,
    CustomCategoriesModule
  ],
  exports: [
    FormsModule,
    TopbarComponent,
    SidebarComponent,
    MapInterfaceComponent,
    FooterComponent
    //LegendComponent
  ]
})
export class InterfaceModule { }
