import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BsModalRef, ModalOptions,BsModalService } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/social-distance-editor/services/data.service';
import { Form } from '@angular/forms';
import { SocialdistancingService } from 'src/app/social-distance-editor/services/socialdistancing.service';
import { DVMService } from 'src/app/social-distance-editor/services/dvm.service';
import { InventoryService } from 'src/app/social-distance-editor/services/inventory.service';
import { CategoriesService } from 'src/app/social-distance-editor/services/categories.service';
import { FormatNumberService } from 'src/app/social-distance-editor/services/format-number.service';
import { SelectionService } from 'src/app/social-distance-editor/services/selection.service';

//import { ModalService } from 'src/app/services/modal.service';
import { ComputeResultsModalComponent } from '../compute-results-modal/compute-results-modal.component';
import { ComputeSectionResultsModalComponent } from '../compute-sectionresults-modal/compute-sectionresults-modal.component';
import { Subscription } from 'rxjs';
import {ComputedseatsService} from "../../../services/computedseats.service";
import { AllocatorService } from 'src/app/social-distance-editor/plugins/allocator/services/allocator.service';
import { TiersService } from 'src/app/social-distance-editor/services/tiers.service';
import { take } from 'rxjs/operators';
import {PlatformLocation} from "@angular/common";


@Component({
  selector: 'app-edit-sectionsimulation-modal',
  templateUrl: './edit-sectionsimulation-modal.component.html',
  styleUrls: ['./edit-sectionsimulation-modal.component.scss']
})
export class EditSectionSimulationModalComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  simulationParameters: Array<any>;
  paginatedSimulationParameters = [];
  simulation;

  subsimulation;
  default_subsim_flag = false;
  sections_selected_value = "";
  subsimcapacity = 0;
  selection_capacity = 0;

  inputHash: any;
  computedTotalsStringsHash = {};
  socialDistancing;
  totalPercentage: number;
  currentAvailability;
  aisleSeats = 0;
  nlocked;
  isViewInitialized = false;
  simulationName;
  nameInEdit;
  limit2groups;
  fallback = false;
  kill_start = true;
  kill_end = true;
  isSectionSocialDistancing = false;
  current_side_tokill = 'Kill seats from both sides of the section';
  disabledKillSide: boolean = false;
  simulationcapacity;

  hiddenReason;
  loadingmap;
  socialDistancingConfig;
  sdParentNodes;
  sdNodes;
  get availability() {
    return this.currentAvailability;
  }

  get venueName() {
    if (this.simulation) {
      return this.dataService.venueData[this.simulation.configuration.venueId].name;
    }
  }

  constructor(private bsModalRef: BsModalRef,
              private dataService: DataService,
              private dvmService: DVMService,
              private inventoryService: InventoryService,
              private categories: CategoriesService,
              private socialDistancingService: SocialdistancingService,
              private numberFormatService: FormatNumberService,
              //private modalService: ModalService,
              private BsModalService: BsModalService,
              private selection: SelectionService,
              private computedseatsService: ComputedseatsService,
              private cdr: ChangeDetectorRef,
              private allocatorService: AllocatorService,
              private tiersService: TiersService,
              private location: PlatformLocation)  {
    this.location.onPopState(()=> {
      this.onClose();
    });
  }


  ngOnInit(): void {
    this.subscriptions.push(this.getSimulation());

    this.paginateSimulationParameters();
    //this.nlocked = this.dvmService.getLockedseats().length;
    this.tiersService.getTotalTiersAV().pipe(
      take(1)
    ).subscribe(res=>{
      this.nlocked = res.locked;
    })
    this.isSectionSocialDistancing = this.socialDistancingService.isSectionSocialDistancing;
  }

  paginateSimulationParameters() {

    const sp = JSON.parse(JSON.stringify(this.simulationParameters));
    sp.sort((a, b) => (a.nseats > b.nseats) ? 1 : -1);
    const half = Math.ceil(sp.length / 2);

    this.paginatedSimulationParameters[0] = sp.splice(0, half);
    this.paginatedSimulationParameters[1] = sp;

  }

  private refreshsimulationcapacity(){
    if(this.simulation.custom_groups && this.simulation.custom_groups.capacity){
      this.simulationcapacity = this.simulation.custom_groups.capacity;
    }else{
      this.simulationcapacity = this.simulation.capacity;
    }
  }
  private getSimulation() {
    return this.dataService.getSimulationParameters(this.dataService.currentSimulationId).subscribe(
      simulation => {
        this.dataService.simulationData = simulation;
        this.simulation = simulation;
        this.refreshsimulationcapacity()

        const totalSeats = this.simulation.configuration.capacity;
        this.currentAvailability = (100 * this.simulationcapacity / totalSeats).toFixed(2);

        this.default_subsim_flag = false;
        this.dataService.subsimulationData = simulation.subsimulations;
        
        let selected_sections = this.dvmService.viewer.getNodesByGroups("section","selected");
        //let selected_sections = this.sections_selected_ids;
        //console.log(selected_sections);
        if (selected_sections.length>0){
          selected_sections = selected_sections.map(x => x.id);
          for(let i = 0; i<selected_sections.length;i++){
            this.sections_selected_value +=selected_sections[i].split("_")[1]+", ";
            this.subsimcapacity+=this.dvmService.viewer.getNodesByState("seat","available",selected_sections[i]).length;
            this.selection_capacity += this.dvmService.viewer.getNodesByParent(selected_sections[i]).length;
          }
          this.sections_selected_value = this.sections_selected_value.substring(0, this.sections_selected_value.length - 2);
          this.dataService.affectedSubsimulation = []; 
          if (this.dataService.subsimulationData.length > 0){
            
            for(let i = 0;i<this.dataService.subsimulationData.length;i++){
              let exists = selected_sections.indexOf(this.dataService.subsimulationData[i].section);
              if (exists>-1){
                this.dataService.affectedSubsimulation.push(this.dataService.subsimulationData[i]);
                if (!this.default_subsim_flag){
                  this.default_subsim_flag = true;
                  this.subsimulation = this.dataService.subsimulationData[i];
                  if(!this.subsimulation.parameters){
                    this.subsimulation.parameters = this.simulation.parameters;
                  }
                  this.formularyData(this.subsimulation);
                }
              }
            }

            if(!this.default_subsim_flag){
              this.formularyData(this.simulation);
            }

          }else{
            this.formularyData(this.simulation);
          }
        }
      }
    );
  }
 

  private formularyData(simulation){
    this.limit2groups = simulation.limit2groups;
    this.simulationName = simulation.name;
    this.socialDistancing = parseFloat(simulation.social_distancing);
    this.kill_start = false;
    this.kill_end = false;
    this.aisleSeats = simulation.isle_seats;

    this.disabledKillSide = true;
    if (simulation.isle_seats) {
      this.kill_start = simulation.aisle_seats_start;
      this.kill_end = simulation.aisle_seats_end;
      this.disabledKillSide = false;
      this.setAisleSideSelectedText(simulation);
    }

    if(simulation.fallback){
      this.fallback = true;
    }
    
    for (const key in this.inputHash) {
      if (this.inputHash.hasOwnProperty(key)) {
        this.computedTotalsStringsHash[key] = this.inputHash[key]['nseats_per'];
      }
    }
    this.totalPercentage = 0;
    let sum = 0;

    simulation.parameters.forEach(param => {
      let p = parseFloat(param.percentage);
      sum += p;
    });
    for (const p of simulation.parameters) {
      //if (this.inputHash[p.id]) {
        this.inputHash[p.parameter.id] = {percentage: 0, computed_per: 0, nseats_per: 0};
      //}

      // this.inputHash[p.parameter.id]['percentage'] = parseInt((p.percentage * 100).toFixed(0));
      
      // if (typeof p.computed_nseats === 'number') {
      //   this.inputHash[p.parameter.id]['computed_nseats'] = p.computed_nseats;
      // }
      // this.inputHash[p.parameter.id]['computed_per'] = p.computed_percentage * 100;
      // this.inputHash[p.parameter.id]['nseats_per'] = p.nseats_percentage * 100;
      // // if (this.inputHash[p.parameter.id]['computed_per']) {
      // //   this.computedTotalsStringsHash[p.parameter.id] = this.inputHash[p.parameter.id]['computed_per'].toFixed(2);
      // // }
      // if (this.inputHash[p.parameter.id]['nseats_per']) {
      //   this.computedTotalsStringsHash[p.parameter.id] = this.inputHash[p.parameter.id]['nseats_per'].toFixed(2);
      // }
      this.totalPercentage += this.inputHash[p.parameter.id].percentage;
    }
    
  }


  public onClose(): void {
    this.hiddenReason = 'close';
    this.socialDistancingService.isSectionSocialDistancing = false;
    this.bsModalRef.hide();
  }

  private applySocialDistancing(){
    this.loadingmap = document.getElementById('loadingmap');
    this.loadingmap.classList.remove('hidden');
    const parameters = {};
    this.subsimulation.parameters.forEach(element => {
      parameters[element.parameter.nseats] = element.percentage;
    });

    this.socialDistancingConfig = {
      securityDistance: this.socialDistancing,
      isleSeats: this.aisleSeats,
      parameters,
      limit2groups : this.limit2groups,
      fallback : this.subsimulation.fallback,
      aisle_seats_sides :{
        start : this.kill_start,
        end : this.kill_end
      },
    };
    this.sdParentNodes = this.dvmService.viewer.getNodesByGroups("section","selected");
    this.sdNodes = [];
    for (let i = 0;i<this.sdParentNodes.length;i++){
      this.sdNodes = this.sdNodes.concat(this.dvmService.viewer.getNodesByParent(this.sdParentNodes[i]));
    }
    console.log(this.sdParentNodes);
    this.hideLoading();
    this.hiddenReason = 'closetoresult';
    this.bsModalRef.hide();
  }

  public showLoading(){
    document.getElementById('loading-modal-edit').classList.remove('d-none');
    document.getElementById('step-form').classList.add('d-none');
    return null;
  }
  public hideLoading(){
    document.getElementById('loading-modal-edit').classList.add('d-none');
    document.getElementById('step-form').classList.remove('d-none');
    return null;

  }
  public onSubmit(ngForm: Form): void {
    

    if (!this.isDisabled()) {
      this.showLoading();
      let sections_selected = this.dvmService.viewer.getNodesByGroups("section","selected").map(x => x.id);
      let body = {
        name: this.simulationName,
        social_distancing: this.socialDistancing,
        isle_seats: this.aisleSeats,
        limit2groups : this.limit2groups,
        aisle_seats_start : this.kill_start,
        aisle_seats_end : this.kill_end,
        fallback : 0,
        capacity: "0",
        section : sections_selected,
        simulation_id : this.simulation.id
      };

      let params_options = [];
      let max_param_id  = 0;
      Object.entries(this.inputHash).forEach(([key, value]) => {
        let percentage = 0;
        let param_id = parseInt(key, 10);
        if (value['percentage']) {
          percentage = value['percentage'] as number / 100;
        }
        if(percentage && param_id>max_param_id){
          max_param_id = param_id;
        }
        params_options.push(
          { param_id: param_id, percentage, computed_percentage: 0 }
        );
      });
      if(this.fallback){
        for(let i=0; i<this.simulationParameters.length; i++){
  
          if(this.simulationParameters[i].id === max_param_id){
            body.fallback = this.simulationParameters[i].nseats;
          }
        }
      }
      if(this.simulation.allocation){
        this.allocatorService.clearAllocationFromSimulation();
      }

      this.subscriptions.push(this.dataService.createSubsimulation(body).subscribe(
        subsimulation_response => {
          
          const newSimulationParameters = {
            options: params_options,
            subsimulation : subsimulation_response.result.map(n=>n.id)
          };
          this.subscriptions.push(this.dataService.createSubsimulationParameters(newSimulationParameters).subscribe(
            success => {

              

              this.getSimulation().add(
                response => {
                  this.applySocialDistancing();
                }
              );
            }
          ));
        },
        error => { console.log(error); }
      ));
    }
  }



  get units() {
    if (this.dataService.getUser()) {
      return this.dataService.getUnits(this.dataService.getUser().user.client.metric_system);
    }
  }

  public onSetDistancing(action: 'minus' | 'sum'): void {
    if (this.numberFormatService.getUnits() === 'ft') {
      if (action === 'minus' && this.socialDistancing > 0.5) {
        this.socialDistancing -= 0.0305;
      }
      if (action === 'sum') {
        this.socialDistancing += 0.0305;
      }
    } else {
      if (action === 'minus' && this.socialDistancing > 0.5) {
        this.socialDistancing -= 0.10;
      }
      if (action === 'sum') {
        this.socialDistancing += 0.10;
      }
    }
  }

  public onChangeAisleSeat(action: 'minus' | 'sum') {
    if (action === 'minus' && this.aisleSeats > 0) {
      this.aisleSeats -= 1;
    }
    if (action === 'sum' && this.aisleSeats < 4) {
      this.aisleSeats += 1;
    }
    if(this.aisleSeats==0){
      this.kill_start = false;
      this.kill_end = false;
      this.disabledKillSide = true;
    }else if(this.aisleSeats>0 && !this.kill_start && !this.kill_end){
      this.kill_start = true;
      this.kill_end = true;
      this.disabledKillSide = false;
    }
  }


  public onChangeNumber(event: Event, max, id, isAisle?) {
    this.clampInput(event.target as HTMLInputElement, 0, max, id, isAisle);
    this.checkTotalPercentage();
  }

  private clampInput(input: HTMLInputElement, min = 0, max = 100, id?, isAisle?) {
    if (parseInt(input.value, 10)) {
      const inputValue = parseInt(input.value, 10);
      const clampped = this.clamp(inputValue, min, max).toString();
      input.value = clampped;
      if (id) {
        this.inputHash[id].percentage = parseInt(clampped, 10);
        console.log(this.inputHash[id].percentage)
      }
      if (isAisle) {
        this.aisleSeats = parseInt(clampped, 10);
      }
    }else{
      input.value = '0';
    }
  }

  private clamp(input: number, min: number, max: number): number {
    return Math.min(Math.max(min, input), max);
  }

  public checkTotalPercentage(): void {
    this.totalPercentage = 0;
    this.simulationParameters.forEach(
      p => {
        let value = parseInt((document.getElementById(p.name) as HTMLInputElement).value, 10);
        if (typeof value == 'undefined' || !value) {
          value = 0;
        }
        this.totalPercentage += value;
      }
    );
  }

  public formatSocialDistancing(num: number): number {
    if (num) {
      return Number(this.numberFormatService.formatSocialDistancing(num));
    } else {
      return 0;
    }
  }

  public isDisabled(type_error?): boolean {
    if(type_error){
      if(type_error == 'percent'){
        if (this.totalPercentage !== 100){
          return true;
        }else{
          return false;
        }
      }
    }


    if (this.totalPercentage !== 100) {
      return true;
    } else {
      return false;
    }
  }
  public nameIsDisabled(): boolean {
    if(this.simulationName){
      return false;
    }
    return true;
  }
  public updateSimulationName(){
    if (!this.nameIsDisabled()){
      const body = { name: this.simulationName };
      this.subscriptions.push(this.dataService.editSimulation(this.simulation.id, body).subscribe(
        success => {
          this.simulation = success.result;
          this.dataService.setCurrentSimulation$(success.result);
          this.disableEditName();
        },
        error => { console.log(error); }
        ));
    }
  }
  public formatNumber(price: number): string {
    return this.numberFormatService.formatNumber(price);
  }

  public enableEditName(){
    this.nameInEdit = true;
    return this.nameInEdit;
  }
  public disableEditName(){
    this.nameInEdit = false;
    this.simulationName = this.simulation.name;
    return this.nameInEdit;
  }

  public checkLimit2groups(){
    this.limit2groups = !this.limit2groups;
  }
  public checkFallback(){
    this.fallback = !this.fallback;
  }

  public setAisleSideSelected(side?: number) {
    switch(side){
      case 1:
        this.kill_start = true;
        this.kill_end = false;
        this.current_side_tokill ='Kill seats from the beginning of the row';
        break;
      case 2:
          this.kill_start = false;
          this.kill_end = true;
          this.current_side_tokill ='Kill seats from the ending of the row';
        break;
      default:
        this.kill_start = true;
        this.kill_end = true;
        this.current_side_tokill ='Kill seats from both sides of the section';

        break;
    }
  }

  public setAisleSideSelectedText(subsimulation){
    if(subsimulation.aisle_seats_start && !subsimulation.aisle_seats_end){
      this.setAisleSideSelected(1);
    }else if(!subsimulation.aisle_seats_start && subsimulation.aisle_seats_end){
      this.setAisleSideSelected(2);
    }else{
      this.setAisleSideSelected(null);
    }
    return;
  }

  getSectionNodes() {
    const selection = this.dvmService.viewer.getNodesByGroups('section', 'selected');
    let result = [];
    selection.forEach(section => {
      result = result.concat(this.dvmService.viewer.getNodesByParent(section.id));
    });
    // if (sectionId && typeof sectionId === 'string') {
    //   return this.dvmService.viewer.getNodesByParent(sectionId);
    // }
    return result;
  }
  getSectionsAffected(){
    return this.dvmService.viewer.getNodesByGroups('section', 'selected');
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
