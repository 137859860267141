import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Router, NavigationEnd } from '@angular/router';
import { FormatNumberService } from './social-distance-editor/services/format-number.service';
declare let gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'social-distancing-manager';
  currentLocation;

  get isSimulation() {
    if (this.currentLocation) {
      return this.currentLocation.includes('simulation');
    } else {
      return false;
    }
  }

  get isIntegration() {
    if (this.currentLocation && (this.currentLocation.includes('simulation')  || this.currentLocation.includes('setup'))){
        return true;
    }
    return false;
  }

  constructor(private translate: TranslateService,
              private localeService: FormatNumberService,
              private router: Router) {
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            gtag('config', 'UA-63464443-4', {
              page_path: event.urlAfterRedirects
            });
          }
        });
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if (localStorage.getItem('language') !== null) {
      const language = localStorage.getItem('language');
      this.localeService.setCurrentLocale(language);
      this.translate.use(language);

    } else {
      this.localeService.setCurrentLocale('en');
      this.translate.use('en');
    }

    this.router.events.subscribe( (e) => {
      // tslint:disable-next-line: no-unused-expression
      e instanceof NavigationEnd ? this.currentLocation = '/' + e.url.split('/')[1] : '';
    });
  }

}
