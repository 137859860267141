import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/social-distance-editor/services/data.service';
import { Simulation } from '../../models/simulation-data.model';

@Component({
  selector: 'app-confirm-delete-simulation',
  templateUrl: './confirm-delete-simulation.component.html',
  styleUrls: ['./confirm-delete-simulation.component.scss']
})
export class ConfirmDeleteSimulationComponent implements OnInit {
  simulation : Simulation;
  simulationId;
  closeReason;
  constructor(private bsModalRef: BsModalRef,
              private dataService: DataService) { }

  ngOnInit(): void {
  }

  public onClose(reason: 'success' | 'cancel'): void {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onConfirm(): void {
    const s = this.dataService.deleteSimulation(this.simulationId).subscribe(
      success => {
        s.unsubscribe();
        this.onClose('success');
      }
    );
  }

}
