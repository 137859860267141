import { Component, OnInit } from '@angular/core';
import { PublicVideosComponent } from '../social-distance-editor/shared/modals/public-videos/public-videos.component';
import { ModalOptions, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { youtubeVideo } from '../social-distance-editor/shared/models/video.model';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss','../documentation/documentation.component.scss']
})
export class FaqsComponent implements OnInit {

  constructor(
    private modalService: BsModalService
  ) { }
  bsModalRef: BsModalRef;

  ngOnInit(): void {
  }
  public openVideo(url, title){
    const videoData : youtubeVideo ={
      raw : url,
      title : title
    }
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered modal-dialog-video',
      backdrop: false,
      ignoreBackdropClick: false,
      initialState: {
        video : videoData
      }
    };
    this.bsModalRef = this.modalService.show(PublicVideosComponent, modalConfig);
  }
}
