<div>
    <div class='modal-header'>
        <h3>{{"confirmDeleteConfigModal.title" | translate}}</h3>
        <button type="button" class="btn-modal-header" (click)="onDismiss('cancel')" data-dismiss="modal">&times;</button>
    </div>
    
    <div class='modal-content'>
        <div class='data-content'>
            <p>
                Are you sure do you want to delete the allocation? This action can't be undone.<br>
                You will be redirected to the simulation view.
            </p>
        </div>
    </div>
    <div class='modal-footer'>
        <button id='cancelsimulation-btn' class='btn' type="button" (click)="onDismiss('cancel')">Cancel</button>
        <button (click)="onDeleteAllocation()" class='btn btn-danger'>{{"confirmDeleteConfigModal.buttonConfirm" | translate}}</button>
    </div>    
</div>