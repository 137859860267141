<div class="sidebar-container" [ngClass]="[displaySidebar ? 'open': '', page==='shared' ? 'shared-wrap':'']">
    <div class="sidebar">
        <div style="position:absolute;top: 0; left: 0; z-index: 9; display:none">
            <button (click)="onDisplaySidebar()">Open Sidebar</button>
        </div>
        <div class='flex-columns-wrap' [ngClass]="page ==='shared' ? 'shared-wrap' : ''">

            <div class="flex-column flex-column-section">
                <div class='flex-column-content'>

                    <span class='flex-column-header'>
                        {{'setup.sidebar.venueinformation' | translate}}
                    </span>
                    <div class='flex-column-content'>
                        <div>
                            <div class='flex-column-content-item'> <b>{{venueData?.name}}</b>
                            </div>
                            <div class='flex-column-content-item'>
                                {{'setup.sidebar.venuecapacity' | translate}}:
                               <!--<b>{{formatNumber(venueData?.capacity)}}</b>--> 
                               <b>{{formatNumber(configuration?.capacity)}}</b>
                            </div>
                            <div class='flex-column-content-item' *ngIf="isSimulation">
                                {{'setup.sidebar.newcapacity' | translate}}: 
                                <b class='color-blue'>{{formatNumber(availability)}}</b></div>
                            <div class='flex-column-content-item' *ngIf="levels.length">{{currentLevelName}}:
                                <b>{{formatNumber(currentLevelAvailability)}}</b> seats
                            </div>
                        </div>

                        <div>
                            <button class='btn' (click)="onCategoriesPrices()">
                                {{'setup.sidebar.advancedinfo' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-column flex-column-section">
                <span class='flex-column-header'>{{'setup.sidebar.visualizationMode' | translate}}</span>
                <div class='flex-column-content'>
                    <div *ngIf="levels && levels.length" class="dropdown-wrap" dropdown>
                        <button dropdownToggle type="button" class="btn-dropdown dropdown-toggle"
                            aria-controls="dropdown-animated-levels">
                            <span id='currentlevel'>
                                <span>{{currentLevelName}}</span>
                                <!--<span *ngIf="hasMapChanged">{{currentLevelName}}</span>-->
                                <!--<span *ngIf="!hasMapChanged">SELECT LEVEL</span>-->
                            </span>
                        </button>
                        <ul id="dropdown-animated-levels" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                            role="menu" aria-labelledby="dropdown-animated-levels">
                            <li class='dropdown-item' role="menuitem"
                                [ngClass]="{'active': isCurrentMap(level['map_id'])}"
                                *ngFor="let level of levels; let i = index"
                                (click)="loadMap(level['map_id'], level['name'])">
                                <span>{{level['name']}}</span>
                            </li>
                        </ul>
                    </div>
                    <div id='switcher-btns'>
                    <!--

                        <button (click)="toggleRows(!isRowsActive)" class='btn color-black border-gray'
                        [ngClass]="{'active': !isRowsActive}">
                        {{'setup.sidebar.sections' | translate}}
                    </button><button (click)="toggleRows(isRowsActive)" class='btn color-black border-gray'
                    [ngClass]="{'active': isRowsActive}">
                    {{'setup.sidebar.rows' | translate}}
                </button>
            -->
                        <button 
                                (click)="switchLayers('section',false)" id="switcher-section" class='button-current-layer btn color-black border-gray'
                                [ngClass]="{'active': currentLayer==='section'}">
                                {{'setup.sidebar.sections' | translate}}
                                <span class='guide-assign'>{{'setup.sidebar.changeView' | translate}}</span>
                                <span class='guide-assign'>{{'setup.sidebar.onDisplay' | translate}}</span>
                        </button>
                        <button  
                                (click)="switchLayers('row',false)" id="switcher-row" class='button-current-layer btn color-black border-gray'
                                [ngClass]="{'active': currentLayer==='row'}">
                                {{'setup.sidebar.rows' | translate}}
                                <span class='guide-assign'>{{'setup.sidebar.changeView' | translate}}</span>
                                <span class='guide-assign'>{{'setup.sidebar.onDisplay' | translate}}</span>                      
                        </button>
                        <button (click)="switchLayers('seat',false)" id="switcher-seat" class='button-current-layer btn color-black border-gray'
                                [ngClass]="{'active': currentLayer==='seat'}">
                                {{'setup.sidebar.seats' | translate}}
                                <span class='guide-assign'>{{'setup.sidebar.changeView' | translate}}</span>
                                <span class='guide-assign'>{{'setup.sidebar.onDisplay' | translate}}</span>                      
                        </button>
                    </div>
                </div>
            </div>

            <div class="flex-column flex-column-section" *ngIf="!isSimulation && !(allowCustomCategories && configurationHasCustomCategories)">
                <span class='flex-column-header'>{{'setup.sidebar.categories' | translate}}</span>
                <!--
                        <button (click)="removeCategories(true)">Remove categories</button>
                    -->
                <div id='categories-btns-wrap' class='col pl-0 pr-0'>
                    <!--<button *ngFor="let category of categories.getCategoriesList()" title='{{category.name}}' class='btn-icon' [ngClass]="{'active': currentCategories.includes(category.group)}" (click)="setCategory(category.group)">-->
                    <div class='categories-btns-item' *ngFor="let category of categories.getCategoriesList()">
                        <!-- <div class="icon-container">
                                <div class="icon" [ngClass]="category.group"></div>
                            </div> -->
                        <button type='button' class='btn-category border-blue' (click)="setCategory(category.group)">
                            <span class='legend-symbol {{category.group}}'></span>
                            <span>{{category.name}}</span>
                            <span class='guide-assign'>{{'setup.sidebar.assign' | translate}}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="flex-column flex-column-section" *ngIf="allowCustomCategories && configurationHasCustomCategories">
                <span class='flex-column-header'>{{'setup.sidebar.categories' | translate}}</span>
                <app-custom-categories [interactive]="false"></app-custom-categories>
            </div>



            <div class="flex-column flex-column-section" *ngIf="!isSimulation">
                <span class='flex-column-header'>{{'setup.sidebar.groupOptions' | translate}}</span>
                        <!--<button (click)="createGroup()" class='btn-icon' [ngClass]="{'active': groupsNumber}" title='Group'>-->
                <div id='group-wrap'>
                    <button [disabled]="(isGroupSelected && isStrictlyGrouped || currentLayer==='seat') || currentSelectionCount <= 1"
                        (click)="createGroup()" class='btn border-blue' title='Group'>
                        GROUP
                    </button>
                    <button [disabled]="!isGroupSelected || currentLayer==='seat'" (click)="deleteGroup()" class='btn border-blue'
                        title='Ungroup'>
                        UNGROUP
                    </button>
                </div>
            </div>
            <div class='flex-column flex-column-section'>
                <span class='flex-column-header'>
                    {{'setup.sidebar.selectoptions' | translate}}
                </span>
                <div class='flex-column-content'>
                    <button id='btn-unselect' (click)="btnUnselectNodes()" class='btn'
                    [ngClass]="{'disabled':!currentSelection.length}">
                        {{'setup.sidebar.unSelectAll' | translate}}
                    </button>
                    
                </div>
            </div>
            <div id='simulationedition-col' class="flex-column flex-column-section" *ngIf="isSimulation && !isAllocation && page !== 'shared'">
                <span class='flex-column-header'>
                    {{'setup.sidebar.socialdistancing' | translate}}
                </span>
                <div class='flex-column-content'>
                    <!--<button class='btn' (click)="onEditSimulation()">{{'setup.sidebar.editsimulation' | translate}}</button>-->
                    
                    <button *ngIf="!isAllocation" type="button" class='btn' (click)="onComputeResults()"> {{'setup.sidebar.venueparameters' | translate}}</button>

                    <button id='btn-sectionparams' *ngIf="isSimulation && !isAllocation"
                    [ngClass]="{'disabled':currentLayer !== 'section' || !currentSelection.length}"
                    type="button" class='btn mt-1' (click)="onComputeSectionResultsSubSimulation()">{{'setup.sidebar.sectionparameters' | translate}}</button>

                    
                   
                    <button *ngIf="isSimulation && !isAllocation" (click)="toggleMoveGroups()" class='btn mt-1'
                        [ngClass]="{'active': isGroupsSelection,'disabled':currentLayer=='section' || hasAllocation} ">
                        {{'setup.sidebar.moveseatgroups' | translate}}
                    </button>

                </div>
            </div>
            <div class='flex-column flex-column-section' *ngIf="!configurationHasCustomCategories && isSimulation && !isAllocation && page !== 'shared'">
                <span class='flex-column-header'>
                    {{'setup.sidebar.availabilityoptions' | translate}}
                </span>
                <div class='flex-column-content'>
                    <button id='btn-enableselection' (click)="selectToEnable()" 
                        class='btn mt-1'
                        [ngClass]="{'active':isMultiSelection,'disabled':currentLayer=='seat'}">
                        {{'setup.sidebar.editallseats' | translate}}
                    </button>
                
                    
                    <button (click)="toggleSingleSelection()" class='btn mt-1'
                        [ngClass]="{'active': isSinglesSelection,'disabled':currentLayer=='section' || hasAllocation}">
                        {{'setup.sidebar.editsingleseats' | translate}}
                    </button>
                </div>
            </div>
            <div id='allocation-col' class="flex-column flex-column-section" *ngIf="!configurationHasCustomCategories && allowAllocation && page !== 'shared' && isSimulation">
                <span class='flex-column-header'>
                    {{'setup.sidebar.membersallocation' | translate}}
                </span>
                <div class='flex-column-content'>
                    <!--<button class='btn' (click)="onEditSimulation()">{{'setup.sidebar.editsimulation' | translate}}</button>-->
                    <button *ngIf="allowAllocation && hasAllocation"
                            type="button" class='btn mt-1' (click)="toggleAllocatedSeats()"
                            [ngClass]="{'active': displayAllocationBtn}">
                        Display allocation
                    </button>
                    <button id='btn-new-allocation' *ngIf="isSimulation && allowAllocation && !hasAllocation"
                            type="button" class='btn mt-1' (click)="onNewAllocation()">
                        {{'setup.sidebar.newallocation' | translate}}
                    </button>
                    <button *ngIf="isAllocation && hasAllocation"
                        type="button" class='btn mt-1' (click)="onNewAllocation()">
                    {{'setup.sidebar.updateallocation' | translate}}
                </button>
                    <button id='btn-download-allocation' *ngIf="isAllocation && allowAllocation && hasAllocation"
                            (click)="onDownloadAllocation()" class='btn mt-1'
                            title='Download allocation result'>
                        <div class='icon-container-crop'>
                            <div class='icon download'></div>
                        </div>
                        <span>{{'setup.sidebar.allocationresult' | translate}}</span>
                    </button>

                    <button id='delete-allocation' *ngIf="allowAllocation && isAllocation && hasAllocation"
                            type="button" class='btn mt-1' (click)="confirmDeleteAllocation()">
                        Delete allocation
                    </button>
                    <div *ngIf="allowAllocation && isSimulation && hasAllocation && !isAllocation">
                        <button id='go-allocation' type="button" class='btn mt-1' (click)="onViewAllocation()">
                            {{'setup.sidebar.goallocation' | translate}}
                        </button>
                    </div>
                    <br>
                    <button id='go-simulation' *ngIf="allowAllocation && isSimulation && isAllocation"
                    type="button" class='btn mt-1' (click)="onViewSimulation()">
                        {{'setup.sidebar.gosimulation' | translate}}
                    </button>
                </div>
            </div>
            
            <div class="flex-column flex-column-section" *ngIf="page==='shared'">
                <span class='flex-column-header'>
                    {{'setup.sidebar.viewresults' | translate}}
                </span>
                <div class='flex-column-content'>
                    <button type="button" class='btn' (click)="onComputeResults(page)">RESULTS</button>
                </div>
            </div>

            <div class="flex-column flex-column-section" *ngIf="!isSimulation">
                <div class='flex-column-header'>
                    <span>{{'setup.sidebar.assignPrice' | translate}} {{currency}}</span>
                </div>
                <div class='flex-column-content'>
                    <div class='container-fluid'>
                        <div class='row align-items-center'>
                            <div class='col-auto pl-0 pr-0'>
                                <input type="number" id='priceinput' min="0" max="100000"
                                    [value]="currentSelectionPricing" (keyup)='priceValueChange($event.target.value)'>
                            </div>
                            <div class='col-auto p-0'>

                                <button id='btnsaveprice' (click)="setNewPricing()" class='btn disabled border-blue'
                                    title='Assign price'>
                                    SAVE
                                </button>
                                <!-- <button (click)="restartPricing()" class='btn-round' title='Undo changes'>
                                        <div class='icon-container-crop'>
                                            <div class='icon undo'></div>
                                        </div>
                                    </button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!--END CONTENT-->

        <!--START FOOTER-->
        <div class='flex-column-footer' [ngClass]="{'shared-footer': page==='shared'}">
            <!-- <div class='flex-column flex-column-section'>
                <div class='flex-column-header'>
                    <span>{{'setup.sidebar.mapCategories' | translate}}</span>
                </div>
                <div class='flex-column-content'>
                    <ul class='list-unstyled m-0'>
                        <li *ngFor="let category of categories.getCategoriesList()">
                            <div class="circle" [ngStyle]="{'background': category.color}"></div>
                            <span class="legend-text text-bold">{{category.name}}</span>
                        </li>
                        <li>
                            <div class="circle" style='background-color: "rgb(255, 0, 6)"'></div>
                            <span class="legend-text text-bold">Selected</span>
                        </li>
                    </ul>
                </div>
            </div> -->
            <div id='upload-section' class="flex-column flex-column-section" *ngIf="page !== 'shared'">
                <div class='flex-column-header mb-0'>
                    <div *ngIf="!(allowCustomCategories && configurationHasCustomCategories) else customcategories">
                        <button id='btn-download' (click)="onDownloadManifest()" class='btn text-uppercase'
                            title='Download Seatmanifest' [ngClass]="{'mb-0' : isUploadActive}">
                            <div class='icon-container-crop'>
                                <div class='icon download'></div>
                            </div>
                            <span>{{'setup.sidebar.seatManifest' | translate}}</span>
                        </button>
                    </div>
                    <ng-template #customcategories>                           

                        <div *ngIf='ondownloadCustomcategories else readydownload'>
                            <i class="fa fa-spinner fa-spin fa-2x fa-fw"></i> Processing data
                            <span class="sr-only">Loading...</span>
                        </div>
                        <ng-template #readydownload>
                            <button id='btn-download' (click)="downloadCustomCategoriesVenueData()" class='btn text-uppercase'
                                title='Download VenueData' [ngClass]="{'mb-0' : isUploadActive}">
                                <div class='icon-container-crop'>
                                    <div class='icon download'></div>
                                </div>
                                <span>{{'setup.sidebar.seatManifest' | translate}}</span>
                            </button>
                        </ng-template>
                    </ng-template>
<!--                TODO: Aqui se hace upload del csv-->
                    <!--
                    <div class='asrow'>
                        <div class='col-auto pl-0  pr-0 text-center'>
                            <button *ngIf="!isUploadActive else fileName" (click)="onTriggerUploadManifest()" title='Upload' id="uploadTriggerButton"  class='mb-0 btn-icon text-uppercase'>
                                <div class='icon-container'>
                                    <div class='icon upload'></div>
                                </div>
                            </button>
                        </div>
                        <ng-template #fileName>
                            <hr>
                            <div id='uploading-preview'>
                                <span class='uploading-preview-lab'>File to be uploaded:</span><br>
                                <span class='uploading-preview-val'>{{uploadSeatmanifest.name}}</span>
                            </div>
                        </ng-template>
                    </div>
                    -->
                </div>
<!--                TODO: Abrir un Modal-->
                <div class='flex-column-content'>
                    <form #uploadCsv="ngForm" (ngSubmit)="onUploadManifest()">
                        <input #inputFile class="d-none" type="file" (change)="handleFileInput($event.target.files)"
                            id="uploadInput" name="seatmanifest" accept=".csv" />
                        <div *ngIf="isUploadActive" class='row justify-content-between'>
                            <div class='col'>
                                <button type='button' class='btn' (click)="onCancelUpload()">Cancel</button>
                            </div>
                            <div class='col'>
                                <input class='btn-reversed' type="submit" value="Send" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class='ghost-footer'>
                &copy; 2020 3D Digital Venue
            </div>
        </div>
    </div>
</div>


<app-selectiondata [page]="page"></app-selectiondata>
