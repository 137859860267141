import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, AfterViewInit {

    @Input() name = 'btn-name';
    @Input() disabled = true;

    @ViewChild('button') button!: ElementRef;

    public readonly observableClick: Observable<MouseEvent>;
    protected subscriberClick: Subscriber<MouseEvent> | null = null;

    constructor() {
        this.observableClick = new Observable<MouseEvent>((subscriber) => {
            this.subscriberClick = subscriber;
        });
    }

    ngOnInit(): void { }

    ngAfterViewInit(): void {
        const button = this.button.nativeElement as HTMLButtonElement;
        button.addEventListener('click', (event) => {
            if (this.subscriberClick) {
                this.subscriberClick.next(event);
            }
        });

    }

    reset(): void {
        this.disabled = true;
    }
}
