

<div id='laliga-header' *ngIf='client==="laliga" else public'>
    <div id='headerbar' class="header-container">
        <div class='asrow align-items-center no-gutters justify-content-between'>
            <div id='global-title' class='col-auto'  [ngClass]="{'laliga': client==='laliga'}">            
                <img *ngIf='client==="laliga"' class='clientlogo' src='/assets/img/documentation/laliga/laliga-logo.png' height='40' alt='LaLiga logo'>
                <div id='d3vheader-content'>
                    <a id='d3vheader-link' href='/{{client}}' title='Homepage' class='color-gray'>SOCIAL DISTANCE EDITOR</a>
                    
                    <small class='powered'>Powered by 3D Digital Venue</small>
                </div>
            </div>
            <div class='col-auto text-right'>
                <div class='d-none d-md-block'>
                    <a class='header-link' href="/laliga#mainfeatures">{{'home.section2.title' | translate}}</a>
                    <a class='header-link' href='/documentation/laliga' title='Documentation'>{{'home.menu.doc' | translate}}</a>
                    <a class='header-link' *ngIf='client==="laliga"' (click)='onOpenContactCau()' title="{{'home.menu.laliga.contacto' | translate}}">{{'home.menu.laliga.contacto' | translate}}</a>
                    <button class='btn header-btn-home' (click)='onOpenLoginModal()'>Log in</button>
                </div>
            </div>
            <div class='d-sm-block d-md-none'>
                <div dropdown class="nav-item dropdown mr-2">
                    <a dropdownToggle class="nav-link dropdown-toggle header-btn-home" data-toggle="dropdown"
                   role="button" aria-haspopup="true" aria-expanded="false">
                    <div class='icon-container-crop'>
                        <div class='icon menu'></div>
                    </div>
                </a>
                    <ul class="dropdown-menu" *dropdownMenu >
                        <li >
                            <a href="/laliga#mainfeatures" clasS='dropdown-item'>{{'home.section2.title' | translate}}</a>
                        </li>
                        <li >
                            <a href='/documentation/laliga' class="dropdown-item">{{'home.menu.doc' | translate}}</a>
                        </li>
                        <li *ngIf='client==="laliga"'>
                            <a class='dropdown-item' (click)='onOpenContactCau()' title="{{'home.menu.laliga.contacto' | translate}}">{{'home.menu.laliga.contacto' | translate}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #public>
    <app-header-public></app-header-public>
</ng-template>
<div id='page-container' class='page-container'>

    <nav id="navbar" class="navbar navbar-light bg-light fixed-top">
        <ul class="nav nav-pills">
            <li dropdown class="nav-item dropdown mr-2">
                <a id='changelogbtn' dropdownToggle class="nav-link dropdown-toggle active" data-toggle="dropdown"
               role="button" aria-haspopup="true" aria-expanded="false">Changelog</a>
                <ul class="dropdown-menu" *dropdownMenu >
                    <li *ngFor="let v of versions">
                        <a class="dropdown-item" scrollTo scrollYTarget ="{{v.offsetTop}}">Version {{v.version}}</a>
                    </li>
                </ul>
            </li>
            <li dropdown class="nav-item dropdown">
                <a id='faqsbtn' dropdownToggle class="nav-link dropdown-toggle" data-toggle="dropdown" 
                role="button" aria-haspopup="true" aria-expanded="false">FAQs</a>
                <ul class="dropdown-menu" *dropdownMenu> 
                    <li *ngFor="let f of faqs"><a class="dropdown-item"  scrollTo scrollYTarget ="{{f.offsetTop}}">{{ f.title | translate }}</a></li>
                </ul>
            </li>
        </ul>
    </nav>
    <div class='container'>
        <h2>{{'documentation.changelog.title' | translate}} </h2>
        <div *ngIf='!client else commingsoon'>
            <h3 id="v2-0">{{'documentation.changelog.version' | translate}} 2.0: Allocator {{'documentation.changelog.dates.version20' | translate}}</h3>
            <ul>
                <li>{{'documentation.changelog.version200' | translate}} <a href='mailto:enquiries@3ddigitalvenue.com'>enquiries@3ddigitalvenue.com</a></li>
            </ul>
        </div>
        <ng-template #commingsoon>
            <h3 id="v2-0">{{'documentation.changelog.version' | translate}} 2.0</h3>
            <ul>
                <li>{{'documentation.changelog.version200_coomingsoon' | translate}} </li>
            </ul>
        </ng-template>
        <h3 id="v1-9">{{'documentation.changelog.version' | translate}} 1.9 {{'documentation.changelog.dates.version19' | translate}}</h3>
        <ul>
            <li>{{'documentation.changelog.version190' | translate}} </li>
            <li>{{'documentation.changelog.version191' | translate}} </li>
            <li>{{'documentation.changelog.version192' | translate}} </li>
            <li>{{'documentation.changelog.version193' | translate}} <span (click)='openVideo("https://www.youtube.com/embed/76xO-OMvoDw","How to change availability on sections and rows")'>{{'documentation.seevideo' | translate}}</span></li>
        </ul>

        <h3 id="v1-8">{{'documentation.changelog.version' | translate}} 1.8 {{'documentation.changelog.dates.version18' | translate}}</h3>
        <ul>
            <li>{{'documentation.changelog.version180' | translate}}</li>
            <li>{{'documentation.changelog.version181' | translate}}
                <span (click)='openVideo("https://www.youtube.com/embed/0os2IHFVZns","How to kill aisle seats from each side")'>{{'documentation.seevideo' | translate}}</span></li>
        </ul>
        <h3 id="v1-7">{{'documentation.changelog.version' | translate}} 1.7 {{'documentation.changelog.dates.version17' | translate}}</h3>
        <ul>
            <li>{{'documentation.changelog.version170' | translate}}
                <span (click)='openVideo("https://www.youtube.com/embed/oDA9lbfXAw0","How to add the limited view category")'>{{'documentation.seevideo' | translate}}</span>
            </li>
            <li>{{'documentation.changelog.version171' | translate}}</li>
            <li>{{'documentation.changelog.version172' | translate}}
                <span (click)='openVideo("https://www.youtube.com/embed/53rTbKOaTbg","How to create simulations by sections")'>{{'documentation.seevideo' | translate}}</span>
            </li>
        </ul>
        <h3 id="v1-6">{{'documentation.changelog.version' | translate}} 1.6 {{'documentation.changelog.dates.version16' | translate}}</h3>
        <ul>
            <li>{{'documentation.changelog.version160' | translate}}
                <span (click)='openVideo("https://www.youtube.com/embed/UFnS81qTu88","How to eliminate aisle seats")'>{{'documentation.seevideo' | translate}}</span></li>
            <li>{{'documentation.changelog.version161' | translate}} </li>
        </ul>
        <div id="v1-5">
            <h3>{{'documentation.changelog.version' | translate}} 1.5 {{'documentation.changelog.dates.version15' | translate}}</h3>
            <ul>
                <li>{{'documentation.changelog.version150' | translate}}</li>
                <li>{{'documentation.changelog.version151' | translate}}</li>
            </ul>
        </div>
        <h3 id="v1-4">{{'documentation.changelog.version' | translate}} 1.4 {{'documentation.changelog.dates.version14' | translate}}</h3>
        <ul>
            <li>{{'documentation.changelog.version140' | translate}}</li>
            <li>{{'documentation.changelog.version141' | translate}}<br>
                {{'documentation.changelog.version141-detail' | translate}} 
                <span (click)='openVideo("https://www.youtube.com/embed/GsMK_Gmmaqo","How to edit single seats")'>{{'documentation.seevideo' | translate}}</span>
            </li>
            <li>{{'documentation.changelog.version142' | translate}} </li>
        </ul>
        <h3 id="v1-3">{{'documentation.changelog.version' | translate}} 1.3 {{'documentation.changelog.dates.version13' | translate}}</h3>
        <ul>
            <li>{{'documentation.changelog.version130' | translate}}</li>
            <li>{{'documentation.changelog.version131' | translate}}
                <span (click)='openVideo("https://www.youtube.com/embed/mhfv2PCwI7k","How to move groups")'>{{'documentation.seevideo' | translate}}</span>
            </li>
            <li>{{'documentation.changelog.version132' | translate}}
                <span (click)='openVideo("https://www.youtube.com/embed/NaOamajdWh4","How to limit capacity to two groups per row")'>{{'documentation.seevideo' | translate}}</span>
            </li>
            <li>{{'documentation.changelog.version133' | translate}} </li>
        </ul>
        <h3 id="v1-2">{{'documentation.changelog.version' | translate}} 1.2 {{'documentation.changelog.dates.version12' | translate}}</h3>
        <ul>
            <li>{{'documentation.changelog.version120' | translate}}
                <span (click)='openVideo("https://www.youtube.com/embed/T721MbSeQFQ","How to duplicate simulations")'>{{'documentation.seevideo' | translate}}</span>
            </li>
            <li>{{'documentation.changelog.version121' | translate}}
                <span (click)='openVideo("https://www.youtube.com/embed/CnMFHvSD9Kk","How to share your simulations")'>{{'documentation.seevideo' | translate}}</span>
            </li>
            <li>{{'documentation.changelog.version122' | translate}}</li>
            <li>{{'documentation.changelog.version123' | translate}}</li>
        </ul>
        <h3 id="v1-1">{{'documentation.changelog.version' | translate}} 1.1 {{'documentation.changelog.dates.version11' | translate}}</h3>
        <ul>
            <li>{{'documentation.changelog.version110' | translate}} </li>
            <li>{{'documentation.changelog.version111' | translate}} </li>
            <li>{{'documentation.changelog.version112' | translate}} </li>
            <li>{{'documentation.changelog.version113' | translate}} </li>
        </ul>


        <h3 id="v1-0">{{'documentation.changelog.version' | translate}} 1.0</h3>
        <ul>
            <li>{{'documentation.changelog.version100' | translate}} </li>
            <li>{{'documentation.changelog.version101' | translate}} </li>
            <li>{{'documentation.changelog.version102' | translate}} </li>
            <li>{{'documentation.changelog.version103' | translate}} </li>
            <li>{{'documentation.changelog.version104' | translate}} </li>
            <li>{{'documentation.changelog.version105' | translate}} </li>
            <li>{{'documentation.changelog.version106' | translate}} </li>
            <li>{{'documentation.changelog.version107' | translate}} </li>
        </ul>
    </div>
    <div id='faqs-container'  class='container'>
        <h2 id="faqs">{{'documentation.faqs.title' | translate}}</h2>

        <h3 id="faqs1">{{'documentation.faqs.faqs1.title' | translate}}</h3>
    
        <h4>{{'documentation.faqs.faqs1.q1' | translate}}</h4>
        <p>{{'documentation.faqs.faqs1.a1' | translate}}
            <span class='m-0' (click)='openVideo("https://www.youtube.com/embed/rTnsOk8X6hY","")'>video</span>.
        </p>

        <div *ngIf='!client'>
            <h4>{{'documentation.faqs.faqs1.q2' | translate}}</h4>
            <p>{{'documentation.faqs.faqs1.a2' | translate}} </p>
            <h4>{{'documentation.faqs.faqs1.q3' | translate}}</h4>
            <p>{{'documentation.faqs.faqs1.a3' | translate}} <a href="mailto:enquiries@3dditigalvenue.com" title='Mail to enquiries'>enquiries@3dditigalvenue.com</a></p>
        </div>
        
        <h4>{{'documentation.faqs.faqs1.q4' | translate}}</h4>
        <p>{{'documentation.faqs.faqs1.a4' | translate}}</p>

        <!--realizar simulaciones-->

        <h3 id="faqs2">{{'documentation.faqs.faqs2.title' | translate}}</h3>
        
        <h4>{{'documentation.faqs.faqs2.q1' | translate}}</h4>
        <p>{{'documentation.faqs.faqs2.a1' | translate}}
            {{'documentation.faqs.checkthis' | translate}} <span class='m-0' (click)='openVideo("https://www.youtube.com/embed/53rTbKOaTbg","How to run a simulation with different parameters in one section")'>  {{'documentation.video' | translate}} </span>{{'documentation.faqs.faqs2.a11' | translate}}</p>

        <h4>{{'documentation.faqs.faqs2.q2' | translate}}</h4>
        <p>
            {{'documentation.faqs.faqs2.a2' | translate}}
        </p>
        <h4>{{'documentation.faqs.faqs2.q3' | translate}}</h4>
        <p>
            {{'documentation.faqs.faqs2.a3' | translate}}
            {{'documentation.faqs.seehowitworks' | translate}} <span class='m-0' (click)='openVideo("https://www.youtube.com/embed/mhfv2PCwI7k","How to edit the layout that the algorithm calculates")'> {{'documentation.faqs.here' | translate}}</span>.
        </p>

        <h4>{{'documentation.faqs.faqs2.q4' | translate}}</h4>
        <p>{{'documentation.faqs.faqs2.a4' | translate}}</p>

            <!--aspectos tecnicos-->
        <h3 id="faqs3">{{'documentation.faqs.faqs3.title' | translate}}</h3>
        <h4>{{'documentation.faqs.faqs3.q1' | translate}}</h4>
        <p>{{'documentation.faqs.faqs3.a1' | translate}}</p>
        <h4>{{'documentation.faqs.faqs3.q2' | translate}}</h4>
        <p>{{'documentation.faqs.faqs3.a2' | translate}}</p>
        <h4>{{'documentation.faqs.faqs3.q3' | translate}}</h4>
        <p>{{'documentation.faqs.faqs3.a3' | translate}} </p>
        <h4>{{'documentation.faqs.faqs3.q4' | translate}}</h4>
        <p>{{'documentation.faqs.faqs3.a4' | translate}}</p>
        <h4>{{'documentation.faqs.faqs3.q5' | translate}}</h4>
        <p>{{'documentation.faqs.faqs3.a5' | translate}}</p>

        <!--Allocator-->
        <div *ngIf="!client">
            <h3 id="faqs4">{{'documentation.faqs.faqs4.title' | translate}}</h3>
            <h4>{{'documentation.faqs.faqs4.q1' | translate}}</h4>
            <p>{{'documentation.faqs.faqs4.a1' | translate}}  <a href='mailto:support@3ddigitalvenue.com'>support@3ddigitalvenue.com</a>  {{'documentation.faqs.faqs4.a11' | translate}}</p>
            <h4>{{'documentation.faqs.faqs4.q2' | translate}}</h4>
            <p>{{'documentation.faqs.faqs4.a2' | translate}}</p>
            <h4>{{'documentation.faqs.faqs4.q3' | translate}}</h4>
            <p>{{'documentation.faqs.faqs4.a3' | translate}} </p>
            <h4>{{'documentation.faqs.faqs4.q4' | translate}}</h4>
            <p>{{'documentation.faqs.faqs4.a4' | translate}} </p>
            <h4>{{'documentation.faqs.faqs4.q5' | translate}}</h4>
            <p>{{'documentation.faqs.faqs4.a5' | translate}} </p>
            <h4>{{'documentation.faqs.faqs4.q6' | translate}}</h4>
            <p>{{'documentation.faqs.faqs4.a6' | translate}} </p>
            <h4>{{'documentation.faqs.faqs4.q7' | translate}}</h4>
            <p>{{'documentation.faqs.faqs4.a7' | translate}} <a href='mailto:support@3ddigitalvenue.com'>support@3ddigitalvenue.com</a> {{'documentation.faqs.faqs4.a71' | translate}}</p>
        </div>
    </div>
</div>