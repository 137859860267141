import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatNumberService {

  private currentLocale: string;
  private currency: string;
  private units: string;

  constructor() { }

  public setCurrentLocale(locale: string): void {
    this.currentLocale = locale;
  }

  public getCurrentLocale(): string {
    return this.currentLocale;
  }

  public setCurrency(currency: string): void {
    this.currency = currency;
  }

  public getCurrency(): string {
    return this.currency;
  }

  public setUnits(units: string): void {
    this.units = units;
  }

  public getUnits(): string {
    return this.units;
  }

  public formatNumber(num: number): string {
    const numb = new Intl.NumberFormat('en').format(num);
    if (this.currency === 'EUR') {
      return this.formatNumberEs(num);
    } else {
      return numb;
    }
  }

  public formatCurrencyNumber(num: number): string {
    const numb = new Intl.NumberFormat('en').format(num);
    let formatted;
    if (this.currency === 'eur') {
      formatted = this.formatNumberEs(num) + ' ' + this.currency;
    } else {
      formatted = this.currency + ' ' + numb;

    }

    return formatted;
  }

  private formatNumberEs(num: number): string {
    return new Intl.NumberFormat('de-DE').format(num);
  }

  public formatSocialDistancing(num: number): string {
    switch (this.units) {
      case 'ft':
        return (num / 0.3048).toFixed(2);
        break;
      case 'm':
        return num.toFixed(2);
        break;
    }

  }

}
