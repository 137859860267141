import { Injectable, Inject } from '@angular/core';
import { createPopper, Placement } from '@popperjs/core';
import { GeneralHandler } from './general.handler';
import { DVM_CONFIG } from 'src/assets/configuration/digitalvenue.configuration';
import { DVMService } from '../dvm.service';
import { DVMConfiguration } from 'src/app/social-distance-editor/shared/models/dvm-configuration.model';
import { PricingService } from '../pricing.service';
import { InventoryService } from '../inventory.service';
import { SelectionService } from '../selection.service';
import { AllocatorService } from 'src/app/social-distance-editor/plugins/allocator/services/allocator.service';
import { DataService } from '../data.service';
import {CustomCategoriesService} from '../../plugins/custom-categories/services/custom-categories.service';
import {
    CUSTOM_CATEGORIES_BY_VENUE,
    customCategoriesbyVenue
} from '../../plugins/custom-categories/configuration/categories.configuration';
import {CustomCategoriesByVenue} from '../../plugins/custom-categories/models/custom-categories.model';

@Injectable()
export class PopoverHandler extends GeneralHandler {

    tooltip: HTMLElement;
    view3DId;
    language = 'en';
    constructor(protected dvmService: DVMService,
                private pricing: PricingService,
                private inventory: InventoryService,
                private selection: SelectionService,
                @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
                @Inject(CUSTOM_CATEGORIES_BY_VENUE) private customCategoriesByVenue: CustomCategoriesByVenue,
                private allocatorService: AllocatorService,
                private customCategoriesService: CustomCategoriesService,
                private dataService: DataService) {
        super('enter', dvmService);

    }

    protected handle(obj) {
        // this.popover3dview.deleteTimer();
        let seating,section,row,seat;
        if(obj.nodes.length){
            seating = obj.nodes[0].id.split('-');
            section = seating[0].split('_')[1].split('(')[0];
            row = seating[1];
            seat = seating[2];
        }
        if (localStorage.getItem('language') !== null) {
            this.language = localStorage.getItem('language');
        }
        if (obj.nodes.length && (obj.nodes[0].state === 'available' || obj.nodes[0].state === 'selected') 
            || (this.customCategoriesService.allowCustomCategories && this.inventory.getInventoryData()[section]?.elements[row]?.elements[seat]?.data.customcategory)) {

            if (this.dvmService.viewer.layers.getLayerLevel() === 0 && obj.nodes[0].type === 'section'
                || this.dvmService.viewer.layers.getLayerLevel() === 1 && obj.nodes[0].type === 'seat') {

                const node = obj.nodes[0];
                // this.popover3dview.currentView = node.id;

                // Receive tooltip HTML Element
                this.tooltip = document.querySelector('#tooltip');

                // Generate tooltip content based on node type
                let tooltipContent;
                if (node.type === 'section') {
                    tooltipContent = this.sectionPopoverContent(node);
                } else if (node.type === 'seat') {
                    tooltipContent = this.seatPopoverContent(node);
                }

                // Create content HTML Element, clean previous popover and append
                const tooltipContentHTML = document.createElement('div');
                tooltipContentHTML.innerHTML = tooltipContent.trim();
                const popoverBodyNode = document.querySelector('#tooltip .popover-body');
                while (popoverBodyNode.firstChild) {
                    popoverBodyNode.removeChild(popoverBodyNode.firstChild);
                }
                popoverBodyNode.appendChild(tooltipContentHTML);

                // Calculate popover placement
                let placement: Placement;
                if (node.anchor.dom[1] > (document.getElementById('main-interface').offsetHeight / 2)) {
                    placement = 'top';
                } else {
                    placement = 'bottom';
                }

                // Initiate popover
                createPopper(node, this.tooltip, {
                    placement,
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 8],
                            },
                        },
                        {
                            name: 'flip',
                            options: {
                                fallbackPlacements: [],
                            }
                        }
                    ],
                });

                // Display popover
                this.tooltip.setAttribute('data-show', '');

                let p = document.getElementById('tooltip');
                p.onclick = (res => {
                // onclick stuff
                    // Close popovers
                    const tooltip: HTMLElement = document.querySelector('#tooltip');
                    tooltip.removeAttribute('data-show');
                    // Handle        
                    const viewer = obj.instance;
                    const nodes = obj.nodes;
                    if (nodes.length) {
                        viewer.hover(null);
                        this.selection.selectionSubscriptionsArray.forEach(
                            e => { e.unsubscribe(); }
                        );
                    }
                    viewer.removeNodesFromGroup(viewer.getNodesByGroups('seat', 'tomove'), 'tomove');
                    viewer.removeNodesFromGroup(viewer.getNodesByGroups('seat', 'warning'), 'warning');
                    viewer.removeNodesFromGroup(viewer.getNodesByGroups('seat', 'singleselection'), 'singleselection');
                });
            }
        }
    }


    sectionPopoverContent(node) {
        // const sectionCategories = [];
        // console.log(node.groups);
        // for ( const c of node.groups ) {
        //     console.log(c);
        //     if (c) {
        //         console.log('entro if');
        //         sectionCategories.push(c);
        //     }
        // }
        const areaName = node.id.split('_')[1].split('(')[0];
        const sectionCategories = this.inventory.getInventoryProperty('category', areaName);
        let tooltipContent;
        const seatsAvailable = this.inventory.getInventoryBySection(areaName, true).length;
        const totalSeats = this.inventory.getInventoryBySection(areaName).length;
        const totalRevenue = this.pricing.getRevenueBySection(areaName).revenue;
        const totalNewRevenue = this.pricing.getRevenueBySection(areaName).newrevenue;
        //const totalRevenue = 0;
        tooltipContent = `<ul class='list-unstyled ticket-selected'>
                <li>
                    <span class='ticket-lab text-uppercase'>Section ${areaName}</span>
                    <span class='ticket-val'>
                    `;
        for ( const c of sectionCategories ) {
                            tooltipContent += `<span class='legend-symbol ${c}'></span>`;
                        }
        tooltipContent += `
                    </span>
                </li>
                <li>
                    <span class='ticket-lab'>Total Section Rows</span>
                    `+
                    //`<span class='ticket-val'>${this.inventory.getRowsCountBySectionId(node.id)}</span>`+
                    `<span class='ticket-val'>${Object.keys(this.dvmService.viewer.getNodeById(node.id).rows).length}</span>`+
                `</li>`;
        // tslint:disable-next-line: align
        tooltipContent += `
            <li>
                <span class='ticket-lab'>Total Section Capacity</span>
                <span class='ticket-val font-bold'>` + this.pricing.formatNumber(totalSeats) + `</span>
            </li>`;
            let p = this.pricing.getCurrentPricing('section', [node], false, true);
            let pricedom = ``;
            let pricelab = `Seat price`;
            if (typeof p === 'string') {
                pricedom += `<span class='ticket-val-item'>` + p + `</span>`;
            } else {
                pricelab = `Seat prices`;
                for (let i = 0; i < p.length; i++) {
                    pricedom += `<span class='ticket-val-item'>` + this.pricing.formatNumber(p[i]) + `</span>`;
                }
            }
            tooltipContent += `
                    <li>
                        <span class='ticket-lab'>`+ pricelab + `</span>
                        <span class='ticket-val'>`+ pricedom + `</span>
                    </li>
                    <li>
                        <span class='ticket-lab mb'>Total Section Revenue</span>
                        <span class='ticket-val'>`+ this.pricing.formatNumber(totalRevenue) + `</span>
                    </li>`;
        // if (totalSeats !== 0 && seatsAvailable === 0) {
        //     tooltipContent += `
        //     <li>
        //         <span class='ticket-lab'>Total Section Capacity</span>
        //         <span class='ticket-val font-bold'>` + this.pricing.formatNumber(totalSeats) + `</span>
        //     </li>`;
        // }
        // // tslint:disable-next-line: align
        // if (totalSeats === seatsAvailable) {
        //     tooltipContent += `
        //         <li>
        //             <span class='ticket-lab'>Total Section Capacity</span>
        //             <span class='ticket-val font-bold'>` + this.pricing.formatNumber(totalSeats) + `</span>
        //         </li>`;
        // }
        // tslint:disable-next-line: align
        if (totalSeats !== 0 && seatsAvailable !== 0 && totalSeats !== seatsAvailable) {
            // tooltipContent += `
            //     <li>
            //         <span class='ticket-lab'>Total Section Capacity</span>
            //         <span class='ticket-val font-bold'>` + this.pricing.formatNumber(totalSeats) + `</span>
            //     </li>`;
            tooltipContent += `<li>
                    <span class='ticket-lab'>New Section Capacity</span>
                    <span class='ticket-val font-bold color-blue'>` + this.pricing.formatNumber(seatsAvailable) + `</span>
                </li>`;
        }

        if (totalNewRevenue) {
            tooltipContent += `
                    <li>
                        <span class='ticket-lab'>New Section Revenue</span>
                        <span class='ticket-val color-blue'>`+ this.pricing.formatNumber(totalNewRevenue) + `</span>
                    </li>`;
        }
        tooltipContent += `</ul>`;

        return tooltipContent;
    }

    seatPopoverContent(node) {
        const seating = node.id.split('-');
        const section = seating[0].split('_')[1].split('(')[0];
        const row = seating[1];
        const seat = seating[2];
        const seatsAvailable = this.inventory.getInventoryByRow(section, row, true).length;
        const totalSeats = this.inventory.getInventoryByRow(section, row).length;
        const totalRevenue = this.pricing.getRevenueBySection(section, row).revenue;
        const totalNewRevenue = this.pricing.getRevenueBySection(section, row).newrevenue;
        const sectionCategories = this.inventory.getInventoryProperty('category', section);
        let tooltipContent;
        tooltipContent = `<ul class='flex ticket-selected-seat'>
            <li>
                    <span class='ticket-lab'>Section ` + section + `</span>
                    <span class='ticket-val'>
                    `;
        if (this.customCategoriesService.allowCustomCategories) {
            const venueId = this.dvmService.viewer.getVenueId();
            const customCategoriesData = this.customCategoriesByVenue[venueId];
            const sectionStuff = this.inventory.getInventoryData()?.[section]?.elements;
            if (sectionStuff) {
                const sectinCustomCategories = new Set<string>();
                this.inventory.iterateInventoryElements((el) => {
                    if (el.customcategory) {
                        sectinCustomCategories.add(el.customcategory);
                    }
                }, sectionStuff);
                for (const c of sectinCustomCategories ) {
                    tooltipContent += `<span class='legend-symbol ${c}' style='background-color: ${customCategoriesData[c]?.color ?? 'white'}'></span>`;
                }
            }
        }
        // else {
        for (const c of sectionCategories) {
            tooltipContent += `<span class='legend-symbol ${c}'></span>`;
        // }
        }
        tooltipContent += `
                        </span>
                    </li>
                <li>
                    <span class='ticket-lab'>Row ID</span>
                    <span class='ticket-val font-bold'>` + row + `</span>
                </li>
                `;
        if(this.dvmService.activeVisualizationLayer==='seat'){
        tooltipContent +=`
            <li>
            <span class='ticket-lab'>Seat ID</span>
            <span class='ticket-val font-bold'>` + seat + `</span>
            </li>
            `;
        }
        // tslint:disable-next-line: align
        if(this.dvmService.activeVisualizationLayer==='row'){

        
            tooltipContent += `
                <li>
                    <span class='ticket-lab'>Total Row Capacity</span>
                    <span class='ticket-val font-bold'>` + this.pricing.formatNumber(totalSeats) + `</span>
                </li>`;
        }

       let p = this.inventory.getInventoryData()[section].elements[row].elements[seat].data.price;
                tooltipContent += `<li>
                                <span class='ticket-lab'>Seat Price</span>
                                <span class='ticket-val'>` + p + `<span class='ticket-val-end'></span></span>
                            </li>`;

        if(this.dvmService.activeVisualizationLayer==='row'){     
            tooltipContent += `<li>
                            <span class='ticket-lab mb'>Total Row Revenue</span>
                            <span class='ticket-val'>`+ this.pricing.formatNumber(totalRevenue) + `</span>
                        </li>`;
            // tslint:disable-next-line: align
            if (totalSeats !== 0 && seatsAvailable !== 0 && totalSeats !== seatsAvailable) {
                tooltipContent += `
                        <li>
                            <span class='ticket-lab'>New Row Capacity</span>
                            <span class='ticket-val font-bold color-blue'>` + this.pricing.formatNumber(seatsAvailable) + `</span>
                            </li>`;
            }
            if (totalNewRevenue) {
                tooltipContent += `
                            <li>
                                <span class='ticket-lab'>New Row Revenue</span>
                                <span class='ticket-val color-blue'>`+ this.pricing.formatNumber(totalNewRevenue) + `</span>
                            </li>`;
            }
        }
        const user_plugins_refs = this.getUserPluginsRefs();
        if (this.allocatorService.allowAllocation && this.allocatorService.showAllocatedSeats){
            if (this.dataService.simulationData && this.dataService.simulationData.allocation){
                const allocData = this.inventory.getInventoryData()[section].elements[row].elements[seat].data.allocation?.popup;
                if (allocData){
                    for (const key in allocData) {
                        if (allocData.hasOwnProperty(key)) {
                            tooltipContent +=
                                `<li>
                                    <span class='ticket-lab'>${key}</span>
                                    <span class='ticket-val color-blue'>${allocData[key]}</span>
                                </li>`;
                        }
                    }
                }
            }
        }

        if(this.customCategoriesService.allowCustomCategories){
            if (this.inventory.getInventoryData()[section].elements[row].elements[seat].data.customcategory){
                let customcat = this.inventory.getInventoryData()[section].elements[row].elements[seat].data.customcategory;
                tooltipContent +=
                `<li>
                    <span class='ticket-lab'>Seat Category</span>
                    <span class='ticket-val color-blue'>${customcat}</span>
                </li>`;
            }
        }
        tooltipContent += `</ul>`;

        return tooltipContent;
    }

    private getUserPluginsRefs(){
        let refs=[];
        if(this.dataService.user){
            let plugs = this.dataService.user.user.plugins;
            if(plugs.length>0){
                for (let i  =0;i<plugs.length;i++){
                    refs.push(plugs[i].ref)
                }
            }
        }
        return refs;
    }
}
