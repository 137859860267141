import { Injectable, Inject } from '@angular/core';
import { DVM_CONFIG } from '../../../assets/configuration/digitalvenue.configuration';
import { Subject } from 'rxjs';
import {DVMConfiguration} from '../shared/models/dvm-configuration.model';
import { S_IFREG } from 'constants';
import { CategoriesService } from './categories.service';

declare let DVM;

@Injectable({
    providedIn: 'root'
})

export class DVMService {

    viewer;
    viewer3d;
    viewerSubject: Subject<any> = new Subject();
    viewer3dSubject: Subject<any> = new Subject();
    mapSubject: Subject<any> = new Subject();
    isViewerSubjectInitialized = false;
    isViewer3dSubjectInitialized = false;
    subscribedCallbacks = {};
    subscribed3dCallbacks = {};
    activeVisualizationLayer;
    isConfiguration = false;
    isSimulation = false;
    venuetotaltiersav;
    constructor(
        @Inject(DVM_CONFIG) private DVMConfig: DVMConfiguration) {

        this.viewerSubject.subscribe(
            viewer => this.viewer = viewer
        );
    }

    public getDVMConfig(): DVMConfiguration {
        return JSON.parse(JSON.stringify(this.DVMConfig));
    }

    public setTotalTiersAV(total){
        this.venuetotaltiersav = total;
    }
    public getTotalTiersAV(){
        return this.venuetotaltiersav;
    }
    public restartDVM(venueId?: any, mapId?: any) {
        //console.log('Restart DVM', venueId, mapId);
        // tslint:disable-next-line: forin
        for (const event in this.subscribedCallbacks) {
            this.subscribedCallbacks[event].forEach(callback => {
                this.viewer.unsubscribe(event, callback);
            });
        }
        // tslint:disable-next-line: forin
        for (const event in this.subscribed3dCallbacks) {
            this.subscribed3dCallbacks[event].forEach(callback => {
                this.viewer3d.unsubscribe(event, callback);
            });
        }
        this.isViewerSubjectInitialized = false;
        this.isViewer3dSubjectInitialized = false;
        const viewerConfig = this.DVMConfig;
        if (venueId) {
            viewerConfig.venue_id = venueId;
            if (this.viewer && !mapId) {
                viewerConfig.map_id = this.viewer.getMapId();
            }
        }
        if (mapId) {
            viewerConfig.map_id = mapId;
        }
        //console.log(viewerConfig);
        this.initializeDVM('map_viewer', viewerConfig);
    }

    private initializeDVM(moduleName, config) {
        //console.log('initialize');
        DVM.loadModule(moduleName, config)
            .then((viewer) => {
                this.viewer = viewer;
                (window as any).viewer = viewer;
                // this.loadMap(this.DVMConfig);
                this.loadMap(config).then(
                    r => {
                        this.mapSubject.next(this.viewer);
                    }
                );
                if (!this.isViewerSubjectInitialized) {
                    this.viewerSubject.next(this.viewer);
                    this.isViewerSubjectInitialized = true;
                }
                viewer.flags.automatic_selection = false;
                viewer.flags.automatic_hover = false;
                viewer.flags.fixed_aspect_ratio = false;
            })
            .catch((err) => {
                console.error(err);
            });

        const viewer3dConfig = JSON.parse(JSON.stringify(config));
        viewer3dConfig.container = 'viewer3d-container';
        viewer3dConfig.plugins = ['navigation'];
        DVM.loadModule('3d_viewer', viewer3dConfig)
            .then((viewer3d) => {
                this.viewer3d = viewer3d;
                if ((window as any).viewer3d == null) {
                    (window as any).viewer3d = [];
                }
                (window as any).viewer3d.push(viewer3d);
                if (!this.isViewer3dSubjectInitialized) {
                    this.viewer3dSubject.next(this.viewer3d);
                    this.isViewer3dSubjectInitialized = true;
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    changeMapConfiguration(venueId, mapId?) {
        if (this.viewer) {
            const viewerConfig = JSON.parse(JSON.stringify(this.DVMConfig));
            viewerConfig.venue_id = venueId;
            viewerConfig.map_id = this.viewer.getMapId();
            if (mapId) {
                viewerConfig.map_id = mapId;
            } else {
                viewerConfig.map_id = this.viewer.getMapId();
            }
            this.loadMap(viewerConfig).then(
                r => {
                    this.mapSubject.next();
                }
            );
        }
    }

    subscribeHandler(event: 'click' | 'end_load' | 'enter' | 'leave', callback) {
        const viewerSubjectSubscribe = this.viewerSubject.subscribe(
            viewer => {
                this.viewer.subscribe(event, callback);
                if (!this.subscribedCallbacks[event]) {
                    this.subscribedCallbacks[event] = [];
                }
                this.subscribedCallbacks[event].push(callback);
                viewerSubjectSubscribe.unsubscribe();
            }
        );
    }

    subscribe3dHandler(event: 'click_navigation_node', callback) {
        const viewer3dSubjectSubscribe = this.viewer3dSubject.subscribe(
            viewer3d => {
                this.viewer3d.subscribe(event, callback);
                if (!this.subscribed3dCallbacks[event]) {
                    this.subscribed3dCallbacks[event] = [];
                }
                this.subscribed3dCallbacks[event].push(callback);
                viewer3dSubjectSubscribe.unsubscribe();
            }
        );
    }

    public loadMap(loadOptions) {
        return this.viewer.loadMap(loadOptions).then(() => {
            this.applyStyles2(this.viewer);
        });
    }

    public load3DView(nodeId) {
        const loadOptions = JSON.parse(JSON.stringify(this.DVMConfig));
        loadOptions.view_id = nodeId;
        loadOptions.venue_id = this.viewer.getVenueId();
        this.viewer3d.loadView3d(loadOptions);
    }

    public getLockedseats(){
        return this.viewer.getNodesByGroups('seat', 'locked');

    }

    

    private applyStyles2(viewer){
        const regularFill = '#36B369';
        const regularFillOutline = '#198044';
        const regularFillHover = '#CCFFE1';
        const regularFillHoverOutline = '#36B369';

        const vipFill = '#CCA829';
        const vipFillOutline = '#80661A';
        const vipFillHover = '#FFF2CC';
        const vipFillHoverOutline = '#CCA829'

        const disabledFill = '#3688B3';
        const disabledFillOutline = '#1A5D80';
        const disabledFillHover = '#CCEEFF';
        const disabledFillHoverOutline = '#3688B3';

        const lockedFill = '#000000';
        const lockedFillOutline = '#808080';
        const lockedFillHover = '#CCCCCC';
        const lockedFillHoverOutline = '#808080';

        const restrictedFill = '#C34DFF';
        const restrictedFillOutline = '#7639AD';
        const restrictedFillHover = '#e4bef7';
        const restrictedFillHoverOutline = '#7639AD';

        const selectedFill = '#FF4D4D';
        const selectedFillOutline = '#801A1A';
        const selectedFillHover = '#FFCCCC';
        const selectedFillHoverOutline = '#FF4D4D';

        const tomoveFill = 'rgb(100, 0, 5)';
        const warningFill = 'orange';
        
        const allocationFill = '#1F8EFA';
        const allocationFillOutline = '#1a6ebc';
        const allocationFillHover = '#1F8EFA';
        const allocationFillHoverOutline = '#1a6ebc';


        const styles = viewer.getStyles();
        for(let i =0;i<styles.length;i++){
            let layer = styles[i];
            if(layer.seat){
                let available = layer.seat.available;
                available.normal.regular = {fillStyle: regularFill,fillOpacity: 1,strokeStyle: regularFillOutline};
                available.normal.vip = {fillStyle: vipFill,fillOpacity: 1,strokeStyle: vipFillOutline};
                available.normal.disabled = {fillStyle: disabledFill,fillOpacity: 1,strokeStyle: disabledFillOutline};
                available.normal.locked = {fillStyle: lockedFill,fillOpacity: 1,strokeStyle: lockedFillOutline};
                available.normal.restricted = {fillStyle: restrictedFill,fillOpacity: 1,strokeStyle: restrictedFillOutline};
                available.normal.allocation = {fillStyle: allocationFill,fillOpacity: 1,strokeStyle: allocationFillOutline};
                
                available.normal.grouped = {strokeStyle: "yellow"};
                available.normal.selected = {fillStyle: selectedFill,fillOpacity: 1,strokeStyle: selectedFillOutline};
                available.normal.warning = {fillStyle: warningFill,fillOpacity: 1,strokeStyle: selectedFillOutline};
                // TODO: Setear un color correcto y un icono para las sillas onhold
                available.normal.onhold = {fillStyle:'#FFFFFF', fillOpacity: 1, strokeOpacity: '#000000'};
                // available.selected = {normal:{none:{}}};
                // available.selected.normal.none = {fillStyle: selectedFill,fillOpacity: 1,strokeStyle: selectedFillOutline};

                let unavailable = layer.seat.unavailable;
                if (!unavailable.hover){
                    unavailable.hover = {}
                }
                unavailable.normal.tomove = {fillStyle: tomoveFill,fillOpacity: 1,strokeStyle: tomoveFill};
                unavailable.normal.lockedborderfilled = {fillStyle: lockedFillHover,fillOpacity: 1,strokeStyle: lockedFill};
                unavailable.normal.locked = {fillStyle: lockedFill,fillOpacity: 1,strokeStyle: lockedFillOutline};
                unavailable.hover.tomove = {cursor: "pointer"};
                unavailable.normal.selected = available.normal.selected;
                unavailable.normal.onhold = available.normal.onhold;

                //current
                unavailable.hover.singleselection = {strokeStyle: regularFillOutline, cursor: "pointer"};
                unavailable.hover.regularselection = {strokeStyle: regularFillOutline, cursor: "pointer"};
                unavailable.hover.restrictedselection = {strokeStyle: restrictedFillOutline, cursor: "pointer"};
                unavailable.hover.vipselection = {strokeStyle: vipFillOutline, cursor: "pointer"};
                unavailable.hover.disabledselection = {strokeStyle: disabledFillOutline, cursor: "pointer"};

                //testing
                //default linewidth: 0.075
/*                 let linewidth  = 0.200;

                unavailable.hover.singleselection = {fillStyle: regularFillHover,strokeStyle: regularFillHoverOutline, cursor: "pointer"};
                unavailable.hover.regularselection = {fillStyle: regularFillHover, strokeStyle: regularFillHoverOutline, cursor: "pointer"};
                unavailable.hover.restrictedselection = {fillStyle: restrictedFillHover, strokeStyle: restrictedFillHoverOutline, cursor: "pointer"};
                unavailable.hover.vipselection = {fillStyle: vipFillHover,strokeStyle: vipFillHoverOutline, cursor: "pointer"};
                unavailable.hover.disabledselection = {fillStyle: disabledFillHover,strokeStyle: disabledFillHoverOutline, cursor: "pointer"};
 */

                if(!available.hover){
                    available.hover = {
                        regular:{},
                        vip:{},
                        disabled:{},
                        locked:{},
                    };
                }
                available.hover.regular = {fillStyle: regularFillHover,fillOpacity: 1,strokeStyle: regularFillHoverOutline};
                available.hover.vip = {fillStyle: vipFillHover,fillOpacity: 1,strokeStyle: vipFillHoverOutline};
                available.hover.disabled = {fillStyle: disabledFillHover,fillOpacity: 1,strokeStyle: disabledFillHoverOutline};
                available.hover.restricted = {fillStyle: restrictedFillHover,fillOpacity: 1,strokeStyle: restrictedFillHoverOutline};
                available.hover.locked = {fillStyle: lockedFillHover,fillOpacity: 1,strokeStyle: lockedFillHoverOutline};
                available.hover.allocation = {fillStyle: allocationFillHover,fillOpacity: 1,strokeStyle: allocationFillHoverOutline};
                available.hover.grouped = { strokeStyle: "yellow"};
                available.hover.selected = {fillStyle: selectedFillHover,fillOpacity: 1,strokeStyle: selectedFillHoverOutline};
                available.hover.tomove = {fillStyle: tomoveFill,fillOpacity: 1,strokeStyle: tomoveFill};
                available.hover.singleselection = {fillStyle: unavailable.normal.none.fillStyle};

                available.selected = {
                        hover:{
                            none:{fillStyle: selectedFillHover,fillOpacity: 1,strokeStyle: selectedFillHoverOutline,lineWidth:0.15}
                        }
                    };
                
            }

            if(layer.section){
                let available = layer.section.available;
                let default_opacity = available.normal.none.opacity;
                available.normal.none.opacity = 1;
                available.normal.regular = {fillStyle: regularFill,fillOpacity: 1,strokeStyle: regularFillOutline,lineWidth:1};
                available.normal.vip = {fillStyle: vipFill,fillOpacity: 1,strokeStyle: vipFillOutline,lineWidth:1};
                available.normal.disabled = {fillStyle: disabledFill,fillOpacity: 1,strokeStyle: disabledFillOutline,lineWidth:1};
                available.normal.restricted = {fillStyle: restrictedFill,fillOpacity: 1,strokeStyle: restrictedFillOutline,lineWidth:1};
                available.normal.locked = {fillStyle: lockedFill,fillOpacity: 1,strokeStyle: lockedFillOutline,lineWidth:1};
                available.normal.allocation = {fillStyle: allocationFill,fillOpacity: 1,strokeStyle: allocationFillOutline,lineWidth:1};
                available.normal.grouped = {strokeStyle: "yellow",lineWidth:0.4};
                available.normal.selected = {fillStyle: selectedFill,fillOpacity: 1,strokeStyle: selectedFillOutline,lineWidth:1};

                if(!available.hover){
                    available.hover = {
                        regular:{},
                        vip:{},
                        disabled:{},
                        locked:{},
                        restricted:{},
                    };
                }
                available.hover.regular = {fillStyle: regularFillHover,fillOpacity: 1,strokeStyle: regularFillHoverOutline,lineWidth:1};
                available.hover.vip = {fillStyle: vipFillHover,fillOpacity: 1,strokeStyle: vipFillHoverOutline,lineWidth:1};
                available.hover.disabled = {fillStyle: disabledFillHover,fillOpacity: 1,strokeStyle: disabledFillHoverOutline,lineWidth:1};
                available.hover.locked = {fillStyle: lockedFillHover,fillOpacity: 1,strokeStyle: lockedFillHoverOutline,lineWidth:1};
                available.hover.locked = {fillStyle: allocationFillHover,fillOpacity: 1,strokeStyle: allocationFillHoverOutline,lineWidth:1};
                available.hover.restricted = {fillStyle: restrictedFillHover,fillOpacity: 1,strokeStyle: restrictedFillHoverOutline,lineWidth:1};
                available.hover.grouped = { strokeStyle: "yellow",lineWidth:0.4};
                available.hover.selected = {fillStyle: selectedFillHover,fillOpacity: 1,strokeStyle: selectedFillHoverOutline,lineWidth:1};

                let unavailable = layer.section.unavailable;
                let unavailable_color = "#8A8D8F";
                let unavailable_border = "#8A8D8F";


                unavailable.normal.none.fillStyle= unavailable_color;
                unavailable.normal.none.fillOpacity= 0.5;
                unavailable.normal.none.opacity= 1;
                unavailable.normal.none.strokeStyle= unavailable_border;
                unavailable.normal.none.lineWidth=1;
                unavailable.normal.none.textFillStyle= "#ffffff";
                unavailable.normal.selected = {fillStyle: selectedFillHover,fillOpacity: 1,strokeStyle: selectedFillHoverOutline,lineWidth:1};



                if(!unavailable.hover){
                    unavailable.hover ={ none: {} }
                }
                unavailable.hover.none.fillStyle= "#bbbbbb";
                unavailable.hover.none.fillOpacity= 0.5;
                unavailable.hover.none.opacity= 1;
                unavailable.hover.none.strokeStyle= "#8a8d8f";
                unavailable.hover.none.lineWidth=1;
                unavailable.hover.none.textFillStyle= "#ffffff";
                unavailable.hover.none.cursor = 'pointer';
                unavailable.hover.selected = {fillStyle: selectedFillHover,fillOpacity: 1,strokeStyle: selectedFillHoverOutline,lineWidth:1};

                if(i==1){
                    //section on seat layer
                    let disable = "#dddddd";

                    available.normal.regular = {fillStyle: disable,fillOpacity: 1,lineWidth:0};
                    available.normal.vip = {fillStyle: disable,fillOpacity: 1,lineWidth:0};
                    available.normal.disabled = {fillStyle: disable,fillOpacity: 1,lineWidth:0};
                    available.normal.locked = {fillStyle: disable,fillOpacity: 1,lineWidth:0};
                    available.normal.restricted = {fillStyle: disable,fillOpacity: 1,lineWidth:0};
                    //available.normal.grouped = {strokeStyle: "yellow"};
                    //available.normal.selected = {fillStyle: disabled,fillOpacity: 1};

                    if(!available.hover){
                        available.hover = {
                            regular:{},
                            vip:{},
                            disabled:{},
                            locked:{},
                            restricted:{}
                        };
                    }
                    available.hover.regular = {fillStyle: disable,fillOpacity: 1,lineWidth:0};
                    available.hover.vip = {fillStyle: disable,fillOpacity: 1,lineWidth:0};
                    available.hover.disabled = {fillStyle: disable,fillOpacity: 1,lineWidth:0};
                    available.hover.locked = {fillStyle: disable,fillOpacity: 1,lineWidth:0};
                    available.hover.restricted = {fillStyle: disable,fillOpacity: 1,lineWidth:0};
                    //available.hover.grouped = { strokeStyle: "yellow"};
                    //available.normal.selected = {fillStyle: selectedFill,fillOpacity: 1};

                    let unavailable = layer.section.unavailable;
                    unavailable.normal.none.opacity = 0.1;
                    unavailable.normal.none.text = false;
                    unavailable.hover.none =  unavailable.normal.none;
  
                    available.normal.none.opacity = default_opacity;
                }
            }
        }
        viewer.setStyles(styles);
    }

    private getNodeId(obj) {
        return (obj.nodes.length > 0) ? obj.nodes[0].id : null;
    }

    public getLogicRowSeats(node){
        let seats = [];
        let [section, row, seat] = node.id.split("_")[1].split("-");
        let node_seats = this.viewer.getNodesByParent("S_"+section);
        for(let i =0;i<node_seats.length;i++){
            let compare_row = node_seats[i].id.split("_")[1].split("-")[1];
            if(compare_row===row){
                seats.push(node_seats[i].id);
            }
        }
        return seats;
    }

    public getVenueAvailability(config_tier_list: any[],printcategories?:boolean): Promise<any>{
        let promises = [];
        let venue_av = {
            capacity : 0,
            tiers: [],
            locked : 0
        }
        if(!config_tier_list.length){
            return new Promise((s,r)=>{
                venue_av.capacity = this.viewer.getNodesByType("seat").length;
                s(venue_av);
            });
        }

        config_tier_list.forEach(tier => {
            const viewerConfig = JSON.parse(JSON.stringify(this.DVMConfig));
                console.log(this.viewer);
                viewerConfig.venue_id = this.viewer.getVenueId();
                viewerConfig.map_id = tier.map_id;
                viewerConfig.container = 'dummy-viewer-container';
            let p = new Promise((success, reject) => {
                DVM.loadModule('map_viewer', viewerConfig).then(
                dummy_viewer => {
                    dummy_viewer.loadMap(viewerConfig).then(
                        response => {
                            let c = dummy_viewer.getNodesByType("seat").length;
                            tier.capacity = c;
                            venue_av.tiers.push(tier);
                            venue_av.capacity+=c;
                            venue_av.locked +=dummy_viewer.getNodesByGroups('seat', 'locked').length;
                            dummy_viewer.reset();
                            dummy_viewer.close();
                            success(null);
                        }).catch((err) => {
                            console.error(err);
                            reject(err);
                          });
                }).catch((err) => {
                    console.error(err);
                    reject(err);
                  });
            });
            promises.push(p);
        });


        return Promise.all(promises).then(result=>{
            return venue_av;
        });
    }
}
