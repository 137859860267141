<div>
    <div class='modal-header'>
        <h3>{{"confirmDeleteSimulationModal.title" | translate}}</h3>
        <button type="button" class="btn-modal-header" (click)="onClose('cancel')" data-dismiss="modal">&times;</button>
    </div>
    
    <div class='modal-content'>
        <p class='mb-3'>
            {{"confirmDeleteSimulationModal.body1" | translate}} <b style='font-size: 14px'>{{simulation.name}}</b>
        </p>
        <p class='m-0'>
            
            {{"confirmDeleteSimulationModal.body" | translate}}
        </p>
    </div>
    <div class='modal-footer'>
        <button id='cancelsimulation-btn' class='btn' type="button" (click)="onClose('cancel')">Cancel</button>
        <button (click)="onConfirm()" class='btn btn-danger d-inline-block'>{{"confirmDeleteSimulationModal.buttonConfirm" | translate}}</button>
    </div>    
</div>