import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CustomCategoriesService, CustomCategory} from '../../services/custom-categories.service';
import {Observable, Subject} from 'rxjs';

@Component({
  selector: 'app-custom-categories',
  templateUrl: './custom-categories.component.html',
  styleUrls: ['./custom-categories.component.scss']
})
export class CustomCategoriesComponent implements OnInit, OnDestroy {

  @Input() public interactive = true;
  @Input() public hoverString = 'setup.sidebar.assign';
  private categoryClickSubject$ = new Subject<CustomCategory>();

  public get categoryClickObservable$(): Observable<CustomCategory> {
    return this.categoryClickSubject$.asObservable();
  }

  constructor(public categories: CustomCategoriesService) {}

  ngOnInit(): void {
  }

  getCategories() {
    return Array.from(this.categories.customCategories.values());
  }

  triggerCategoryClick(category: CustomCategory): void {
    this.categoryClickSubject$.next(category);
  }

  ngOnDestroy(): void {
    this.categoryClickSubject$.next();
    this.categoryClickSubject$.complete();
  }
}
