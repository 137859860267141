import { Injectable } from "@angular/core";
import { AsyncSubject, config, Observable, Subject } from "rxjs";
import { SCVDataService } from "../seatingchartviewer/services/data.service";
import { DataService } from "../social-distance-editor/services/data.service";
import { Venue } from "../social-distance-editor/shared/models/venue-data.model";
import { User } from "../social-distance-editor/shared/models/user-data.model";
import { NavigationEnd, Router } from "@angular/router";

export interface Platform {
  auth: User | null;
  venues: {
    all: Venue[];
    selectList: Venue[];
    default: Venue | null;
  };
  configuration: Configuration;
  route: any;
}
interface Configuration {
  private: boolean;
  workspace: boolean; // Is v2 : workspace + scv + future new plugins
  onlySDM: boolean; // Flag allow only sdm deprecated version
}
@Injectable({ providedIn: "root" })
export class PlatformService {
  private invokeClientVenue: Subject<any> = new Subject();
  private invokeConfiguration: Subject<any> = new Subject();
  private asyncConfiguration$: AsyncSubject<any> = new AsyncSubject();

  private platform: Platform = {
    auth: null,
    venues: {
      all: [],
      selectList: [],
      default: null,
    },
    configuration: {
      private: false,
      workspace: false, // Is v2 : workspace + scv + future new plugins
      onlySDM: false, // Flag allow only sdm deprecated version
    },
    route: {},
  };

  constructor(private SCVDataService: SCVDataService, private GeneralDataService: DataService, private router: Router) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.platform.route = val;
      }
    });
  }
  // Setting platform config
  public getPlatform$(): Observable<any> {
    return this.invokeConfiguration.asObservable();
  }
  public getPlatform(): Platform {
    return this.platform;
  }

  public nextPlatform(platform: Platform): void {
    this.invokeConfiguration.next(platform);
  }
  public setAsyncPlatform(platform: Platform): void {
    this.asyncConfiguration$.next(platform);
    this.asyncConfiguration$.complete();
  }
  public getAsyncPlatform$(): Observable<any> {
    return this.asyncConfiguration$.asObservable();
  }
  public setAuth(auth: User) {
    this.platform.auth = auth;
    this.nextPlatform(this.platform);
  }

  public refreshPlatform() {
    this.nextPlatform(this.platform);
  }
  // Setting config
  public setConfiguration(configuration: Configuration) {
    this.platform.configuration = configuration;
    this.nextPlatform(this.platform);
  }
  public setConfigurationPrivate(status: boolean) {
    this.platform.configuration.private = status;
    this.nextPlatform(this.platform);
  }
  public setConfigurationWorkspace(status: boolean) {
    this.platform.configuration.workspace = status;
    if (status) {
      this.platform.configuration.onlySDM = false;
    }
    this.nextPlatform(this.platform);
  }
  public setConfigurationOnlySDM(status: boolean) {
    this.platform.configuration.onlySDM = status;
    if (status) {
      this.platform.configuration.workspace = false;
    }
    this.nextPlatform(this.platform);
  }

  // Setting venue management
  public getClientVenues$(): Observable<any> {
    return this.invokeClientVenue.asObservable();
  }

  public nextClientVenue(clientVenues: Venue[]): void {
    this.nextPlatform(this.platform);
    this.invokeClientVenue.next(clientVenues);
  }

  public removeClientVenues() {
    this.platform.venues.all = [];
    this.platform.venues.selectList = [];
    this.platform.venues.default = null;
    this.nextClientVenue(this.platform.venues.all);
  }
  public setClientVenues(clientVenues: Venue[], defaultVenue?: Venue) {
    if (clientVenues.length) {
      this.platform.venues.all = [];
      this.platform.venues.selectList = [];
      this.platform.venues.default = null;
      this.platform.venues.all = clientVenues;
      this.platform.venues.selectList = clientVenues;
      if (!this.platform.venues.default) {
        this.platform.venues.default = this.platform.venues.selectList[0];
      }
      if (defaultVenue) {
        this.platform.venues.default = defaultVenue;
      }
      this.nextClientVenue(clientVenues);
    }
    return this.platform.venues;
  }

  public setClientVenueFiltered(all: Venue[], filteredList: Venue[]) {
    this.platform.venues.all = all;
    this.platform.venues.selectList = [];
    this.platform.venues.default = null;
    if (filteredList.length) {
      this.platform.venues.selectList = filteredList;
      this.platform.venues.default = filteredList[0];
    }
    console.log(this.platform);
    this.nextClientVenue(filteredList);
  }

  public setDefaultVenue(venue: Venue) {
    this.platform.venues.default = venue;
    this.nextClientVenue(this.platform.venues.selectList);
  }
}
