<div>
    <div class='modal-header'>
        <button type="button" class="btn-modal-header" (click)="onDismiss()" data-dismiss="modal">&times;</button>
        <div class='row'>
            <div class='col-12'>
                <h3>{{'categoryPriceModal.title' | translate}} </h3>     
            </div>
            <div class='col-12'>
                <div id='venueinfo-data'>
                    <span class='topbardata-item'>{{'setup.topbar.location' | translate}}: <b>{{venueData?.city}}, {{venueData?.country}}</b></span>
                    <span class='topbardata-item'>{{'setup.topbar.currency' | translate}}: <b>{{clientData?.client.currency | uppercase}}</b></span>
                    <!-- <span class='topbardata-item'>{{'setup.topbar.currency' | translate}}: <b>{{currencySymbol | uppercase}}</b></span> -->
                    <span class='topbardata-item'>{{'setup.topbar.units' | translate}}: <b>{{units | titlecase}}</b></span>
                </div>
            </div>
        </div>
    </div>
    
    <div class='modal-content'>
        <table class='default'>
            <thead>
                <tr *ngIf="isSimulation">
                    <th id='empty-header'></th>
                    <th colspan='4' class='first-th header-th text-center'>{{simulation?.configuration.name}}</th>
                    <th colspan='2' class='last-th text-center'>{{simulation?.name}}</th>
                </tr>
                <tr>
                    <th>{{'categoryPriceModal.category' | translate}}</th>
                    <th  [ngClass]="{'simulation-subheader-first': isSimulation}" class='text-right'>{{'categoryPriceModal.nSections' | translate}}</th>
                    <th class='text-right'>{{'categoryPriceModal.price' | translate}}</th>
                    <th class='text-right'>{{'categoryPriceModal.capacity' | translate}}</th>
                    <th [ngClass]="{'simulation-subheader-last': isSimulation}" class='text-right'>{{'categoryPriceModal.totalRevenue' | translate}}</th>
                    <th *ngIf="isSimulation" class='text-right'>{{'categoryPriceModal.newcapacity' | translate}}</th>
                    <th *ngIf="isSimulation" class='text-right'>{{'categoryPriceModal.newrevenue' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let c of categories">
                    <td> <span class='legend-ico {{c?.group}}'></span>{{c?.name | titlecase}}</td>
                    <td [ngClass]="{'simulation-subheader-first': isSimulation}" class='text-right'>{{c?.nsections_string}}</td>
                    <td class='text-right'>{{c?.unit_price}}</td>
                    <td class='text-right'>{{c?.nseats_string}}</td>
                    <td [ngClass]="{'simulation-subheader-last': isSimulation}" class='text-right'>{{c?.revenue_total_string}}</td>
                    <td *ngIf="isSimulation" class='color-blue text-right'>{{c?.nseats_available_string}}</td>
                    <td *ngIf="isSimulation" class='color-blue text-right'>{{c?.revenue_total_available_string}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td><b>TOTAL</b></td>
                    <td class='text-right'><span class='font-bold'>{{totalSectionsString}}</span></td>
                    <td class='text-right'><span class='color-blue font-bold'></span></td>
                    <td class='text-right'><span class='font-bold'>{{totalSeatsString}}</span></td>
                    <td class='text-right'><span class='font-bold'>{{totalRevenueString}}</span></td>
                    <td *ngIf="isSimulation" class='text-right'><span class='color-blue font-bold'>{{totalSeatsAvailableString}}</span></td>
                    <td *ngIf="isSimulation" class='text-right'><span class='color-blue font-bold'>{{totalRevenueAvailableString}}</span></td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>