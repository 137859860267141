import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/social-distance-editor/services/data.service';
import { Form } from '@angular/forms';
import { SocialdistancingService } from 'src/app/social-distance-editor/services/socialdistancing.service';
import { DVMService } from 'src/app/social-distance-editor/services/dvm.service';
import { FormatNumberService } from 'src/app/social-distance-editor/services/format-number.service';
import { SelectionService } from 'src/app/social-distance-editor/services/selection.service';
import { Subscription } from 'rxjs';
import { AllocatorService } from 'src/app/social-distance-editor/plugins/allocator/services/allocator.service';
import { TiersService } from 'src/app/social-distance-editor/services/tiers.service';
import { take } from 'rxjs/operators';
import {PlatformLocation} from "@angular/common";


@Component({
  selector: 'app-edit-simulation-modal',
  templateUrl: './edit-simulation-modal.component.html',
  styleUrls: ['./edit-simulation-modal.component.scss']
})
export class EditSimulationModalComponent implements OnInit {

  private subscriptions: Subscription[] = [];

  simulationParameters: Array<any>;
  paginatedSimulationParameters = [];
  simulation;

  subsimulation;
  default_subsim_flag = false;
  sections_selected_value = "";
  subsimcapacity = 0;
  simulationcapacity;
  simAvailabilityPercentage;
  simConfigAvailabilityPercentage;

  inputHash: any;
  computedTotalsStringsHash = {};
  socialDistancing;
  totalPercentage: number;
  currentAvailability;
  aisleSeats = 0;
  nlocked;
  isViewInitialized = false;
  simulationName;
  nameInEdit;
  limit2groups;
  fallback = false;

  isSectionSocialDistancing = false;

  hiddenReason: string;
  loadingmap;
  socialDistancingConfig;
  get availability() {
    return this.currentAvailability;
  }

  get venueName() {
    if (this.simulation) {
      return this.dataService.venueData[this.simulation.configuration.venueId].name;
    }
  }

  constructor(private bsModalRef: BsModalRef,
              private modalService: BsModalService,
              private dataService: DataService,
              private dvmService: DVMService,
              private socialDistancingService: SocialdistancingService,
              private numberFormatService: FormatNumberService,
              private selection: SelectionService,
              private cdr: ChangeDetectorRef,
              private allocatorService: AllocatorService,
              private tiersService: TiersService,
              private location: PlatformLocation) {
    this.location.onPopState(()=> {
      this.onClose();
    });
  }

  ngOnInit(): void {

    const s = this.getSimulation().add(res=>{
      s.unsubscribe();
    });
    

    this.isSectionSocialDistancing = this.socialDistancingService.isSectionSocialDistancing;
  }
  private getAvailabilityPercentage(){
    //let av = this.dvmService.viewer.getNodesByState('seat', 'available').length;
    this.nlocked = this.dvmService.getLockedseats().length;
    const totalSeats = this.simulation.configuration.capacity;

    this.tiersService.getTotalTiersAV().pipe(
      take(1)
    ).subscribe(res=>{
      this.nlocked = res.locked;
      this.simConfigAvailabilityPercentage = (100 * this.simulationcapacity / (totalSeats - this.nlocked)).toFixed(2);
    })
    //this.simAvailabilityPercentage = (100 * this.simulation.capacity / this.simulation.configuration.capacity).toFixed(2);
    
    this.refreshsimulationcapacity();
    this.simAvailabilityPercentage = (100 * this.simulationcapacity / totalSeats).toFixed(2);
    this.paginateSimulationParameters()
    //this.customAvailabilityPercentage = (100 * av / this.simulation.configuration.capacity).toFixed(2);

  }
  private paginateSimulationParameters() {
    
    const sp = JSON.parse(JSON.stringify(this.simulationParameters));
    sp.sort((a, b) => (a.nseats > b.nseats) ? 1 : -1);
    const half = Math.ceil(sp.length / 2);

    this.paginatedSimulationParameters[0] = sp.splice(0, half);
    this.paginatedSimulationParameters[1] = sp;

  }
  private refreshsimulationcapacity(){
    if(this.simulation.custom_groups && this.simulation.custom_groups.capacity){
      this.simulationcapacity = this.simulation.custom_groups.capacity;
    }else{
      this.simulationcapacity = this.simulation.capacity;
    }
  }

  private getSimulation() {
    return this.dataService.getSimulationParameters(this.dataService.currentSimulationId).subscribe(
      simulation => {
        this.dataService.simulationData = simulation;
        this.simulation = simulation;
        this.refreshsimulationcapacity();
        this.formularyData(simulation);
        this.getAvailabilityPercentage();
      }
    );
  }

  private formularyData(simulation){
    this.limit2groups = simulation.limit2groups;
    this.simulationName = simulation.name;
    this.socialDistancing = parseFloat(simulation.social_distancing);


    if (simulation.isle_seats) {
      this.aisleSeats = simulation.isle_seats;
    }
    if(simulation.fallback){
      this.fallback = true;
    }

    
    for (const key in this.inputHash) {
      if (this.inputHash.hasOwnProperty(key)) {
        this.computedTotalsStringsHash[key] = this.inputHash[key]['nseats_per'];
      }
    }
    this.totalPercentage = 0;
    let sum = 0;
    if (!simulation.parameters) {
      this.inputHash[3] = {percentage : 100, computed_per: 0, nseats_per: 0};
    } else {
      simulation.parameters.forEach(param => {
        let p = parseFloat(param.percentage);
        sum += p;
      });
    }
    for (const p of simulation.parameters) {
      if (!this.inputHash[p.id]) {
        this.inputHash[p.parameter.id] = {percentage: 0, computed_per: 0, nseats_per: 0};
      }
      if (p.parameter.id === 3 && !sum) {
        this.inputHash[3].percentage = 100;
      } else {
        this.inputHash[p.parameter.id]['percentage'] = parseInt((p.percentage * 100).toFixed(0));
      }
      if (typeof p.computed_nseats === 'number') {
        this.inputHash[p.parameter.id]['computed_nseats'] = p.computed_nseats;
      }
      this.inputHash[p.parameter.id]['computed_per'] = p.computed_percentage * 100;
      this.inputHash[p.parameter.id]['nseats_per'] = p.nseats_percentage * 100;
      // if (this.inputHash[p.parameter.id]['computed_per']) {
      //   this.computedTotalsStringsHash[p.parameter.id] = this.inputHash[p.parameter.id]['computed_per'].toFixed(2);
      // }
      if (this.inputHash[p.parameter.id]['nseats_per']) {
        this.computedTotalsStringsHash[p.parameter.id] = this.inputHash[p.parameter.id]['nseats_per'].toFixed(2);
      }
      this.totalPercentage += this.inputHash[p.parameter.id].percentage;

    }

    //console.log(this.inputHash);

  }


  public onClose(): void {
    this.socialDistancingService.isSectionSocialDistancing = false;
    this.hiddenReason = 'close';
    this.bsModalRef.hide();
  }

  private applySocialDistancing(){
    this.loadingmap = document.getElementById('loadingmap');
    this.loadingmap.classList.remove('hidden');
    const parameters = {};
    this.simulation.parameters.forEach(element => {
      parameters[element.parameter.nseats] = element.percentage;
    });
    this.socialDistancingConfig = {
      securityDistance: this.socialDistancing,
      isleSeats: this.aisleSeats,
      parameters,
      limit2groups : this.limit2groups,
      fallback : this.simulation.fallback,
      aisle_seats_sides:{
        start : true,
        end : true,
      }
    };
  }


  public onSubmit(ngForm: Form): void {
    if (!this.isDisabled()) {
      this.loadingmap = document.getElementById('loadingmap');
      this.loadingmap.classList.remove('hidden');
      this.hiddenReason = 'closetoresult';
      this.bsModalRef.hide();
      
      let body = {
        name: this.simulationName,
        social_distancing: this.socialDistancing,
        isle_seats: this.aisleSeats,
        limit2groups : this.limit2groups,
        fallback : 0,
        custom_groups : {},
        allocation : 0
      };

      let params_options = [];
      let max_param_id  = 0;
      Object.entries(this.inputHash).forEach(([key, value]) => {
        let percentage = 0;
        let param_id = parseInt(key, 10);
        if (value['percentage']) {
          percentage = value['percentage'] as number / 100;
        }
        if(percentage && param_id>max_param_id){
          max_param_id = param_id;
        }
        params_options.push(
          { param_id: param_id, percentage, computed_percentage: 0 }
        );
      });
      if(this.fallback){
        for(let i=0; i<this.simulationParameters.length; i++){

          if(this.simulationParameters[i].id === max_param_id){
            body.fallback = this.simulationParameters[i].nseats;
          }
        }
      }
      //console.log(body);
      this.subscriptions.push(this.dataService.editSimulation(this.simulation.id, body).subscribe(
        success => {
          console.log('Edit simulation success: ', body);
          if(this.simulation.allocation){
            this.allocatorService.clearAllocationFromSimulation();
          }
          if(success.result.subsimulations.length>0){
            for(let i =0;i<success.result.subsimulations.length;i++){
              let subsim_id = success.result.subsimulations[i].id;
              this.dataService.deleteSubsimulation(subsim_id).subscribe();
            }
          }

        },
        error => { console.log(error); }
      ).add(
        success => {
            const newSimulationParameters = {
              simulation: this.simulation.id,
              options: params_options
            };
            this.subscriptions.push(this.dataService.createSimulationParameters(newSimulationParameters).subscribe(
              success => {
                this.getSimulation().add(
                  response => {
                    this.applySocialDistancing();
                  }
                );
              }
            ));
        }
      ));
    }

  }


  get units() {
    if (this.dataService.getUser()) {
      return this.dataService.getUnits(this.dataService.getUser().user.client.metric_system);
    }
  }

  public onSetDistancing(action: 'minus' | 'sum'): void {
    if (this.numberFormatService.getUnits() === 'ft') {
      if (action === 'minus' && this.socialDistancing > 0.5) {
        this.socialDistancing -= 0.0305;
      }
      if (action === 'sum') {
        this.socialDistancing += 0.0305;
      }
    } else {
      if (action === 'minus' && this.socialDistancing > 0.5) {
        this.socialDistancing -= 0.10;
      }
      if (action === 'sum') {
        this.socialDistancing += 0.10;
      }
    }
  }

  public onChangeAisleSeat(action: 'minus' | 'sum') {
    if (action === 'minus' && this.aisleSeats > 0) {
      this.aisleSeats -= 1;
    }
    if (action === 'sum' && this.aisleSeats < 4) {
      this.aisleSeats += 1;
    }
  }

  public onChangeNumber(event: Event, max, id, isAisle?) {
    this.clampInput(event.target as HTMLInputElement, 0, max, id, isAisle);
    this.checkTotalPercentage();
  }

  private clampInput(input: HTMLInputElement, min = 0, max = 100, id?, isAisle?) {
    
    if (parseInt(input.value, 10)) {
      const inputValue = parseInt(input.value, 10);
      const clampped = this.clamp(inputValue, min, max).toString();
      input.value = clampped;
      if (id) {
        this.inputHash[id].percentage = parseInt(this.inputHash[id].percentage);
        this.inputHash[id].percentage = parseInt(clampped, 10);
        
      }
      if (isAisle) {
        this.aisleSeats = parseInt(clampped, 10);
      }
    }else{
      input.value = '0';
    }
  }

  private clamp(input: number, min: number, max: number): number {
    return Math.min(Math.max(min, input), max);
  }

  public checkTotalPercentage(): void {
    this.totalPercentage = 0;
    this.simulationParameters.forEach(
      p => {
        let value = parseInt((document.getElementById(p.name) as HTMLInputElement).value, 10);
        if (typeof value == 'undefined' || !value) {
          value = 0;
        }
        this.totalPercentage += value;
      }
    );
  }

  public formatSocialDistancing(num: number): number {
    if (num) {
      return Number(this.numberFormatService.formatSocialDistancing(num));
    } else {
      return 0;
    }
  }

  public isDisabled(): boolean {
    if (this.totalPercentage !== 100) {
      return true;
    } else {
      return false;
    }
  }
  public nameIsDisabled(): boolean {
    if(this.simulationName){
      return false;
    }
    return true;
  }
  public updateSimulationName(){
    if (!this.nameIsDisabled()){
      const body = { name: this.simulationName };
      this.subscriptions.push(this.dataService.editSimulation(this.simulation.id, body).subscribe(
        success => {
          this.simulation = success.result;
          this.dataService.setCurrentSimulation$(success.result);
          this.disableEditName();
        },
        error => { console.log(error); }
        ));
    }
  }
  public formatNumber(price: number): string {
    return this.numberFormatService.formatNumber(price);
  }

  public enableEditName(){
    this.nameInEdit = true;
    return this.nameInEdit;
  }
  public disableEditName(){
    this.nameInEdit = false;
    this.simulationName = this.simulation.name;
    return this.nameInEdit;
  }

  public checkLimit2groups(){
    this.limit2groups = !this.limit2groups;
  }
  public checkFallback(){
    this.fallback = !this.fallback;
  }

  getSectionNodes() {
    const selection = this.dvmService.viewer.getNodesByGroups('section', 'selected');
    let result = [];
    selection.forEach(section => {
      result = result.concat(this.dvmService.viewer.getNodesByParent(section.id));
    });
    // if (sectionId && typeof sectionId === 'string') {
    //   return this.dvmService.viewer.getNodesByParent(sectionId);
    // }
    return result;
  }
  getSectionsAffected(){
    return this.dvmService.viewer.getNodesByGroups('section', 'selected');
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
