
<div id='newsimulation-modal' class='modal-wrap'>
    <div class='modal-header'>
        <h3>{{'copySimulationModal.title' | translate}}</h3>
        <button type="button" class="btn-modal-header" (click)="onClose('cancel')" data-dismiss="modal">
            <span>&times;</span>
        </button>
    </div>
    <div class='modal-content'>
        <form #formSimulation="ngForm" (ngSubmit)="onSubmit(formSimulation)" (keydown.enter)="$event.preventDefault()">
            <div class='step'>
                <div class='row'>

                    <div class='col-6 p-0'>
                        <span class='data-content'>{{'editSimulationModal.venue' | translate}}: <b>{{currentVenue?.name}}</b></span>
                        <span class='data-content'>{{'editSimulationModal.configuration' | translate}}: <b>{{originalSimulation?.configuration?.name}}</b></span>
                        <span class='data-content'>{{'editSimulationModal.simulation' | translate}}: 
                            <input class='text-input color-blue' name="copySimulationName" [(ngModel)]="copySimulationName" value='{{originalSimulation?.name}}'>
                        </span>
                    </div>
                    <div class='col-6 p-0'>
                        <span class='data-content'>{{'editSimulationModal.venuecapacity' | translate}}: <b>{{formatNumber(currentVenue?.capacity)}}</b></span>
                        <span class='data-content'>{{'editSimulationModal.configseats' | translate}}: <b style='margin-right:10px;'>{{formatNumber(configAvailability)}}</b> <small>({{originalCategories.locked.total}} locked seats)</small></span>
                        <span class='data-content'>
                            <div *ngIf="customCapacity">
                                {{'editSimulationModal.simresult' | translate}}:
                                <b class='color-blue'>{{formatNumber(originalSimulation?.capacity)}}</b>
                                <small class='color-blue'> ({{customCapacityPercent}}%)</small>
                            </div>
                            <div *ngIf="!customCapacity">
                                {{'editSimulationModal.simresult' | translate}}:
                                <b class='color-blue'>{{formatNumber(originalSimulation?.capacity)}}</b>
                                <small class='color-blue'> ({{simPercentAv}}%)</small>
                            </div>  
                        </span>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-auto p-0'>
                        <label><b>1. {{'editSimulationModal.chooseSocialDistancing' | translate}}</b></label>
                    </div>
                    <div class='col-auto p-0'>
                        <div *ngIf="multiSDMValues?.sd_values.length>1 else singlesdvalue" class='asrow align-items-center h100'>
                            <div class='col'>
                                <span class='f12'>There are different values between the section parameters and the rest of the venue.<br> All will be copied.</span>
                            </div>
                        </div>
                        
                        
                        <ng-template #singlesdvalue>
                            <div class='sd-btns text-center'>
                                <span class='sd-value d-inline-block'> {{currentSDMValue}}</span>
                                <span class='sd-unit d-inline-block'> {{units}} </span>
                            </div>
                        </ng-template>
                        
                    </div>
                    <div class='col p-0'>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-auto p-0'>
                        <label><b>2. {{'editSimulationModal.aisle_note' | translate}}</b></label>
                    </div>
                    <div class='col-auto p-0 opt-col'>
                        <div *ngIf="multiSDMValues?.aisle_seats.length>1 else singleaislevalue" class='asrow align-items-center h100'>
                            <div class='col'>
                                <span class='f12'>There are different values between the section parameters and the rest of the venue.<br> All will be copied.</span>
                            </div>
                        </div>
                        
                        
                        <ng-template #singleaislevalue>
                            <div class='sd-btns text-left'>
                                <span class='sd-value d-inline-block'>{{originalSimulation?.isle_seats}}</span>
                                <span class='sd-unit d-inline-block'>{{'editSimulationModal.seats' | translate}} </span>
                            </div>
                        </ng-template>
                    </div>
                    <div class='col p-0'></div>
                </div>
                <div class='row' *ngIf='!customGroups'>
                    <div class='col-12 p-0'>
                        <label><b>3. {{'copySimulationModal.editGroups' | translate}}</b></label>
                    </div>
                    <!-- HALF 1 -->
                    <div class='group-options col pl-0'>
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th class='text-center'>{{'copySimulationModal.desired' | translate}}</th>
                                    <th class='text-right'>{{'copySimulationModal.calculated' | translate}}</th>
                                    <th class='text-right'>{{'copySimulationModal.availableseats' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let option of paginatedSimulationParameters[0]; let i = index">
                                    <td><span class='opt-lab'>{{'editSimulationModal.optionparam.'+option.parameter.name | translate}}</span></td>
                                    <td class='text-center'>
                                        {{option.percentage}}
                                    </td>
                                    <td class='text-center'>{{option.computed_percentage}}</td>
                                    <td class='text-right'>
                                        <span class='color-blue' *ngIf="option.computed_nseats else nocomputed_nseats">
                                            <b>{{formatNumber(option.computed_nseats)}}  </b>
                                        </span>
                                        <ng-template #nocomputed_nseats>
                                            <span class='color-blue'><b>0</b></span>
                                        </ng-template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- HALF 2 -->
                    <div *ngIf="paginatedSimulationParameters[1] && paginatedSimulationParameters[1].length" class='group-options col pr-0'>
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th class='text-center'>{{'computeResultModal.desired' | translate}}</th>
                                    <th class='text-center'>{{'computeResultModal.computed_percentage' | translate}}</th>
                                    <th class='text-right'>{{'computeResultModal.availableseats' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let option of paginatedSimulationParameters[1]; let i = index">
                                    <td><span class='opt-lab'>{{'editSimulationModal.optionparam.'+option.parameter.name | translate}}</span></td>
                                    <td class='text-center'>
                                        {{option.percentage}}
                                    </td>
                                    <td class='text-center'>{{option.computed_percentage}}</td>
                                    <td class='text-right'>
                                        <span class='color-blue' *ngIf="option.computed_nseats else nocomputed_nseats">
                                            <b>{{formatNumber(option.computed_nseats)}}  </b>
                                        </span>
                                        <ng-template #nocomputed_nseats>
                                            <span class='color-blue'><b>0</b></span>
                                        </ng-template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class='row' *ngIf='customGroups'>
                    <div class='col-12 p-0'>
                        <label><b>3. {{'copySimulationModal.editGroups' | translate}}</b></label>
                    </div>
                    <div class='parentgroupoption-col asrow p-0'>
                        <div class='group-options col-6 pl-0'>
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class='text-center'>{{'copySimulationModal.ngroups' | translate}}</th>
                                        <th class='text-right'>{{'copySimulationModal.availableseats' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor='let group of paginatedSimulationParameters[0]'>
                                        <td class='text-left'>
                                            <span class='opt-lab'>{{group.name}}</span></td>
                                        <td class='text-center'>
                                            <span>{{group.ngroups}}</span>
                                        </td>
                                        <td class='text-right'>
                                            <span class='color-blue'><b>{{group.available_seats}} </b> <small>({{group.percentage}}%)</small></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- HALF 2 -->
                        <div class='group-options col-6 pr-0' *ngIf="paginatedSimulationParameters[1] && paginatedSimulationParameters[1].length">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class='text-center'>{{'copySimulationModal.ngroups' | translate}}</th>
                                        <th class='text-right'>{{'copySimulationModal.availableseats' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor='let group of paginatedSimulationParameters[1]'>
                                        <td class='text-left'>
                                            <span class='opt-lab'>{{group.name}}</span></td>
                                        <td class='text-center'>
                                            <span>{{group.ngroups}}</span>
                                        </td>
                                        <td class='text-right'>
                                            <span class='color-blue'><b>{{group.available_seats}} </b> <small>({{group.percentage}}%)</small></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class='row' *ngIf='originalSimulation?.limit2groups'>
                    <div class='col p-0'>
                        <b>{{'computeResultModal.confirmlimit2groups' | translate}}</b>
                    </div>
                </div>
            </div>
            <div class='modal-footer'>
                <button id='cancelsimulation-btn' class='btn' type="button" (click)="onClose('cancel')">{{'editSimulationModal.buttonCancel' | translate}}</button>
                <button id='newsimulation-btn' class='btn' type="submit" [disabled]="isDisabled()">{{'copySimulationModal.buttonConfirm' | translate}}</button>
            </div>
        </form>
    </div>
</div>