import { Component, OnInit } from '@angular/core';
import { ModalOptions, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PdfsubscriberComponent } from '../../social-distance-editor/shared/modals/pdfsubscriber/pdfsubscriber.component';
import { LoginModalComponent } from '../../social-distance-editor/shared/modals/login-modal/login-modal.component';
//import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header-public',
  templateUrl: './header-public.component.html',
  styleUrls: ['./header-public.component.scss','../../header/header.component.scss']
})
export class HeaderPublicComponent implements OnInit {

  constructor(
    private modalService: BsModalService,
    //private activatedRoute: ActivatedRoute
  ) { }

  bsModalRef: BsModalRef;

  ngOnInit(): void {

  }

  public onOpenPdfSubscriberModal(): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered',
      backdrop: false,
      ignoreBackdropClick: false,
      initialState: {}
    };
    this.bsModalRef = this.modalService.show(PdfsubscriberComponent, modalConfig);
  }

  public onOpenLoginModal(): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered',
      backdrop: false,
      ignoreBackdropClick: false,
      initialState: {}
    };
    this.bsModalRef = this.modalService.show(LoginModalComponent, modalConfig);
  }
}
