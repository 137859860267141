import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/social-distance-editor/services/data.service';
import { FormatNumberService } from 'src/app/social-distance-editor/services/format-number.service';
import { DVMService } from 'src/app/social-distance-editor/services/dvm.service';
import { Router } from '@angular/router';
import {SocialdistancingService} from "../../../services/socialdistancing.service";
import {from} from "rxjs";
import { InventoryService } from 'src/app/social-distance-editor/services/inventory.service';
import { TiersService,Itotaltiers } from 'src/app/social-distance-editor/services/tiers.service';
import { take } from 'rxjs/operators';
import {PlatformLocation} from "@angular/common";

@Component({
  selector: 'app-compute-results-modal',
  templateUrl: './compute-results-modal.component.html',
  styleUrls: ['./compute-results-modal.component.scss']
})
export class ComputeResultsModalComponent implements OnInit {

  page;
  simulation;
  simAvailabilityPercentage;
  simAvailabilotyPercentageFromConfig;
  paginatedSimulationParameters = [];
  paginatedCustomParameters = [];
  nlocked: number;
  paramsHash = {};
  paramsList = [];
  totalAisleSeats = 10;
  customGroupsHashed;
  customGroups = false;
  customNGroupsKeys;
  totalCustomSeats;
  customAvailabilityPercentage;
  customConfigAvailabilityPercentage;
  customGroupsData = [];
  configuration;
  hiddenReason: string;

  get units() {
    if (this.dataService.getUser()) {
      return this.dataService.getUnits(this.dataService.getUser().user.client.metric_system);
    }
  }

  get availability() {
    return this.simAvailabilityPercentage;
  }

  get configAvailabilityPercent(){
    return this.simAvailabilotyPercentageFromConfig;
  }
  get venueName() {
    if (this.simulation) {
      return this.dataService.venueData[this.simulation.configuration.venueId].name;
    }
  }

  constructor(private bsModalRef: BsModalRef,
              private modalService: BsModalService,
              private dataService: DataService,
              private numberFormatService: FormatNumberService,
              private dvmService: DVMService,
              private router: Router,
              private socialdistancingService: SocialdistancingService,
              private inventoryService: InventoryService,
              private tiersService: TiersService,
              private location: PlatformLocation)  {
    this.location.onPopState(()=> {
      this.onDismiss();
    });
  }

  ngOnInit(): void {
      if (this.page === 'shared') {
        this.setAllParams(this.dataService.shareDataAllParams)
        this.getSimulationShared();
        
      } else {
        //console.log("Data Service on compute results modal component", this.dataService);
          this.setAllParams(this.dataService.listParams.params);
          this.getSimulation();
      }
  }
  

  paginateSimulationParameters() {
    
    const sp = JSON.parse(JSON.stringify(this.simulation.parameters));

    let automated_totalseats = 0;

    this.customGroups = false;
    for(let i=0;i<sp.length;i++){
      let nseats = sp[i].parameter.nseats;
      automated_totalseats+=sp[i].computed_nseats;
      if(this.customGroupsHashed && this.customGroupsHashed[nseats] && this.customGroupsHashed[nseats]!=sp[i].computed_nseats){
          this.customGroups=true;
      }
    }

    if(this.totalCustomSeats && this.totalCustomSeats!=automated_totalseats){
      this.customGroups = true;
    }
    //console.log(this.customNGroupsKeys);
    //console.log(this.customGroupsHashed);
    sp.sort((a, b) => (a.parameter.nseats > b.parameter.nseats) ? 1 : -1);
    if (sp.length > 4) {
      const half = Math.ceil(sp.length / 2);
      this.paginatedSimulationParameters[0] = sp.splice(0, half);
      this.paginatedSimulationParameters[1] = sp;
    } else {
      this.paginatedSimulationParameters[0] = sp;
      this.paginatedSimulationParameters[1] = [];
    }
    //  calcula el procentaje de grupos en base a la capacidad de la configuracion
    //  this.addAvailabilityPercentageFromTotalConfig();

    // console.log( (this.paginatedSimulationParameters[0][2].percentage*100).toFixed(0));
  }
  private addAvailabilityPercentageFromTotalConfig(){
    const config_capacity = this.simulation.configuration.capacity;
    for(let i = 0;i< this.paginatedSimulationParameters.length;i++){
      for (let j = 0;j<this.paginatedSimulationParameters[i].length;j++){
        let computed_nseats = this.paginatedSimulationParameters[i][j].computed_nseats;
        const percentage_config = computed_nseats/config_capacity;
        this.paginatedSimulationParameters[i][j]['percentage_config'] = percentage_config;
      }
    }
  }

  
  private getAvailabilityPercentage(){
    let av = this.dvmService.viewer.getNodesByState('seat', 'available').length;
    this.customAvailabilityPercentage = (100 * av / this.simulation.configuration.capacity).toFixed(2);
    //this.nlocked = this.dvmService.getLockedseats().length;
    
    this.tiersService.getTotalTiersAV().pipe(
      take(1)
      ).subscribe(res=>{
        this.nlocked = res.locked;
        this.customConfigAvailabilityPercentage = (100 * av / (this.simulation.configuration.capacity-this.nlocked)).toFixed(2);
        this.simAvailabilotyPercentageFromConfig = (100 * this.simulation.capacity / (this.simulation.configuration.capacity - this.nlocked)).toFixed(2);
    })
    this.simAvailabilityPercentage = (100 * this.simulation.capacity / this.simulation.configuration.capacity).toFixed(2);
    console.log(this.simulation);
  }


  private customParameters(){
    
    this.totalCustomSeats = Object.keys(this.customGroupsHashed).reduce((sum,key)=>sum+ (parseFloat(this.customGroupsHashed[key])),0);

    for(let grouptype in this.customGroupsHashed){
      let name = '';
      if(grouptype === '1'){
        name =  'Single Seats';
      }else if(grouptype === '2'){
        name = 'Pairs';
      }else{
        name = 'Groups Of '+grouptype+' Seats';
      }
      this.customGroupsData.push({
        group_size:grouptype,
        available_seats: this.customGroupsHashed[grouptype],
        name:name,
        ngroups: this.customGroupsHashed[grouptype]/parseInt(grouptype),
        percentage: ( 100 * this.customGroupsHashed[grouptype] / parseInt(this.totalCustomSeats) ).toFixed(2)
      });
    }

    this.customGroupsData.sort((a, b) => (parseInt(a.group_size) > parseInt(b.group_size)) ? 1 : -1);
    const half = Math.ceil(this.customGroupsData.length / 2);

    this.paginatedCustomParameters[0] = this.customGroupsData.splice(0,half);
    this.paginatedCustomParameters[1] = this.customGroupsData;
    
  }

  private setAllParams(params){
    this.paramsList = [];
    for(let i =0;i<params.length;i++){
      if(params[i].editable===true){
        this.paramsList.push(params[i]);
      }
    }
    return true;
  }


  private getSimulation(): any {
    return this.dataService.getSimulationParameters(this.dataService.currentSimulationId).subscribe(
      simulation => {
        return this.socialdistancingService.updateMapEdges(this.dvmService.viewer, simulation.isle_seats).subscribe(
          () => {
            this.simulation = simulation;
            //console.log(simulation);
              this.totalAisleSeats = this.socialdistancingService.killedEdges.size;
              let sum = 0;
              //this.simulation.capacity = 0;
              let simparams = [];
              this.simulation.parameters.forEach(parameter => {
                if (parameter.percentage) {
                  sum += parseFloat(parameter.percentage);
/*                   if (parameter.computed_nseats){
                    this.simulation.capacity += parameter.computed_nseats;
                  } */
                  simparams.push(parameter.parameter.id);
                }
              });
              this.dataService.simulationData = this.simulation;
              this.dataService.setCurrentSimulation$(this.simulation);
              this.socialdistancingService.calculationFinishedsubject.next();
              this.inventoryService.availabilityLoadedSubject.next();
            //console.log(simparams, sum);
              this.addAllParams(simparams,sum);


              const maps = [];
              if (this.configuration.tier_list.length) {
                this.configuration.tier_list.forEach(level => {
                  maps.push(level.map_id);
                });
              }
              //console.log(this.dataService.simulationData);
              if(this.dataService.simulationData.subsimulations.length>0 || this.dataService.simulationData.custom_groups && "dirty" in this.dataService.simulationData.custom_groups){
                if(this.dataService.simulationData.custom_groups?.dirty===true || this.dataService.simulationData.subsimulations.length>0){
                  
                  //jonpovi magick skill
                  const observable = from(this.socialdistancingService.getGroups(this.dvmService.viewer,maps));
                  let o = observable.subscribe(result=>{
                    o.unsubscribe()
                    let u = this.updateCustomGroups(result).subscribe(result=>{
                      u.unsubscribe();
                      this.dataService.simulationData = this.dataService.simulationData;
                      this.dataService.setCurrentSimulation$(this.dataService.simulationData);
                    });
                    this.customGroupsHashed = result;
                    this.customNGroupsKeys = Object.keys(this.customGroupsHashed);
                    this.customParameters();
                    this.paginateSimulationParameters();
                    this.getAvailabilityPercentage();
                    this.endloading();
                  });
                }else{

                    this.customGroupsHashed = this.dataService.simulationData.custom_groups.groups;
                    this.customNGroupsKeys = Object.keys(this.customGroupsHashed);
                    this.customParameters();
                    this.paginateSimulationParameters();
                    this.getAvailabilityPercentage();
                    this.endloading();
                  
                  
                }
              }else{

                this.paginateSimulationParameters();
                this.getAvailabilityPercentage();
                this.endloading();
              }
            }
        );
      }
    );

  }

  private sumObjectKeys(obj){
    return Object.keys(obj).reduce((sum,key)=>sum+ (parseFloat(obj[key])),0);

  }

  private updateCustomGroups(json){
    //this.totalCustomSeats = Object.keys(this.customGroupsHashed).reduce((sum,key)=>sum+ (parseFloat(this.customGroupsHashed[key])),0);
    let patch_sim = {
      custom_groups : {
        dirty : false,
        groups: json,
        //capacity: this.sumObjectKeys(json)
      }
    }
    //if(patch_sim.custom_groups.capacity!=this.simulation.capacity){
      patch_sim['capacity'] = this.sumObjectKeys(json);
    //}
    this.simulation.custom_groups = patch_sim.custom_groups;
    return this.dataService.editSimulation(this.simulation.id,patch_sim);
  }

  private endloading(){
    document.getElementById("loading-modal").classList.add("d-none");
    document.getElementById("step-content").classList.remove("d-none");
    return null;
  }

  /**
   * Get simulation when it's a shared view
   */
  private getSimulationShared(): void {
    const data = this.dataService.shareData;
    let sum = 0;
    this.simulation = data.simulation;
    this.simulation.parameters = data.parameters;
    this.simulation.capacity = 0;
    let simparams = [];
    data.parameters.forEach(parameter => {
      if (parameter.percentage) {
        sum += parseFloat(parameter.percentage);
        if (parameter.computed_nseats){
          this.simulation.capacity += parameter.computed_nseats;
        }
        simparams.push(parameter.parameter.id);
      }
    });

    this.addAllParams(simparams,sum);

    const maps = [];
    if (this.configuration.tier_list) {
      this.configuration.tier_list.forEach(level => {
        maps.push(level.map_id);
      });
    }
    //jonpovi magick skill
    const observable = from(this.socialdistancingService.getGroups(this.dvmService.viewer,maps));
    let o = observable.subscribe(result=>{
      o.unsubscribe();
      this.customGroupsHashed = result;
      this.customNGroupsKeys = Object.keys(this.customGroupsHashed);
      this.customParameters();
      this.paginateSimulationParameters();
      this.getAvailabilityPercentage();
      this.endloading()
    });

    //
    
  }

  
  private addAllParams(simparams,sum){
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.paramsList.length; i++){
      if(simparams.indexOf(this.paramsList[i].id) === -1 && this.paramsList[i].nseats > 0 && this.paramsList[i].editable===true){
        // tslint:disable-next-line: variable-name
        let default_param = {
          percentage: 0,
          computed_nseats: 0,
          computed_percentage: 0,
          nseats_percentage: 0,
          parameter : {},
        };
        default_param.parameter = this.paramsList[i];
        let already_added = false;
        for(let j =0;j<this.simulation.parameters.length;j++){
          if (this.simulation.parameters[j].parameter.id==this.paramsList[i].id){
            already_added = true;
          }
        }
        if(!already_added){
          this.simulation.parameters.push(default_param);
        }
      }
    }
    if (sum === 0) {
      this.simulation.parameters.forEach(parameter => {
        if (parameter.parameter.id === 3) {
          parameter.percentage = '1';
        }
      });
    }
    return true;
  }
  public onDismiss() {
    this.hiddenReason = 'close';
    this.bsModalRef.hide();
  }
  public closeToEdit(){
    this.hiddenReason = 'closetoedit';
    this.bsModalRef.hide();
  }

  public formatSocialDistancing(num: number): number {

    if (num) {
      return Number(this.numberFormatService.formatSocialDistancing(num));
    } else {
      return 0;
    }
  }

  public formatNumber(price: number): string {
    return this.numberFormatService.formatNumber(price);
  }

}
