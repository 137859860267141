<div id='newsimulation-modal' class='modal-wrap'>
    <div class='modal-header'>
        <h3>{{'newSimulationModal.title' | translate}}</h3>
        <button type="button" class="btn-modal-header" (click)="onClose()" data-dismiss="modal">
            <span>&times;</span>
        </button>
    </div>
    <div class='modal-content'>
        <form #formSimulation="ngForm" (ngSubmit)="onSubmit(formSimulation)" (keydown.enter)="$event.preventDefault()">
            <div class='step step-title'>
                <div class='row'>
                    <div class='col text-center p-0'>
                        <span class='data-content'>{{'newSimulationModal.venue' | translate}}: <b>{{venueData?.name}}</b></span>
                        <span class='data-content'>{{'newSimulationModal.venuecapacity' | translate}}: <b>{{formatNumber(venueData?.capacity)}}</b></span>
                    </div>
                    <!--
                        <div class='col-12 p-0'>
                            <span class='data-content mb-0'>{{'newSimulationModal.configurationcapacity' | translate}}: <b>{{currentConfigCapacity}}</b></span>
                        </div>
                    -->
                </div>
                <div class='row align-items-center'>
                    <div class='col-6 pl-0 text-left'>
                        <label class='ascol' for="configs"><b>1. {{'newSimulationModal.chooseconfiguration' | translate}}</b></label>
                    </div>
                    <div class='col-6 pr-0 text-left'>
                        <!--<select name="config" id="configs" [(ngModel)]="configSelected">
                             <option *ngFor="let config of configurations | keyvalue" value="{{config.key}}">{{config.value?.name}}</option>
                         </select>-->
                         <div id='configs-dropdown' class='ascol'>
                             <div class="dropdown-wrap" dropdown>
                                 <button dropdownToggle type="button" class="btn-dropdown dropdown-toggle" aria-controls="dropdown-animated-configs">
                                     <span *ngIf="configSelected == null else configNameTemplate">SELECT CONFIGURATION</span>
                                     <ng-template #configNameTemplate>
                                         <span>{{configName | uppercase}}</span>
                                     </ng-template>
                                 </button>
                                 <ul id="dropdown-animated-configs" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dropdown-animated-configs">
                                     <li (click)="setConfigSelected(config.key)" class='dropdown-item' role="menuitem" *ngFor="let config of configurations | keyvalue">
                                         <span>{{config.value?.name}}</span>
                                     </li>
                                 </ul>
                             </div>
                         </div>
                     </div>
                </div>
                <div id='inputs-row' class='row align-items-center'>
                    <div class='col-6 pl-0'>
                        <label for="simulationName"><b>2. {{'newSimulationModal.entersimulationname' | translate}}</b></label>
                    </div>
                    <div class='col-6 pr-0'>
                        <input type="text" id="simulationName" (keyup)="disableConfirmbtn()" name="simulationName" [(ngModel)]="simulationName">
                    </div>
                </div>
                <div class='row align-items-center'>
                    <div class='col-6 pl-0'>
                        <label><b>3. {{'newSimulationModal.chooseSocialDistancing' | translate}}</b></label>
                    </div>
                    <div class='col-6 pr-0'>
                        <div class='sd-btns text-center'>
                            <button id='btn-less-distance' type='button' class='btn-round d-inline' (click)="onSetDistancing('minus')">
                                <div class='icon-container-round'>
                                    <div class='icon minus'></div>
                                </div>
                            </button>
                            <span class='sd-value d-inline-block'>{{formatSocialDistancing(socialDistancing)}}</span>
                            <span class='sd-unit d-inline-block'>{{units}}</span>
                            <button id='btn-more-distance' type='button' class=' d-inline btn-round' (click)="onSetDistancing('sum')">
                                <div class='icon-container-round'>
                                    <div class='icon plus'></div>
                                </div>
                            </button>
                        </div>
                        <input id="securityDistance" type="hidden" name="socialDistancing" [(ngModel)]="socialDistancing" />
                        
                    </div>
                </div>
                

                <div class='row align-items-center'>
                    <div class='col pl-0'>
                        <label><b>4. {{'editSimulationModal.aisle_note' | translate}}</b></label>
                    </div>
                    <div class='col pr-0 opt-col'>
                        <div class='sd-btns text-center'>
                            <button id='btn-less-aisle' type='button' class='btn-round d-inline' (click)="onChangeAisleSeat('minus')">
                                <div class='icon-container-round'>
                                    <div class='icon minus'></div>
                                </div>
                            </button>
                            <span class='sd-value d-inline-block'>{{aisleSeats}}</span>
                            <span class='sd-unit d-inline-block'>{{'newSimulationModal.seats' | translate}}</span>
                            <button id='btn-more-aisle' type='button' class=' d-inline btn-round' (click)="onChangeAisleSeat('sum')">
                                <div class='icon-container-round'>
                                    <div class='icon plus'></div>
                                </div>
                            </button>
                        </div>

                    </div>
                </div>
           
                <div class='row' *ngIf="allowCustomCategories && configSelected && !!configurations[configSelected].custom_categories?.custom_categories?.length">
                    <div class='col pl-0'>
                        <label><b>5. {{'editSimulationModal.customcategories.selectcategories' | translate}}</b></label>
                    </div>
                    <div class='col pr-0'>
                        <ng-multiselect-dropdown
                            [placeholder]="'Select categories'"
                            [settings]="customCategoriesDropdownSettings"
                            [data]="customCategoriesDropdownList"
                            [(ngModel)]="customCategoriesSelectedItems"
                            [ngModelOptions]="{standalone: true}"
                            >
                        </ng-multiselect-dropdown>
                    </div>
                </div>
            </div>
            <div class='modal-footer'>
                <div class='warning'>
                    <p class='text-center'>{{'newSimulationModal.groupwarning' | translate}}</p>
                </div>
                <button id='cancelsimulation-btn' class='btn' type="button" (click)="onClose()">{{'newSimulationModal.buttonCancel' | translate}}</button>
                <button id='newsimulation-btn' class='btn' type="submit" [disabled]="disableConfirmbtn()">{{'newSimulationModal.buttonConfirm' | translate}}</button>
            </div>
        </form>
    </div>
</div>
