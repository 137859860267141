import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/auth';
import { FormatNumberService } from 'src/app/social-distance-editor/services/format-number.service';
import { AuthorizationData } from '../../models/authorization-data.model';
import { ForgotPasswordModalComponent } from '../forgot-password-modal/forgot-password-modal.component';

@Component({
  selector: 'app-login',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {
  loginData: AuthorizationData = {
    username: '',
    password: '',
    'grant-type': 'password'
  };
  errors: boolean;
  constructor(private authService: AuthenticationService,
    private router: Router,
    private modalService: BsModalService,
    private localeService: FormatNumberService,
    private translate: TranslateService,
    private bsModalRef: BsModalRef) { }

  ngOnInit(): void {}

  public onSubmit(): void {
    this.authService.login(this.loginData).subscribe(
      response => {
        
        this.onDismiss();
        this.setLanguage(response.user.client.language);
        response.user.plugins
        const hasWorkspace = response.user.plugins.filter(p=>{ if( p && p.type === 'scv') { return true } });
        if(hasWorkspace.length){
          this.router.navigate(['/workspace']);
        }else{
          this.router.navigate(['/home']);
        }
      },
      error => {
        this.showError(error);
        console.log(error);
      }
    );
  }


  public onForgotPassword(): void {
    this.onDismiss();
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered',
      backdrop: false,
      ignoreBackdropClick: false,
      initialState: {}
    };
    this.bsModalRef = this.modalService.show(ForgotPasswordModalComponent, modalConfig);
  }

  public showError(error): void {
    this.errors = true;
  }

  public setLanguage(language: string): void {
    if (language === 'es-ES') {
      localStorage.setItem('language', 'es');
      this.localeService.setCurrentLocale('es');
      this.translate.use('es');
    } else {
      localStorage.setItem('language', 'en');
      this.localeService.setCurrentLocale('en');
      this.translate.use('en');
    }
  }
  public onDismiss() {
    this.bsModalRef.hide();
  }
  
}
