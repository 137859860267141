import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { GroupsService } from 'src/app/social-distance-editor/services/groups.service';
import { CategoriesService } from 'src/app/social-distance-editor/services/categories.service';
import { SelectionService } from 'src/app/social-distance-editor/services/selection.service';

@Component({
  selector: 'app-selection-footer',
  templateUrl: './selection-footer.component.html',
  styleUrls: ['./selection-footer.component.scss']
})
export class SelectionFooterComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  @Input() page: 'setup' | 'simulation';

  get isSimulation() {
    return this.page === 'simulation';
  }

  nodeType: 'section' | 'seat' = 'section';
  currentCategories: string | string[] = [];
  currentSelection = [];
  currentSelectionRows: number;

  get currentSelectionCount() {
    if (this.nodeType === 'section') {
      return this.currentSelection.length;
    } else if (this.nodeType === 'seat') {
      return this.currentSelectionRows;
    }
    return 0;
  }

  get groupsNumber() {
    const groupsHash = {};
    this.currentSelection.forEach(element => {
      if (this.groups.groupsHash.nodes[element.id]) {
        groupsHash[this.groups.groupsHash.nodes[element.id]] = true;
      }
    });
    return Object.keys(groupsHash).length;
  }

  get categoriesNumber() {
    return this.currentCategories.length;
  }

  get currentSelectionType() {
    if (this.nodeType === 'section') {
      return 'section';
    } else if (this.nodeType === 'seat') {
      return 'row';
    }
    return '';
  }

  constructor(private groups: GroupsService,
              public categories: CategoriesService,
              private selection: SelectionService
              ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.selection.nodeType.subscribe(
      type => {
        this.nodeType = type;
      }
    ));
    this.subscriptions.push(this.selection.selectionSubject.subscribe(
      selection => {
        const categoriesWeights = {
          regular: 1,
          vip: 2,
          disabled: 3,
          locked: 4
        };
        this.currentSelection = selection;
        if (this.nodeType === 'seat') {
          const rowsHash = {};
          selection.forEach(seat => {
            const row = seat.id.split('-')[0] + '-' + [1];
            rowsHash[row] = true;
          });
          this.currentSelectionRows = Object.keys(rowsHash).length;
        }
        const currentCategories = this.categories.getCurrentCategories(this.nodeType, selection);
        currentCategories.sort((a, b) => categoriesWeights[a] - categoriesWeights[b]);
        if (currentCategories) {
          const index = currentCategories.indexOf('none');
          if (index > -1) {
            currentCategories.splice(index, 1);
          }
          if (typeof currentCategories === 'string') {
            this.currentCategories = [currentCategories];
          } else {
            this.currentCategories = currentCategories;
          }
        } else {
          this.currentCategories = [];
        }
      }
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
