<div id='headerbar' class="header-container">
    <div class='asrow align-items-center no-gutters justify-content-between'>
        <div id='global-title' class='col-auto'>            
            <div id='d3vheader-content'>
                <a id='d3vheader-link' href='/' title='Homepage' class='color-gray'>SOCIAL DISTANCE EDITOR</a>
                <small class='powered'>Powered by 3D Digital Venue</small>
            </div>
        </div>

    
        <div class='col-auto text-right'>
            <div class='d-none d-md-block'>
                <a class='header-link' href='/#mainfeatures'>Main Features</a>
                <a class='header-link' href='/documentation' title='Documentation'>Documentation</a>
                <a class='header-link' (click)='onOpenPdfSubscriberModal()' title='More info'>More info</a>
                <button class='btn header-btn-home' (click)='onOpenLoginModal()'>Log in</button>
            </div>
        </div>
        <div class='d-sm-block d-md-none'>
            <div dropdown class="nav-item dropdown">
                <a dropdownToggle class="nav-link dropdown-toggle header-btn-home" data-toggle="dropdown"
               role="button" aria-haspopup="true" aria-expanded="false">
                    <div class='icon-container-crop'>
                        <div class='icon menu'></div>
                    </div>
                </a>
                <ul class="dropdown-menu" *dropdownMenu >
                    <li >
                        <a  href='/#mainfeatures' clasS='dropdown-item'>Main Features</a>
                    </li>
                    <li >
                        <a href='/documentation' class="dropdown-item">Documentation</a>
                    </li>
                    <li >
                        <a (click)='onOpenPdfSubscriberModal()' class="dropdown-item">More info</a>
                    </li>

                </ul>
            </div>
        </div>
    </div>
</div>