import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/social-distance-editor/services/data.service';
import { FormatNumberService } from 'src/app/social-distance-editor/services/format-number.service';
import { DVMService } from 'src/app/social-distance-editor/services/dvm.service';
import { Router } from '@angular/router';
import {SocialdistancingService} from "../../../services/socialdistancing.service";
import {ComputedseatsService} from "../../../services/computedseats.service";
import {from} from "rxjs";
import { TiersService } from 'src/app/social-distance-editor/services/tiers.service';
import { take } from 'rxjs/operators';
import {PlatformLocation} from "@angular/common";

@Component({
  selector: 'app-compute-sectionresults-modal',
  templateUrl: './compute-sectionresults-modal.component.html',
  styleUrls: ['./compute-sectionresults-modal.component.scss']
})
export class ComputeSectionResultsModalComponent implements OnInit {

  page;
  simulation;
  simAvailabilityPercentage;
  paginatedSimulationParameters = [];
  paginatedCustomParameters = [];
  nlocked;
  paramsHash = {};
  paramsList = [];
  totalAisleSeats = 10;
  customGroupsHashed;
  customGroups = false;
  customNGroupsKeys;
  totalCustomSeats;
  customAvailabilityPercentage;
  customGroupsData = [];
  configuration;
  subsimulation;
  default_subsim_flag = false;
  sections_selected_value = "";
  subsimcapacity = 0;
  selection_capacity = 0;
  simulationcapacity;
  sdParentNodes;

  affectedSectionParams = [];
  modalData = {
    social_distancing : 0,
    aisle_seats : "",
    limit2groups: "",
    fallback: "",
    parameters : []
  };

  affectedSubsims = [];
  subscriptions = [];

  hiddenReason;
  get units() {
    if (this.dataService.getUser()) {
      return this.dataService.getUnits(this.dataService.getUser().user.client.metric_system);
    }
  }

  get availability() {
    return this.simAvailabilityPercentage;
  }

  get venueName() {
    if (this.simulation) {
      return this.dataService.venueData[this.simulation.configuration.venueId].name;
    }
  }

  constructor(private bsModalRef: BsModalRef,
              private modalService: BsModalService,
              private dataService: DataService,
              private numberFormatService: FormatNumberService,
              private dvmService: DVMService,
              private router: Router,
              private computedseatsService: ComputedseatsService,
              private socialdistancingService: SocialdistancingService,
              private tiersService: TiersService,
              private location: PlatformLocation) {
    this.location.onPopState(()=> {
      this.onDismiss();
    });
  }

  ngOnInit(): void {
      if (this.page === 'shared') {
        this.getSimulationShared();
        
      } else {
          this.getSimulation();
      }
  }
  
  paginateSimulationParameters(){
    const sp = JSON.parse(JSON.stringify(this.modalData.parameters));
    sp.sort((a, b) => (a.nseats > b.nseats) ? 1 : -1);

    if (sp.length > 4) {
      const half = Math.ceil(sp.length / 2);
      this.paginatedSimulationParameters[0] = sp.splice(0, half);
      this.paginatedSimulationParameters[1] = sp;
    } else {
      this.paginatedSimulationParameters[0] = sp;
      this.paginatedSimulationParameters[1] = [];
    }
  }

  private resetModalData(){
    this.modalData = {
      social_distancing : 0,
      aisle_seats : "",
      limit2groups: "",
      fallback: "",
      parameters : []
    };
  }
  private refreshsimulationcapacity(){
    if(this.simulation.custom_groups && this.simulation.custom_groups.capacity){
      this.simulationcapacity = this.simulation.custom_groups.capacity;
    }else{
      this.simulationcapacity = this.simulation.capacity;
    }
  }
  private getAvailabilityPercentage(){
    //let av = this.dvmService.viewer.getNodesByState('seat', 'available').length;
    this.nlocked = this.dvmService.getLockedseats().length;
    this.tiersService.getTotalTiersAV().pipe(
      take(1)
    ).subscribe(res=>{
      this.nlocked = res.locked;
    })
    //this.simAvailabilityPercentage = (100 * this.simulation.capacity / this.simulation.configuration.capacity).toFixed(2);
    
    const totalSeats = this.simulation.configuration.capacity;
    this.refreshsimulationcapacity();
    this.simAvailabilityPercentage = (100 * this.simulationcapacity / totalSeats).toFixed(2);

    //this.customAvailabilityPercentage = (100 * av / this.simulation.configuration.capacity).toFixed(2);

  }

  private getSimulation(): any {
    return this.dataService.getSimulationParameters(this.dataService.currentSimulationId).subscribe(
      simulation => {
        return this.socialdistancingService.updateMapEdges(this.dvmService.viewer, simulation.isle_seats).subscribe(
            () => {
              this.simulation = simulation;
              this.refreshsimulationcapacity();

              //this.simulation.capacity = this.dvmService.viewer.getNodesByState("seat","available");
              this.totalAisleSeats = this.socialdistancingService.killedEdges.size;

              if (this.sdParentNodes.length>0){
                this.sdParentNodes = this.sdParentNodes.map(x => x.id);
                this.affectedSubsims = this.getAffetedSubsims(this.simulation.subsimulations,this.sdParentNodes);
                for(let i = 0; i<this.sdParentNodes.length;i++){
                  this.sections_selected_value +=this.sdParentNodes[i].split("_")[1]+", ";
                  this.subsimcapacity+=this.dvmService.viewer.getNodesByState("seat","available",this.sdParentNodes[i]).length;
                  this.selection_capacity += this.dvmService.viewer.getNodesByParent(this.sdParentNodes[i]).length;
                }
                this.sections_selected_value = this.sections_selected_value.substring(0, this.sections_selected_value.length - 2);
              

              //Default modaldata
              this.modalData.social_distancing = this.simulation.social_distancing;
              this.modalData.aisle_seats = this.simulation.isle_seats;
              this.modalData.fallback = this.simulation.fallback;
              this.modalData.limit2groups = this.simulation.limit2groups;
              
              if(this.affectedSubsims.length>0){
                this.setModalData();
              }

              if(this.dataService.simulationData.custom_groups?.dirty===true || this.dataService.simulationData.subsimulations.length>0){
                  const maps = [];
                  console.log(this.simulation.configuration);
                  if (this.simulation.configuration.tier_list.length) {
                    this.simulation.configuration.tier_list.forEach(level => {
                      maps.push(level.map_id);
                    });
                  }
                  //jonpovi magick skill
                  const observable = from(this.socialdistancingService.getGroups(this.dvmService.viewer,maps));
                  let o = observable.subscribe(result=>{
                    o.unsubscribe()
                    let u = this.updateCustomGroups(result).subscribe(result=>{
                      u.unsubscribe();
                      return this.computedseatsService.getParams(this.simulation,this.sdParentNodes).then(result=>{
                        this.dvmService.viewer.addNodesToGroup(this.sdParentNodes,"selected");
                        //this.socialdistancingService.isSectionSocialDistancing = true;
                        this.paginatedSimulationParameters = result;
                        this.getAvailabilityPercentage();
                        this.endloading();
                      })
                    });
                  });
              }else{
                return this.computedseatsService.getParams(this.simulation,this.sdParentNodes).then(result=>{
                  this.dvmService.viewer.addNodesToGroup(this.sdParentNodes,"selected");
                  //this.socialdistancingService.isSectionSocialDistancing = true;
                  this.paginatedSimulationParameters = result;
                  this.getAvailabilityPercentage();
                  this.endloading();
                })
              }
            }
        });
      }
    );

  }
  private sumObjectKeys(obj){
    return Object.keys(obj).reduce((sum,key)=>sum+ (parseFloat(obj[key])),0);

  }
  private updateCustomGroups(json){
    //this.totalCustomSeats = Object.keys(this.customGroupsHashed).reduce((sum,key)=>sum+ (parseFloat(this.customGroupsHashed[key])),0);
    let patch_sim = {
      custom_groups : {
        dirty : false,
        groups: json,
        //capacity: this.sumObjectKeys(json)
      }
    }
    //if(patch_sim.custom_groups.capacity!=this.simulation.capacity){
      patch_sim['capacity'] = this.sumObjectKeys(json);
    //}
    this.simulation.custom_groups = patch_sim.custom_groups;
    return this.dataService.editSimulation(this.simulation.id,patch_sim);
  }
  private setModalData(){
    this.resetModalData();
    let subsims_sd = [];
    let subsims_aisle = [];
    let subsims_limit2groups = [];
    let subsims_fallback = [];

    for(let i =0;i<this.affectedSubsims.length;i++){
      if (subsims_sd.indexOf(this.affectedSubsims[i].social_distancing)===-1){
        subsims_sd.push(this.affectedSubsims[i].social_distancing);
      }
      if (subsims_aisle.indexOf(this.affectedSubsims[i].isle_seats)===-1){
        subsims_aisle.push(this.affectedSubsims[i].isle_seats);
      }
      if (this.affectedSubsims[i].limit2groups){
        if (subsims_limit2groups.indexOf(1)===-1){
          subsims_limit2groups.push(1);
        }
      }else{
        if (subsims_limit2groups.indexOf(0)===-1){
          subsims_limit2groups.push(0);
        }
      }

      if(this.affectedSubsims[i].fallback){
        if (subsims_fallback.indexOf(1)===-1){
          subsims_fallback.push(1);
        }
      }else{
        if (subsims_fallback.indexOf(0)===-1){
          subsims_fallback.push(0);
        }
      }
    }
    if(subsims_sd.length==1){
      this.modalData.social_distancing = subsims_sd[0];
    }else{
      this.modalData.social_distancing = 0;
    }
    if(subsims_aisle.length==1){
      this.modalData.aisle_seats = subsims_aisle[0];
    }else{
      this.modalData.aisle_seats = "multiple";
    }
    if(subsims_fallback.length==1){
      this.modalData.fallback = subsims_fallback[0];
    }else{
      this.modalData.fallback = "multiple";
    }
    if(subsims_limit2groups.length==1){
      this.modalData.limit2groups = subsims_limit2groups[0];
    }else{
      this.modalData.limit2groups = "multiple";
    }
    return this.modalData;
  }
  private getAffetedSubsims(subsims,affected_sections){
    let affected_sims = [];
    for(let i =0;i<subsims.length;i++){
      if(affected_sections.indexOf(subsims[i].section)>-1){
        affected_sims.push(subsims[i]);
      }
    }
    return affected_sims;
  }

  private endloading(){
    document.getElementById("loading-modal").classList.add("d-none");
    document.getElementById("step-content").classList.remove("d-none");
    return null;
  }

  /**
   * Get simulation when it's a shared view
   */
  private getSimulationShared(): any {
    const data = this.dataService.shareData;
    this.simulation = data.simulation;
    this.simulation.parameters = data.parameters;

    return this.computedseatsService.getParams(this.simulation,this.sdParentNodes).then(result=>{
      let nseats_key = Object.keys(result);
      this.affectedSectionParams = [];
      for(let i = 0;i<nseats_key.length;i++){
        this.affectedSectionParams.push(result[nseats_key[i]]);
      }
      this.getAvailabilityPercentage();
      this.paginateSimulationParameters();
      this.endloading();
    })

  }

  public onDismiss() {
    //this.dvmService.viewer.removeNodesFromGroup(this.sdParentNodes,"selected");
    this.hiddenReason = 'close';
    this.bsModalRef.hide();
  }
  public closeToEdit(){
    this.hiddenReason = 'closetoedit'
    this.bsModalRef.hide();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }


  public formatSocialDistancing(num: number): number {

    if (num) {
      return Number(this.numberFormatService.formatSocialDistancing(num));
    } else {
      return 0;
    }
  }

  public formatNumber(price: number): string {
    return this.numberFormatService.formatNumber(price);
  }

}
