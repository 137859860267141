// import { SeatgeekConnectionConfiguration } from 'ngx-seatgeek-connection';
import { environment } from '../../environments/environment';
import { InjectionToken } from '@angular/core';
import { DVMConfiguration } from 'src/app/social-distance-editor/shared/models/dvm-configuration.model';


export const DVM_CONFIG = new InjectionToken<DVMConfiguration>('DVM Configuration');
export const DVMConfig: DVMConfiguration = {
    venue_id: 'eu-es-00008-default', // nam-us-00045-rangers-sec - nam-us-00104-sec - nam-us-10190-dodgers-sec
    map_id: 'main',
    container: 'viewer-container',
    version: 'development',
    new_styles: true,
    plugins: ['social_distancing'],
    advanced: true
};
