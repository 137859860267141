import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormatNumberService } from './format-number.service';
import { Observable, Subject } from 'rxjs';
import { User } from '../shared/models/user-data.model';
import { Simulation } from '../shared/models/simulation-data.model';
import { Venue } from '../shared/models/venue-data.model';
import { Configuration } from '../shared/models/configuration-data.model';
import { ConfigurationCategory } from '../shared/models/configuration-category-data.model';
import { Parameter } from '../shared/models/parameter-data.model';
import { ConfigurationCategoryInput } from '../shared/models/configuration-category-input-data.model';
import { SimulationCategoryInput } from '../shared/models/simulation-category-input-data.model';
import {map} from 'rxjs/operators';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

export interface CloneConfigurationBody {
  original: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {

  apiRoot = '/api';
  publicRoot = '/public';
  
  user: User;
  currentConfigurationId: number;
  currentSimulationId: number;
  currentShareKey: string;
  configHash: { [key: number]: Configuration } = {};
  configCategoriesHash: { [key: number]: ConfigurationCategory } = {};
  configCategories: Array<ConfigurationCategory> = [];

  parameters: Array<Parameter> = [];
  // tslint:disable-next-line: variable-name
  unitsHash = {
    m: {
      es: 'Metros',
      en: 'Meters'
    },
    ft: {
      es: 'Pies',
      en: 'Feet'
    }
  };

  venueData;
  private currentVenue$ = new Subject<number>();
  public clientVenuesList: Venue[];
  currentVenue;
  simulationData: Simulation;
  subsimulationData;
  affectedSubsimulation;

  private currentSimulation$ = new Subject<Simulation>();
  private currentConfiguration$ = new Subject<Configuration>();
  inputHash = {};
  private inputHash$ = new Subject<any>();
  listParams;
  listParamsHashed;

  get editSimulation$() {
    return this.editSimulationSubject$.asObservable();
  }
  private readonly editSimulationSubject$ = new Subject<any>();

  constructor(protected http: HttpClient,
              protected formatMumber: FormatNumberService) {
    this.currentVenue$.next(0);
  }

  shareData;
  shareDataSubject = new Subject();
  shareDataAllParams = [];

  
  // USER / CLIENT
  /**
   * Sets this.client
   * @param client to set
   */
  setUser(user): void {
    this.user = user;
    this.formatMumber.setCurrency(user.user.client.currency);
    this.formatMumber.setUnits(user.user.client.metric_system);
  }

  /**
   * Gets this.client
   * @returns Client
   */
  getUser(): User {
    return this.user;
  }

  public getHttp(){
    return {'http': this.http,'apiRoot':this.apiRoot};
  }

  public getClientConfigs(clientId: number): Observable<Venue[]> {
    return this.http.get(`${this.apiRoot}/clients/${clientId}/venues`) as Observable<Venue[]>;
  }

  public setClientVenues(venues: Venue[]){
    this.clientVenuesList = venues;
  }

  // ¿Está en desuso?, eliminar en un futuro
  public getClientSimulations(clientId: number, page?: number): Observable<Array<Simulation>> {
    if (page) {
      return this.http.get(`${this.apiRoot}/clients/${clientId}/simulations/${page}`) as Observable<Array<Simulation>>;
    } else {
      return this.http.get(`${this.apiRoot}/clients/${clientId}/simulations`) as Observable<Array<Simulation>>;
    }
  }


  // VENUE DATA

  public getCurrentVenue$(): Observable<number> {
    return this.currentVenue$.asObservable();
  }

  public setCurrentVenue$(venueId: number): void {
    this.currentVenue$.next(venueId);
  }

  public getVenueSimulations(venueId: number, page?: number): Observable<Array<Simulation>> {
    if (page) {
      return this.http.get(`${this.apiRoot}/venues/${venueId}/simulations/${page}`) as Observable<Array<Simulation>>;
    } else {
      return this.http.get(`${this.apiRoot}/venues/${venueId}/simulations`) as Observable<Array<Simulation>>;
    }
  }

  public getUnits(unit: string): string {
    let language = 'en';
    if (localStorage.getItem('language') !== null) {
      language = localStorage.getItem('language');
    }
    return this.unitsHash[unit][language];
  }

  public getCategories() {
    return this.http.get(`${this.apiRoot}/categories`) as Observable<any>;
  }

  // CONFIGURATIONS

  public setGroups(configId, data): Observable<any> {
    const body = {
      content: JSON.stringify(data)
    };
    return this.http.post(`${this.apiRoot}/configurations/${configId}/groups`, body);
  }

  public getGroups(configId): Observable<any> {
    return this.http.get(`${this.apiRoot}/configurations/${configId}/groups`);
  }

  /**
   * Gets the seatmanifest from the API and returns an observable
   * @param venueId to attach the file
   * @param param optional param, by default API sends json
   */
  public getSeatManifest(configId, param?: 'csv' | 'json'): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders(),
      params: new HttpParams()
    };
    if (param === 'csv') {
      httpOptions.headers = httpOptions.headers.append('Accept', 'text/csv');
      httpOptions.params = httpOptions.params.append('mime', 'csv');
    } else {
      httpOptions.params = httpOptions.params.append('mime', 'json');
    }
    return this.http.get(`${this.apiRoot}/configurations/${configId}/seatmanifest`, httpOptions)
      .pipe(map((s) => {
        let code = 200;
        if ((s as any).statusCode) {
          code = (s as any).statusCode;
        }
        return {statusCode: code, response: s};
      }));
  }

  public editConfiguration(configurationId: number, body): Observable<any> {
    return this.http.patch(`${this.apiRoot}/configurations/${configurationId}`, body);
  }

  /**
   * Uploads the seatmanifest to the API and returns an observable
   * @param venueId to attach the file
   * @param file to send
   */
  public uploadSeatManifest(configId, file: File): Observable<any> {
    const csvData: FormData = new FormData();
    csvData.append('content', file, file.name);
    return this.http.post(`${this.apiRoot}/configurations/${configId}/seatmanifest`, csvData);
  }

  public sendSeatManifestData(configId, data): Observable<any> {
    // console.log(data);
    const blob = new Blob([JSON.stringify(data)], {type: 'application/json'});
    const body = new FormData();
    body.append('seatmanifest', blob, 'manifest.json');
    // console.log(configId, data);
    return this.http.post(`${this.apiRoot}/configurations/${configId}/seatmanifest`, body);
  }

  public getConfig(configId: number): Observable<Configuration> {
    return this.http.get(`${this.apiRoot}/configurations/${configId}/`) as Observable<Configuration>;
  }

  public getConfigurationsCategories(configId: number): Observable<ConfigurationCategory> {
    return this.http.get(`${this.apiRoot}/configurations/${configId}/categories`) as Observable<ConfigurationCategory>;
  }

  public setConfigHash(config: Array<Configuration>): void {
    for (const c of config) {
      this.configHash[c.id] = c;
    }
  }

  public getConfigHash(): { [key: number]: Configuration } {
    return this.configHash;
  }

  public getConfigById(id: number): Configuration | undefined {
    return this.configHash[id];
  }

  // public setConfigCategoriesHash(config: Array<ConfigurationCategory>): void {
  //   for (const c of config) {
  //     this.configCategoriesHash[c.id] = c;
  //   }
  // }

  // public getConfigCategories(): { [key: number]: ConfigurationCategory } {
  //   return this.configCategoriesHash;
  // }

  // public getConfigCategoryByConfigId(id: number): ConfigurationCategory | undefined {
  //   return this.configCategoriesHash[id];
  // }
  public postConfigurationCategories(configurationCategories: ConfigurationCategoryInput) {
    return this.http.post(`${this.apiRoot}/configuration_categories/create`, configurationCategories);
  }

  public getConfigurationCategories(configId) {
    return this.http.get(`${this.apiRoot}/configurations/${configId}/categories`);
  }
  // PARAMETERS
  public getParameterOrCreate(nseats): any {
    return this.http.get(`${this.apiRoot}/parameters/get_or_create?nseats=` + nseats);
  }

  public getParameters(): any {
    return this.http.get(`${this.apiRoot}/parameters`);
  }
  public getCurrentConfiguration$(): Observable<Configuration> {
    return this.currentConfiguration$.asObservable();
  }
  public setCurrentConfiguration$(configuration: Configuration): void {
    this.currentConfiguration$.next(configuration);
  }

  // SIMULATIONS

  // POST  simulations/:id/social_groups
  public setSocialGroups(simulationId, data): Observable<any> {
    const body = {
      content: JSON.stringify(data)
    };
    return this.http.post(`${this.apiRoot}/simulations/${simulationId}/social_groups`, body);
  }

  // Si queremos que la simulacion nos traiga tambien la subsimulacion y
  // a su vez los parametros de esta ultima, debemos añadir un query param. ejemplo:
  // GET - /api/simulations/:id?subsimulation=true

  public getCurrentSimulation$(): Observable<Simulation> {
    return this.currentSimulation$.asObservable();
  }

  public setCurrentSimulation$(simulation: Simulation): void {
    this.currentSimulation$.next(simulation);
  }

  public getInputHash$(): Observable<any> {
    return this.inputHash$.asObservable();
  }

  public setInputHash$(inputHash: any): void {
    this.inputHash$.next(inputHash);
  }

  public deleteSimulation(simulationId: number): Observable<any> {
    return this.http.delete(`${this.apiRoot}/simulations/${simulationId}`);
  }

  public deleteConfiguration(configurationId: number): Observable<any> {
    return this.http.delete(`${this.apiRoot}/configurations/${configurationId}`);
  }

  public createSimulation(data: Simulation): Observable<any> {
    return this.http.post(`${this.apiRoot}/simulations`, data);
  }
  public cloneConfiguration(data: CloneConfigurationBody): Observable<any> {
    return this.http.post(`${this.apiRoot}/configurations/clone`, data);
  }
  // public cloneConfiguration(data: Simulation): Observable<any> {
  //   return this.http.post(`${this.apiRoot}/simulations`, data);
  // }
  public copySimulation(data: any): Observable<any> {
    return this.http.post(`${this.apiRoot}/simulations/clone`, data);
  }
  public getSimulation(simulationId: number): Observable<any> {
    return this.http.get(`${this.apiRoot}/simulations/${simulationId}/?subsimulation=true`) as Observable<any>;
  }
  public getSimulationParameters(simulationId: number): Observable<any> {
    return this.http.get(`${this.apiRoot}/simulations/${simulationId}/parameters?subsimulation=true`) as Observable<any>;
  }

  /**
   * Gets the seatmanifest from the API and returns an observable
   * @param venueId to attach the file
   * @param param optional param, by default API sends json
   */
  public getSimulationSeatManifest(simulationId, param?: 'csv' | 'json'): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders(),
      params: new HttpParams()
    };
    if (param === 'csv') {
      httpOptions.headers = httpOptions.headers.append('Accept', 'text/csv');
      httpOptions.params = httpOptions.params.append('mime', 'csv');
    } else {
      httpOptions.params = httpOptions.params.append('mime', 'json');
    }
    return this.http.get(`${this.apiRoot}/simulations/${simulationId}/seatmanifest`, httpOptions)
      .pipe(map((s) => {
        let code = 200;
        if ((s as any).statusCode) {
          code = (s as any).statusCode;
        }
        return {statusCode: code, response: s};
      }));

  }

  /**
   * Gets the seatmanifest-hold from the API and returns an observable
   * @param venueId to attach the file
   * @param param optional param, by default API sends json
   */
  public getSimulationSeatManifestHold(simulationId, param?: 'csv' | 'json'): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders(),
      params: new HttpParams()
    };
    if (param === 'csv') {
      httpOptions.headers = httpOptions.headers.append('Accept', 'text/csv');
      httpOptions.params = httpOptions.params.append('mime', 'csv');
    } else {
      httpOptions.params = httpOptions.params.append('mime', 'json');
    }
    return this.http.get(`${this.apiRoot}/simulations/${simulationId}/seatmanifest-hold`, httpOptions)
        .pipe(map((s) => {
          let code = 200;
          if ((s as any).statusCode) {
            code = (s as any).statusCode;
          }
          return {statusCode: code, response: s};
        }));

  }

  public sendSimulationSeatManifestData(simulationId, data): Observable<any> {
    // const content = JSON.stringify(data);
    // const body = {
    //   content: content
    // };
    const blob = new Blob([JSON.stringify(data)], {type: 'application/json'});
    const body = new FormData();
    body.append('seatmanifest', blob, 'manifest.json');
    return this.http.post(`${this.apiRoot}/simulations/${simulationId}/seatmanifest`, body);
  }

  public getAllParameters(): Observable<any> {
    return this.http.get(`${this.apiRoot}/parameters`);
  }

  public editSimulation(simulationId: number, body): Observable<any> {
    this.editSimulationSubject$.next(body);
    return this.http.patch(`${this.apiRoot}/simulations/${simulationId}`, body);
  }
  public editSubSimulation(subsimulationId: number, body): Observable<any> {
    return this.http.patch(`${this.apiRoot}/subsimulations/${subsimulationId}`, body);
  }
  public setSimulationGroups(simulationId, data): Observable<any> {
    const body = {
      content: JSON.stringify(data)
    };
    return this.http.post(`${this.apiRoot}/simulations/${simulationId}/groups`, body);
  }

  public getSimulationGroups(simulationId): Observable<any> {
    return this.http.get(`${this.apiRoot}/simulations/${simulationId}/groups`);
  }

  public createSimulationParameters(body: any): Observable<any> {
    return this.http.post(`${this.apiRoot}/simulations_parameters/create`, body);
  }

  public editSimulationParameters(parameterId: number, body: any): Observable<any> {
    return this.http.patch(`${this.apiRoot}/simulations_parameter/${parameterId}`, body);
  }

  public postSimulationCategories(simulationCategories: SimulationCategoryInput) {
    return this.http.post(`${this.apiRoot}/simulations_categories/create`, simulationCategories);
  }

  public getSimulationCategories(simulationId): Observable<any> {
    return this.http.get(`${this.apiRoot}/simulations/${simulationId}/categories`);
  }

  // SUBSIMULATIONS

  //   POST - /api/subsimulations
  // - body: {
  // 	"name": "test subsimulation",
  //     "social_distancing": 1.444,
  //     "capacity": 18,
  //     "simulation_id": 210,
  //     "isle_seats": 5,
  //     "fallback": 3
  // }
  public createSubsimulation(data): Observable<any> {
    return this.http.post(`${this.apiRoot}/subsimulations`, data);
  }

  // Para borrar una subsimulacion:
  // DELETE: /api/subsimulations/:id
  public deleteSubsimulation(subsimulationId: number): Observable<any> {
    return this.http.delete(`${this.apiRoot}/subsimulations/${subsimulationId}`);
  }

  // Si quereis solo los parametros de una subsimulacion:
  // GET: /api/subsimulations/:id/parameters
  public getSubsimulationParameters(subsimulationId: number): Observable<any> {
    return this.http.get(`${this.apiRoot}/subsimulations/${subsimulationId}/parameters`);
  }

  // Crear parametros de una subsimulacion, igual que simulaciones:
  // POST: /api/subsimulations_parameters/create
  // body: {
  // 	"subsimulation": 1,
  // 	"options": [
  // 		{"param_id": 2, "percentage":"20", "computed_percentage": "0.34234", "computed_nseats": 10}
  // 	]
  // }
  public createSubsimulationParameters(body: any): Observable<any> {
    return this.http.post(`${this.apiRoot}/subsimulations_parameters/create`, body);
  }

  // SHARE SIMULATIONS

  public getSimulationShareKey(simulationId): Observable<any> {
    return this.http.get(`${this.apiRoot}/simulations/${simulationId}/share`);
  }

  public stopSharingSimulation(simulationId): Observable<any> {
    return this.http.get(`${this.apiRoot}/simulations/${simulationId}/noshare`);
  }

  public getSharedSimulation(shareKey): Observable<any> {
    return this.http.get(`${this.apiRoot}/shared/simulation/?k=${shareKey}`);
  }

  public getSharedSimulationSeatManifest(shareKey): Observable<any> {
    return this.http.get(`${this.apiRoot}/shared/simulation/seatmanifest/?k=${shareKey}`)
      .pipe(map((s) => {
        let code = 200;
        if ((s as any).statusCode) {
          code = (s as any).statusCode;
        }
        return {statusCode: code, response: s};
      }));
  }

  // plugins
  // alocation: missing groups

  public deleteAllocationResult(simulationId: number, pluginRef: string): Observable<any> {
    return this.http.delete(`${this.apiRoot}/allocation/${pluginRef}/${simulationId}/result`);
  }

  public sendAllocationResult(simulationId: number, pluginRef: string, blob: Blob): Observable<any> {
    const body = new FormData();
    body.append('allocation_result', blob, 'allocation_result.zip');
    return this.http.post(`${this.apiRoot}/allocation/${pluginRef}/${simulationId}/result`, body);
  }

  public getAllocationResult(simulationId: number, pluginRef: string): Observable<any> {
    return this.http.get(`${this.apiRoot}/allocation/${pluginRef}/${simulationId}/result`, { responseType: 'arraybuffer' });
  }

  //update password
  public updatepassword(currentpass,newpass){
    let body = {
      current_password : currentpass,
      password : newpass
    }
    return this.http.patch(`${this.apiRoot}/users/password`,body);

  }

  //pdfsubscriber send email
  public pdfsubscriber(fullname,email){
    let body = {
      fullname : fullname,
      email : email
    }
    return this.http.post(`${this.publicRoot}/mailer/pdfsubscriber`,body);
  }



}
