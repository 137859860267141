<div class="body-container shared-sim-body">
    <!-- <app-topbar [page]="'simulation'"></app-topbar>             -->
    <app-sidebar [page]="'shared'" [configurationSubject]="configurationSubject"></app-sidebar>

    <!--
        <app-topbar [page]="'simulation'" [configurationSubject]="configurationSubject"></app-topbar>
    -->
    <div class="simulation-container">
        <app-map-interface class='interface-map-section'></app-map-interface>
        <app-footer [page]="'simulation'"></app-footer>
    </div>
    <div id="loadingmap">
        <img src="../assets/img/interface/loading.gif" alt="loading" height="50">
        <div id="loading-process" class="hidden">100%</div>
    </div>
</div>