import { Component, Inject, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DVMService } from 'src/app/social-distance-editor/services/dvm.service';
import { ClickHandler } from 'src/app/social-distance-editor/services/handlers/click.handler';
import { InventoryService } from 'src/app/social-distance-editor/services/inventory.service';

@Component({
  selector: 'app-confirm-edit-multi-seat',
  templateUrl: './confirm-edit-multi-seat.component.html',
  styleUrls: ['./confirm-edit-multi-seat.component.scss'],
})
export class ConfirmEditMultiSeatComponent implements OnInit {
  closeReason: 'success' | 'cancel';
  affected_seats;
  max_seats;
  status_tochange;
  error;
  constructor(
    private bsModalRef: BsModalRef,
    private dvmservice: DVMService,
    private inventoryService: InventoryService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    if(this.affected_seats.length){
      this.dvmservice.viewer.addNodesToGroup(this.affected_seats[0].id.split("-")[0],"selected");
      this.dvmservice.viewer.addNodesToGroup(this.affected_seats,"selected");
    }
  }

  public onConfirm(): void {
    let control_dirty_action = 'remove';
    let update_inventory_status = null;
    let section_affected = this.affected_seats[0].id.split("-")[0];

    let n_av = this.dvmservice.viewer.getNodesByState("seat","available",section_affected).length;
    if(this.status_tochange==='available'){
      control_dirty_action = 'add';
      update_inventory_status = 'available';
      if(n_av >0){
        this.dvmservice.viewer.setAvailable('section', section_affected);
      }
      this.dvmservice.viewer.setAvailable('seat', this.affected_seats);


    }else{
      if(!n_av){
        this.dvmservice.viewer.setUnavailable('section', section_affected);
      }else{
        this.dvmservice.viewer.setAvailable('section', section_affected);

      }
      this.dvmservice.viewer.setUnavailable('seat', this.affected_seats);
    }
    
    this.inventoryService.controlDirty(this.affected_seats,control_dirty_action);
    let toastrOpt = {
      msg : "Availability and Seatmanifest data successfully updated",
      timeOut: 4000
  }
    this.inventoryService.updateInventory(this.affected_seats,update_inventory_status,toastrOpt);

    this.onDismiss('success');
  }

  public onDismiss(reason: 'success' | 'cancel'): void {

    this.dvmservice.viewer.removeNodesFromGroup(this.dvmservice.viewer.getNodesByGroups('seat', 'selected'),"selected")
    this.dvmservice.viewer.removeNodesFromGroup(this.dvmservice.viewer.getNodesByGroups('section', 'selected'),"selected")
    this.closeReason = reason;
    this.bsModalRef.hide();
  }
}
