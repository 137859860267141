<div>
    <div class='modal-header'>
        <h3>{{'forgotPasswordModal.title' | translate}}</h3>
        <button type="button" class="btn-modal-header" (click)="onDismiss()" data-dismiss="modal">
            <span>&times;</span>
        </button>
    </div>
    
    <div class='modal-content'>
        <p>{{'forgotPasswordModal.body' | translate}} <a href='mailto:enquiries@3ddigitalvenue.com'>enquiries@3ddigitalvenue.com</a></p>
    </div>
</div>