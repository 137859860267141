import { Injectable, Inject } from "@angular/core";
import { DVMService } from "./dvm.service";
import { Subject, from } from "rxjs";
import { DataService } from "./data.service";
import { InventoryService } from "./inventory.service";
import { DVM_CONFIG } from "src/assets/configuration/digitalvenue.configuration";
import { map } from "rxjs/operators";
import { SelectionService } from "./selection.service";
import { CategoriesService } from "./categories.service";
import { ISOLATED_GROUPS } from "../../../assets/data/isolated-groups.configuration";
import { IsolatedGroups } from "../shared/models/isolated-groups.model";
import { GroupsService } from "./groups.service";
import {
  MapViewer,
  MapViewerInputNodes,
  MapViewerNodeLike,
  SocialDistancingComputeInput,
  SocialDistancingFilterEdgesInput,
  SocialDistancingFilterNearestInput,
  SocialDistancingGetNeighborsInput,
  SocialDistancingIsolatedGroups,
} from "@3ddv/dvm-internal";

declare let DVM;

// export interface SocialdistancingConfig {
//   securityDistance: number;
//   parameters: {
//     [key: number]: number;
//   };
// }

@Injectable({
  providedIn: "root",
})
export class SocialdistancingService {
  currentMode;
  iterations = 1;
  lastResult;

  useExternalData: true;

  neighborDistance = 0.65;
  securityDistance: number;
  parameters = {};
  probabilityGroup2 = 0.3;
  probabilityGroup3 = 0.3;
  probabilityGroup4 = 0.3;
  fallback = 0;
  isleSeats = 0;
  configSubject = new Subject<any>();
  calculationFinishedsubject = new Subject<any>();
  isConfigSet = false;
  limit2groups = false;
  numberOfCalculations = 1;
  calculationsHash = {};
  groups_seats = {};
  availabilityBuffer;

  isSectionSocialDistancing = false;
  sectionsAffected = [];

  edges: Set<string> = new Set();
  killedEdges: Set<string> = new Set();
  allgroups = {};

  aisle_seats_sides = {
    start: true,
    end: true,
  };

  constructor(
    private dvmService: DVMService,
    private categories: CategoriesService,
    private inventory: InventoryService,
    private dataService: DataService,
    private selectionService: SelectionService,
    private groupsService: GroupsService,
    @Inject(DVM_CONFIG) private DVMConfig,
    @Inject(ISOLATED_GROUPS) private isolatedGroups: IsolatedGroups
  ) {}

  getGroupsData() {
    return this.groups_seats;
  }

  updateMapEdges(viewer: MapViewer, count: number) {
    const options: SocialDistancingFilterEdgesInput = {
      neighbor_distance: this.neighborDistance,
      count,
    };
    const p = viewer.social_distancing.filterEdges(options).then((result) => {
      this.edges.clear();
      result.invalidated.forEach((n) => this.edges.add(n));
      this.updateKilledEdges(viewer);
    });
    return from(p);
  }

  updateKilledEdges(viewer: MapViewer) {
    this.killedEdges.clear();
    const unavailable = viewer.getNodesByState("seat", "unavailable");
    unavailable.forEach((n) => {
      if (this.edges.has(n.id)) this.killedEdges.add(n.id);
    });
  }

  getNeighbors(
    viewer: MapViewer,
    seat: MapViewerNodeLike,
    count?: number,
    invalidated?: string[],
    validated?: string[]
  ) {
    if (!count) {
      count = Infinity;
    }
    const options: SocialDistancingGetNeighborsInput = {
      count,
      invalidated,
      validated,
      isolated_groups: this.buildIsolatedGroups(viewer),
    };
    return from(viewer.social_distancing.getNeighbors(options, seat));
  }

  filterNearest(viewer: MapViewer, seats: MapViewerInputNodes, invalidated?: string[], validated?: string[]) {
    const options: SocialDistancingFilterNearestInput = {
      security_distance: this.securityDistance,
      invalidated,
      validated,
    };
    return from(viewer.social_distancing.filterNearest(options, seats));
  }

  getSocialDistancingConfig() {
    return {
      securityDistance: this.securityDistance,
      isleSeats: this.isleSeats,
      limit2groups: this.limit2groups,
      fallback: this.fallback,
      parameters: this.parameters,
      aisle_seats_start: this.aisle_seats_sides.start,
      aisle_seats_end: this.aisle_seats_sides.end,
    };
  }
  setSocialDistancingConfig(sdConfig) {
    this.parameters = {};
    this.securityDistance = sdConfig.securityDistance;
    this.isleSeats = sdConfig.isleSeats;
    this.limit2groups = sdConfig.limit2groups;
    this.fallback = sdConfig.fallback;
    this.aisle_seats_sides = {
      start: sdConfig.aisle_seats_sides.start,
      end: sdConfig.aisle_seats_sides.end,
    };
    // tslint:disable-next-line: forin
    for (const parameter in sdConfig.parameters) {
      if (typeof sdConfig.parameters[parameter] === "string") {
        this.parameters[parameter] = parseFloat(sdConfig.parameters[parameter]);
      }
      // if (this.parameters[parameter] == 1) {
      //   this.parameters[parameter] = Infinity;
      // } else if (this.parameters[parameter] == 0) {
      //   delete this.parameters[parameter];
      // }
    }
    this.isConfigSet = true;
    this.configSubject.next();
  }

  computeSimple(viewer, blacklist: string[], whitelist: string[], callback?, maps?, nodes?) {
    const neighbor = this.neighborDistance;
    const security = this.securityDistance;

    const group = 2;
    const fallback = this.fallback;

    const options: SocialDistancingComputeInput = {
      fixed_groups: { [group]: Infinity },
      fallback,
      security_distance: security,
      neighbor_distance: neighbor,
      // blacklist,
      //   whitelist,
      invalidated: blacklist,
      validated: whitelist,
      kill_edges: this.isleSeats,
      use_external_data: this.useExternalData,
      from_edges: true,
    };
    if (!maps || !maps.length) {
      maps = [this.dvmService.viewer.getMapId()];
    }
    this.computeSocialDistancing(viewer, maps, options, callback);
    // console.log('------INPUT------');
    // console.log(input);
    // viewer.social_distancing.compute(input, undefined, this.catchProgress)
    // .then((result) => {
    //   this.onResolveCompute(viewer, result, callback);
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
  }

  computeProbabilistic(viewer, blacklist: string[], whitelist: string[], callback?, maps?, nodes?): void {
    const neighbor = this.neighborDistance;
    const security = this.securityDistance;
    const iterations = this.iterations;
    const fallback = this.fallback;
    const groups = this.parameters;
    const options: SocialDistancingComputeInput = {
      probabilistic_groups: groups,
      iterations,
      security_distance: security,
      neighbor_distance: neighbor,
      fallback,
      // blacklist,
      validated: whitelist,
      invalidated: blacklist,
      kill_edges: this.isleSeats,
      use_external_data: this.useExternalData,
      from_edges: true,
      kill_start: true,
      kill_end: true,
      only_from_edges: this.limit2groups,
    };
    if (!maps || !maps.length) {
      maps = [this.dvmService.viewer.getMapId()];
    }
    if (nodes && nodes.length) {
      // options.kill_start = this.aisle_seats_sides.start;
      // options.kill_end = this.aisle_seats_sides.end;

      this.computeSectionSocialDistancing(viewer, options, nodes, callback);
    } else {
      this.computeSocialDistancing(viewer, maps, options, callback);
    }
  }
  public getGroupsBySection(viewer: MapViewer, sections: string[]) {
    let nodes = [];
    for (let i = 0; i < sections.length; i++) {
      nodes = nodes.concat(viewer.getNodesByParent(sections[i]));
    }

    const unavailable = viewer.getNodesByState("seat", "unavailable");
    const invalidatedByGroup = viewer.getNodesByGroups("seat", this.groupsService.getGroupBehaviors("invalidated"));
    const validated = viewer.getNodesByGroups("seat", this.groupsService.getGroupBehaviors("validated"));
    const invalidated = unavailable.concat(invalidatedByGroup);

    return viewer.social_distancing
      .getGroups(
        {
          neighbor_distance: this.neighborDistance,
          invalidated: invalidated.map((n) => n.id),
          validated: validated.map((n) => n.id),
          isolated_groups: this.buildIsolatedGroups(viewer),
        },
        true,
        nodes
      )
      .then((result) => {
        let groups_seats = {};
        let ngroups = Object.keys(result);
        for (let i = 0; i < ngroups.length; i++) {
          groups_seats[ngroups[i]] = result[ngroups[i]] * parseInt(ngroups[i]);
        }
        return groups_seats;
      });
  }

  // TODO: Juntar esta funcion con la de abajo
  public getGroupsFullExport(viewer: MapViewer, maps?: any) {
    // return viewer.social_distancing['getGroups'](
    //   {
    //     neighbor_distance: this.neighborDistance,
    //     invalidated: viewer.getNodesByState('seat', 'unavailable').map(n => n.id)
    //   }, false)
    //   .then(
    //     result => {
    //       console.log(result);
    //       if (this.dataService.currentSimulationId) {
    //         return this.dataService.setSocialGroups(this.dataService.currentSimulationId, result);
    //       }
    // });
    //   console.log('ON FULL EXPORT GROUP: INVALIDATED: ', viewer.getNodesByState('seat', 'unavailable').map(n => n.id));
    //   console.log('ON FULL EXPORT GROUP: VALIDATED: ', viewer.getNodesByGroups('seat', 'onhold').map(n=>n.id));
    return viewer.social_distancing
      .getGroups(
        {
          neighbor_distance: this.neighborDistance,
          invalidated: viewer.getNodesByState("seat", "unavailable").map((n) => n.id),
          isolated_groups: this.buildIsolatedGroups(viewer),
        },
        false
      )
      .then((result) => {
        this.groups_seats = {};
        let ngroups = Object.keys(result);
        for (let i = 0; i < ngroups.length; i++) {
          this.groups_seats[ngroups[i]] = result[ngroups[i]];
        }

        if (!maps || !maps.length) {
          if (this.dataService.currentSimulationId) {
            return this.dataService.setSocialGroups(this.dataService.currentSimulationId, this.groups_seats);
          }
        } else {
          const index = maps.indexOf(viewer.getMapId());
          if (index > -1) {
            maps.splice(index, 1);
          }
          let promiseArray = [];
          maps.forEach((map) => {
            const viewerConfig = JSON.parse(JSON.stringify(this.DVMConfig));
            viewerConfig.venue_id = this.dvmService.viewer.getVenueId();
            viewerConfig.map_id = map;
            viewerConfig.container = "dummy-viewer-container";

            const promise = DVM.loadModule("map_viewer", viewerConfig).then((viewer: MapViewer) => {
              return viewer.loadMap(viewerConfig).then((response) => {
                const availableInventory = this.inventory.getAvailableInventory(true).seats;
                viewer.setAvailability("seat", availableInventory);
                return viewer.social_distancing
                  .getGroups(
                    {
                      neighbor_distance: this.neighborDistance,
                      invalidated: viewer.getNodesByState("seat", "unavailable").map((n) => n.id),
                      isolated_groups: this.buildIsolatedGroups(viewer),
                    },
                    false
                  )
                  .then((result) => {
                    let ngroups = Object.keys(result);

                    for (let i = 0; i < ngroups.length; i++) {
                      if (this.groups_seats[ngroups[i]]) {
                        this.groups_seats[ngroups[i]] = this.groups_seats[ngroups[i]].concat(result[ngroups[i]]);
                      } else {
                        this.groups_seats[ngroups[i]] = result[ngroups[i]];
                      }
                    }
                    viewer.reset();
                    viewer.close();
                  });
              });
            });
            promiseArray.push(promise);
          }); //END FOREACH
          return Promise.all(promiseArray)
            .then((results) => {
              if (this.dataService.currentSimulationId) {
                return this.dataService.setSocialGroups(this.dataService.currentSimulationId, this.groups_seats);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  }

  public getGroups(viewer: MapViewer, maps?: any) {
    return viewer.social_distancing
      .getGroups(
        {
          neighbor_distance: this.neighborDistance,
          invalidated: viewer.getNodesByState("seat", "unavailable").map((n) => n.id),
          isolated_groups: this.buildIsolatedGroups(viewer),
        },
        true
      )
      .then((result) => {
        this.groups_seats = {};
        let ngroups = Object.keys(result);
        for (let i = 0; i < ngroups.length; i++) {
          this.groups_seats[ngroups[i]] = result[ngroups[i]] * parseInt(ngroups[i]);
        }

        if (!maps || !maps.length) {
          return this.groups_seats;
        } else {
          const index = maps.indexOf(viewer.getMapId());
          if (index > -1) {
            maps.splice(index, 1);
          }
          let promiseArray = [];
          maps.forEach((map) => {
            const viewerConfig = JSON.parse(JSON.stringify(this.DVMConfig));
            viewerConfig.venue_id = this.dvmService.viewer.getVenueId();
            viewerConfig.map_id = map;
            viewerConfig.container = "dummy-viewer-container";

            const promise = DVM.loadModule("map_viewer", viewerConfig).then((viewer: MapViewer) => {
              return viewer.loadMap(viewerConfig).then((response) => {
                const availableInventory = this.inventory.getAvailableInventory(true).seats;
                viewer.setAvailability("seat", availableInventory);
                return viewer.social_distancing
                  .getGroups(
                    {
                      neighbor_distance: this.neighborDistance,
                      invalidated: viewer.getNodesByState("seat", "unavailable").map((n) => n.id),
                      isolated_groups: this.buildIsolatedGroups(viewer),
                    },
                    true
                  )
                  .then((result) => {
                    let ngroups = Object.keys(result);
                    for (let i = 0; i < ngroups.length; i++) {
                      if (this.groups_seats[ngroups[i]]) {
                        this.groups_seats[ngroups[i]] += result[ngroups[i]] * parseInt(ngroups[i]);
                      } else {
                        this.groups_seats[ngroups[i]] = result[ngroups[i]] * parseInt(ngroups[i]);
                      }
                    }

                    viewer.reset();
                    viewer.close();
                  });
              });
            });

            promiseArray.push(promise);
          }); //END FOREACH
          return Promise.all(promiseArray)
            .then((results) => {
              return this.groups_seats;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  }

  public buildIsolatedGroups(viewer: MapViewer): SocialDistancingIsolatedGroups | null {
    const data = this.isolatedGroups[viewer.getVenueId()];
    if (data) {
      const output = {};
      for (const groupId in data) {
        if (data.hasOwnProperty(groupId)) {
          const sectionIds = data[groupId];

          sectionIds.forEach((sectionId) => {
            if (output[groupId] == null) {
              output[groupId] = [];
            }
            viewer.getNodesByParent(sectionId).forEach((node) => {
              output[groupId].push(node.id);
            });
          });
        }
      }
      return output;
    }
    return null;
  }

  private computeSocialDistancing(viewer, maps, options, callback) {
    console.log("Compute SD, maps: ", maps);
    if (maps && maps.length) {
      this.calculationsHash = {};
      this.numberOfCalculations = maps.length;
      const promiseArray = [];
      maps.forEach((map) => {
        const viewerConfig = JSON.parse(JSON.stringify(this.DVMConfig));
        viewerConfig.venue_id = this.dvmService.viewer.getVenueId();
        viewerConfig.map_id = map;
        viewerConfig.container = "dummy-viewer-container";
        const promise = new Promise((success, reject) => {
          DVM.loadModule("map_viewer", viewerConfig).then((viewer) => {
            viewer.loadMap(viewerConfig).then((response) => {
              const context = {
                map_id: map,
                calculationsHash: this.calculationsHash,
                numberOfCalculations: this.numberOfCalculations,
              };

              // -----------
              // Ya que la blacklist se genera a partir del mapa, se tiene que generar para cada tier
              const inventory = this.inventory.getAvailableInventory();
              viewer.setAvailability("section", inventory.sections);
              viewer.setAvailability("seat", inventory.seats);
              this.categories.printInventoryCategories(viewer);

              const unavailable = viewer.getNodesByState("seat", "unavailable");
              const invalidatedByGroup = viewer.getNodesByGroups(
                "seat",
                this.groupsService.getGroupBehaviors("invalidated")
              );
              const validated = viewer.getNodesByGroups("seat", this.groupsService.getGroupBehaviors("validated"));
              const invalidated = unavailable.concat(invalidatedByGroup);

              options.invalidated = invalidated.map((x) => x.id);
              options.validated = validated.map((x) => x.id);
              options.isolated_groups = this.buildIsolatedGroups(viewer);
              // -----------

              viewer.social_distancing
                .compute(options, null, this.catchProgress.bind(context))
                .then((result) => {
                  viewer.reset();
                  viewer.close();
                  success(result);
                })
                .catch((err) => {
                  console.error(err);
                  reject(err);
                });
            });
          });
        });
        promiseArray.push(promise);
      });

      Promise.all(promiseArray).then((results) => {
        console.log("PROMISE RESOLVED");
        const totalResults = {
          availability: [],
          grouped: {},
          total_nodes: 0,
        };
        results.forEach((result) => {
          totalResults.availability = totalResults.availability.concat(result.availability);
          totalResults.total_nodes += result.total_nodes;
          for (const key in result.grouped) {
            if (result.grouped.hasOwnProperty(key)) {
              if (!totalResults.grouped[key]) {
                totalResults.grouped[key] = [];
              }
              totalResults.grouped[key] = totalResults.grouped[key].concat(result.grouped[key]);
            }
          }
        });
        this.onResolveCompute(viewer, totalResults, callback);
      });
    }
  }

  private computeSectionSocialDistancing(viewer: MapViewer, options, nodes, callback) {
    const promise = new Promise((success, reject) => {
      const context = {
        map_id: map,
        calculationsHash: this.calculationsHash,
        numberOfCalculations: this.numberOfCalculations,
      };
      let socialDistancingNodes = null;
      if (this.isSectionSocialDistancing && nodes && nodes.length) {
        socialDistancingNodes = nodes;
      }
      console.log("HERE: ", this.isSectionSocialDistancing, socialDistancingNodes);
      options.isolated_groups = this.buildIsolatedGroups(viewer);
      viewer.social_distancing
        .compute(options, socialDistancingNodes, this.catchProgress.bind(context))
        .then((result) => {
          console.log(result);
          success(result);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
    promise.then((result) => {
      const totalResults = {
        availability: [],
        grouped: {},
        total_nodes: 0,
      };
      totalResults.availability = totalResults.availability.concat(result["availability"]);
      totalResults.total_nodes += result["total_nodes"];
      for (const key in result["grouped"]) {
        if (result["grouped"].hasOwnProperty(key)) {
          if (!totalResults.grouped[key]) {
            totalResults.grouped[key] = [];
          }
          totalResults.grouped[key] = totalResults.grouped[key].concat(result["grouped"][key]);
        }
      }
      this.onResolveCompute(viewer, totalResults, callback, nodes);
    });
  }

  private catchProgress(progress) {
    let totalProgress = 0;
    //if (this['map_id'] && !this.calculationsHash[this['map_id']]) {
    if (this["map_id"]) {
      this.calculationsHash[this["map_id"]] = progress;
    }
    Object.values(this.calculationsHash).forEach((value) => {
      totalProgress += value as number;
    });
    if (document.getElementById("loading-process")) {
      if (totalProgress === this.numberOfCalculations) {
        document.getElementById("loading-process").classList.add("hidden");
      } else {
        if (totalProgress === 0) {
          document.getElementById("loading-process").classList.remove("hidden");
        }
        document.getElementById("loading-process").innerText =
          ((totalProgress * 100) / this.numberOfCalculations).toFixed(0) + "%";
      }
    }
  }

  //Borra las onholds en los unavailables
  // TODO: buscar una mejor forma de borrar los onholds de los unavailable
  // Una solucion podria ser meter las sillas de onhold en el estado available.
  // Otra solucion es separar las sillas en la funcion de getAvailableInventory y alli sacar grupos de sillas de
  // Available, Onhold y Unavailable
  private getValidatedInvalidated(viewer: MapViewer): { validated: string[]; invalidated: string[] } {
    const onholds = viewer.getNodesByGroups("seat", "onhold").map((n) => n.id);
    let unavailbles = viewer.getNodesByState("seat", "unavailable").map((n) => n.id);

    function arrayRemove(arr, value) {
      return arr.filter(function (ele) {
        return ele != value;
      });
    }

    for (let i = 0; i < onholds.length; ++i) {
      unavailbles = arrayRemove(unavailbles, onholds[i]);
    }
    return { validated: onholds, invalidated: unavailbles };
  }

  private isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  private onResolveCompute(viewer, result: any, callback?, nodes?): void {
    console.log("ON RESOLVE COMPUTE");
    // LENGTH GROUPS
    let totalGroupLength = 0;
    // tslint:disable-next-line: forin
    this.dataService.setSocialGroups(this.dataService.currentSimulationId, result.grouped).subscribe((response) => {
      console.log(response);
    });
    for (const g of Object.values(result.grouped)) {
      totalGroupLength += Object.keys(g).length;
    }
    // PARAMETERS
    let params;
    const newSimulationParameters = {
      simulation: this.dataService.currentSimulationId,
      options: [],
    };
    const newSectionSimulationParameters = {
      simulation: this.dataService.currentSimulationId,
      options: [],
    };
    const inputHash = JSON.parse(JSON.stringify(this.dataService.inputHash));

    const nSeatshash = {};

    const createParams = () => {
      for (const p of params) {
        if (p.parameter) {
          nSeatshash[p.parameter.nseats] = p.parameter.id;
        } else if (p.id) {
          nSeatshash[p.nseats] = p.id;
        }
      }

      for (const [key, value] of Object.entries(result.grouped)) {
        let percentage = 0;

        if (typeof nSeatshash[key] !== "undefined" && nSeatshash[key] !== null) {
          if (inputHash[nSeatshash[key]]) {
            percentage = inputHash[nSeatshash[key]]["percentage"] / 100;
          }
          if (this.isEmpty(inputHash) && nSeatshash[key] == 3) {
            percentage = 1;
          }
          // else if (nSeatshash[key] === 3) {
          //   percentage = 1;
          // }

          //if(nodes && nodes.length){

          //}else{
          const computedPercentage = Object.keys(value).length / totalGroupLength;
          // const nseatsPercentage = (Object.keys(value).length * parseInt(key, 10)) / result.total_nodes;
          const nseatsPercentage = (Object.keys(value).length * parseInt(key, 10)) / result.availability.length;
          newSimulationParameters["options"].push({
            param_id: nSeatshash[key],
            computed_percentage: computedPercentage,
            nseats_percentage: nseatsPercentage,
            computed_nseats: Object.keys(value).length * parseInt(key),
            percentage,
          });
          //}
        }
      }

      const afterCreateParams = (subsims?) => {
        console.log("AFTER CREATE PARAMS");
        this.lastResult = result;
        if (nodes && nodes.length) {
          this.inventory.setInventoryData(this.availabilityBuffer);
          const availableInventory = this.inventory.getAvailableInventory(true).seats;
          const nodesMap = nodes.map((x) => x.id);
          let newAvailability = availableInventory.filter((x) => !nodesMap.includes(x));
          newAvailability = newAvailability.concat(result.availability);
          result.availability = newAvailability;
        }
        // this.inventory.setInventoryData(this.inventory.comparativeInventory);
        // No tiene sentido hacer un setAvailability justo aquí, porque en el inventory hay más datos que no vienen de la lib. Hay que esperar a que el inventory esté listo (una vez se ha hecho 'editSimulation')
        // viewer.setAvailability('seat', result.availability);
        //this.categories.printInventoryCategories(viewer);
        this.inventory.availabilityLoadedSubject.next();
        // this.inventory.getAvailableInventory(true);
        if (callback) {
          callback(result.availability);
          this.isSectionSocialDistancing = false;
          let selected_nodes = [];
          // TODO: Aqui se cogen sillas y no se que
          selected_nodes = selected_nodes.concat(this.dvmService.viewer.getNodesByGroups("section", "selected"));
          selected_nodes = selected_nodes.concat(this.dvmService.viewer.getNodesByGroups("seat", "selected"));
          if (selected_nodes.length) {
            this.dvmService.viewer.addNodesToGroup(selected_nodes, "selected");
          }
          let haslocked_nodes = this.dvmService.viewer.getNodesByGroups("section", "locked");
          if (haslocked_nodes.length) {
            this.dvmService.viewer.setUnavailable("section", haslocked_nodes);
          }

          haslocked_nodes = this.dvmService.viewer.getNodesByGroups("seat", "locked");
          if (haslocked_nodes.length) {
            this.dvmService.viewer.setUnavailable("seat", haslocked_nodes);
            this.dvmService.viewer.addNodesToGroup(haslocked_nodes, "locked");
          }

          /* 
          this.dvmService.viewer.removeNodesFromGroup(this.dvmService.viewer.getNodesByGroups("seat", "selected"), 'selected');
          this.dvmService.viewer.removeNodesFromGroup(this.dvmService.viewer.getNodesByGroups("section", "selected"), 'selected');
          this.selectionService.selectionSubject.next([]); */
        }
        this.calculationFinishedsubject.next(result);
        // this.modalService.openEditSimulation();
        const body = {
          capacity: result.availability.length,
        };
        this.dataService.editSimulation(this.dataService.currentSimulationId, body).subscribe(
          (success) => {
            this.dataService.setCurrentSimulation$(success.result);
            this.inventory.availabilityLoadedSubject.next();
            const availableInventory = this.inventory.getAvailableInventory(true).seats;
            this.dvmService.viewer.setAvailability("seat", availableInventory);
          },
          (error) => {
            console.log(error);
          }
        );
        if (subsims && subsims.length > 0) {
          for (let i = 0; i < subsims.length; i++) {
            let subsim_id = subsims[i].id;
            let body = {
              capacity: this.dvmService.viewer.getNodesByState("seat", "available", subsims[i].section).length,
            };
            this.dataService.editSubSimulation(subsim_id, body).subscribe((success) => {
              console.log("subsim update");
            });
          }
        }
      };

      if (nodes && nodes.length) {
        let config = this.getSocialDistancingConfig();
        const body = {
          name: "Sim: " + this.dataService.currentSimulationId,
          social_distancing: config.securityDistance,
          capacity: "0",
          simulation_id: this.dataService.currentSimulationId,
          isle_seats: config.isleSeats,
          fallback: config.fallback,
          section: this.sectionsAffected.map((n) => n.id),
          limit2groups: config.limit2groups,
          aisle_seats_start: config.aisle_seats_start,
          aisle_seats_end: config.aisle_seats_end,
        };
        this.dataService.createSubsimulation(body).subscribe((response) => {
          newSimulationParameters["subsimulation"] = response.result.map((n) => n.id);
          this.dataService.createSubsimulationParameters(newSimulationParameters).subscribe(
            (success) => {
              console.log(success);
              afterCreateParams(response.result);
            },
            (error) => {
              console.error(error);
            }
          );
        });
      } else {
        this.dataService.createSimulationParameters(newSimulationParameters).subscribe(
          (success) => {
            afterCreateParams();
          },
          (error) => {
            console.error(error);
          }
        );
      }
    };

    if (nodes && nodes.length) {
      if (
        this.dataService.affectedSubsimulation[0] &&
        this.dataService.affectedSubsimulation[0].parameters.length !== 0
      ) {
        params = this.dataService.affectedSubsimulation[0].parameters;
        createParams();
      } else {
        this.dataService.getAllParameters().subscribe((emptyParams) => {
          params = emptyParams["params"];
          createParams();
        });
      }
    } else {
      this.dataService.getSimulationParameters(this.dataService.currentSimulationId).subscribe((parameters) => {
        console.log(parameters);

        if (parameters.parameters.length !== 0) {
          params = parameters.parameters;
          createParams();
        } else {
          this.dataService.getAllParameters().subscribe((emptyParams) => {
            params = emptyParams["params"];
            createParams();
          });
        }
      });
    }
  }

  arr_diff(a1, a2) {
    var a = [],
      diff = [];

    for (var i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }
    for (var i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }
    for (var k in a) {
      diff.push(k);
    }
    return diff;
  }
}
