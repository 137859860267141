

<app-header-public></app-header-public>

<div class='page-container'>
    <div class='container'>
    <h2><b>Privacy Policy: Additional information on Data Protection</b></h2>
    <h3 class='mt-0'><b>1. Controller </b></h3>
    <p class='m-0'> <b>Who is the controller of the processing of your personal data?</b></p>
    <p>
        Identity: Mobile Media Content, S.L. <br>
        NIF: B-65799454<br>
        Address: C\ Àlaba 61, 6th 1 A-2, 08005 Barcelona (Spain)<br>
        Email: <a href="mailto:enquiries@3ddigitalvenue.com">enquiries@3ddigitalvenue.com</a>
    </p>
    <h3><b>2. PURPOSES OF DATA PROCESSING </b></h3>
    <p>In compliance with the provisions of the General Data Protection Regulation (EU) 2016/679, 
        as well as well  as  the  national  data  protection  applicable  law,  we  inform  you  
        that  Mobile  Media  Content,  S.L. processes  the  personal  data  you  provide  in  order  
        to  send  you  commercial  communications  of  your interest.</p>
    <h3><b>3. DATA STORAGE PERIOD</b></h3>
    <p>Your data will be stored until you request their removal, and, where appropriate, 
        for the time necessary to fulfil any legal obligations.</p>
    <h3><b>4. LEGITIMATION</b></h3>
    <p class='m-0'>
        <b>What is the legitimacy for the processing of your personal data?</b>
    </p>
    <p>
        The legal basis for the processing of your personal data corresponding to the purposes (1) to (3) included in section 2 above ("Purposes") are the following:
    </p>
    <ul>
        <li>In relation to the purpose (1) the legal basis is the express consent (clear affirmative action) given to Mobile Media Content, S.L. at the time of collection of your personal data.</li>
        <li>In relation to the purposes (2) and (3) the legal basis are the (i) the express consent (clear affirmative action) given to Mobile Media Content, S.L. at the time of collection of your personal data, as well as, (ii) the intention to enter into a contract.</li>
    </ul>

    <h3><b>5. RECIPIENTS</b></h3>
    <p class='m-0'>Your personal data will not be transferred to any third party, except to:</p>
    <ul>
        <li>Third  parties  whose  intervention  is  necessary  for  the  correct  management  of  the  service provision.</li>
        <li>Public bodies and authorities (administrative or judicial) in those cases where a legal rule so provides.</li>
    </ul>

    <h3><b>6. RIGHTS</b></h3>
    <p class='m-0'><b>What rights do you have in relation to your personal data and how can you exercise them?</b></p>
    <p class='m-0'>
        You have the right to exercise the following rights:
    </p>
    <ul>
        <li>Access to your personal data.</li>
        <li>Request the rectification or modification of inaccurate data.</li>
        <li>Request their deletion when, among other reasons, the data are no longer necessary for the purposes they were collected.</li>
        <li>Request, under certain circumstances, the limitation of the processing of your data, in which case we will only keep them for the exercise or defense of claims.</li>
        <li>Oppose,  in  certain  circumstances  and  for  reasons  related  to  your  particular  situation,  the processing of your data. MOBILE MEDIA CONTENT, S.L. will cease to process the data, except for compelling legitimate reasons, or the exercise or defense of possible claims.</li>
        <li>Request the portability of the data.</li>
    </ul>
    <p>
        Likewise,  you  may  withdraw  the  consent  given  at  any  time,  without  this  affecting  the  legality  of  the processing based on the consent given prior to its withdrawal. If you wish to exercise any of your rights, you may contact us, either in person to the corporate address of  MOBILE  MEDIA  CONTENT,  S.L.  at  the  address  given  above,  or  by  sending  an  e-mail  to <a href='mailto:enquiries@3ddigitalvenue.com'>enquiries@3ddigitalvenue.com</a>, identifying yourself accordingly.Finally, we inform you that you can address the Spanish Data Protection Agency and other competent public bodies for any claim derived from the processing of your personal data. 
    </p>
</div>
</div>
