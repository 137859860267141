import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-interface',
  templateUrl: './map-interface.component.html',
  styleUrls: ['./map-interface.component.scss']
})
export class MapInterfaceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
