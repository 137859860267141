import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DataService} from './data.service';
import { DVMService} from './dvm.service';
import { SocialdistancingService} from './socialdistancing.service';

@Injectable({
    providedIn: 'root'
})
export class ComputedseatsService {

    paramsListBD;
    hashedParamsListBD;
    constructor(private dataService: DataService,
                private dvmService : DVMService,
                private socialdistancingService: SocialdistancingService) {
        this.resetBdParams();
    }
    
    private hashArray(data,key,newkey?){
        let res = {};
        for(let i =0;i<data.length;i++){
            if(data[i][key]){
                res[data[i][key]]=data[i];
                res[data[i][key]]['result']={
                    available_seats : 0,
                    availability_percent : 0
                }
            }
        }
        return res;
    }

    public getParams(simulation,affected_sections?:string[]){
        return this.matchParamsByAffectedSections(affected_sections);
    }
    
    private resetBdParams(){
        this.paramsListBD = this.dataService.listParams.params;
        this.hashedParamsListBD = this.hashArray(this.paramsListBD,"nseats");
    }
    private matchParamsByAffectedSections(affected_sections: string[]){
        return this.socialdistancingService.getGroupsBySection(this.dvmService.viewer,affected_sections)
        .then(
            result=>{
                this.resetBdParams();
                let total_affected_seats = 0;
                for (let i =0;i<affected_sections.length;i++){
                    total_affected_seats+=this.dvmService.viewer.getNodesByParent(affected_sections[i]).length;
                }

                let result_nseats = Object.keys(result);
                let params_toshow_inmodal = [];
                for(let i = 0;i<result_nseats.length;i++){
                    let group = {
                        "name":"",
                        "result":{}
                    };
                    if(!this.hashedParamsListBD[result_nseats[i]]){
                        group["name"]= 'Groups Of '+result_nseats[i]+' Seats';
                    }else{
                        group['name']=this.hashedParamsListBD[result_nseats[i]].name;
                    }
                    group['result']={
                        "group_size": parseInt(result_nseats[i]),
                        "num_of_groups" :  result[result_nseats[i]] / parseInt(result_nseats[i]),
                        "available_seats" : parseInt(result[result_nseats[i]]),
                        "availability_percent" : result[result_nseats[i]] * 100 / total_affected_seats
                    }

                    params_toshow_inmodal.push(group);
                }

                params_toshow_inmodal.sort((a, b) => (parseInt(a.result.group_size) > parseInt(b.result.group_size)) ? 1 : -1);
                const half = Math.ceil(params_toshow_inmodal.length / 2);
            
                 let ret = [];
                    ret[0] = params_toshow_inmodal.splice(0,half);
                    ret[1] = params_toshow_inmodal;
                return ret;
            }
        );
    }

    
}
