import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {CloneConfigurationBody, DataService} from 'src/app/social-distance-editor/services/data.service';
import { Form } from '@angular/forms';
import { Router } from '@angular/router';

import { SocialdistancingService } from 'src/app/social-distance-editor/services/socialdistancing.service';
import { InventoryService } from 'src/app/social-distance-editor/services/inventory.service';
import { CategoriesService } from 'src/app/social-distance-editor/services/categories.service';
import { FormatNumberService } from 'src/app/social-distance-editor/services/format-number.service';
import { ModalService } from 'src/app/social-distance-editor/services/modal.service';
import { Subscription } from 'rxjs';
import { Configuration } from '../../models/configuration-data.model';
import { ToastrService } from 'ngx-toastr';
import { UploadbuttonComponent } from '../../buttons/uploadbutton/uploadbutton.component';
import { CustomCategoriesService } from '../../../plugins/custom-categories/services/custom-categories.service';
import { AuthenticationService } from 'src/app/auth';
import { CustomCategoriesPlugin } from 'src/app/social-distance-editor/plugins/custom-categories/custom-categories.module';


@Component({
  selector: 'app-new-config-modal',
  templateUrl: './new-config-modal.component.html',
  styleUrls: ['./new-config-modal.component.scss']
})
export class NewConfigModalComponent implements OnInit, OnDestroy {
  @ViewChild('uploadVenueData') venueDataCSV!: UploadbuttonComponent;

  currentVenue;
  venuecapacity;
  configSelectedKey;
  configSelectedName;
  configurations;
  clientId;
  configurationSelected;
  // form
  newconfigname;
  //on submit
  cloningconfig;
  private subscriptions: Subscription[] = [];

  // modal
  closeReason;
  constructor(private bsModalRef: BsModalRef,
              private dataService: DataService,
              private inventoryService: InventoryService,
              private categories: CategoriesService,
              private socialDistancingService: SocialdistancingService,
              private numberFormatService: FormatNumberService,
              private customCategoriesService: CustomCategoriesService,
              private modalService: ModalService,
              private router: Router,
              private toastr: ToastrService,
              private authService: AuthenticationService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.venuecapacity = this.numberFormatService.formatNumber(this.currentVenue.capacity);
    this.configurations = this.currentVenue.configurations;

    if (this.configurations.length === 1){
      this.configSelectedKey = 0;
      this.configSelectedName = this.configurations[0].name;
      this.configurationSelected = this.configurations[0];
    }
  }


  public onClose(reason: 'success' | 'cancel'): void {
    this.closeReason = reason;
    this.bsModalRef.hide();
  }

  public onSubmit(ngForm: Form): void {
    if (!this.disableConfirmbtn()) {
      let csv;
      let inputFile;
      let filename;
      this.cloningconfig = true;
      if(this.customCategoriesService.allowCustomCategories){
        csv = this.venueDataCSV.getFile();
        inputFile = this.venueDataCSV.getFileRaw();
        if(inputFile.length){
          filename = inputFile[0].name;
        }
      }
      const body: CloneConfigurationBody = {
        original : this.configurationSelected.id,
        name : this.newconfigname
      };
      // si csv es valido, significa que estan subiendo un fichero con custom categories, estan usando el plugin
      if (csv) {
        const promiseList = [
            this.dataService.getSeatManifest(this.configurationSelected.id).toPromise(),
            this.readText(csv, 'iso-8859-1')
        ];
        Promise.all(promiseList).then((results) => {
          // Merge del fichero introducido por el file y el manifest
          const [seatmanifestjson, customcsv] = results;
          const result = this.customCategoriesService.buildManiestWithCustomCategories(customcsv, seatmanifestjson.response);
          // Una vez el merge esta completo clonamos la configuracion
          const { inventory, categories } = result;
          body.custom_categories = {
            custom_categories: categories,
          };
          return this.dataService.cloneConfiguration(body).toPromise()
              .then((response: { message: string, new_configuration: Configuration }) => {
                  return { configuration: response.new_configuration, seatmanifest: inventory};
   
              })
              .then((data)=>{
                return this.customCategoriesService.postVenueDataFile('configuration', data.configuration.id,'raw',customcsv,filename).toPromise()
                  .then( (response)=>{
                    console.log("uploaded Original venue data files");
                    return data;
                  });
              }).then((data)=>{
                return this.customCategoriesService.postVenueDataFile('configuration', data.configuration.id,'parsed',JSON.stringify(data.seatmanifest),filename).toPromise()
                  .then( (response)=>{
                    return data;
                  });
              }).then((data) => {
                // Una vez la configuracion se a creado correctamente, subimos le manifest con las custom categories
                return this.dataService.sendSeatManifestData(data.configuration.id, data.seatmanifest).toPromise();
              }).then((response) => {
                // Una vez completado de forma correcta vamos a la ruta de la configuracion y mostramos el toast de info
                this.cloningconfig = false;
                this.onClose('success');
                this.toastr.success('', 'New configuration successfully saved');
              }).catch((e) => {
                // En caso de errores cerramos y mostramos el toast informativo.
                console.error(e);
                this.cloningconfig = false;
                this.onClose('cancel');
                this.toastr.error('', 'An unexpected error occurred');
              });
            });
      } else {
        const s = this.dataService.cloneConfiguration(body).subscribe(
          response => {
            s.unsubscribe();
            if (response.message){
              this.onClose('success');
              // this.router.navigate(['/simulation/' + response.result.id]);
              this.toastr.success('', 'New configuration successfully saved');
            }
          
          });
      }
    }
  }

  get units() {
    if (this.dataService.getUser()) {
      return this.dataService.getUnits(this.dataService.getUser().user.client.metric_system);
    }
  }



  public formatNumber(price: number): string {
    return this.numberFormatService.formatNumber(price);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public setConfigSelected(configIdKey: number) {
    this.configSelectedKey = configIdKey;
    this.configSelectedName = this.configurations[configIdKey].name;
    this.configurationSelected  = this.configurations[configIdKey];
  }

  public disableConfirmbtn(){
      if (!this.newconfigname || typeof this.configSelectedKey === 'undefined'){
        return true;
      }
      return false;
  }

  public hasPlugin(plugin: string): boolean {
    if (!this.dataService.getUser()){ return false; }
    const pluginList = this.dataService.getUser().user.plugins;
    const i = pluginList.findIndex((addon) => addon.ref === plugin);
    return i > -1;
  }

  private readText(file: Blob, encoding?: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('Cannot read file'));
        }
      };
      reader.onerror = () => reject(new Error('Cannot read file'));

      reader.readAsText(file, encoding);
    });
  }
}
