import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() isSimulation;
  page;
  client_param;
  currentyear = new Date().getFullYear();
  constructor(
    private router: Router,
    private route : ActivatedRoute
    ) {}

  ngOnInit(): void {
    this.router.events.subscribe( (route) => {
      if ( route instanceof NavigationEnd ) {
        const url_splitted = route.url.split('/');
        const url = url_splitted[1];
        this.page = '';
        if ( url === 'setup') {
          this.page = 'setup';
        }
        if ( url === 'simulation') {
          this.page = 'simulation';
        }
        if(url==='laliga'){
          this.client_param = url;
        }else{

          this.client_param = url_splitted[2];
        }

      }
    });
  }
}
