<!-- <app-navigation-bar></app-navigation-bar> -->
<div id='main-interface' class='wrap-interface'>
  <div id="viewer-container"></div>
  <div id="dummy-viewer-container" style="display: none"></div>
</div>

<!-- MINIMAP -->
<!-- <div class='d-none d-md-block nopadding' id="minimap">
  <app-areamap-mini [mapType]="this.app_config.mapType" [blockSelected]="blockSelected" [state]="status"></app-areamap-mini>
  <tk3d-map-mini [type]="'blockmap'" class="hidden"></tk3d-map-mini>
  <tk3d-map-mini [type]="'seatmap'" class="hidden"></tk3d-map-mini>
</div> -->

<!-- POPPER TOOLTIP -->
<div id="tooltip" class="popover tk3dpopover" role="tooltip">
  <div id="arrow" data-popper-arrow></div>
  <h3 class="popover-header"></h3>
  <div class="popover-body"></div>
</div>

<!-- MAP INTERFACE BUTTONS -->
<div id='map-icon-left' class='map-icon'><div class='icon-container'><div class='icon left'></div></div></div>
<div id='map-icon-top' class='map-icon'><div class='icon-container'><div class='icon top'></div></div></div>
<div id='map-icon-right' class='map-icon'><div class='icon-container'><div class='icon right'></div></div></div>
<div id='map-icon-bottom' class='map-icon'><div class='icon-container'><div class='icon bottom'></div></div></div>

<div id='map-icon-zoom'>
  <div id='map-icon-zoom-plus' class='map-icon'><div class='icon-container'><div class='icon plus'></div></div></div>
  <div id='map-icon-zoom-minus' class='map-icon'><div class='icon-container'><div class='icon minus'></div></div></div>
</div>

<!-- LEGEND
<app-legend></app-legend> -->