<div>
    <div class='modal-header'>
        <h3>{{"exportonholdModal.title" | translate}}</h3>
        <button type="button" class="btn-modal-header" (click)="onDismiss()" data-dismiss="modal">&times;</button>
    </div>

    <div class='modal-content'>
        <h4>
            {{"exportonholdModal.body" | translate}}
        </h4>
        <hr>
        <p>
            {{"exportonholdModal.postdata" | translate}}
        </p>
    </div>
    <div class='modal-footer'>
        <button (click)="onDenied()" class='btn d-inline-block'>{{"exportonholdModal.buttonDecline" | translate}}</button>
        <button (click)="onConfirm()" class='btn d-inline-block'>{{"exportonholdModal.buttonConfirm" | translate}}</button>
    </div>
</div>
