<div class="button-upload">
    <div #uploadFileDiv>
        <button class="btn btn-upload text-uppercase" type='button' [disabled]="disabled" #button>
            <div class='icon-container-crop'>
                <div class='icon upload'></div>
            </div>
            <span>{{name}}</span>
        </button>
    </div>
    <div #removeFileDiv class="d-none">
        <span class="file-name color-blue">{{filename}}</span><span class="file-extension color-blue">.{{fileExtension}}</span>
        <button class="btn btn-remove text-uppercase" (click)="removeFile()">Remove</button>
    </div>
</div>
