import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewSimulationModalComponent } from './modals/new-simulation-modal/new-simulation-modal.component';
import { CopySimulationModalComponent } from './modals/copy-simulation-modal/copy-simulation-modal.component';
import { NewConfigModalComponent } from './modals/new-config-modal/new-config-modal.component';
import { ConfirmDeleteConfigComponent } from './modals/confirm-delete-config/confirm-delete-config.component';
import { ForgotPasswordModalComponent } from './modals/forgot-password-modal/forgot-password-modal.component';
import { ChangePasswordModalComponent } from './modals/change-password-modal/change-password-modal.component';
import { FormatDatePipe } from './pipes/date-format.pipe';
import { SearchPipe } from './pipes/search-table.pipe';
import { CategoryPriceModalComponent } from './modals/category-price-modal/category-price-modal.component';
import { FormsModule } from '@angular/forms';
import { EditSimulationModalComponent } from './modals/edit-simulation-modal/edit-simulation-modal.component';
import { EditSectionSimulationModalComponent } from './modals/edit-sectionsimulation-modal/edit-sectionsimulation-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDeleteSimulationComponent } from './modals/confirm-delete-simulation/confirm-delete-simulation.component';
import { ComputeResultsModalComponent } from './modals/compute-results-modal/compute-results-modal.component';
import { ComputeSectionResultsModalComponent } from './modals/compute-sectionresults-modal/compute-sectionresults-modal.component';
import { ConfirmLogoutModalComponent } from './modals/confirm-logout-modal/confirm-logout-modal.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ShareSimulationModalComponent } from './modals/share-simulation-modal/share-simulation-modal.component';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import {ButtonComponent} from './buttons/button/button.component';
import {DownloadbuttonComponent} from './buttons/downloadbutton/downloadbutton.component';
import {UploadbuttonComponent} from './buttons/uploadbutton/uploadbutton.component';
import { LoginModalComponent } from './modals/login-modal/login-modal.component';
import { PublicVideosComponent } from './modals/public-videos/public-videos.component';
import { PdfsubscriberComponent } from './modals/pdfsubscriber/pdfsubscriber.component';
import { LaligaContactCauModalComponent } from './modals/laliga-contact-cau-modal/laliga-contact-cau-modal.component';
import { ConfirmEditMultiSeatComponent } from './modals/confirm-edit-multi-seat/confirm-edit-multi-seat.component';
import { ExportOnholdModalComponent } from './modals/export-onhold-modal/export-onhold-modal.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';



@NgModule({
  declarations: [
    NewSimulationModalComponent,
    CopySimulationModalComponent,
    NewConfigModalComponent,
    ForgotPasswordModalComponent,
    ChangePasswordModalComponent,
    FormatDatePipe,
    SearchPipe,
    CategoryPriceModalComponent,
    EditSimulationModalComponent,
    EditSectionSimulationModalComponent,
    ConfirmDeleteSimulationComponent,
    ConfirmDeleteConfigComponent,
    ComputeResultsModalComponent,
    ComputeSectionResultsModalComponent,
    ConfirmLogoutModalComponent,
    ShareSimulationModalComponent,
    ErrorModalComponent,
    ButtonComponent,
    DownloadbuttonComponent,
    UploadbuttonComponent,
    LoginModalComponent,
    PublicVideosComponent,
    PdfsubscriberComponent,
    LaligaContactCauModalComponent,
    ConfirmEditMultiSeatComponent,
    ExportOnholdModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    BsDropdownModule.forRoot(),
    NgMultiSelectDropDownModule,

  ],
  exports: [
    FormatDatePipe,
    SearchPipe,
    ButtonComponent,
    DownloadbuttonComponent,
    UploadbuttonComponent,
  ],
  providers: [
  ]
})
export class SharedModule { }
