import { Injectable } from "@angular/core";
import { InventoryService } from "../../../../services/inventory.service";
import { AllocatorService } from "../allocator.service";
import { DataService } from "../../../../services/data.service";
import JSZip from "jszip";
import { SocialdistancingService } from "../../../../services/socialdistancing.service";
import { parseCSVToJSON } from "../../utils/parse";
import iconv from "iconv-lite";
import {
  MapViewer,
  SocialDistancingAssignGroup,
  SocialDistancingAssignGroupResult,
  SocialDistancingAssignOutput,
  SocialDistancingAssignToCurrentResult,
} from "@3ddv/dvm-internal";

export interface LaligaVenueDataRow {
  IDZONA: number; // int
  ZONA: string; // string(100)
  ZONA_ABREVIADO: string; // string(50)
  PUERTA: string; // string(50)
  IDASIENTO: number; // int
  POS_FILA: number; // int
  POS_ASIENTO: number; // int
  FILA: string; // string(50)
  ASIENTO: string; // string(50)
  SLOT: string | null; // string(50)
  ESTADO_AVET: TEstadoAvet; // string(1)
  PATH_DESC: string | null; // string(25)
  IDEVENT: number; // int
  IDGRUPOZONA: number | null; // int
  GRUPOZONA: string | null; // string(50)
}

type TReservas = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "F" | "G" | "H" | "I" | "J" | "K" | "R" | null;
type TEstadoAvet = TReservas | "L" | "O";
type TEstadoAsiento = TEstadoAvet | "B";

export interface LaligaSubscriberDataRow {
  IDPERSONA: number | null; // int
  DNI: string | null; // string(25)
  APELLIDO_1: string | null; // string(100)
  APELLIDO_2: string | null; // string(35)
  NOMBRE: string | null; // string(25)
  EMPRESA: string | null; // string(100)
  EMAIL: string | null; // string(100)
  IDZONA: number | null; // int
  IDASIENTO: number | null; // int
  IDZONA2: number | null; // int
  IDGRUPOZONA: number | null; // int
  GROUP_PERSON: string | null; // string(50)
  GROUP_ADMIN: 0 | 1 | null; // int
  PRIORIDAD: number | null; // int
  PRICE: number | null; // money
  IDEVENT: number; // int
}

export interface LaligaOutputRow {
  IDEVENT: number; // int
  IDASIENTO: number; // int
  ESTADO_ASIENTO: TEstadoAsiento; // string(1)
  IDPERSONA: number | null; // int
  DNI: string | null; // string(25)
  APELLIDO_1: string | null; // string(100)
  APELLIDO_2: string | null; // string(35)
  NOMBRE: string | null; // string(25)
  EMPRESA: string | null; // string(100)
  EMAIL: string | null; // string(100)
  GROUP_PERSON: string | null; // string(50)
  GROUP_ADMIN: 0 | 1 | null;
  SLOT: string | null; // string(50)
  PRICE: number | null; // money
  internalState: "available" | "reserved" | "surplus" | "blocked" | "unused" | "unknown";
  allocated: boolean;
}

export interface LaligaUnassignedOutputRow {
  IDEVENT: number; // int
  IDPERSONA: number | null; // int
  DNI: string | null; // string(25)
  APELLIDO_1: string | null; // string(100)
  APELLIDO_2: string | null; // string(35)
  NOMBRE: string | null; // string(25)
  EMPRESA: string | null; // string(100)
  EMAIL: string | null; // string(100)
  GROUP_PERSON: string | null; // string(50)
  GROUP_ADMIN: 0 | 1 | null;
  PRICE: number | null; // money
  allocated: boolean;
}

interface CUSTOM {
  abonados: LaligaSubscriberDataRow[];
  preferred: string[];
}

export class LaligaRepeatedError extends Error {
  constructor(
    message: string,
    public repeatedRows: LaligaSubscriberDataRow[],
    public file: Blob,
    public filename: string
  ) {
    super(message);
  }
}

@Injectable({
  providedIn: "root",
})
export class LaligaParserService {
  constructor(
    private inventoryService: InventoryService,
    private allocatorService: AllocatorService,
    private dataService: DataService,
    private socialdistancingService: SocialdistancingService
  ) {}

  public fullAssign(
    viewer: MapViewer,
    csvAbonados: string,
    csvAforo: string,
    socialDistancing: number,
    aisleSeats: number,
    outputPrefix: string,
    upgrade: boolean,
    downgrade: boolean,
    maps?: string[]
  ): Promise<SocialDistancingAssignOutput> {
    const { aforoByAsientoId, aforoBySeatId, zonasByLocalidad } = this.parseVenueData(viewer, csvAforo);
    const { groups, subscribersByUniqueId, repeatedRows } = this.parseSubscribersData(
      viewer,
      csvAbonados,
      zonasByLocalidad,
      upgrade,
      downgrade
    );

    if (repeatedRows.length > 0) {
      return Promise.reject(this.generateRepeatedError(repeatedRows, outputPrefix));
    }

    return this.allocatorService.assignGroups(viewer, { groups, maps }, socialDistancing, aisleSeats).then((result) => {
      const assignResult = new Set(result.groups);
      const unavailable = new Set(result.unavailable.killed_edges.concat(result.unavailable.security));
      const output = this.generateOutputCsvFromCurrent(
        aforoBySeatId,
        subscribersByUniqueId,
        assignResult,
        undefined,
        unavailable
      );
      const outputUnassigned = this.generateOutputUnassignedCsv(subscribersByUniqueId, new Set(result.unassigned));
      return this.endAssign(viewer, output, outputUnassigned, aforoByAsientoId, outputPrefix, result);
    });
  }

  private generateRepeatedError(repeatedRows: LaligaSubscriberDataRow[], outputPrefix: string): LaligaRepeatedError {
    const buffer = iconv.encode(this.stringifyRepeatedRows(repeatedRows), "ISO-8859-1");
    const blob = new Blob([buffer], { type: "text/csv" });
    const filename = `${outputPrefix}REPEATED.csv`;
    const message = `${repeatedRows.length} repeated subscribers have been found.`;
    return new LaligaRepeatedError(message, repeatedRows, blob, filename);
  }

  public fullAssignToCurrent(
    viewer: MapViewer,
    csvAbonados: string,
    csvAforo: string,
    outputPrefix: string,
    upgrade: boolean,
    downgrade: boolean,
    maps?: string[],
    maxAssignedClients?: number
  ): Promise<SocialDistancingAssignToCurrentResult> {
    const { aforoByAsientoId, aforoBySeatId, zonasByLocalidad } = this.parseVenueData(viewer, csvAforo);
    const { groups, subscribersByUniqueId, repeatedRows } = this.parseSubscribersData(
      viewer,
      csvAbonados,
      zonasByLocalidad,
      upgrade,
      downgrade
    );

    if (repeatedRows.length > 0) {
      return Promise.reject(this.generateRepeatedError(repeatedRows, outputPrefix));
    }

    if (groups.length > 0) {
      return this.allocatorService
        .assignGroupsToCurrent(viewer, { groups, maps }, maxAssignedClients)
        .then((result) => {
          const surplus: Set<string> = new Set(result.surplus);
          const resultAssigned = result.assigned;
          const resultUnassigned = result.unassigned;
          const output = this.generateOutputCsvFromCurrent(
            aforoBySeatId,
            subscribersByUniqueId,
            resultAssigned,
            surplus
          );
          const outputUnassigned = this.generateOutputUnassignedCsv(subscribersByUniqueId, resultUnassigned);
          return this.endAssign(viewer, output, outputUnassigned, aforoByAsientoId, outputPrefix, result);
        });
    } else {
      const output = this.generateOutputCsvFromCurrent(aforoBySeatId, subscribersByUniqueId, new Set());
      const outputUnassigned = this.generateOutputUnassignedCsv(subscribersByUniqueId, new Set());
      const fakeResult: SocialDistancingAssignToCurrentResult = {
        assigned: new Set(),
        unassigned: new Set(),
        surplus: [],
        available: [],
      };
      return this.endAssign(viewer, output, outputUnassigned, aforoByAsientoId, outputPrefix, fakeResult);
    }
  }

  private endAssign<T>(
    viewer: MapViewer,
    outputAssigned: LaligaOutputRow[],
    outputUnassigned: LaligaUnassignedOutputRow[],
    aforoByAsientoId: Map<number, LaligaVenueDataRow>,
    outputPrefix: string,
    result: T
  ): Promise<T> {
    const inventory = this.inventoryService.getInventoryData();
    this.allocatorService.clearInventoryAllocation(inventory);
    return this.fillInventoryAllocation(inventory, outputAssigned, aforoByAsientoId, true).then(() => {
      const zip = new JSZip();
      const buffer1 = iconv.encode(this.stringifyOutputCsv(outputAssigned), "ISO-8859-1");
      const buffer2 = iconv.encode(this.stringifyUnassignedOutputCsv(outputUnassigned), "ISO-8859-1");
      const blob1 = new Blob([buffer1], { type: "text/csv" });
      const blob2 = new Blob([buffer2], { type: "text/csv" });
      zip.file(`${outputPrefix}EXPORT_DATA.csv`, blob1);
      zip.file(`${outputPrefix}UNASSIGNED_DATA.csv`, blob2);

      return zip
        .generateAsync({ type: "blob", mimeType: "application/zip" })
        .then((zipFile) => {
          this.allocatorService.resultFile = zipFile;
          this.allocatorService.resultFileName = "result.zip";
          return this.dataService
            .sendAllocationResult(this.dataService.currentSimulationId, this.allocatorService.ref, zipFile)
            .toPromise();
        })
        .then(() => {
          const inv = this.inventoryService.getAvailableInventory(true);
          this.inventoryService.updateTotalsHash();
          viewer.setAvailability("section", inv.sections);
          viewer.setAvailability("seat", inv.seats);
          this.inventoryService.availabilityLoadedSubject.next();

          return result;
        });
    });
  }

  private stringifyOutputCsv(output: LaligaOutputRow[]): string {
    const str1 =
      "IDEVENT;IDASIENTO;ESTADO_ASIENTO;IDPERSONA;DNI;APELLIDO_1;APELLIDO_2;NOMBRE;EMPRESA;EMAIL;GROUP_PERSON;GROUP_ADMIN;SLOT;PRICE\n";
    const str2 = output
      .map((row) => {
        const str =
          (row.IDEVENT ?? "") +
          ";" +
          (row.IDASIENTO ?? "") +
          ";" +
          (row.ESTADO_ASIENTO ?? "") +
          ";" +
          (row.IDPERSONA ?? "") +
          ";" +
          (row.DNI ?? "") +
          ";" +
          (row.APELLIDO_1 ?? "") +
          ";" +
          (row.APELLIDO_2 ?? "") +
          ";" +
          (row.NOMBRE ?? "") +
          ";" +
          (row.EMPRESA ?? "") +
          ";" +
          (row.EMAIL ?? "") +
          ";" +
          (row.GROUP_PERSON ?? "") +
          ";" +
          (row.GROUP_ADMIN ?? "") +
          ";" +
          (row.SLOT ?? "") +
          ";" +
          (row.PRICE ?? "");
        return str;
      })
      .join("\n");
    return str1 + str2;
  }

  private stringifyRepeatedRows(repeated: LaligaSubscriberDataRow[]): string {
    const str1 =
      "IDPERSONA;DNI;APELLIDO_1;APELLIDO_2;NOMBRE;EMPRESA;EMAIL;IDZONA;IDASIENTO;IDZONA2;IDGRUPOZONA;GROUP_PERSON;GROUP_ADMIN;PRIORIDAD;PRICE;IDEVENT\n";
    const str2 = repeated
      .map((row) => {
        const str =
          (row.IDPERSONA ?? "") +
          ";" +
          (row.DNI ?? "") +
          ";" +
          (row.APELLIDO_1 ?? "") +
          ";" +
          (row.APELLIDO_2 ?? "") +
          ";" +
          (row.NOMBRE ?? "") +
          ";" +
          (row.EMPRESA ?? "") +
          ";" +
          (row.EMAIL ?? "") +
          ";" +
          (row.IDZONA ?? "") +
          ";" +
          (row.IDASIENTO ?? "") +
          ";" +
          (row.IDZONA2 ?? "") +
          ";" +
          (row.IDGRUPOZONA ?? "") +
          ";" +
          (row.GROUP_PERSON ?? "") +
          ";" +
          (row.GROUP_ADMIN ?? "") +
          ";" +
          (row.PRIORIDAD ?? "") +
          ";" +
          (row.PRICE ?? "") +
          ";" +
          (row.IDEVENT ?? "");
        return str;
      })
      .join("\n");
    return str1 + str2;
  }

  private stringifyUnassignedOutputCsv(output: LaligaUnassignedOutputRow[]): string {
    const str1 = "IDEVENT;IDPERSONA;DNI;APELLIDO_1;APELLIDO_2;NOMBRE;EMPRESA;EMAIL;GROUP_PERSON;GROUP_ADMIN;PRICE\n";
    const str2 = output
      .map((row) => {
        const str =
          (row.IDEVENT ?? "") +
          ";" +
          (row.IDPERSONA ?? "") +
          ";" +
          (row.DNI ?? "") +
          ";" +
          (row.APELLIDO_1 ?? "") +
          ";" +
          (row.APELLIDO_2 ?? "") +
          ";" +
          (row.NOMBRE ?? "") +
          ";" +
          (row.EMPRESA ?? "") +
          ";" +
          (row.EMAIL ?? "") +
          ";" +
          (row.GROUP_PERSON ?? "") +
          ";" +
          (row.GROUP_ADMIN ?? "") +
          ";" +
          (row.PRICE ?? "");
        return str;
      })
      .join("\n");
    return str1 + str2;
  }

  private generateOutputUnassignedCsv(
    subscribersByUniqueId: Map<string, LaligaSubscriberDataRow>,
    resultUnassigned: Set<SocialDistancingAssignGroupResult>
  ): LaligaUnassignedOutputRow[] {
    const output: LaligaUnassignedOutputRow[] = [];
    for (const group of resultUnassigned) {
      const { members } = group;
      for (const uniqueId of members) {
        const subscriberRow = subscribersByUniqueId.get(uniqueId);
        if (subscriberRow) {
          const outputRow: LaligaUnassignedOutputRow = {
            IDEVENT: subscriberRow.IDEVENT,
            IDPERSONA: subscriberRow.IDPERSONA,
            DNI: subscriberRow.DNI,
            APELLIDO_1: subscriberRow.APELLIDO_1,
            APELLIDO_2: subscriberRow.APELLIDO_2,
            NOMBRE: subscriberRow.NOMBRE,
            EMPRESA: subscriberRow.EMPRESA,
            EMAIL: subscriberRow.EMAIL,
            GROUP_PERSON: subscriberRow.GROUP_PERSON,
            GROUP_ADMIN: subscriberRow.GROUP_ADMIN,
            PRICE: subscriberRow.PRICE,
            allocated: false,
          };
          output.push(outputRow);
        }
      }
    }
    return output;
  }

  private generateOutputCsvFromCurrent(
    aforoBySeatId: Map<string, LaligaVenueDataRow>,
    subscribersByUniqueId: Map<string, LaligaSubscriberDataRow>,
    resultAssigned: Set<SocialDistancingAssignGroupResult>,
    resultSurplus?: Set<string>,
    unavailable?: Set<string>
  ): LaligaOutputRow[] {
    const inventory = this.inventoryService.getInventoryData();

    const output: LaligaOutputRow[] = [];
    const rowsByAsientoId: Map<number, LaligaOutputRow> = new Map();

    const missingVenueRows: string[] = [];
    const missingSubscriberRows: string[] = [];

    for (const group of resultAssigned) {
      const { members, assigned } = group;
      if (members.length === assigned.length) {
        for (let i = 0; i < members.length; ++i) {
          const uniqueId = members[i];
          const seatId = assigned[i];
          const venueRow = aforoBySeatId.get(seatId);
          const subscriberRow = subscribersByUniqueId.get(uniqueId);

          if (!venueRow) {
            // console.warn(`Missing venue data row`);
            missingVenueRows.push(seatId);
          }

          if (!subscriberRow) {
            // console.warn(`Missing subscriber row (fake ids!)`);
            missingSubscriberRows.push(uniqueId);
          }

          if (venueRow && subscriberRow) {
            const outputRow: LaligaOutputRow = {
              IDEVENT: venueRow.IDEVENT,
              IDASIENTO: venueRow.IDASIENTO,
              ESTADO_ASIENTO: "O",
              IDPERSONA: subscriberRow.IDPERSONA,
              DNI: subscriberRow.DNI,
              APELLIDO_1: subscriberRow.APELLIDO_1,
              APELLIDO_2: subscriberRow.APELLIDO_2,
              NOMBRE: subscriberRow.NOMBRE,
              EMPRESA: subscriberRow.EMPRESA,
              EMAIL: subscriberRow.EMAIL,
              GROUP_PERSON: subscriberRow.GROUP_PERSON,
              GROUP_ADMIN: subscriberRow.GROUP_ADMIN,
              SLOT: venueRow.SLOT,
              PRICE: subscriberRow.PRICE,
              internalState: "reserved",
              allocated: true,
            };
            rowsByAsientoId.set(venueRow.IDASIENTO, outputRow);
          }
        }
      }
    }

    if (missingVenueRows.length) {
      console.warn(`Missing venue data row:\n${missingVenueRows.join(", ")}`);
      console.warn(`Total: ${missingVenueRows.length}\n`);
    }

    if (missingSubscriberRows.length) {
      console.warn(`Missing subscriber row (fake ids!):\n${missingSubscriberRows.join(", ")}`);
      console.warn(`Total: ${missingSubscriberRows.length}\n`);
    }

    for (const [seatId, venueRow] of aforoBySeatId) {
      const { IDASIENTO } = venueRow;
      let outputRow: LaligaOutputRow | null = rowsByAsientoId.get(IDASIENTO);
      if (outputRow == null) {
        const _sectionId = venueRow.IDZONA.toString();
        const _rowId = cleanId(venueRow.FILA);
        const _seatId = cleanId(venueRow.ASIENTO);
        const data = inventory[_sectionId]?.elements[_rowId]?.elements[_seatId]?.data;
        outputRow = {
          IDEVENT: venueRow.IDEVENT,
          IDASIENTO: venueRow.IDASIENTO,
          ESTADO_ASIENTO: data?.status === "onhold" ? "O" : "B", // venueRow.ESTADO_AVET,
          IDPERSONA: null, // abonadoRow?.IDPERSONA ?? null, // TODO: debería ser null? (desasignar asiento original)
          DNI: null,
          APELLIDO_1: null, // abonadoRow?.APELLIDO_1 ?? null, // TODO: debería ser null? (desasignar asiento original)
          APELLIDO_2: null, // abonadoRow?.APELLIDO_2 ?? null, // TODO: debería ser null? (desasignar asiento original)
          NOMBRE: null, // abonadoRow?.NOMBRE ?? null, // TODO: debería ser null? (desasignar asiento original)
          EMPRESA: null,
          EMAIL: null,
          GROUP_PERSON: null, // abonadoRow?.GROUP_PERSON ?? null, // TODO: debería ser null? (desasignar asiento original)
          GROUP_ADMIN: null,
          SLOT: venueRow.SLOT,
          PRICE: null, // abonadoRow?.PRICE ?? null, // TODO: debería ser null? (desasignar asiento original)
          internalState: "unused",
          allocated: false,
        };

        if (resultSurplus) {
          if (resultSurplus.has(seatId)) {
            outputRow.ESTADO_ASIENTO = "L";
            // outputRow.ESTADO_ASIENTO = 'B';
            outputRow.internalState = "surplus";
          }
        }

        if (unavailable) {
          if (unavailable.has(seatId)) {
            outputRow.ESTADO_ASIENTO = "B";
            outputRow.internalState = "blocked";
          } else {
            // arr.push(seatId);
            // outputRow.ESTADO_ASIENTO = 'B';
            // outputRow.internalState = 'unknown';
          }
        }
      }

      output.push(outputRow);
    }

    return output;
  }

  private fillInventoryAllocation(
    inventory,
    result: LaligaOutputRow[],
    aforoByAsientoId: Map<number, LaligaVenueDataRow>,
    assignToCurrent: boolean
  ) {
    for (const resultRow of result) {
      const { IDASIENTO, IDPERSONA, GROUP_PERSON, EMPRESA } = resultRow;

      const aforoRow = aforoByAsientoId.get(IDASIENTO);
      if (aforoRow) {
        const { IDZONA, FILA, ASIENTO } = aforoRow;
        const sectionId = IDZONA.toString();
        const rowId = cleanId(FILA);
        const seatId = cleanId(ASIENTO);
        if (sectionId && rowId && seatId) {
          const data = inventory[sectionId]?.elements[rowId]?.elements[seatId]?.data;
          if (data) {
            if (data.category === "locked" && resultRow.IDPERSONA != null) {
              console.warn(`locked seat has been assigned: ${data.code}`);
            }

            if (data.allocation) {
              console.warn("ALREADY ASSIGNED");
            }
            data.allocation = Object.assign({}, resultRow);

            // TODO: Revisar si se quiere esto
            if (assignToCurrent) {
              if (data.status === "available" && resultRow.internalState === "unused") {
                resultRow.ESTADO_ASIENTO = "L";
              }
            }

            // preserve status 'onhold'
            if (data.status !== "onhold") {
              data.status =
                resultRow.ESTADO_ASIENTO === "B" || data.category === "locked" ? "unavailable" : "available";
            }

            data.price = resultRow.PRICE?.toFixed(2) ?? "1"; // TODO: asegurarse de que hay que setear precio

            if (IDPERSONA) {
              addPopupProperty(data.allocation, "Client ID", IDPERSONA);
            }

            /*if (EMPRESA) {
              addPopupProperty(data.allocation, 'Company', EMPRESA);
            }*/

            if (GROUP_PERSON) {
              addPopupProperty(data.allocation, "Group ID", GROUP_PERSON);
            }
          } else {
            console.warn(`seatId do not match with inventory: ${sectionId} - ${rowId} - ${seatId}`);
          }
        }
      }
    }

    function addPopupProperty(allocObj, property, value) {
      if (!allocObj.popup) {
        allocObj.popup = {};
      }
      allocObj.popup[property] = value;
    }

    return this.allocatorService.setSimulationAsAllocation(inventory);
  }

  private _getPreferredZones(
    zonasByLocalidad: Map<number, Set<number>>,
    preferred: Set<string>,
    IDGRUPOZONA: number,
    upgrade: boolean,
    downgrade: boolean
  ): Set<string> {
    addZone(IDGRUPOZONA);

    if (upgrade || downgrade) {
      const ids = Array.from(zonasByLocalidad.keys()).sort((a, b) => b - a);

      if (upgrade) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < ids.length; ++i) {
          const id = ids[i];
          if (id > IDGRUPOZONA) {
            addZone(id);
          }
        }
      }

      if (downgrade) {
        for (let i = ids.length - 1; i >= 0; --i) {
          const id = ids[i];
          if (id < IDGRUPOZONA) {
            addZone(id);
          }
        }
      }
    }

    return preferred;

    function addZone(id) {
      const zonas = zonasByLocalidad.get(id);
      if (zonas) {
        zonas.forEach((zona) => preferred.add(`S_${zona}`));
      }
    }
  }

  private parseSubscribersData(
    viewer: MapViewer,
    csvAbonados: string,
    zonasByLocalidad: Map<number, Set<number>>,
    upgrade: boolean,
    downgrade: boolean
  ) {
    let UNIQUEID = 1;
    let subscriberRows;
    try {
      subscriberRows = parseCSVToJSON<LaligaSubscriberDataRow>(
        csvAbonados,
        ";",
        mapSubscriberData,
        isSubscriberValidKey,
        areSubscriberValidKeys
      );
    } catch (err) {
      // throw new Error('Invalid subscribers file: there are invalid or missing keys.');
      throw err;
    }
    // const subscriberRows = csvSubscribersGenerator(viewer, 10000, { 1: 1, 2: 1, 3: 1, 4: 1 });

    const abonadosByZone: Map<number, Set<LaligaSubscriberDataRow>> = new Map();
    const abonadosByPersonId: Map<number, LaligaSubscriberDataRow> = new Map();
    const abonadosByAsientoId: Map<number, LaligaSubscriberDataRow> = new Map();
    const groupsMap: Map<string, SocialDistancingAssignGroup> = new Map();
    const subscribersByUniqueId: Map<string, LaligaSubscriberDataRow> = new Map();
    const repeatedRows: LaligaSubscriberDataRow[] = [];
    for (const row of subscriberRows) {
      const { GROUP_ADMIN, GROUP_PERSON, IDPERSONA, PRIORIDAD, IDZONA, IDZONA2, IDGRUPOZONA, IDASIENTO, EMPRESA } = row;

      if (IDASIENTO) {
        abonadosByAsientoId.set(IDASIENTO, row);
      }

      let ok = false;
      if (IDPERSONA) {
        if (abonadosByPersonId.has(IDPERSONA)) {
          repeatedRows.push(row);
        } else {
          ok = true;
          abonadosByPersonId.set(IDPERSONA, row);
        }
      } else if (EMPRESA) {
        ok = true;
      }

      if (ok) {
        const uniqueId = `${UNIQUEID++}`;
        subscribersByUniqueId.set(uniqueId, row);

        const preferred: Set<string> = new Set();
        if (IDZONA) {
          preferred.add(`S_${IDZONA}`);
        }
        if (IDZONA2) {
          preferred.add(`S_${IDZONA2}`);
        }
        if (IDGRUPOZONA) {
          this._getPreferredZones(zonasByLocalidad, preferred, IDGRUPOZONA, upgrade, downgrade);
        }
        if (!GROUP_PERSON) {
          // NO GROUP
          const groupId = `nogroup_${uniqueId}_`;
          const group: SocialDistancingAssignGroup = {
            group_id: groupId,
            members: [uniqueId],
            priority: PRIORIDAD ?? Infinity,
            preferred: Array.from(preferred),
          };
          groupsMap.set(groupId, group);
        } else {
          // TODO: GROUP
          let group = groupsMap.get(GROUP_PERSON);
          if (group) {
            group.members.push(uniqueId);
          } else {
            group = {
              group_id: GROUP_PERSON,
              members: [uniqueId],
              priority: PRIORIDAD,
              preferred: Array.from(preferred),
            };
            groupsMap.set(GROUP_PERSON, group);
          }
        }
      } else {
        console.warn("Problem with CSV row");
      }
    }

    const groups = Array.from(groupsMap.values());

    return { subscriberRows, groups, subscribersByUniqueId, repeatedRows };
  }

  private parseVenueData(viewer: MapViewer, csvAforo) {
    let aforoRows;
    try {
      aforoRows = parseCSVToJSON<LaligaVenueDataRow>(csvAforo, ";", mapVenueData, isAforoValidKey, areVenueValidKeys);
    } catch (err) {
      // throw new Error('Invalid venue data file: there are invalid or missing keys.');
      throw err;
    }
    // const aforoRows = csvVenueGenerator(viewer);
    // console.log(aforoRows);

    const aforoByZone: Map<number, Set<LaligaVenueDataRow>> = new Map();
    const aforoBySlot: Map<string, Set<LaligaVenueDataRow>> = new Map();
    const aforoBySeatId: Map<string, LaligaVenueDataRow> = new Map();
    const aforoByAsientoId: Map<number, LaligaVenueDataRow> = new Map();
    const aforoBySection: Map<string, Set<LaligaVenueDataRow>> = new Map();
    const zonasByLocalidad: Map<number, Set<number>> = new Map();

    const missingInventory: string[] = [];
    for (const row of aforoRows) {
      const { IDGRUPOZONA, SLOT, IDZONA, FILA, ASIENTO, IDASIENTO } = row;

      const sectionId = IDZONA.toString();
      const rowId = cleanId(FILA);
      const seatId = cleanId(ASIENTO);
      const fullSectionId = `S_${sectionId}`;

      if (IDGRUPOZONA) {
        let set = zonasByLocalidad.get(IDGRUPOZONA);
        if (!set) {
          set = new Set<number>();
          zonasByLocalidad.set(IDGRUPOZONA, set);
        }
        set.add(IDZONA);
      }

      aforoByAsientoId.set(IDASIENTO, row);

      if (IDZONA && FILA && ASIENTO) {
        const fullSeatId = `${fullSectionId}-${rowId}-${seatId}`;
        aforoBySeatId.set(fullSeatId, row);
      } else {
        console.warn("Missing IDZONA or FILA or ASIENTO");
      }

      const inventory = this.inventoryService.getInventoryData();
      const data = inventory[sectionId]?.elements[rowId]?.elements[seatId]?.data;

      if (!data) {
        missingInventory.push(`S_${sectionId}-${rowId}-${seatId}`);
      }

      if (SLOT) {
        let set = aforoBySlot.get(SLOT);
        if (!set) {
          set = new Set();
          aforoBySlot.set(SLOT, set);
        }
        set.add(row);
      }

      {
        let set = aforoBySection.get(fullSectionId);
        if (!set) {
          set = new Set();
          aforoBySection.set(fullSectionId, set);
        }
        set.add(row);
      }

      {
        let set = aforoByZone.get(IDZONA);
        if (!set) {
          set = new Set();
          aforoByZone.set(IDZONA, set);
        }
        set.add(row);
      }
    }

    if (missingInventory.length) {
      console.warn(`INVENTORY AND VENUE DATA MISSMATCH:\n${missingInventory.join(", ")}`);
    }
    return { aforoRows, aforoBySeatId, aforoByAsientoId, zonasByLocalidad };
  }
}

export function cleanId(id: string): string {
  for (let i = 0; i < id.length; ++i) {
    const char = id[i];
    if (char !== "0") {
      return id.slice(i);
    }
  }
  return "0";
}

// ----- VALIDATION HELPERS -----

function mapVenueData(key: keyof LaligaVenueDataRow, value: string): any {
  value = value.trim();
  switch (key) {
    case "IDZONA":
    case "IDASIENTO":
    case "POS_FILA":
    case "POS_ASIENTO":
    case "IDEVENT": {
      if (!value) {
        throw new Error(`Invalid venue data csv: value of '${key}' cannot be null (value found: '${value}').`);
      }
      const result = parseInt(value, 10);
      if (isNaN(result)) {
        throw new Error(`Invalid venue data csv: value of '${key}' must be a number (value found: '${value}').`);
      }
      return result;
    }
    case "ZONA":
    case "ZONA_ABREVIADO":
    case "PUERTA":
    case "FILA":
    case "ASIENTO":
      if (!value) {
        throw new Error(`Invalid venue data csv: value of '${key}' cannot be null (value found: '${value}').`);
      }
      return value;

    case "SLOT":
    case "PATH_DESC":
    case "GRUPOZONA":
      return valueIsNull(value) ? null : value;
    case "IDGRUPOZONA": {
      if (valueIsNull(value)) {
        return null;
      } else {
        const result = parseInt(value, 10);
        if (isNaN(result)) {
          throw new Error(`Invalid venue data csv: value of '${key}' must be a number ( value found: '${value}').`);
        }
        return result;
      }
    }

    case "ESTADO_AVET":
      if (!validateEstadoAvet(value)) {
        throw new Error(`Invalid venue data csv: value of '${key}' is not valid (value found: '${value}').`);
      }
  }
  return value;
}
const subscriberValidKeys = new Set([
  "IDPERSONA",
  "DNI",
  "APELLIDO_1",
  "APELLIDO_2",
  "NOMBRE",
  "EMPRESA",
  "EMAIL",
  "IDZONA",
  "IDASIENTO",
  "IDZONA2",
  "IDGRUPOZONA",
  "GROUP_PERSON",
  "GROUP_ADMIN",
  "PRIORIDAD",
  "PRICE",
  "IDEVENT",
]);
function isSubscriberValidKey(key: string): boolean {
  const result = subscriberValidKeys.has(key);
  if (!result) {
    const errmsg = `Invalid subscribers file: '${key}' is not a valid key.`;
    console.warn(errmsg);
    throw new Error(errmsg);
  }
  return result;
}

function areSubscriberValidKeys(keys: string[]): boolean {
  const set = new Set(keys);
  let result = true;
  for (const key of subscriberValidKeys) {
    if (!set.has(key)) {
      result = false;
      const errmsg = `Invalid subscribers file: key '${key}' is missing.`;
      console.warn(errmsg);
      throw new Error(errmsg);
    }
  }
  return result;
}

const venueValidKeys = new Set([
  "IDZONA",
  "ZONA",
  "ZONA_ABREVIADO",
  "PUERTA",
  "IDASIENTO",
  "POS_FILA",
  "POS_ASIENTO",
  "FILA",
  "ASIENTO",
  "SLOT",
  "ESTADO_AVET",
  "PATH_DESC",
  "IDEVENT",
  "IDGRUPOZONA",
  "GRUPOZONA",
]);
function isAforoValidKey(key: string): boolean {
  const result = venueValidKeys.has(key);
  if (!result) {
    const errmsg = `Invalid venue data file: '${key}' is not a valid key.`;
    console.warn(errmsg);
    throw new Error(errmsg);
  }
  return result;
}

function areVenueValidKeys(keys: string[]): boolean {
  const set = new Set(keys);
  let result = true;
  for (const key of venueValidKeys) {
    if (!set.has(key)) {
      result = false;
      const errmsg = `Invalid venue data file: key '${key}' is missing.`;
      console.warn(errmsg);
      throw new Error(errmsg);
    }
  }
  return result;
}

function mapSubscriberData(key: keyof LaligaSubscriberDataRow, value: string): any {
  value = value.trim();
  switch (key) {
    // case 'DNI':
    // case 'APELLIDO_1':
    // case 'APELLIDO_2':
    // case 'NOMBRE':
    //   if (!value || valueIsNull(value)) {
    //     throw new Error(`Invalid subscribers csv: value of '${key}' cannot be null (value found: '${value}').`);
    //   }
    //   return value;

    // case 'IDPERSONA':
    case "IDEVENT": {
      if (!value) {
        throw new Error(`Invalid venue data csv: value of '${key}' cannot be null (value found: '${value}').`);
      }
      const result = parseInt(value, 10);
      if (isNaN(result)) {
        throw new Error(`Invalid subscribers csv: value of '${key}' must be a number (value found: '${value}').`);
      }
      return result;
    }

    case "IDPERSONA":
    case "IDASIENTO":
    case "IDZONA":
    case "IDZONA2":
    case "GROUP_ADMIN":
    case "PRIORIDAD":
    case "IDGRUPOZONA": {
      if (valueIsNull(value)) {
        return null;
      } else {
        const result = parseInt(value, 10);
        if (isNaN(result)) {
          throw new Error(`Invalid subscribers csv: value of '${key}' must be a number (value found: '${value}').`);
        }
        return result;
      }
    }

    case "DNI":
    case "APELLIDO_1":
    case "APELLIDO_2":
    case "NOMBRE":
    case "EMPRESA":
    case "EMAIL":
    case "GROUP_PERSON":
      return valueIsNull(value) ? null : value;

    case "PRICE": {
      if (valueIsNull(value)) {
        return null;
      } else {
        const result = parseFloat(value);
        if (isNaN(result)) {
          throw new Error(`Invalid subscribers csv: value of '${key}' must be a number (value found: '${value}').`);
        }
        return result;
      }
    }
  }
  return value;
}

const ReservasIds: Set<TReservas> = new Set([
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "R",
]);
const EstadoAvetIds: Set<TEstadoAvet> = new Set(["L", "O"]);
const EstadoAsientoIds: Set<TEstadoAsiento> = new Set(["B"]);

function validateReservas(input: any): boolean {
  return ReservasIds.has(input);
}
function validateEstadoAvet(input: any): boolean {
  return EstadoAvetIds.has(input) || validateReservas(input);
}
function ValidateEstadoAsiento(input: any): boolean {
  return EstadoAsientoIds.has(input) || validateEstadoAvet(input) || validateReservas(input);
}

function valueIsNull(v: string) {
  if (v == null) {
    throw new Error("Value should be a string");
  }

  return v === "NULL" || v === "";
}

function csvGenerator(viewer: MapViewer, maxGroups: number, groupsProbability: { [key: number]: number }) {
  const subscribers = csvSubscribersGenerator(viewer, maxGroups, groupsProbability);
  const venue = csvVenueGenerator(viewer);

  return { subscribers, venue };
}

function csvVenueGenerator(viewer: MapViewer) {
  const seats = viewer.getNodesByType("seat");

  const rows: LaligaVenueDataRow[] = [];
  let idasiento = 1;
  for (const seat of seats) {
    const split = seat.id.split("S_")[1].split("-");
    const IDZONA = split[0];
    const FILA = split[1];
    const ASIENTO = split[2];

    const row: LaligaVenueDataRow = {
      IDZONA: parseInt(IDZONA, 10),
      ZONA: IDZONA,
      ZONA_ABREVIADO: IDZONA,
      PUERTA: "A",
      IDASIENTO: idasiento++,
      POS_FILA: 1, // TODO
      POS_ASIENTO: seat.row.indexOf(seat.id) + 1,
      FILA,
      ASIENTO,
      SLOT: null,
      ESTADO_AVET: "L",
      PATH_DESC: null,
      IDEVENT: 1,
      IDGRUPOZONA: null,
      GRUPOZONA: null,
    };

    rows.push(row);
  }

  return rows;
}

function csvSubscribersGenerator(viewer: MapViewer, maxGroups: number, groupsProbability: { [key: number]: number }) {
  const entries = Object.entries(groupsProbability);
  // const sum = Object.values(porcentajes).reduce((acc, curr) => acc + curr);

  const zones = viewer.getNodesByType("section").map((n) => parseInt(n.id.split("S_")[1], 10));
  let nextGroup = 1;
  let nextClient = 10000;
  let nextPriority = 1000;
  const groups: LaligaSubscriberDataRow[] = [];
  for (let i = 0; i < maxGroups; ++i) {
    const groupId = nextGroup++;
    const numGroup = getRandomGroup(entries);
    const priority = nextPriority++;
    for (let j = 0; j < numGroup; ++j) {
      const clientId = nextClient++;
      const row: LaligaSubscriberDataRow = {
        IDPERSONA: clientId,
        DNI: rand(10000000, 99999999) + "A",
        APELLIDO_1: "A",
        APELLIDO_2: "B",
        NOMBRE: "C",
        EMPRESA: null,
        EMAIL: null,
        IDZONA: zones[rand(0, zones.length - 1)],
        IDASIENTO: null,
        IDZONA2: null,
        IDGRUPOZONA: 1,
        GROUP_PERSON: numGroup > 1 ? `${groupId}` : null,
        GROUP_ADMIN: numGroup > 1 && j === 0 ? 1 : 0,
        PRIORIDAD: priority,
        PRICE: 1,
        IDEVENT: 1,
      };
      groups.push(row);
    }
  }

  return groups;
}

function getRandomGroup(probabilities: [string, number][]): number {
  const probs: number[] = [];
  let total = 0;

  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < probabilities.length; ++i) {
    const value = probabilities[i][1];
    probs.push(total + value);
    total += value;
  }

  const r = Math.random() * total;

  for (let i = 0; i < probs.length; ++i) {
    if (r < probs[i]) {
      return parseInt(probabilities[i][0], 10);
    }
  }

  return -1;
}

function rand(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
