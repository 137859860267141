<div>
    <div class='modal-header'>
        <h3>More info</h3>
        <button type="button" class="btn-modal-header" (click)="onDismiss()" data-dismiss="modal">
            <span>&times;</span>
        </button>
    </div>
    
    <div class='modal-content'>
        <div class='form-wrapper'>
            <h2><b>Want to know more?</b></h2>
            <p>Complete the form to receive a PDF with further information</p>
            <form>
                <div>
                    <div class='d-item'>
                        <label><b>FULL NAME</b></label>
                        <input type='text' name='fullname'[(ngModel)]='pdfsubscriber.fullname'>
                    </div>
                    <div class='d-item'>
                        <label><b>EMAIL</b></label>
                        <input type='text' name='email' [(ngModel)]='pdfsubscriber.email'>
                    </div>
                    <div  class='d-item checkbox-item'>
                        <input type='checkbox' id='lopd' name='lopd' [(ngModel)]='pdfsubscriber.lopd'>
                        <label id='lopd-label' for='lopd'>
                        <a href='/legal' title='Privacy Policy LOPD' class='color-gray' target="_blank">
                            I have read, understand and accept the Terms of Use and the Information on Data Protection.
                            </a>
                        </label>
                    </div>
                    <div class="d-item alert alert-info text-center" *ngIf="loading" cleanError role="alert">
                        {{loading}}
                    </div>
                    <div class="d-item alert alert-danger" *ngIf="errors" role="alert">
                        {{errors}}
                    </div>
                    <div class='modal-footer'>
                            <button class='btn' type='button' (click)='onDismiss()'>{{'login.cancel' | translate}}</button>
                            <button class='btn-reversed' type="submit" (click)='onSubmit()'>Receive info</button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>