<div class='container'>
<h2 id="faqs">Frequently Asked Questions</h2>
<h3 id="faqs1">SOCIAL DISTANCE EDITOR</h3>
<h4>What is the social distance editor?</h4>
<p>The Social Distance Editor is a tool designed to receive fans safely back at the stadiums. To do this, the client enters the social distance parameters into the system and the tool shows the most efficient layout on the map.
    <span (click)='openVideo("https://www.youtube.com/embed/Ffe71p6rLco","What is the Social Distance Editor")'>See video</span>
</p>
<h4>Can I use the SOCIAL DISTANCE EDITOR for non-sports venues such as arenas, or theaters, or opera houses?</h4>
<p>The SOCIAL DISTANCE EDITOR can be used in any type of venue as long as it has chairs on it. </p>
<h4>What’s the cost of using this tool?</h4>
<p>The cost of the tool is variable. This can depend on the type of 2D or 2D Pro map, or on the capacity of the stadium. If you want more information, send us a mail here  <a href="">(enquiries@3dditigalvenue.com)</a></p>
<h4>What type of technical information do we require to build a map for the SOCIAL DISTANCE EDITOR?</h4>
<p>The 3DDV team uses architectural information to build the map taking into account the different types of seats and the slope differences to obtain simulations with the highest accuracy. Click HERE to see more information about technical materials. (PDF DANI)</p>
<h3 id="faqs2">PERFORMING SIMULATIONS</h3>
<h4>How can I adapt my social distance simulations if I have different configurations on my venue?</h4>
<p>The SOCIAL DISTANCE EDITOR allows to hold an unlimited number of configurations to perfectly match every kind of event. Also, you can perform as many simulations as you want with each configuration. </p>
<h4>Can I change the social distance chosen once i’ve done a simulation</h4>
<p>Can I change the social distance chosen once i’ve done a simulation Yes, on your dashboard you will see all your configurations and edit them at any time you want. </p>
<h4>Can I edit the layout that the algorithm calculates?</h4>
<p>Yes. You are provided with the option to move, add or remove anytype of groups or singles. If the Social Distance you’ve determined is broken by any of these moves, you will be warned in orange. (see video here) </p>
<h4>Are the simulations for the whole venue? </h4>
<p>By default, the simulations are performed throughout the room, except for the seats that are labeled as locked. However, individual simulations can be performed in one or more sections with the most suitable parameters. </p>
<h3 id="faqs3">TECHNICAL ISSUES</h3>
<h4>Why am I having different spaces between groups depending on the area of the venue?</h4>
<p>Normally there are different separations between rows and seats along the venue. This is due to several reasons such as different types or size of seats, the distance between them and slope differences. </p>
<h4>From which point of the seat is the system calculating the distance?</h4> From which point of the seat is the system calculating the distance?
<h4>How can I share the layout I want with my ticketing platform?</h4> Once you have performed your simulation, you will be able to download the seat manifest
<h4>Can I know the new revenue that I will obtain with my new capacity and social distance?</h4> Yes, to do so, you must assign prices to the different sections (or on a row or seat level) and once the simulation is performed, you will see your new revenue and capacity.
</div>