import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './social-distance-editor/landing/landing.component';
//import { LoginComponent } from './login/login.component';
import { VenueSetupComponent } from './social-distance-editor/venue-setup/venue-setup.component';
import { AuthGuard } from './auth';
import { HelpComponent } from './help/help.component';
import { SimulationComponent } from './social-distance-editor/simulation/simulation.component';
import { ShareSimulationComponent } from './social-distance-editor/share-simulation/share-simulation.component';
import { AllocationComponent } from './social-distance-editor/plugins/allocator/components/allocation/allocation.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { HomeComponent } from './home/home.component';
import { LegalComponent } from './legal/legal.component';

const routes: Routes = [

  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
    component: HomeComponent
  },

  {
    path: '',
    // canActivate: [AuthGuard],
    children: [
      {
        path: 'workspace',
        loadChildren: () => import('./workspace/workspace.module').then(m => m.WorkspaceModule),
      },
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () => import('./social-distance-editor/socialdistanceeditor.module').then(m => m.SocialdistanceeditorModule),
        //component: LandingComponent
      },
/*       {
        path: 'help',
        component: HelpComponent
      },
      {
        path: 'setup',
        loadChildren: () => import('./social-distance-editor/socialdistanceeditor.module').then(m => m.SocialdistanceeditorModule),
        redirectTo: ''
      },
      {
        path: 'setup/:id',
        loadChildren: () => import('./social-distance-editor/socialdistanceeditor.module').then(m => m.SocialdistanceeditorModule),
        component: VenueSetupComponent
      },
      {
        path: 'simulation',
        loadChildren: () => import('./social-distance-editor/socialdistanceeditor.module').then(m => m.SocialdistanceeditorModule),
        redirectTo: ''
      },
      {
        path: 'simulation/:id',
        loadChildren: () => import('./social-distance-editor/socialdistanceeditor.module').then(m => m.SocialdistanceeditorModule),
        component: SimulationComponent
      },
      {
        path: 'allocation/:id',
        loadChildren: () => import('./social-distance-editor/socialdistanceeditor.module').then(m => m.SocialdistanceeditorModule),
        component: AllocationComponent
      }, */
      
    ]
  },
  {
    path: 'login',
    redirectTo: ''
  },
  {
    path: 'laliga',
    component: HomeComponent
  },
  {
    path: 'documentation',
    component: DocumentationComponent
  },
  {
    path: 'documentation/:client',
    component: DocumentationComponent
  },
  {
    path: 'legal',
    component: LegalComponent
  },
  {
    path: 'share-simulation/:key',
    component: ShareSimulationComponent
  },
  {
    path: 'share-simulation',
    redirectTo: ''
  },
  {
    path: 'share-simulation/:key',
    component: ShareSimulationComponent
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
