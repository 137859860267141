import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ButtonComponent} from '../button/button.component';

@Component({
  selector: 'app-downloadbutton',
  templateUrl: './downloadbutton.component.html',
  styleUrls: ['../button/button.component.scss', './downloadbutton.component.scss']
})
export class DownloadbuttonComponent extends ButtonComponent implements OnInit, AfterViewInit {

    private file: Blob | null = null;
    private filename: string | null = null;
    constructor() {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.observableClick.subscribe((event) => {
            this.triggerDownload();
        });
    }

    reset(): void {
        super.reset();
        this.clearFile();
    }

    hasFile(): boolean {
        return this.file != null && this.filename != null;
    }

    setFile(file: Blob, filename: string): void {
        this.file = file;
        this.filename = filename;
    }

    clearFile(): void {
        this.file = null;
        this.filename = null;
        this.disabled = true;
    }

    triggerDownload(): void {
        if (this.file && this.filename) {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(this.file);
            a.download = this.filename;
            a.click();
        }
    }
}
