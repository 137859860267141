import { Injectable } from '@angular/core';
import { DVMService } from './dvm.service';
import { DataService } from './data.service';
import { ActivatedRoute } from '@angular/router';
import {Observable, Subject} from 'rxjs';

interface GroupBehaviors {
    [key: string]: string[];
}

const defaultBehaviors: GroupBehaviors = {
    validated: ['onhold'],
    invalidated: ['locked']
};

@Injectable({
    providedIn: 'root'
})
export class GroupsService {

    groupsHash = {
        groups: {},
        nodes: {},
        lastGroup: 0
    };

    public get resetGroupBehaviors$(): Observable<void> {
        return this.resetGroupBehaviorsSubject$.asObservable();
    }

    private resetGroupBehaviorsSubject$ = new Subject<void>();

    private groupBehaviors: GroupBehaviors = {
        validated: ['onhold'],
        invalidated: ['locked']
    };

    constructor(private dataService: DataService,
                private dvmService: DVMService,
                private activatedRoute: ActivatedRoute ) {
        this.dataService.getCurrentConfiguration$().subscribe(() => {
            this.resetGroupBehaviors();
        });

        this.dataService.getCurrentSimulation$().subscribe(() => {
            this.resetGroupBehaviors();
        });
    }

    resetGroupBehaviors(): void {
        this.groupBehaviors = {};
        for (const i in defaultBehaviors) {
            if (defaultBehaviors.hasOwnProperty(i)) {
                this.groupBehaviors[i] = defaultBehaviors[i].slice();
            }
        }

        this.resetGroupBehaviorsSubject$.next();
    }

    addGroupsToBehaviors(behavior: string, ...groups: string[]): void {
        this.groupBehaviors[behavior]?.push(...groups);
    }

    getGroupBehaviors(behaviors: string | string[]): string[] {
        if (Array.isArray(behaviors)) {
            const result: string[] = [];
            behaviors.forEach((behavior: string) => {
                if (this.groupBehaviors[behavior]) {
                    result.push(...this.groupBehaviors[behavior]);
                }
            });
            return result;
        } else {
            return this.groupBehaviors[behaviors]?.slice() ?? [];
        }
    }

    setGroupsHash(data) {
        this.groupsHash = data;
        if (!data.lastGroup) {
            this.groupsHash.lastGroup = 0;
        }
        for (const node in this.groupsHash.nodes) {
            if (this.groupsHash.nodes[node]) {
                this.groupsHash.nodes[node].forEach(group => {
                    this.dvmService.viewer.addNodesToGroup(node, group.toString());
                });
                this.dvmService.viewer.addNodesToGroup(node, 'grouped');
            }
        }
    }

    createGroup(nodes: any[], isSimulation?) {
        this.groupsHash.lastGroup++;
        this.groupsHash.groups[this.groupsHash.lastGroup] = [];
        nodes.forEach(node => {
            if (typeof this.groupsHash.nodes[node.id] === 'undefined') {
                this.groupsHash.nodes[node.id] = [];
            }
            this.groupsHash.groups[this.groupsHash.lastGroup].push(node.id);
            this.groupsHash.nodes[node.id].push(this.groupsHash.lastGroup);
        });
        this.dvmService.viewer.addNodesToGroup(nodes, this.groupsHash.lastGroup.toString());
        this.dvmService.viewer.addNodesToGroup(nodes, 'grouped');
        if (isSimulation) {
            this.postSimulationGroups();
        } else {
            this.postGroups();
        }
    }

    deleteGroup(nodes: any[], isSimulation?) {
        nodes.forEach(node => {
            this.groupsHash.nodes[node.id].forEach(group => {
                if (group) {
                    this.dvmService.viewer.removeNodesFromGroup(this.groupsHash.groups[group], group.toString());
                } else {
                    this.dvmService.viewer.removeNodesFromGroup(this.groupsHash.groups[group], group);
                }
                this.dvmService.viewer.removeNodesFromGroup(this.groupsHash.groups[group], 'grouped');
                delete this.groupsHash.groups[group];
            });
            delete this.groupsHash.nodes[node.id];
        });
        if (isSimulation) {
            this.postSimulationGroups();
        } else {
            this.postGroups();
        }
    }

    postGroups() {
        const s = this.dataService.setGroups(this.dataService.currentConfigurationId, this.groupsHash).subscribe(
            response => {
                s.unsubscribe();
                console.log(response);
            }
        );
    }

    postSimulationGroups() {
        const s = this.dataService.setSimulationGroups(this.dataService.currentSimulationId, this.groupsHash).subscribe(
            response => {
                s.unsubscribe();
                console.log(response);
            }
        );
    }

}
