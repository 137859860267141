import {InjectionToken} from '@angular/core';

import { IsolatedGroups } from 'src/app/social-distance-editor/shared/models/isolated-groups.model';


export const ISOLATED_GROUPS = new InjectionToken<IsolatedGroups>('Isolated Groups');
export const isolatedGroups = {
  'eu-es-00001-fcbarcelona': {
    '1CNL0': [
      'S_6037',
      'S_38',
      'S_39',
      'S_41',
      'S_42',
      'S_43',
      'S_6137',
      'S_138',
      'S_139',
      'S_140',
      'S_141',
      'S_142',
      'S_6143',
    ],
    '1CNT0': ['S_6153', 'S_6150', 'S_151', 'S_152', 'S_153'],
    '1CSL0': [
      'S_6023',
      'S_24',
      'S_25',
      'S_27',
      'S_28',
      'S_6029',
      'S_6123',
      'S_124',
      'S_125',
      'S_126',
      'S_127',
      'S_128',
      'S_6129',
    ],
    '1CST0': ['S_6113', 'S_6016', 'S_113', 'S_114', 'S_115', 'S_6116'],
    '1GN00': [
      'S_1043',
      'S_44',
      'S_45',
      'S_46',
      'S_47',
      'S_48',
      'S_49',
      'S_50',
      'S_6050',
      'S_143',
      'S_144',
      'S_145',
      'S_146',
      'S_147',
      'S_148',
      'S_149',
      'S_150',
    ],
    '1GS00': [
      'S_16',
      'S_17',
      'S_18',
      'S_19',
      'S_20',
      'S_21',
      'S_22',
      'S_23',
      'S_116',
      'S_117',
      'S_118',
      'S_119',
      'S_120',
      'S_121',
      'S_122',
      'S_123',
    ],
    '1L000': [
      'S_29',
      'S_30',
      'S_31',
      'S_32',
      'S_33',
      'S_34',
      'S_35',
      'S_36',
      'S_37',
      'S_129',
      'S_130',
      'S_131',
      'S_132',
      'S_133',
      'S_134',
      'S_135',
      'S_136',
      'S_137',
    ],
    '1TC00': [
      'S_2',
      'S_3',
      'S_4',
      'S_5',
      'S_6',
      'S_7',
      'S_8',
      'S_9',
      'S_10',
      'S_102',
      'S_103',
      'S_104',
      'S_105',
      'S_106',
      'S_107',
      'S_108',
      'S_109',
      'S_110',
    ],
    '1TN00': ['S_1', 'S_6002', 'S_100', 'S_101', 'S_6102'],
    '1TS00': ['S_6010', 'S_11', 'S_6110', 'S_111', 'S_112'],
    '2CNLI': ['S_6235', 'S_236', 'S_237', 'S_238', 'S_239', 'S_240', 'S_6241'],
    '2CNLS': ['S_6339', 'S_340', 'S_341', 'S_342', 'S_343', 'S_344', 'S_345', 'S_346', 'S_6347'],
    '2CNTI': ['S_6248', 'S_249', 'S_250', 'S_6358'],
    '2CNTS': ['S_6354', 'S_355', 'S_356', 'S_357', 'S_358'],
    '2CSLI': ['S_6221', 'S_222', 'S_223', 'S_224', 'S_225', 'S_226', 'S_6227'],
    '2CSLS': ['S_6323', 'S_324', 'S_325', 'S_326', 'S_327', 'S_328', 'S_329', 'S_330', 'S_6331'],
    '2CSTI': ['S_6312', 'S_212', 'S_213', 'S_6214'],
    '2CSTS': ['S_312', 'S_313', 'S_314', 'S_315', 'S_6316'],
    '2GN0I': ['S_241', 'S_242', 'S_243', 'S_244', 'S_245', 'S_246', 'S_247', 'S_248'],
    '2GN0S': ['S_347', 'S_348', 'S_349', 'S_350', 'S_351', 'S_352', 'S_353', 'S_354'],
    '2GS0I': ['S_214', 'S_215', 'S_216', 'S_217', 'S_218', 'S_219', 'S_220', 'S_221'],
    '2GS0S': ['S_316', 'S_317', 'S_318', 'S_319', 'S_320', 'S_321', 'S_322', 'S_323'],
    '2L00I': ['S_227', 'S_228', 'S_229', 'S_230', 'S_231', 'S_232', 'S_233', 'S_234', 'S_235'],
    '2L00S': ['S_331', 'S_332', 'S_333', 'S_334', 'S_335', 'S_336', 'S_337', 'S_338', 'S_339'],
    '2TC0I': ['S_201', 'S_202', 'S_203', 'S_204', 'S_205', 'S_206', 'S_207', 'S_208', 'S_209', 'S_210'],
    '2TC0S': ['S_301', 'S_302', 'S_303', 'S_304', 'S_305', 'S_306', 'S_307', 'S_308', 'S_309', 'S_310'],
    '2TN0I': ['S_200', 'S_6201'],
    '2TN0S': ['S_300', 'S_6301'],
    '2TS0I': ['S_6210', 'S_211'],
    '2TS0S': ['S_6310', 'S_311'],
    '3CNLI': ['S_6439', 'S_440', 'S_441', 'S_442', 'S_443', 'S_444', 'S_445', 'S_446', 'S_6447'],
    '3CNLS': ['S_6539', 'S_540', 'S_541', 'S_542', 'S_543', 'S_544', 'S_545', 'S_546', 'S_6547'],
    '3CNTI': ['S_6454', 'S_455', 'S_456', 'S_457'],
    '3CSLI': ['S_6423', 'S_424', 'S_425', 'S_426', 'S_427', 'S_428', 'S_429', 'S_430', 'S_6431'],
    '3CSLS': ['S_6523', 'S_524', 'S_525', 'S_526', 'S_527', 'S_528', 'S_529', 'S_530', 'S_6531'],
    '3CSTI': ['S_413', 'S_414', 'S_415', 'S_6416'],
    '3GN0I': ['S_447', 'S_448', 'S_449', 'S_450', 'S_451', 'S_452', 'S_453', 'S_454'],
    '3GN0S': ['S_547', 'S_548', 'S_549'],
    '3GS0I': ['S_416', 'S_417', 'S_418', 'S_419', 'S_420', 'S_421', 'S_422', 'S_423'],
    '3GS0S': ['S_521', 'S_522', 'S_523'],
    '3L00I': ['S_431', 'S_432', 'S_433', 'S_434', 'S_435', 'S_436', 'S_437', 'S_438', 'S_439'],
    '3L00S': ['S_531', 'S_532', 'S_533', 'S_534', 'S_535', 'S_536', 'S_537', 'S_538', 'S_539'],
    '3TC00': ['S_402', 'S_403', 'S_404', 'S_405', 'S_406', 'S_407', 'S_408', 'S_409', 'S_410', 'S_411'],
    BBCN: ['S_3008', 'S_3007', 'S_3006', 'S_3005', 'S_3004', 'S_3003', 'S_3002', 'S_3001'],
    BBCS: ['S_3032', 'S_3031', 'S_3030', 'S_3029', 'S_3028', 'S_3027', 'S_3026', 'S_3025'],
    BBL0: [
      'S_3024',
      'S_3023',
      'S_3022',
      'S_3021',
      'S_3020',
      'S_3019',
      'S_3018',
      'S_3017',
      'S_3016',
      'S_3015',
      'S_3014',
      'S_3013',
      'S_3012',
      'S_3011',
      'S_3010',
      'S_3009',
    ],
    Z: ['S_1003', 'S_1004', 'S_1005', 'S_1006', 'S_1007', 'S_1008', 'S_1009'],
    BCNL: ['S_2351', 'S_2360', 'S_2370', 'S_2380', 'S_2390', 'S_2400', 'S_2411'],
    SBCN: ['S_1339', 'S_1340', 'S_1341', 'S_1342', 'S_1343', 'S_1344', 'S_1345', 'S_1346'],
    BCNT: ['S_2481', 'S_2490', 'S_2500', 'S_3580'],
    BCSL: ['S_2211', 'S_2220', 'S_2230', 'S_2240', 'S_2250', 'S_2260', 'S_2271'],
    SBCS: ['S_1323', 'S_1324', 'S_1325', 'S_1326', 'S_1327', 'S_1328', 'S_1329', 'S_1330'],
    BCST: ['S_3120', 'S_2120', 'S_2130', 'S_2141'],
    BGN0: ['S_2410', 'S_2420', 'S_2430', 'S_2440', 'S_2450', 'S_2460', 'S_2470', 'S_2480'],
    SBGN: ['S_1347', 'S_1348', 'S_1349', 'S_1350', 'S_1351', 'S_1352'],
    BGS0: ['S_2140', 'S_2150', 'S_2160', 'S_2170', 'S_2180', 'S_2190', 'S_2200', 'S_2210'],
    SBGS: ['S_1318', 'S_1319', 'S_1320', 'S_1321', 'S_1322'],
    BL00: ['S_2270', 'S_2280', 'S_2290', 'S_2300', 'S_2310', 'S_2320', 'S_2330', 'S_2340', 'S_2350'],
    SBL: ['S_1331', 'S_1332', 'S_1333', 'S_1334', 'S_1335', 'S_1336', 'S_1337', 'S_1338'],
    BTC0: ['S_2010', 'S_2020', 'S_2040', 'S_2050', 'S_2060', 'S_2070', 'S_2090', 'S_2100'],
    BBTC: [
      'S_4005',
      'S_4006',
      'S_4007',
      'S_4008',
      'S_4009',
      'S_4010',
      'S_4011',
      'S_4012',
      'S_4013',
      'S_4014',
      'S_4015',
      'S_4016',
      'S_4017',
      'S_4018',
      'S_4019',
      'S_4020',
    ],
    BTN0: ['S_2000', 'S_2011'],
    BBTN: ['S_4001', 'S_4002', 'S_4003', 'S_4004'],
    BTS0: ['S_2101', 'S_2110'],
    BBTS: ['S_4021', 'S_4022', 'S_4023', 'S_4024'],
    BBTCN: ['S_1302'],
    BBTCS: ['S_1309'],
    PN: ['S_1401'],
    PNR: ['S_401'],
    PS: ['S_1411', 'S_1412', 'S_412'],
  }
};
