import {InjectionToken} from '@angular/core';
import {CustomCategoriesByVenue} from '../models/custom-categories.model';

export const CUSTOM_CATEGORIES_BY_VENUE = new InjectionToken<CustomCategoriesByVenue>('Custom Categories By Venue');
export const customCategoriesbyVenue: CustomCategoriesByVenue = {
  'eu-es-00001-fcbarcelona': {
    1: {title: '1', color: '#608000'},
    2: {title: '2', color: '#e0e0d1'},
    3: {title: '3', color: '#99004d'},
    4: {title: '4', color: '#ffb3d9'},
    5: {title: '5', color: '#4d0026'},
    6: {title: '6', color: '#c61aff'},
    7: {title: '7', color: '#003300'},
    8: {title: '8', color: '#ff66ff'},
    9: {title: '9', color: '#806000'},
    E: {title: '9', color: '#fff0b3'},
    F: {title: 'F', color: '#ffc299'},
    G: {title: 'G', color: '#e65c00'},
    H: {title: 'H', color: '#e6e600'},
    J: {title: 'J', color: '#400080'},
    K: {title: 'K', color: '#009999'},
    L: {title: 'L', color: '#33cc33'},
    O: {title: 'O', color: '#e60000'},
    P: {title: 'P', color: '#004d00'},
    S: {title: 'S', color: '#009900'}
  }
};
