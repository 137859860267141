import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class VersionService {
    version = '2.0';

    getVersion(){
        return this.version;
    }
}