import { Component, OnInit } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {ActivatedRoute} from "@angular/router";
import {DataService} from "../../../services/data.service";
import {Subscription} from "rxjs";
import {saveAs} from 'file-saver'
import {SocialdistancingService} from "../../../services/socialdistancing.service";
import {DVMService} from "../../../services/dvm.service";

@Component({
  selector: 'app-export-onhold-modal',
  templateUrl: './export-onhold-modal.component.html',
  styleUrls: ['./export-onhold-modal.component.scss']
})
export class ExportOnholdModalComponent implements OnInit {

  private subscriptions: Subscription[] = [];

  configuration; // Valor que viene del sidebar
  id;
  exportonhold: boolean = false;

  constructor(private bsModalRef: BsModalRef,
              private activatedRoute: ActivatedRoute,
              private dataService: DataService,
              private socialDistancing: SocialdistancingService,
              private dvmService: DVMService) { }

  ngOnInit(): void {
  }

  public onConfirm():void {
    // Bajarse el manifest con el status onhold
    this.exportonhold = true;
    this.getGroupsFullExport();
  }

  public onDenied():void {
    // Bajarse el manifest con el status tal cual
    this.exportonhold = false;
    this.getGroupsFullExport();
  }

  private getGroupsFullExport(){
    const maps = [];
    if (this.configuration.tier_list){
      this.configuration.tier_list.forEach(level => {
        maps.push(level.map_id);
      });
    }
    this.socialDistancing.getGroupsFullExport(this.dvmService.viewer, maps).then(
        response => {
          response['subscribe'](
              data=>{
                this.getSSM();
              }
          );
        }
    );

  }

  private getSSM(){
    if(this.exportonhold) {
      this.subscriptions.push(this.dataService.getSimulationSeatManifestHold(this.id,'csv').subscribe(
          data => {
            const csv = data.response;
            const date = new Date().toDateString();
            const blob = new Blob([csv],{type:'text/csv'});
            saveAs(blob,`seatmanifest_${date}.csv`);
          }
      ));
    } else {
      this.subscriptions.push(this.dataService.getSimulationSeatManifest(this.id,'csv').subscribe(
          data => {
            const csv = data.response;
            const date = new Date().toDateString();
            const blob = new Blob([csv], {type: 'text/csv'});
            saveAs(blob, `seatmanifest_${date}.csv`);
          }
      ));
    }
    this.onDismiss();
  }



  public onDismiss(){
    this.bsModalRef.hide();
  }

}
