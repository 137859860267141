import { Injectable, Inject, ViewRef } from "@angular/core";
import { GeneralHandler } from "./general.handler";
import { DVMService } from "../dvm.service";
import { DVM_CONFIG } from "src/assets/configuration/digitalvenue.configuration";
import { DVMConfiguration } from "src/app/social-distance-editor/shared/models/dvm-configuration.model";
import { InventoryService } from "../inventory.service";
import { GroupsService } from "../groups.service";
import { SelectionService } from "../selection.service";
import { SocialdistancingService } from "../socialdistancing.service";
import { CustomCategoriesService } from "src/app/social-distance-editor/plugins/custom-categories/services/custom-categories.service";
import { MapViewer, MapViewerInputNodes } from "@3ddv/dvm-internal";

@Injectable()
export class EnterHandler extends GeneralHandler {
  constructor(
    protected dvmService: DVMService,
    @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
    private inventoryService: InventoryService,
    private groups: GroupsService,
    private selection: SelectionService,
    private socialDistancing: SocialdistancingService,
    private customCategoriesService: CustomCategoriesService
  ) {
    super("enter", dvmService);
  }

  sectionEnterHandler(node, viewer) {
    if (node.state !== "unavailable" || this.selection.isMultiSelection) {
      let isGrouped = false;
      node.groups.forEach((element) => {
        if (parseInt(element, 10)) {
          isGrouped = true;
          viewer.hover(this.groups.groupsHash.groups[element]);
        }
      });
      if (!isGrouped) {
        viewer.hover(node.id);
      }
    }
  }

  seatEnterHandler(node, viewer) {
    const raw_id = node.id.split("_")[1].split("-");
    const section = raw_id[0];
    const row = raw_id[1];
    const seat = raw_id[2];
    if (node.state !== "unavailable" && !this.selection.isSingleSelection && !this.selection.isMultiSelection) {
      let isGrouped = false;
      node.groups.forEach((element) => {
        if (parseInt(element, 10)) {
          isGrouped = true;
          viewer.hover(this.groups.groupsHash.groups[element]);
        }
      });

      if (!isGrouped) {
        if (this.selection.isGroupsSelection) {
          this.selection.selectionSubscriptionsArray.push(
            this.socialDistancing.getNeighbors(viewer, node.id).subscribe((response) => {
              this.selection.selectionSubscriptionsArray.forEach((e) => {
                e.unsubscribe();
              });
              viewer.hover(response);
            })
          );
        } else {
          if (this.dvmService.activeVisualizationLayer === "row") {
            viewer.hover(this.inventoryService.getInventoryByRow(section, row));
          } else if (this.dvmService.activeVisualizationLayer === "seat") {
            viewer.hover(this.inventoryService.getInventoryBySeat(section, row, seat));
          }
        }
      }
    } else if (this.selection.isGroupsSelection && viewer.getNodesByGroups("seat", "selected").length) {
      const selected = viewer.getNodesByGroups("seat", "selected");
      const nselected = selected.length;
      const availableSet = new Set<string>(viewer.getNodesByState("seat", "available").map((n) => n.id));
      selected.forEach((n) => availableSet.delete(n.id));

      // Se meten en 'invalidated' porque no queremos que maten sillas
      const invalidated = viewer
        .getNodesByGroups("seat", this.groups.getGroupBehaviors("validated"))
        .map((x) => x.id)
        .concat(Array.from(availableSet));
      const neighbors = this.socialDistancing.getNeighbors(viewer, node.id, nselected, invalidated);
      this.selection.selectionSubscriptionsArray.push(
        neighbors.subscribe((response: any) => {
          this.selection.selectionSubscriptionsArray.forEach((e) => e.unsubscribe());
          if (response.length !== nselected) {
            return;
          }
          viewer.addNodesToGroup(response, "tomove");
          viewer.hover(response);
          // const selected = viewer.getNodesByGroups('seat', 'selected');
          this._warningNearest(viewer, response as any);
        })
      );
    } else if (this.selection.isSingleSelection || this.selection.isMultiSelection) {
      let hovercss;
      //Set available per row
      if (this.selection.isMultiSelection) {
        const [section, row, seat] = node.id.split("_")[1].split("-");
        let row_seats = this.inventoryService.getInventoryByRow(section, row);
        //let row_seats = this.dvmService.getLogicRowSeats(node);
        if (row_seats.length) {
          let aux_nodes = [];
          let n_available = 0;
          for (let i = 0; i < row_seats.length; i++) {
            let aux_node = viewer.getNodeById(row_seats[i]);
            if (aux_node.state === "available") {
              n_available++;
            }
            //if(aux_node.state==='unavailable'){
            if (aux_node.groups.indexOf("regular") > -1) {
              hovercss = "regularselection";
            } else if (aux_node.groups.indexOf("vip") > -1) {
              hovercss = "vipselection";
            } else if (aux_node.groups.indexOf("disabled") > -1) {
              hovercss = "disabledselection";
            } else if (aux_node.groups.indexOf("restricted") > -1) {
              hovercss = "restrictedselection";
            } else {
              //exception error: map updated, no tiene grupo por defecto
              hovercss = "regularselection";
            }
            if (hovercss) {
              viewer.addNodesToGroup(aux_node, hovercss);
              aux_nodes.push(aux_node);
            }
            //}
            //all_nodes.push(aux_node);
          }
          viewer.hover(aux_nodes);
          if (n_available < aux_nodes.length) {
            this._warningNearest(viewer, aux_nodes);
          }
        }
      } else {
        if (node.groups.indexOf("regular") > -1) {
          hovercss = "regularselection";
        } else if (node.groups.indexOf("vip") > -1) {
          hovercss = "vipselection";
        } else if (node.groups.indexOf("disabled") > -1) {
          hovercss = "disabledselection";
        } else if (node.groups.indexOf("restricted") > -1) {
          hovercss = "restrictedselection";
        }
        if (hovercss) {
          viewer.addNodesToGroup(node, hovercss);
        }
        viewer.hover(node);
        if (node.state === "unavailable" && this.inventoryService.getInventoryBySeat(section, row, seat).length) {
          this._warningNearest(viewer, node);
        }
      }

      // TODO: popups para unavailable?
    }
  }

  private _warningNearest(viewer: MapViewer, nodes: MapViewerInputNodes) {
    const unavailable = viewer.getNodesByState("seat", "unavailable");
    const invalidatedByGroup = viewer.getNodesByGroups("seat", this.groups.getGroupBehaviors("invalidated"));
    const validated = viewer.getNodesByGroups("seat", this.groups.getGroupBehaviors("validated"));
    const invalidated = unavailable.concat(invalidatedByGroup);
    const filter = this.socialDistancing.filterNearest(
      viewer,
      nodes,
      invalidated.map((n) => n.id),
      validated.map((n) => n.id)
    );
    // TODO: Revisar si se está usando correctamente
    this.selection.selectionSubscriptionsArray.push(
      filter.subscribe((response) => {
        this.selection.selectionSubscriptionsArray.forEach((e) => e.unsubscribe());
        viewer.removeNodesFromGroup(viewer.getNodesByGroups("seat", "warning"), "warning");
        viewer.addNodesToGroup(response as any, "warning");
      })
    );
  }

  protected handle(obj) {
    // Close popovers
    const tooltip: HTMLElement = document.querySelector("#tooltip");
    tooltip.removeAttribute("data-show");
    // Handle
    const viewer = obj.instance;
    const nodes = obj.nodes;
    if (nodes.length) {
      if (nodes[0].type === "section") {
        this.sectionEnterHandler(nodes[0], viewer);
      } else if (nodes[0].type === "seat") {
        this.seatEnterHandler(nodes[0], viewer);
      }
    }
  }
}
