
    <nav id="navbar" class="navbar navbar-light bg-light fixed-top">
        <ul class="nav nav-pills">
            <li dropdown class="nav-item dropdown mr-2">
                <a  dropdownToggle class="nav-link dropdown-toggle" data-toggle="dropdown"
               role="button" aria-haspopup="true" aria-expanded="false">Changelog</a>
                <ul class="dropdown-menu" *dropdownMenu >
                    <li *ngFor="let v of versions">
                        <a class="dropdown-item" scrollTo scrollYTarget ="{{v.offsetTop}}">Version {{v.version}}</a>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
    <div class='container'>
    <h2>Changelog</h2>

    <h3 id="v2-0">Version 2.0</h3>
    <ul>
        <li>Allocator (Coming soon) </li>
    </ul>
    <h3 id="v1-9">Version 1.9</h3>
    <ul>
        <li>Correction of errors in the total results. </li>
        <li>Interface improvements. </li>
    </ul>

    <h3 id="v1-8">Version 1.8</h3>
    <ul>
        <li>Correction of calculation errors in sector simulations. </li>
        <li>Added functionality to choose which side to remove aisle seats, at the beginning, end or both sides of the row.
            <span (click)='openVideo("https://www.youtube.com/embed/0os2IHFVZns","How to kill aisle seats from each side")'>See video</span></li>
    </ul>
    <h3 id="v1-7">Version 1.7</h3>
    <ul>
        <li>A new category has been added by default: Limited View, which refers to seats with reduced visibility.
            <span (click)='openVideo("https://www.youtube.com/embed/oDA9lbfXAw0","How to add the limited view category")'>See video</span>
        </li>
        <li>Correction of errors of the overall results of the simulation. </li>
        <li>New functionality to create simulations by sections, allowing each sector to have individual parameters, social distance, and also eliminate different aisle seats.
            <span (click)='openVideo("https://www.youtube.com/embed/53rTbKOaTbg","How to create simulations by sections")'>See video</span>
        </li>
    </ul>
    <h3 id="v1-6">Version 1.6</h3>
    <ul>
        <li>Now showing in the results popup the total number of eliminated aisle seats.
            <span (click)='openVideo("https://www.youtube.com/embed/UFnS81qTu88","How to eliminate aisle seats")'>See video</span></li>
        <li>Improved interface </li>
    </ul>
    <div id="v1-5">
        <h3>Version 1.5</h3>
        <ul>
            <li>The functionality of changing categories and prices at seat level is added in the configuration section. </li>
            <li>Correction of errors when downloading the seat manifest. </li>
        </ul>
    </div>
    <h3 id="v1-4">Version 1.4</h3>
    <ul>
        <li>Bugfixes when moving groups </li>
        <li>Manual editing of the Social Distance algorithm: </li>
        <li>The Edit Single Seats functionality is added, allowing to enable and disable seats that the algorithm has blocked. 
            This functionality does not take into account the Social Distance and allows the user to enable and disable seats as they wish. 
            In case of breaking the social distance, the affected seats will be highlighted in orange.
            <span (click)='openVideo("https://www.youtube.com/embed/GsMK_Gmmaqo","How to edit single seats")'>See video</span>
        </li>
        <li>If you edit the groups manually, the tool will not take into account the desired percentages and will start counting the entered groups. </li>
    </ul>
    <h3 id="v1-3">Version 1.3</h3>
    <ul>
        <li>Functionality to duplicate the base configuration. </li>
        <li>The functionality of moving and merging groups of seats in the simulations has been added.
            <span (click)='openVideo("https://www.youtube.com/embed/mhfv2PCwI7k","How to move groups")'>See video</span>
        </li>
        <li>Added option to limit capacity to two groups per row.
            <span (click)='openVideo("https://www.youtube.com/embed/NaOamajdWh4","How to limit capacity to two groups per row")'>See video</span>
        </li>
        <li>The Social Distance algorithm now starts calculating from the extremes of the section, providing a visual improvement to group results without affecting maximum availability. </li>
    </ul>
    <h3 id="v1-2">Version 1.2</h3>
    <ul>
        <li>The option to duplicate simulation and rename it is added.
            <span (click)='openVideo("https://www.youtube.com/embed/T721MbSeQFQ","How to duplicate simulations")'>See video</span>
        </li>
        <li>Added the option to make a public simulation and share it through a URL.
            <span (click)='openVideo("https://www.youtube.com/embed/CnMFHvSD9Kk","How to share your simulations")'>See video</span>
        </li>
        <li>Possibility of managing more than one venue per customer. </li>
        <li>Interface improvement. </li>
    </ul>
    <h3 id="v1-1">Version 1.1</h3>
    <ul>
        <li>The category and price information is added to the seat or sector when hover is done. </li>
        <li>Optimization of the algorythm. Fastest response time from 20 to 5 seconds. </li>
        <li>Correction of errors on the counting of seats and percentages of availability in the simulations. </li>
        <li>Added information about the percentage of availability in the results screen. </li>
    </ul>


    <h3 id="v1-0">Version 1.0</h3>
    <ul>
        <li>Configuration of the venue.</li>
        <li>Configure prices for each section, row and seat. </li>
        <li>Configure categories by row and section. </li>
        <li>Sections and rows labeled with the locked category will not be taken into account in the Social Distance simulations. </li>
        <li>Create different simulations based on each venue configuration. </li>
        <li>Configurable parameters for the simulation: Social distance between attendees, eliminate aisle seats, percentage of groups. </li>
        <li>Revenue information based on the available seats. </li>
        <li>Availability information of each category. </li>
    </ul>
    </div>