import {Component, Inject, OnDestroy, OnInit, Pipe} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/social-distance-editor/services/data.service';
import { Router } from '@angular/router';
import { User } from '../../models/user-data.model';
import { FormatNumberService } from 'src/app/social-distance-editor/services/format-number.service';
import { Form } from '@angular/forms';
import { Simulation } from '../../models/simulation-data.model';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import {Subscription} from 'rxjs';
import {CustomCategoriesService} from '../../../plugins/custom-categories/services/custom-categories.service';
import {Configuration} from '../../models/configuration-data.model';
import { CustomCategoriesByVenue, CustomCategoryByKey } from 'src/app/social-distance-editor/plugins/custom-categories/models/custom-categories.model';
import { CUSTOM_CATEGORIES_BY_VENUE } from 'src/app/social-distance-editor/plugins/custom-categories/configuration/categories.configuration';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-new-simulation-modal',
  templateUrl: './new-simulation-modal.component.html',
  styleUrls: ['./new-simulation-modal.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})
export class NewSimulationModalComponent implements OnInit, OnDestroy {

  currentVenue;
  user: User;
  configurations: Configuration[];
  advancedOptions = false;
  singleOptions = true;
  venueData;
  configSelected;
  currentConfigCapacity;
  simulationName;
  socialDistancing = 1.5;
  simulationParameters: Array<any>;
  totalPercentage = 0;
  configsHash = {};
  inputHash = {};
  inputAvailability = 30;
  simulationCreated;
  aisleSeats = 0;
  //custom categories
  customCategoriesInfo : CustomCategoryByKey; // raw categories info
  customCategoriesDropdownList = [];
  customCategoriesSelectedItems = [];
  customCategoriesDropdownSettings: IDropdownSettings = {};

  private subscriptions: Subscription[] = [];

  constructor(
              @Inject(CUSTOM_CATEGORIES_BY_VENUE) private customCategoriesByVenue: CustomCategoriesByVenue,
              private bsModalRef: BsModalRef,
              private dataService: DataService,
              private router: Router,
              private numberFormatService: FormatNumberService,
              private customCategoriesService: CustomCategoriesService,
              private sanitizer: DomSanitizer) { }

  get allowCustomCategories(): boolean {
    return this.customCategoriesService.allowCustomCategories;
  }

  ngOnInit(): void {
    if (this.numberFormatService.getUnits() === 'ft') {
      this.socialDistancing = 6;
    } else {
      this.socialDistancing = 1.5;
    }
    const sub = this.dataService.getAllParameters().subscribe(
      parameters => {
        this.simulationParameters = parameters['params'];
        this.simulationParameters.splice(0, 1);
        // this.simulationParameters.forEach(
        //   p => {
        //     this.inputHash[p.id] = 25;
        //   }
        // );
        this.totalPercentage = 100;
      }
    );
    this.subscriptions.push(sub);
    this.configurations = this.dataService.venueData[this.currentVenue].configurations;
      console.log(this.configurations);
    this.currentConfigCapacity = this.formatNumber(this.configurations[0].capacity);

    // if there is only one configuration, then select it by default
    if (this.configurations.length === 1) {
      this.configSelected = Object.keys(this.configurations);
    }
    this.venueData = this.dataService.venueData[this.currentVenue];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public onClose() {
    this.bsModalRef.hide();
  }

  public onConfirm() {

  }

  get units(){
    if (this.dataService.getUser()) {
      return this.dataService.getUnits(this.dataService.getUser().user.client.metric_system);
    }
  }


  public onSubmit(ngForm: Form): void {
    const inputs = ngForm['form']['value'];
    let socialDistancingCopy = this.socialDistancing;
    if (this.numberFormatService.getUnits() === 'ft') {
      socialDistancingCopy = Number((this.socialDistancing * 0.3048).toFixed(4));
    }

    const simulation: Simulation = {
      name: this.simulationName,
      capacity: this.dataService.venueData[this.currentVenue].configurations[this.configSelected].capacity,
      config_id: this.dataService.venueData[this.currentVenue].configurations[this.configSelected].id,
      social_distancing: socialDistancingCopy.toString(),
      isle_seats: this.aisleSeats,
      fallback: 1
    };

    if (this.customCategoriesService.allowCustomCategories) {
      const selectedCategories = this.customCategoriesSelectedItems.map(i => i.item_id);
      simulation.custom_categories = { selected_custom_categories: selectedCategories };
    }

    const s = this.dataService.createSimulation(simulation).subscribe(
      response => {
        s.unsubscribe();
        this.simulationCreated = response.result;
        this.bsModalRef.hide();
        this.router.navigate(['/simulation/' + this.simulationCreated.id]);
      }
    );
  }

  public displayAdvancedOptions(): void {
    this.advancedOptions = !this.advancedOptions;
    this.singleOptions = !this.advancedOptions;
  }

  public formatNumber(price) {
    return this.numberFormatService.formatNumber(price);
  }



  get configName() {
    if (this.configSelected) {
      return this.dataService.venueData[this.currentVenue].configurations[this.configSelected]?.name;
    }
  }


  public onSetDistancing(action: 'minus' | 'sum'): void {
    if (action === 'minus' && this.socialDistancing > 0.5) {
      this.socialDistancing -= 0.10;
    }
    if (action === 'sum') {
      this.socialDistancing += 0.10;
    }
  }

  public disableConfirmbtn(): boolean {
    document.getElementById("newsimulation-btn").setAttribute("disabled",'disabled');
    if ( this.configSelected && (typeof this.simulationName!= 'undefined' && this.simulationName.length>0)){
      document.getElementById("newsimulation-btn").removeAttribute("disabled");
      return false;
    }
    return true;
  }
  public onChangeAisleSeat(action: 'minus' | 'sum') {
    if (action === 'minus' && this.aisleSeats > 0) {
      this.aisleSeats -= 1;
    }
    if (action === 'sum' && this.aisleSeats < 4) {
      this.aisleSeats += 1;
    }
  }


  private clampInput(input: HTMLInputElement, min = 0, max = 100, id?, isAisle?) {
    if (parseInt(input.value, 10)) {
      const inputValue = parseInt(input.value, 10);
      const clampped = this.clamp(inputValue, min, max).toString();
      input.value = clampped;
      if (id) {
        this.inputHash[id].percentage = parseInt(clampped, 10);
      }
      if (isAisle) {
        this.aisleSeats = parseInt(clampped, 10);
      }
    }
  }

  private clamp(input: number, min: number, max: number): number {
    return Math.min(Math.max(min, input), max);
  }

  public formatSocialDistancing(num: number): number {
    return Number(num.toFixed(2));
  }

  public setConfigSelected(configIdKey: string) {
    this.configSelected = configIdKey;
    this.currentConfigCapacity = this.formatNumber(this.configurations[configIdKey].capacity);

    // Plugin Custom categories Only
    if (this.customCategoriesService.allowCustomCategories && this.configurations[configIdKey].custom_categories) {
      this.customCategoriesInfo = this.customCategoriesService.getCutomCategoriesByVenue(this.configurations[configIdKey].venue_code);
      for (const category of this.configurations[configIdKey].custom_categories.custom_categories){
        const dropdownitem = {
          item_id : category,
          item_text : category
        }
        this.customCategoriesDropdownList.push(dropdownitem);
      }
      this.customCategoriesDropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: false
      };


    }
  }
  
}
