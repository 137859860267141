import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/social-distance-editor/services/data.service';

interface pdfsubscriber {
  fullname?: string,
  email : string,
  lopd : boolean
}

@Component({
  selector: 'app-pdfsubscriber',
  templateUrl: './pdfsubscriber.component.html',
  styleUrls: ['./pdfsubscriber.component.scss']
})


export class PdfsubscriberComponent implements OnInit {

  constructor(
    private bsModalRef: BsModalRef,
    private dataService: DataService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.errors = "";
    this.loading = "";
  }

  pdfsubscriber : pdfsubscriber = {
    fullname : "",
    email : "",
    lopd: false
  }

  errors;
  loading;

  public newEmail(){
    this.errors = "";
    this.loading = 'Sending Email...'
  }
  public onSubmit(): void {
    this.newEmail();

    if(!this.pdfsubscriber.lopd){
      
      this.showError("Please check the LOPD checkbox to receive the PDF.");
      return;
    }
    this.dataService.pdfsubscriber(this.pdfsubscriber.fullname,this.pdfsubscriber.email).subscribe(
      response => {
        this.toastr.success("","We have sent an email with further information.")
        this.onDismiss();
      },
      error => {
        this.showError(error);
      }
    );
  }
  public onDismiss() {
    this.bsModalRef.hide();
  }
  public showError(error){
    this.loading = "";
    this.errors = error;
  }
}
