import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormatNumberService } from "../../../../../services/format-number.service";
import { DataService } from "../../../../../services/data.service";
import { Form } from "@angular/forms";
import { UploadbuttonComponent } from "../../../../../shared/buttons/uploadbutton/uploadbutton.component";
import { DVMService } from "../../../../../services/dvm.service";
import { AllocatorService } from "../../../services/allocator.service";
import { InventoryService } from "../../../../../services/inventory.service";
import { Router } from "@angular/router";
import { MlbParserService } from "../../../services/parsers/mlb-parser.service";
import { TiersService } from "../../../../../services/tiers.service";
import { PlatformLocation } from "@angular/common";

interface Result {
  membersAssigned: number;
  membersLeft: number;
  computedPercentage: number;
}

@Component({
  selector: "app-mlb-modal",
  templateUrl: "./mlb-modal.component.html",
  styleUrls: ["./mlb-modal.component.scss"],
})
export class MlbModalComponent implements OnInit, OnDestroy {
  @ViewChild("uploadClientsCsv") clientsCsv!: UploadbuttonComponent;
  @ViewChild("uploadCategoriesCsv") categoriesCsv!: UploadbuttonComponent;

  @ViewChild("step1") modalStep1!: ElementRef<HTMLDivElement>;
  @ViewChild("step2") modalStep2!: ElementRef<HTMLDivElement>;
  @ViewChild("step3") modalStep3!: ElementRef<HTMLDivElement>;
  @ViewChild("step4") modalStep4!: ElementRef<HTMLDivElement>;

  @ViewChild("upgradeCheckbox") upgradeCheckbox!: ElementRef<HTMLInputElement>;
  @ViewChild("downgradeCheckbox") downgradeCheckbox!: ElementRef<HTMLInputElement>;

  simulation;
  currentAvailability: number;
  currentVenue: string;
  configuration;

  upgrade = false;
  downgrade = false;

  result: Result | undefined;

  get venueName() {
    if (this.simulation) {
      return this.dataService.venueData[this.simulation.configuration.venueId].name;
    }
  }

  get availability() {
    return this.currentAvailability;
  }
  get availabilityPercentage() {
    if (this.simulation) {
      return ((this.simulation.capacity / this.simulation.configuration.capacity) * 100).toFixed(2);
    }
  }

  constructor(
    private bsModalRef: BsModalRef,
    private dataService: DataService,
    private numberFormatService: FormatNumberService,
    private dvmService: DVMService,
    private allocatorService: AllocatorService,
    private csvParserService: MlbParserService,
    private inventoryService: InventoryService,
    private router: Router,
    private location: PlatformLocation
  ) {
    this.location.onPopState(() => {
      this.onClose();
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {}

  public onClose(): void {
    this.bsModalRef.hide();
  }

  public hasAllocation() {
    return this.simulation.allocation;
  }
  public formatNumber(price: number): string {
    return this.numberFormatService.formatNumber(price);
  }

  public onSubmit(ngForm: Form): void {
    if (!this.isDisabled()) {
      const csvClientsFile = this.clientsCsv.getFile();
      const csvCategoriesFile = this.categoriesCsv.getFile();
      const viewer = this.dvmService.viewer;

      this.showStep2();

      if (csvClientsFile && csvCategoriesFile && viewer) {
        // TODO: file validator

        Promise.all([csvClientsFile.text(), csvCategoriesFile.text(), this.delay(100)]).then((results) => {
          const [csvClients, csvCategories] = results;

          const maps = [];
          if (this.configuration.tier_list) {
            this.configuration.tier_list.forEach((level) => {
              maps.push(level.map_id);
            });
          }

          // TODO: upgrade y downgrade debería de venir del popover
          const p = this.csvParserService.full(viewer, csvClients, csvCategories, this.upgrade, this.downgrade, maps);
          return Promise.all([p, this.delay(1000)]).then((r) => {
            const result = r[0];
            let error = false;
            let assignedClients = 0;
            let unassignedClients = 0;
            Array.from(result.assigned).forEach((group: any) => (assignedClients += group.assigned.length));
            Array.from(result.unassigned).forEach((group: any) => (unassignedClients += group.members.length));
            const percentage = assignedClients ? (assignedClients / (assignedClients + unassignedClients)) * 100 : 0;
            this.result = {
              computedPercentage: percentage,
              membersLeft: unassignedClients,
              membersAssigned: assignedClients,
            };
            if (!percentage && !result.unassigned.size) {
              error = true;
              this.allocatorService.clearAllocationFromSimulation().then((success) => {
                this.showStep3(error);
              });
            } else {
              this.showStep3(error);
            }
          });
        });
      }
    }
  }

  private delay(time: number): Promise<void> {
    return new Promise<void>((resolve) => setTimeout(resolve, time));
  }

  public isDisabled(): boolean {
    return (!this.clientsCsv?.hasFile() ?? true) || (!this.categoriesCsv?.hasFile() ?? true);
  }

  public onViewAllocation() {
    this.onClose();
    this.router.navigate(["/allocation/" + this.dataService.currentSimulationId]);
  }

  private showStep1() {
    this.modalStep1.nativeElement.classList.remove("d-none");
    this.modalStep2.nativeElement.classList.add("d-none");
    this.modalStep3.nativeElement.classList.add("d-none");
    this.modalStep4.nativeElement.classList.add("d-none");
  }

  private showStep2() {
    this.modalStep1.nativeElement.classList.add("d-none");
    this.modalStep2.nativeElement.classList.remove("d-none");
    this.modalStep3.nativeElement.classList.add("d-none");
    this.modalStep4.nativeElement.classList.add("d-none");
  }

  private showStep3(error: boolean) {
    this.modalStep1.nativeElement.classList.add("d-none");
    this.modalStep2.nativeElement.classList.add("d-none");
    if (!error) {
      this.modalStep3.nativeElement.classList.remove("d-none");
    } else {
      this.modalStep4.nativeElement.classList.remove("d-none");
    }
  }
}
