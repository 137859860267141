import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal/';
import { ComputeResultsModalComponent } from '../shared/modals/compute-results-modal/compute-results-modal.component';
import { ErrorModalComponent } from '../shared/modals/error-modal/error-modal.component';
import { Configuration } from '../shared/models/configuration-data.model';
import {EditSimulationModalComponent} from "../shared/modals/edit-simulation-modal/edit-simulation-modal.component";
import {InventoryService} from "./inventory.service";
import {DVMService} from "./dvm.service";
import {CategoriesService} from "./categories.service";
import {SocialdistancingService} from "./socialdistancing.service";
import {ComputeSectionResultsModalComponent} from "../shared/modals/compute-sectionresults-modal/compute-sectionresults-modal.component";
import {EditSectionSimulationModalComponent} from "../shared/modals/edit-sectionsimulation-modal/edit-sectionsimulation-modal.component";
import {Observable} from "rxjs";
import {ExportOnholdModalComponent} from "../shared/modals/export-onhold-modal/export-onhold-modal.component";
import {GroupsService} from './groups.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private dataService: DataService,
              private bsModalRef: BsModalRef,
              private modalService: BsModalService,
              private inventoryService: InventoryService,
              private dvmService: DVMService,
              private categoriesService: CategoriesService,
              private socialDistancingService: SocialdistancingService,
              private groupsService: GroupsService) {
  }

  // -- PUBLIC METHODS --

  public openExportOnHold(configuration, id):void {
      const modalConfig: ModalOptions = {
          animated: true,
          class: 'modal-dialog-centered',
          backdrop: true,
          ignoreBackdropClick: true,
          initialState:{
              configuration,
              id
          }
      };
      this.bsModalRef = this.modalService.show(ExportOnholdModalComponent, modalConfig);
      const subscription = this.modalService.onHidden.subscribe(
          reason => {
              console.log("Export onhold Modal Closed");
          }
      )
  }
  public openEditSimulation(configuration: [any, any]): void {
    const simulationParameters = configuration[0];
    const inputHash = configuration[1];
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered simulation-modal',
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {
        simulationParameters,
        inputHash
      }
    };
    this.bsModalRef = this.modalService.show(EditSimulationModalComponent, modalConfig);

    const subscription = this.modalService.onHidden.subscribe(
        reason =>{
          switch (this.bsModalRef.content.hiddenReason) {
            default:
            case 'close':
              subscription.unsubscribe();
              break;
            case 'closetoresult':
                if( this.bsModalRef.content.socialDistancingConfig){

                    this.applySocialDistancingOpenResults(
                        this.bsModalRef.content.socialDistancingConfig,
                        this.bsModalRef.content.simulation,
                        this.bsModalRef.content.loadingmap);
                        subscription.unsubscribe();
                }
              break;
          }
        }
    )
  }

  public openEditSectionSimulation(simulationParameters, inputHash){
      const modalConfig: ModalOptions = {
          animated: true,
          class: 'modal-dialog-centered simulation-modal',
          backdrop: true,
          ignoreBackdropClick: true,
          initialState: {
              simulationParameters,
              inputHash
          }
      };
      this.bsModalRef = this.modalService.show(EditSectionSimulationModalComponent, modalConfig);
      const subscription = this.modalService.onHidden.subscribe(
          reason => {
              switch (this.bsModalRef.content.hiddenReason) {
                  default:
                  case 'close':
                      subscription.unsubscribe();
                      break;
                  case 'closetoresult':
                      this.applySocialDistancingOpenResults(
                          this.bsModalRef.content.socialDistancingConfig,
                          this.bsModalRef.content.simulation,
                          this.bsModalRef.content.loadingmap,
                          this.bsModalRef.content.sdNodes,
                          this.bsModalRef.content.sdParentNodes
                          );
                      subscription.unsubscribe();
                      break;
              }

          }
      );
  }

  public openComputedResultsModal(page?: string,configuration?:Configuration): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered simulation-modal',
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {
        page,
        configuration
      }
    };
    // Abre el modal
    this.bsModalRef = this.modalService.show(ComputeResultsModalComponent, modalConfig);

    // subscribe al modal cuando se cierra
    const subscription = this.modalService.onHidden.subscribe(
        reason => {
          // console.log("HIDDEN REASON: ", reason);
          // console.log("CONTENT: ", this.bsModalRef.content.hiddenReason);
          switch (this.bsModalRef.content.hiddenReason) {
            default:
            case 'close':
              subscription.unsubscribe();
              break;

            case 'closetoedit':
              subscription.unsubscribe();
              this.onEditSimulationOpenEdit();
              break;
          }
        }
    )
  }

  public openComputedSectionResultsModal(simulationParameters: any, inputHash:{}, sdParentNodes: any){
      const modalConfig: ModalOptions = {
          animated: true,
          class: 'modal-dialog-centered simulation-modal',
          backdrop: true,
          ignoreBackdropClick: true,
          initialState: {
              simulationParameters,
              inputHash,
              sdParentNodes
          }
      };
      this.bsModalRef = this.modalService.show(ComputeSectionResultsModalComponent, modalConfig);

      const subscription = this.modalService.onHidden.subscribe(
          reason =>{
              switch (this.bsModalRef.content.hiddenReason) {
                  default:
                  case 'close':
                      subscription.unsubscribe();
                      break;
                  case 'closetoedit':
                      this.onEditSubSimulationOpenEditSection();
                      subscription.unsubscribe();
                      break;
              }
          }
      )
  }

  public throwErrorModal(errorMessage?: string): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered simulation-modal',
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {
        errorMessage
      }
    };
    this.bsModalRef = this.modalService.show(ErrorModalComponent, modalConfig);
  }
  // -- END PUBLIC METHODS --

  // -- PRIVATE METHODS --

    private onEditSimulationOpenEdit(): void {
        let simulationParameters;
        const inputHash = {};
        let s = this.dataService.getAllParameters().subscribe(
            parameters => {
                let editable = [];
                for(let i = 0;i<parameters['params'].length;i++){
                    if(parameters['params'][i].editable){
                        editable.push(parameters['params'][i]);
                    }
                }
                simulationParameters = editable;
                simulationParameters.splice(0, 1);
                simulationParameters.forEach(
                    p => {
                        if(p.id){
                            inputHash[p.id] = {percentage: 0, computed_per: 0, nseats_per: 0};
                        }
                    }
                );
                // inputHash[3] = {percentage: 100, computed_per: 0};
                this.dataService.inputHash = inputHash;
                this.dataService.setInputHash$(inputHash);
            })
            .add(
                success => {
                    this.openEditSimulation([simulationParameters, inputHash]);
                }
            )
    }

    private applySocialDistancingOpenResults(socialDistancingConfig: {}, simulation: any, loadingmap?: any,
                                             sdNodes?: [], sdParentNodes?:any){
          this.socialDistancingService.availabilityBuffer = this.inventoryService.getInventoryData();
          this.inventoryService.setInventoryData(this.inventoryService.getComparativeInventoryData());
          const inventory = this.inventoryService.getAvailableInventory();
          this.dvmService.viewer.setAvailability('section', inventory.sections);
          this.dvmService.viewer.setAvailability('seat', inventory.seats);
          this.categoriesService.printInventoryCategories();
          const unavailable = this.dvmService.viewer.getNodesByState('seat', 'unavailable');
          const invalidated = this.dvmService.viewer.getNodesByGroups('seat', this.groupsService.getGroupBehaviors('invalidated'));
          const validated = this.dvmService.viewer.getNodesByGroups('seat', this.groupsService.getGroupBehaviors('validated'));
          const blacklist = unavailable.concat(invalidated).map(x => x.id);
          const whitelist = validated.map(x => x.id);
          // console.log('UNAVAILABLES: ', unavailable, 'VALIDATED ', whitelist);
          // console.log(socialDistancingConfig);
          this.socialDistancingService.setSocialDistancingConfig(socialDistancingConfig);

          const maps = [];
          if (simulation.configuration.tier_list) {
              simulation.configuration.tier_list.forEach(level => {
                  maps.push(level.map_id);
              });
          }

          if(sdNodes && sdParentNodes) {
              this.socialDistancingService.sectionsAffected = sdParentNodes;
              this.socialDistancingService.computeProbabilistic(this.dvmService.viewer, blacklist, whitelist, (result) => {
                  simulation.capacity = result.length;
                  if (loadingmap) { loadingmap.classList.add('hidden');}
                  this.inventoryService.generateSimulationsManifest(this.dataService.currentSimulationId, result)
                      .subscribe((response) => {
                          let patch_sim = {capacity : result.length}
                          this.dataService.editSimulation(simulation.id,patch_sim).subscribe(
                              result=>{
                                  this.inventoryService.updateTotalsHash('simulations');
                                  this.dataService.simulationData = simulation;
                                  this.dataService.setCurrentSimulation$(simulation);
                                  this.inventoryService.availabilityLoadedSubject.next();
                                  this.socialDistancingService.calculationFinishedsubject.next();
                                  let simulationParameters;
                                  const inputHash = {};
                                  this.dataService.getAllParameters().subscribe(
                                      parameters => {
                                          //console.log(parameters);
                                          simulationParameters = parameters['params'];
                                          simulationParameters.splice(0, 1);
                                          simulationParameters.forEach(
                                              p => {
                                                  inputHash[p.id] = {percentage: 0, computed_per: 0};
                                              }
                                          );
                                          // inputHash[3] = {percentage: 100, computed_per: 0};
                                          this.dataService.inputHash = inputHash;
                                          this.dataService.setInputHash$(inputHash);
                                          this.openComputedSectionResultsModal(simulationParameters, inputHash,sdParentNodes);
                                      })
                              }
                          );
                      })
                  ;
              }, maps, sdNodes);
          } else {
              this.socialDistancingService.computeProbabilistic(this.dvmService.viewer, blacklist, whitelist, (result) =>{
                  simulation.capacity = result.length;
                  if (loadingmap) { loadingmap.classList.add('hidden');}
                  this.inventoryService.generateSimulationsManifest(this.dataService.currentSimulationId, result)
                      .subscribe(
                          response => {
                              this.inventoryService.updateTotalsHash('simulations');
                              this.dataService.setCurrentSimulation$(simulation);
                              this.inventoryService.availabilityLoadedSubject.next();
                              this.socialDistancingService.calculationFinishedsubject.next();
                              this.openComputedResultsModal(null,
                                  simulation.configuration);
                          }
                      )
              }, maps);
          }
      }

    private onEditSubSimulationOpenEditSection():void {
      let simulationParameters;
      this.socialDistancingService.isSectionSocialDistancing = true;
      const inputHash = {};
      const subscriptions = [];
      subscriptions.push(this.dataService.getAllParameters().subscribe(
          parameters => {
              //console.log(parameters);
              simulationParameters = parameters['params'];
              simulationParameters.splice(0, 1);
              simulationParameters.forEach(
                  p => {
                      inputHash[p.id] = {percentage: 0, computed_per: 0};
                  }
              );
              // inputHash[3] = {percentage: 100, computed_per: 0};
              this.dataService.inputHash = inputHash;
              this.dataService.setInputHash$(inputHash);
          }
      ).add(
          success => {
              this.openEditSectionSimulation(simulationParameters, inputHash);
          }
      ));
    }


  // -- END PRIVATE METHODS --
}
