import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateFormat'
 })
export class FormatDatePipe implements PipeTransform {
    transform(date: string): string {
      let suffix = 'th';
      const day: string = date.substring(0, 2);
      const monthYear: string = date.substring(2, 15);

      if (day === '1' || day === '21' || day === '31') {
          suffix = 'st';
      } else if (day === '2' || day === '22') {
          suffix = 'nd';
      } else if (day === '3' || day === '23') {
          suffix = 'rd';
      }
      const fullDate: string = monthYear + ' ' + day + suffix + ' ' + monthYear;
      console.log(date);
      console.log(fullDate);
      return fullDate;
    }
}
