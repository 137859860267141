import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { DVMService } from './dvm.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ConfigurationCategoryInput } from '../shared/models/configuration-category-input-data.model';
import { SimulationCategoryInput } from '../shared/models/simulation-category-input-data.model';
import {config, Observable, Subject} from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {ErrorModalComponent} from '../shared/modals/error-modal/error-modal.component';

export interface InventoryElementData {
  category?: string;
  code?: string;
  price?: string;
  [key: string]: any;
}
export interface Inventory {
  [key: string]: {
    data?: InventoryElementData;
    elements?: Inventory;
  };
}
// type Inventory = any;

export interface AvailableInventory {
  seats: string[];
  sections: string[];
}

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  availabilityLoadedSubject = new Subject<any>();
  currentLocation;
  params = [];
  private comparativeInventory: Inventory;
  totalsAvailableHash = {};

  private inventory: Inventory;
  private seatStatus = {};
  private totalsHash = {};
  private seatsCount = {};

  /** Se emite cuando se hace un setInventory */
  private inventorySubject$ = new Subject<Inventory>();
  private preInventorySubject$ = new Subject<Inventory>();

  /** Se emite cuando se hace un setInventory */
  public get inventoryObservable$(): Observable<Inventory> {
    return this.inventorySubject$.asObservable();
  }
  public get preInventoryObservable$(): Observable<Inventory> {
    return this.preInventorySubject$.asObservable();
  }

  /** Se emite cuando se hace un setComparativeInventory */
  private comparativeInventorySubject$ = new Subject<Inventory>();
  private preComparativeInventorySubject$ = new Subject<Inventory>();

  /** Se emite cuando se hace un setComparativeInventory */
  public get comparativeInventoryObservable$(): Observable<Inventory> {
    return this.comparativeInventorySubject$.asObservable();
  }
  public get preComparativeInventoryObservable$(): Observable<Inventory> {
    return this.preComparativeInventorySubject$.asObservable();
  }

  constructor(private data: DataService,
              private dvmService: DVMService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private bsModalRef: BsModalRef,
              private modalService: BsModalService
              //private modals: ModalService) {
  ){
                this.router.events.subscribe( (e) => {
                  // tslint:disable-next-line: no-unused-expression
                  if (e instanceof NavigationEnd) {
                    const split = e.url.split('/');
                    this.currentLocation = '/' + split[1];
                    this.params.push(split[2]);
                  }
                });
  }

  getRowsCountBySectionId(sectionId) {
    return this.seatsCount[sectionId]?.rows;
  }

  getSeatsCountBySectionId(sectionId) {
    return this.seatsCount[sectionId].seats;
  }

  sendInventoryData(isSimulation?) {
    if (isSimulation) {
      return this.data.sendSimulationSeatManifestData(this.data.currentSimulationId, this.inventory);
    } else {
      return this.data.sendSeatManifestData(this.data.currentConfigurationId, this.inventory);
    }
  }

  setComparativeInventoryData(inventory: Inventory): void {
    this.preComparativeInventorySubject$.next(inventory);
    this.comparativeInventory = inventory;
    this.comparativeInventorySubject$.next(inventory);
  }

  getComparativeInventoryData(): Inventory {
    return this.comparativeInventory;
  }

  setInventoryData(inventory: Inventory): void {
    this.preInventorySubject$.next(inventory);
    this.inventory = inventory;
    this.inventorySubject$.next(inventory);
  }

  getInventoryData(): Inventory {
    return this.inventory;
  }

  getTotalsHash() {
    return this.totalsHash;
  }

  iterateInventoryElements(callback: (element: InventoryElementData, ...keys: string[]) => void, inventory?: Inventory, ...keys: string[]) {
    inventory = inventory ?? this.inventory;
    for (const key in inventory) {
      if (inventory.hasOwnProperty(key)) {
        const level = inventory[key];
        const { data, elements } = level;
        if (data && Object.keys(data).length > 0) {
          callback(data, ...keys, key);
        }
        if (elements) {
          this.iterateInventoryElements(callback, elements, ...keys, key);
        }
      }
    }
  }

  setInventoryProperty(property: string, value, section: string, row?: string, callback?,seat?: string) {
    let inventoryRef;
    if (section) {
      inventoryRef = this.inventory[section]?.elements;
    }
    if (row) {
      if(seat){
        inventoryRef[row].elements[seat].data[property] = value;
      }else{
        inventoryRef = inventoryRef[row].elements;
        for (const element in inventoryRef) {
          if (typeof inventoryRef[element].data[property] !== 'undefined') {
            inventoryRef[element].data[property] = value;
          }
        }
      }
    } else {
        for (const elementLevel1 in inventoryRef) {
          if (elementLevel1) {
            for (const elementLevel2 in inventoryRef[elementLevel1].elements) {
              if (typeof inventoryRef[elementLevel1].elements[elementLevel2].data[property] !== 'undefined') {
                inventoryRef[elementLevel1].elements[elementLevel2].data[property] = value;
              }
            }
          }
        }
    }
    if (callback) {
      callback();
    }
  }

  getInventoryProperty(property: string, section: string, row?: string, callback?, seat?:string) {
    // console.log(this.inventory);
    const valuesHash = {};
    let inventoryRef;
    if (section) {
      if (this.inventory[section]) {
        inventoryRef = this.inventory[section].elements;
      } else {
        inventoryRef = [];
      }
    }
    if (row) {
      if(seat){
        valuesHash[inventoryRef[row].elements[seat].data[property]] = true;
      }else{
        if (inventoryRef[row]) {
          inventoryRef = inventoryRef[row].elements;
          for (const element in inventoryRef) {
            if (typeof inventoryRef[element].data[property] !== 'undefined') {
              valuesHash[inventoryRef[element].data[property]] = true;
            }
          }
        } else {
          console.error('Error in section ' + section + ' row ' + row + '. No data found.');
        }

      }

    } else {
      for (const elementLevel1 in inventoryRef) {
        if (elementLevel1) {
          for (const elementLevel2 in inventoryRef[elementLevel1].elements) {
            if (typeof inventoryRef[elementLevel1].elements[elementLevel2].data[property] !== 'undefined') {
              valuesHash[inventoryRef[elementLevel1].elements[elementLevel2].data[property]] = true;
            }
          }
        }
      }
    }
    if (callback) {
      callback();
    }
    return Object.keys(valuesHash);
  }

  // RECURSIVE APPROACH:
  getAvailableInventory(considerStatus?): AvailableInventory {
    const seatsInventory = [];
    const sectionsInventory = {};
    const rowsHash = {};
    this.seatsCount = {};
    const caller = (array) => {
      if(array.statusCode === 404){
        return array;
      }
      Object.values(array).forEach((element) => {
        iteration(element);
      });
    };
    const iteration = (element) => {
      if (element.data.code) {
        if (!considerStatus || (considerStatus && (element.data.status === 'available' || element.data.status === "onhold"))) {
          const section = element.data.code.split('-')[0];
          const row = element.data.code.split('-')[1];
          seatsInventory.push(element.data.code);
          sectionsInventory[section] = true;
          this.seatStatus[element.data.code] = element.data.status;
          if (!this.seatsCount[section]) {
            this.seatsCount[section] = {
              seats: 0,
              rows: 0
            };
          }
          this.seatsCount[section].seats++;
          if (!rowsHash[section + row]) {
            this.seatsCount[section].rows++;
            rowsHash[section + row] = true;
          }
        }
      } else {
        caller(element.elements);
      }
    };
    caller(this.inventory);
    // console.log(this.seatsCount);
    return { seats: seatsInventory, sections: Object.keys(sectionsInventory) };
  }

  updateTotalsHash(update?: 'simulations' | 'configurations' | 'all') {
    this.totalsHash = {};
    this.totalsAvailableHash = {};
    const sectionsHash = {};
    const caller = (array) => {
      Object.values(array).forEach((element) => {
        iteration(element);
      });
    };
    const iteration = (element) => {
      if (element.data.code) {
        const data = element.data;
        if (!this.totalsHash[data.category]) {
          this.totalsHash[data.category] = {};
        }
        if (!this.totalsHash[data.category][data.price]) {
          this.totalsHash[data.category][data.price] = {
            seats: 0,
            sections: 0
          };
        }
        this.totalsHash[data.category][data.price].seats++;
        if (data.status && data.status === 'available') {
          if (!this.totalsAvailableHash[data.category]) {
            this.totalsAvailableHash[data.category] = {};
          }
          if (!this.totalsAvailableHash[data.category][data.price]) {
            this.totalsAvailableHash[data.category][data.price] = {
              seats: 0
            };
          }
          this.totalsAvailableHash[data.category][data.price].seats++;
        }
        const section = element.data.code.split('-')[0].split('_')[1];
        if (!sectionsHash[section]) {
          this.totalsHash[data.category][data.price].sections++;
          sectionsHash[section] = true;
        }
      } else {
        caller(element.elements);
      }
    };
    caller(this.inventory);

    if (update) {
      this.data.getCategories().subscribe(
        categories => {
          if (update === 'configurations' || update === 'all') {
            const config = this.generateCategoryInput(categories.categories, this.totalsHash, 'configurations');
            console.log(config);
            this.data.postConfigurationCategories(config as ConfigurationCategoryInput).subscribe(
              response => {
                //console.log(response);
              }
            );
          }
          if (update === 'simulations' || update === 'all') {
            const config = this.generateCategoryInput(categories.categories, this.totalsAvailableHash, 'simulations');
            //console.log(config);
            this.data.postSimulationCategories(config as SimulationCategoryInput).subscribe(
              response => {
                //console.log(response);
              }
            );
          }
        }
      );
    }
  }

  generateCategoryInput(categories, hash, type: 'simulations' | 'configurations') {
    const categoryIdHash = {};
    const categoriesInput = {};
    if (type === 'simulations') {
      let simulation = this.data.currentSimulationId;
      if (typeof this.data.currentSimulationId === 'string') {
        simulation = parseInt(this.data.currentSimulationId, 10);
      }
      categoriesInput['simulation'] = simulation;
    } else if (type === 'configurations') {
      let config = this.data.currentConfigurationId;
      if (typeof this.data.currentConfigurationId === 'string') {
        config = parseInt(this.data.currentConfigurationId, 10);
      }
      categoriesInput['configuration'] = config;
    }
    categoriesInput['categories'] = [];
    // tslint:disable-next-line: forin
    for (const category in categories) {
      categoryIdHash[categories[category]['name']] = categories[category]['id'];
    }
    // tslint:disable-next-line: forin
    for (const category in hash) {
      const summatories = {};
      // tslint:disable-next-line: forin
      for (const price in hash[category]) {
        if (!summatories[category]) {
          summatories[category] = {
            seats: 0,
            sections: 0
          };
        }
        if (hash[category][price].seats) {
          summatories[category].seats += hash[category][price].seats;
        }
        if (hash[category][price].sections) {
          summatories[category].sections += hash[category][price].sections;
        }
      }
      const categoryInput: SimulationCategoryInput = {
        category: categoryIdHash[category],
        seats: summatories[category].seats,
        sections: summatories[category].sections,
        unit_price: 1,
        revenue: 1
      };
      categoriesInput['categories'].push(categoryInput);
    }
    return categoriesInput;
  }

  getInventoryBySection(section: string, considerStatus?: boolean) {
    const seatsInventory = [];
    for (const row in this.inventory[section]?.elements) {
      if (row && row !== 'data') {
        for (const seat in this.inventory[section].elements[row].elements) {
          if (seat && seat !== 'data') {
            if (!considerStatus || (considerStatus && this.inventory[section].elements[row].elements[seat].data.status === 'available')) {
              seatsInventory.push(this.inventory[section].elements[row].elements[seat].data.code);
            }
            this.seatStatus[this.inventory[section].elements[row].elements[seat].data.code] =
              this.inventory[section].elements[row].elements[seat].data.status;
          }
        }
      }
    }
    return seatsInventory;
  }

  getInventoryByRow(section: string, row: string, considerStatus?: boolean) {
    const seatsInventory = [];
    for (const seat in this.inventory[section]?.elements[row]?.elements) {
      if (seat && seat !== 'data') {
        if (!considerStatus || (considerStatus && this.inventory[section].elements[row].elements[seat].data.status === 'available')) {
          seatsInventory.push(this.inventory[section].elements[row].elements[seat].data.code);
         }
        this.seatStatus[this.inventory[section].elements[row].elements[seat].data.code] =
          this.inventory[section].elements[row].elements[seat].data.status;
      }
    }
    return seatsInventory;
  }
  getInventoryBySeat(section: string, row: string, seat: string, considerStatus?: boolean) {
    const seatsInventory = [];

    let s_element = this.inventory[section]?.elements[row]?.elements[seat]
    if (s_element && s_element!=='data') {
      if (!considerStatus || (considerStatus && this.inventory[section].elements[row].elements[seat].data.status === 'available')) {
        seatsInventory.push(this.inventory[section].elements[row].elements[seat].data.code);
       }
      this.seatStatus[this.inventory[section].elements[row].elements[seat].data.code] =
        this.inventory[section].elements[row].elements[seat].data.status;
    }
    return seatsInventory;
  }
  generateSimulationsManifest(simulationId, availability) {
    // DEBUG
    this.inventory = JSON.parse(JSON.stringify(this.comparativeInventory));
    const issues = {
      sections: {},
      rows: {},
      seats: {}
    };
    let hasIssues = false;
    availability.forEach(seat => {
      const split = seat.split('-');
      const section = split[0].split('_')[1];
      const row = split[1];
      const st = split[2];
      if (this.inventory[section]) {
        if (this.inventory[section].elements[row]) {
          if (this.inventory[section].elements[row].elements[st]) {
            this.inventory[section].elements[row].elements[st].data.status = 'available';
          } else {
            console.log('Seat ' + section + '-' + row + '-' + st + ' does not contain data.');
            issues.seats[section + '-' + row + '-' + st] = true;
            hasIssues = true;
          }
        } else {
          console.log('Row ' + section + '-' + row + ' does not contain elements.');
          issues.rows[section + '-' + row] = true;
          hasIssues = true;
        }
      } else {
        console.log('Section ' + section + ' does not contain elements.');
        issues.sections[section] = true;
        hasIssues = true;
      }
    });
    if (hasIssues){
      console.log(issues);
      let errorMessage = '';
      const sectionIssues = Object.keys(issues.sections);
      if (sectionIssues.length) {
        errorMessage += 'No elements found for sections: ';
        sectionIssues.forEach(element => {
          errorMessage +=  element + '; ';
        });
      }
      const rowIssues = Object.keys(issues.rows);
      if (rowIssues.length) {
        errorMessage += 'No elements found for rows: ';
        rowIssues.forEach(element => {
          errorMessage +=  element + '; ';
        });
      }
      const seatIssues = Object.keys(issues.seats);
      if (seatIssues.length) {
        errorMessage += 'No data found for seats: ';
        seatIssues.forEach(element => {
          errorMessage +=  element + '; ';
        });
      }
      // TODO: Arreglar Dependencia circular
      //this.modals.throwErrorModal(errorMessage);
      const modalConfig: ModalOptions = {
        animated: true,
        class: 'modal-dialog-centered simulation-modal',
        backdrop: true,
        ignoreBackdropClick: true,
        initialState: {
          errorMessage
        }
      };
      this.bsModalRef = this.modalService.show(ErrorModalComponent, modalConfig);
    }
    return this.data.sendSimulationSeatManifestData(simulationId, this.inventory);
  }

  getInventoryRevenue(section: string, row?: string){
    let parent = this.inventory[section].elements;
    let resp = {
      revenue:0,
      newrevenue:0
    };
    if(!row){
      //section revenue
      for (const rows in parent) {
        if (rows && rows !== 'data') {
          for (const seat in parent[rows].elements) {
            if (seat && seat !== 'data') {
              if (parent[rows].elements[seat].data.status && parent[rows].elements[seat].data.status=='available'){
                resp.newrevenue += parseFloat(parent[rows].elements[seat].data.price);
              }
              resp.revenue += parseFloat(parent[rows].elements[seat].data.price);
            }
          }
        }
      }
    }else{
      //row revenue
      parent = this.inventory[section].elements[row].elements;
      for (const seat in parent) {
        if (seat && seat !== 'data') {
          if (parent[seat].data.status && parent[seat].data.status=='available'){
            resp.newrevenue += parseFloat(parent[seat].data.price);
          }
          resp.revenue += parseFloat(parent[seat].data.price);
        }
      }

    }
    return resp;
  }
  
  public updateInventory(nodes_affected,status?: string,toastrOpt?: any){
        
    let raw_sections = [];
    for(let i =0;i<nodes_affected.length;i++){
        raw_sections.push(nodes_affected[i].id.split("-")[0]);
        let [sect,r,ss] = nodes_affected[i].id.split('_')[1].split('-');

        let section_inv = this.getInventoryData()[sect];

        if(!section_inv || !section_inv.elements[r] || !section_inv.elements[r].elements[ss]){
            this.createInventoryItem(nodes_affected[i].id)
        }

        if(status){
            this.getInventoryData()[sect].elements[r].elements[ss].data.status = status;
        }else{
            delete this.getInventoryData()[sect].elements[r].elements[ss].data.status;
        }
    }

    // No tiene sentido añadir esto ya que en update inventory se usa para asignar availability de las sillas
    // las categorias de las sillas ya estan puestas anteriormente.
    // let a = new CategoriesService(this,this.dvmService);
    // let b = a.printInventoryCategories();
    const s = this.sendInventoryData(true).subscribe(
        response => {
            s.unsubscribe();
            this.toastr.clear();
            let default_toastrOpt = {
              msg : 'Seatmanifest updated.',
              timeOut: 10000
            }
            if(toastrOpt){
              if(toastrOpt.msg){
                default_toastrOpt.msg = toastrOpt.msg;
              }
              if(toastrOpt.timeOut){
                default_toastrOpt.timeOut = toastrOpt.timeOut;
              }
            }
            this.toastr.success('', default_toastrOpt.msg, {timeOut:default_toastrOpt.timeOut});
            //Update params on update seats - in dev..
            //this.updateParams(node,viewer);
            this.setInventoryData(this.getInventoryData());
            this.updateTotalsHash('simulations');

            let section_affected = nodes_affected[0].id.split("-")[0];
            let available_nodes = this.dvmService.viewer.getNodesByState("seat", "available",section_affected);
            let all_nodes_in_section = this.dvmService.viewer.getNodesByParent(section_affected);
            if(status==='available'){
                if(this.dvmService.activeVisualizationLayer=='section' || available_nodes===all_nodes_in_section.length){
                    this.dvmService.viewer.setAvailable('section',section_affected );
                }
                this.dvmService.viewer.setAvailable('seat', nodes_affected);
            }else{
                if(this.dvmService.activeVisualizationLayer=='section' || !available_nodes.length){
                    this.dvmService.viewer.setUnavailable('section', section_affected);
                }
                this.dvmService.viewer.setUnavailable('seat', nodes_affected);
            }


        }
    );
}

public createInventoryItem(node_id){
  let inv = this.getInventoryData();
  let [sect,row,seat] = node_id.split('_')[1].split('-');
  if(!inv[sect]){
    inv[sect]={elements:{}, data: {}};
    inv[sect].elements[row] = {elements:{},data: {}};
    inv[sect].elements[row].elements[seat] = { elements:{}, data: { category:"regular", code:node_id, price: "1"} };
  }else if(!inv[sect].elements[row]){
    inv[sect].elements[row] = {elements:{},data: {}};
    inv[sect].elements[row].elements[seat] = { elements:{}, data: { category:"regular", code:node_id, price: "1"} };
  }else if(!inv[sect].elements[row].elements[seat]){
    inv[sect].elements[row].elements[seat] = { elements:{}, data: { category:"regular", code:node_id, price: "1"} };
  }
  this.setInventoryData(inv);
}
public controlDirty(nodes_tochangeavailable: any[],action: string){
/*         if(this.dataService.simulationData.custom_groups === null){
        this.dataService.simulationData.custom_groups = { dirty : false }
    } */

    this.data.simulationData.custom_groups = {dirty : true};
    
    if(this.data.simulationData.capacity){
        if(action.toLocaleLowerCase()==='add'){   
            this.data.simulationData.capacity += nodes_tochangeavailable.length;
        }else if (action.toLocaleLowerCase()==='remove'){
            this.data.simulationData.capacity -= nodes_tochangeavailable.length;
            
        }
    }
    const patch_sim = { capacity : this.data.simulationData.capacity,
                        custom_groups: this.data.simulationData.custom_groups };
    let s = this.data.editSimulation(this.data.simulationData.id,patch_sim).subscribe(result=>{
        s.unsubscribe();
        this.data.setCurrentSimulation$(result.result);
        this.availabilityLoadedSubject.next();
    });
    return null;
}
}
