import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-laliga-contact-cau-modal',
  templateUrl: './laliga-contact-cau-modal.component.html',
  styleUrls: ['./laliga-contact-cau-modal.component.scss']
})
export class LaligaContactCauModalComponent implements OnInit {

  constructor(
    private bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
  }
  public onDismiss() {
    this.bsModalRef.hide();
  }
}
