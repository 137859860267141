
<div id='headerbar' class="header-container">
    <div class='asrow align-items-center no-gutters justify-content-between'>
        <div id='global-title' class='col-auto' [ngClass]="{'laliga': client==='laliga'}">            
            <img *ngIf='client==="laliga"' class='clientlogo' src='/assets/img/documentation/laliga/laliga-logo.png' height='40' alt='LaLiga logo'>
            <div id='d3vheader-content'>
                <a id='d3vheader-link' href='/{{client}}' title='Homepage' class='color-gray'>SOCIAL DISTANCE EDITOR</a>
                
                <small class='powered'>Powered by 3D Digital Venue</small>
            </div>
        </div>

    
        <div class='col-auto text-right'>
            <div class='d-none d-md-block'>
                <a class='header-link' scrollTo scrollTargetSelector ="#mainfeatures">{{'home.section2.title' | translate}}<span *ngIf='client!="laliga"' class='notification-dot'></span></a>
                <a class='header-link' href='{{documentation_url}}' title='Documentation'>{{'home.menu.doc' | translate}}</a>
                <a class='header-link' *ngIf='!client' (click)='onOpenPdfSubscriberModal()' title='More info'>{{'home.menu.moreinfo' | translate}}</a>
                <a class='header-link' *ngIf='client==="laliga"' (click)='onOpenContactCau()' title="{{'home.menu.laliga.contacto' | translate}}">{{'home.menu.laliga.contacto' | translate}}</a>
                <button class='btn header-btn-home' (click)='onOpenLoginModal()'>Log in</button>
            </div>
        </div>
        <div class='d-sm-block d-md-none'>
            <div dropdown class="nav-item dropdown">
                <a dropdownToggle class="nav-link dropdown-toggle header-btn-home" data-toggle="dropdown"
               role="button" aria-haspopup="true" aria-expanded="false">
                <div class='icon-container-crop'>
                    <div class='icon menu'></div>
                </div>
            </a>
                <ul class="dropdown-menu" *dropdownMenu >
                    <li >
                        <a scrollTo scrollTargetSelector ="#mainfeatures" clasS='dropdown-item'>{{'home.section2.title' | translate}}</a>
                    </li>
                    <li >
                        <a href='{{documentation_url}}' class="dropdown-item">{{'home.menu.doc' | translate}}</a>
                    </li>
                    <li *ngIf='!client' >
                        <a (click)='onOpenPdfSubscriberModal()' class="dropdown-item">{{'home.menu.moreinfo' | translate}}</a>
                    </li>
                    <li *ngIf='client==="laliga"'>
                        <a class='dropdown-item' (click)='onOpenContactCau()' title="{{'home.menu.laliga.contacto' | translate}}">{{'home.menu.laliga.contacto' | translate}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>


<div class='header-banner container-fluid'  [ngClass]="{'laliga': client==='laliga'}">
    <div class='asrow align-items-center'>
        <div class='col-lg-6 col-xl-5 col-sm-8 ml-auto text-left'>
            <p class='main'>{{ 'home.banner.b1' | translate }}</p>
            <p class='sub'><b>{{ 'home.banner.b2' | translate }}</b></p>
            <p class='last'>{{ 'home.banner.b3' | translate }}</p>
            <a href={{documentation_url}} title="{{ 'home.section1.version' | translate }} {{version}} {{ 'home.section1.available' | translate }}" class='alert-success'>
                {{ 'home.section1.version' | translate }} {{version}} {{ 'home.section1.available' | translate }}. {{ 'home.section1.seechangelog' | translate }}
            </a>
        </div>
    </div>
</div>

<div class='page-container'>
    <div class='row m-0 align-items-center justify-content-center' style='width:100%'>
        <div id='col-maintext'  class='col-sm-12 col-md-12 col-lg-6'>
            <div class='content'>
                <h1><b>{{ 'home.section1.title' | translate }}</b></h1>
                <p>
                    {{ 'home.section1.content1' | translate }}
                </p>
                <p>
                    {{ 'home.section1.content2' | translate }} <b>{{ 'home.section1.bold1' | translate }}</b> 
                    {{ 'home.section1.content3' | translate }} <b>{{ 'home.section1.bold2' | translate }}</b>
                </p>

            </div>

        </div>
        <div id='col-mainvideo' class='col-lg-6 col-md-12 col-sm-12 pr-0'>
            <div class='custom-container p-0'>
                <iframe height="340" [src]=main_video_url allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
            </div>
        </div>
    </div>
</div>
<section id='mainfeatures'>
    <div class='container-fluid'>
        <div class='row'>
            <div class='col-12'>
                <h2><b>{{ 'home.section2.title' | translate }}</b></h2>
                <div class='row'>
                 <div class='col-xl-6 col-lg-6 col-md-6 col-sm-12' *ngIf='client!="laliga"'>
                        <div class='card-ribbon'>
                            {{ 'home.section2.new' | translate }}
                        </div>
                        <div class='card xxl'>
                            <div class='card-top'>
                                <div class='card-content'>
                                    <h3><b>{{ 'home.section2.feature5.title' | translate }}</b></h3>
                                    <p class='content'>{{ 'home.section2.feature5.content' | translate }}</p>
                                </div>
                                    <p class='text-left'>
                                    <a (click)='openVideo("https://www.youtube.com/embed/1ox08Yaxf8Q","Welcome your season ticket holders back")'><b>{{ 'home.section2.seefullvideo' | translate }}</b></a>
                                </p>
                            </div>
                            <div class='card-bottom' >
                                <div id='feature5_preview'></div>
<!--                                 <video id='catspricing' playsinline preload="metadata" controls="true" muted class="d-none d-sm-block feature_vid d-sm-none d-sm-none" (mouseenter)="playVideo($event)" loop (mouseleave)="stopVideo($event)">
                                    <source src="/assets/img/home/features/videos/2.mov#t=0.001" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video> -->
                            </div>
                        </div>
                    </div>
                    <!--- col-lg-3 col-md-6 col-sm-12 -->
                    <div class='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                        <div class='card xxl'>
                            <div class='card-top'>
                                <div class='card-content'>
                                    <h3><b>{{ 'home.section2.feature1.title' | translate }}</b></h3>
                                    <p class='content'>{{ 'home.section2.feature1.content' | translate }}</p>
                                </div>
                                <p class='text-left'>
                                    <a (click)='openVideo("https://www.youtube.com/embed/67m-dj4SL4s","Apply custom social distance")'><b>{{ 'home.section2.seefullvideo' | translate }}</b></a>
                                </p>
                            </div>
                            <div class='card-bottom' >
                                <div id='feature1_preview'></div>
<!--                                 <video id='customsd' playsinline preload="metadata" controls="true" muted class="d-none d-sm-block  feature_vid" (mouseenter)="playVideo($event)" loop (mouseleave)="stopVideo($event)">
                                    <source src="/assets/img/home/features/videos/1.mov#t=0.001" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video> -->
                            </div>
                        </div>
                    </div>
                    <div class='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                        <div class='card xxl'>
                            <div class='card-top'>
                                <div class='card-content'>
                                    <h3><b>{{ 'home.section2.feature2.title' | translate }}</b></h3>
                                    <p class='content'>{{ 'home.section2.feature2.content' | translate }}</p>
                                </div>
                                    <p class='text-left'>
                                    <a (click)='openVideo("https://www.youtube.com/embed/EljKDoyAEXU","Label categories and assign prices")'><b>{{ 'home.section2.seefullvideo' | translate }}</b></a>
                                </p>
                            </div>
                            <div class='card-bottom' >
                                <div id='feature2_preview'></div>
<!--                                 <video id='catspricing' playsinline preload="metadata" controls="true" muted class="d-none d-sm-block feature_vid d-sm-none d-sm-none" (mouseenter)="playVideo($event)" loop (mouseleave)="stopVideo($event)">
                                    <source src="/assets/img/home/features/videos/2.mov#t=0.001" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video> -->
                            </div>
                        </div>
                    </div>
                    <div class='col-xl-3 col-lg-6 col-md-6 col-sm-12'>
                        <div class='card'>
                            <div class='card-top'>
                                <div class='card-content'>
                                    <h3><b>{{ 'home.section2.feature3.title' | translate }}</b></h3>
                                    <p class='content'>{{ 'home.section2.feature3.content' | translate }}</p>
                                </div>
                                <p class='text-left'>
                                    <a (click)='openVideo("https://www.youtube.com/embed/mhfv2PCwI7k","Move groups and edit seats")'><b>{{ 'home.section2.seefullvideo' | translate }}</b></a>
                                </p>
                            </div>
                            <div class='card-bottom' >
                                <div id='feature3_preview'></div>
<!--                                 <video id='movegroups' playsinline preload="metadata" controls="true" muted class="d-none d-sm-block feature_vid" (mouseenter)="playVideo($event)" loop (mouseleave)="stopVideo($event)">
                                    <source src="/assets/img/home/features/videos/3.mp4#t=0.001" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video> -->
                            </div>
                        </div>
                    </div>
                    <div class='col-xl-3 col-lg-6 col-md-6 col-sm-12'>
                        <div class='card'>
                            <div class='card-top'>
                                <div class='card-content'>
                                    <h3><b>{{ 'home.section2.feature4.title' | translate }}</b></h3>
                                    <p class='content'>{{ 'home.section2.feature4.content' | translate }}</p>
                                </div>
                                <p class='text-left'>
                                    <a (click)='openVideo("https://www.youtube.com/embed/53rTbKOaTbg","Run different simulations in one or more sections.")'><b>{{ 'home.section2.seefullvideo' | translate }}</b></a>
                                </p>
                            </div>
                            <div class='card-bottom' >
                                <div id='feature4_preview'></div>
<!--                                 <video class="d-none d-sm-block feature_vid" playsinline preload="metadata" controls="true" muted (mouseenter)="playVideo($event)" loop (mouseleave)="stopVideo($event)">
                                    <source src="/assets/img/home/features/videos/4.mov#t=0.001" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class='page-bottom'>
    <div>
        <p class='text-center'>{{ 'home.section3.title' | translate }}</p>
    </div>
    <div class='slick-wrapper'>
        <ngx-slick-carousel class="carousel" 
                #slickModal="slick-carousel" 
                [config]="slideConfig" 
                (init)="slickInit($event)"
                (breakpoint)="breakpoint($event)"
                (afterChange)="afterChange($event)"
                (beforeChange)="beforeChange($event)">
                <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                <img src="{{ slide.img }}" alt="{{ slide.desc }}">
                </div>
        </ngx-slick-carousel>
    </div>
</div>