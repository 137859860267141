import { Injectable } from '@angular/core';
import { DVMService } from './dvm.service';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Simulation } from '../shared/models/simulation-data.model';
import { Tier } from '../shared/models/configuration-data.model';
import { CategoriesService } from './categories.service';

declare let DVM;
export interface Itotaltiers {
    capacity: number,
    tiers: any[],
    locked: number
}

@Injectable({
    providedIn: 'root'
})
export class TiersService {
    public simulation : Simulation;
    public tiers : Tier[];
    public totaltiersAV$ = new BehaviorSubject<Itotaltiers>(undefined);

    constructor(private dvmService: DVMService,
                private categories: CategoriesService) {}

    public setData(simulation:Simulation){
        this.simulation = simulation;
        this.tiers = this.setTiers();
    }

    public getSimulation(): Simulation{
        return this.simulation;
    }
    public getTiers(): Tier[]{
        return this.tiers;
    }
    private setTiers(): Tier[]{
        return this.simulation.configuration.tier_list;
    }

    public getDefaultCapacity(): number{
        return this.simulation.capacity;
    }
    
    //
    public setTotalTiersAV(config_tier_list: any[]) : Promise<any>{
        let promises = [];
        let venue_av:Itotaltiers = {
            capacity : 0,
            tiers: [],
            locked : 0
        }
        if(!config_tier_list.length){
            return new Promise((s,r)=>{
                venue_av.capacity = this.dvmService.viewer.getNodesByType("seat").length;
                venue_av.locked =  this.dvmService.viewer.getNodesByGroups('seat', 'locked').length;
                this.setTotalTiersAVSubject(venue_av);
                s(venue_av);
            });
        }

        config_tier_list.forEach(tier => {
            const viewerConfig = this.dvmService.getDVMConfig();
                viewerConfig.venue_id = this.dvmService.viewer.getVenueId();
                viewerConfig.map_id = tier.map_id;
                viewerConfig.container = 'dummy-viewer-container';
            let p = new Promise((success, reject) => {
                DVM.loadModule('map_viewer', viewerConfig).then(
                dummy_viewer => {
                    dummy_viewer.loadMap(viewerConfig)
                    .then(
                        response => {
                            let c = dummy_viewer.getNodesByType("seat").length;
                            tier.capacity = c;
                            venue_av.tiers.push(tier);
                            venue_av.capacity+=c;
                            this.categories.printInventoryCategories(dummy_viewer);
                            venue_av.locked +=dummy_viewer.getNodesByGroups('seat', 'locked').length;
                            dummy_viewer.reset();
                            dummy_viewer.close();
                            success(null);
                        })
                    .catch((err) => {
                        console.error(err);
                        reject(err);
                    });
                }).catch((err) => {
                    console.error(err);
                    reject(err);
                    });
            });
            promises.push(p);
        });


        return Promise.all(promises).then(result=>{
            this.setTotalTiersAVSubject(venue_av);
            return venue_av;
        });
    }

    public setTotalTiersAVSubject(res:Itotaltiers): void{
        this.totaltiersAV$.next(res);
    }

    public getTotalTiersAV() : Observable<Itotaltiers>{
        return this.totaltiersAV$.asObservable();
    }
}
