import { Injectable, Inject } from '@angular/core';
import { DVM_CONFIG } from 'src/assets/configuration/digitalvenue.configuration';
import { DVMConfiguration } from 'src/app/social-distance-editor/shared/models/dvm-configuration.model';
import { DVMService } from '../dvm.service';
import { GeneralHandler } from './general.handler';
import { InventoryService } from '../inventory.service';
import { DataService } from '../data.service';
import { CategoriesService } from '../categories.service';
import { GroupsService } from '../groups.service';
import { SocialdistancingService } from '../socialdistancing.service';
import { TiersService } from '../tiers.service';
import { Router } from '@angular/router';

@Injectable()
export class LoadShareSimulationHandler extends GeneralHandler {
    constructor(@Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
                protected dvmService: DVMService,
                private router: Router,
                private inventoryService: InventoryService,
                private dataService: DataService,
                private groups: GroupsService,
                private categories: CategoriesService,
                private socialDistancing: SocialdistancingService,
                private tiersService : TiersService) {
        super('end_load', dvmService);
    }

    loadBlockmapHandler(viewer) {
        viewer.bindInterfaceAction(document.getElementById('map-icon-top'), 'move-up');
        viewer.bindInterfaceAction(document.getElementById('map-icon-left'), 'move-left');
        viewer.bindInterfaceAction(document.getElementById('map-icon-right'), 'move-right');
        viewer.bindInterfaceAction(document.getElementById('map-icon-bottom'), 'move-down');
        viewer.bindInterfaceAction(document.getElementById('map-icon-zoom-plus'), 'zoom-in');
        viewer.bindInterfaceAction(document.getElementById('map-icon-zoom-minus'), 'zoom-out');

        let s = this.dataService.getSharedSimulationSeatManifest(this.dataService.currentShareKey).subscribe(
            simulationSeatManifest => {
                s.unsubscribe();
                console.log(simulationSeatManifest);
                if (simulationSeatManifest.statusCode === 404) {
                    // No tiene sentido compartir una simulación sin seat manifest o SD computado.
                    this.router.navigate(['/home']);
                } else {

                    this.dvmService.viewer.layers.flags.automatic_control_level = false;
                    this.inventoryService.setInventoryData(simulationSeatManifest.response);
                    const inventory = this.inventoryService.getAvailableInventory(true);
                    this.inventoryService.updateTotalsHash();
                    viewer.setAvailability('section', inventory.sections);
                    viewer.setAvailability('seat', inventory.seats);
                    this.inventoryService.availabilityLoadedSubject.next();
                    this.categories.printInventoryCategories();

                    this.tiersService.setTotalTiersAV(this.dataService.simulationData.configuration.tier_list)
                    .then(res=>{
                        if(document.getElementById('loadingmap')){
                            document.getElementById('loadingmap').classList.add('hidden');
                        }
                        this.socialDistancing.calculationFinishedsubject.next();
                    })
                }
            },
            error => {
                console.error(error);
            }
        );
    }

    getConfigurationGroups(removeLoader?: boolean) {
        const s = this.dataService.getGroups(this.dataService.currentConfigurationId).subscribe(
            groupsData => {
                s.unsubscribe();
                if (groupsData && groupsData.statusCode === 200) {
                    this.groups.setGroupsHash(groupsData.response);
                    this.groups.postSimulationGroups();
                    if (removeLoader) {
                        document.getElementById('loadingmap').classList.add('hidden');
                    }
                }
            },
            error => {
                console.log(error);
            }
        );
    }

    loadSeatmapHandler(viewer) {
        viewer.setAvailability([]);
    }

    protected handle(error, viewer) {
        if (error) { console.error(error); }
        viewer = viewer.instance;
        if (viewer.getMapId() === this.dvmConfig.map_id) {
            this.loadBlockmapHandler(viewer);
        } else {
            this.loadSeatmapHandler(viewer);
        }
    }
}
