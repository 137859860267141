<div id='detail-info' class="selectiondata-container" [ngClass]="{'open': displayInfo}">
    <div class='selectiondata-title'>
            <div *ngIf="objectKeysOf(currentSelectionData).length else nothingselected">
                <div>
                    <span class='main'>{{'setup.sidebar.selectioninfo.selectioninfotitle' | translate}}</span>
                </div>
                <div>
                    <span class='d-flex justify-content-between'>
                        <span >Sections</span>
                        <span>{{totalSections}}</span>
                    </span>
                </div>
                <div>
                    <span class='d-flex justify-content-between'>
                        <span>Rows</span>
                        <span>{{totalRows}}</span>
                    </span>
                </div>
                <div>
                    <span class='d-flex justify-content-between'>
                        <span>Seats</span>
                        <span>{{totalSeats}}</span>
                    </span>
                </div>
                <div>
                    <span class='d-flex justify-content-between'>
                        <span class='mr-2'>Total Revenue:</span>
                        <span class='color-blue'>{{totalRevenueString}}</span>
                        <!--
                            <b  *ngIf='currencySymbol==="USD" || currencySymbol==="GBP"' class='color-blue'>{{currencySymbol}} {{formatNumber(totalRevenue)}}</b>
                            <b  *ngIf='currencySymbol==="EUR"' class='color-blue'>{{formatNumber(totalRevenue)}} {{currencySymbol}}</b>
                        -->
                    </span>
                </div>
            </div>
            <ng-template #nothingselected>
                <span class='main'>{{'setup.sidebar.selectioninfo.nothingselected' | translate}}</span>
            </ng-template>
    </div>
    <div class='elements-container'>
        <div class="element-container" *ngFor="let section of currentSelectionData | keyvalue">
            <div class='parentpadding'>
                <div class='section-title' (click)='toggleSectiondata(section.key,$event)'>
                    <span>SECTION {{section.key}}<span id='tetilla_parent_{{section.key}}' class='tetilla closed'></span></span>
                </div>
                <div id='domid_parent_{{section.key}}' class='section-data closed'>
                    <div class='section-data-item'>
                        <span>Categories
                            <div class='categories-list'>
                                <span class='categories-list-item' *ngFor="let category of objectKeysOf(section.value.data.categories)">
                                    <span class='category-ico {{category}}'></span>
                                </span>
                            </div>
                        </span>
                    </div>
                    <div class='children-list section-data-item' (click)='toggleChildrens(section.key,$event)'>
                        <span class='asbutton'>{{section.value.data.count}} Rows <span id='tetilla_{{section.key}}' class='tetilla closed'></span></span>
                    </div>
                    <div id='domid_{{section.key}}' class='element-container closed'>

                        <div class="children-items childrenpadding" *ngFor="let row of section.value.elements | keyvalue">
                            <div class='row-title'>
                                <span>ROW {{row.key}}</span>
                            </div>
                            <div>
                                <span>Categories
                                    <div class='categories-list'>
                                        <span class='categories-list-item' *ngFor="let category of objectKeysOf(row.value.data.categories)">
                                            <span class='category-ico {{category}}'></span>
                                        </span>
                                    </div>
                                </span>
                            </div>
                            <div>
                                <span>{{row.value.data.count}} Seats</span>
                            </div>
                            <div>
                                <span>Seat Price
                                    <div class='categories-list'>
                                        <span class='categories-list-item' *ngFor="let unitprice of objectKeysOf(row.value.data.unitprice)">
                                            <span class='color-blue'>{{unitprice}}</span>
                                        </span>
                                    </div>
                                </span>
                            </div>
                            <div>
                                <span>Row Revenue <span class='color-blue'> {{row.value.data.priceString}}</span></span>
                            </div>
        
        
                            <!--
                            <div class='children-list' (click)='hideRelated($event)'>
                                <span>N. Seats:  <b>{{row.value.data.count}}</b></span>
                            </div>
                                <div class='element-container'>
                                <div class="children-items" *ngFor="let seat of row.value.elements | keyvalue">
                                    <div>
                                        <span>Seat: <b>{{seat.key}}</b></span>
                                    </div>
                                    <div>
                                        <span>Category:</span>
                                        <span class='categories-list-item'>
                                            <span class='category-ico {{seat.value.data.category}}'></span>
                                        </span>
                                    </div>
                                    <div>
                                        <span>Price: <b>{{seat.value.data.price}}</b></span>
                                    </div>
                                </div>
                            </div> -->
                        </div>
        
                    </div>

                    <div class='section-data-item'>
                        <span>{{section.value.data.subcountString}} Seats</span>
                    </div>
                    <div class='section-data-item' *ngIf="section.value.data.avcount">
                        <span>{{section.value.data.avcountString}} Available Seats</span>
                    </div>
                    <div class='section-data-item'>
                        <span>Seat Price
                            <div class='price-list'>
                                <span class='price-item' *ngFor="let unitprice of objectKeysOf(section.value.data.unitprice)">
                                    <span class='color-blue'>{{unitprice}}</span>
                                </span>
                            </div>
                        </span>
                    </div>
                    <div class='section-data-item'>
                        <span>Section Revenue <span class='color-blue'> {{section.value.data.priceString}}</span></span>
                    </div>
                    <!--
                        <span *ngIf='currencySymbol==="USD" || currencySymbol==="GBP"'>Revenue: <span class='currency-left'>{{currencySymbol}}<b> {{formatNumber(section.value.data.revenue)}}</b></span></span>
                        <span *ngIf='currencySymbol==="EUR"'>Revenue: <span class='currency-right'><b> {{formatNumber(section.value.data.revenue)}}</b> {{currencySymbol}}</span></span>
                    -->

                </div>
            </div>

        </div>
    </div>
</div>
<div class='details-tab' (click)="displayInfoTab()" [ngClass]="{'open' : displayInfo}">
    <div class='icon-container'>
        <div class='icon menu-arrow'></div>
    </div>
</div>
