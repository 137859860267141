import { ReturnStatement } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { userInfo } from 'os';
import { AuthenticationService } from 'src/app/auth';
import { DataService } from 'src/app/social-distance-editor/services/data.service';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})


export class ChangePasswordModalComponent implements OnInit {

  _passdata = {
    current: '',
    password: '',
    repeatpass: '',
    errorrepeat : "",
    errorcurrent : ""

  };
  

  constructor(private bsModalRef: BsModalRef,
              private dataService: DataService,
              private authService: AuthenticationService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this._passdata.errorcurrent = "";
    this._passdata.errorrepeat = "";
  }


  public onDismiss() {
    this.bsModalRef.hide();
  }

  public onSubmit(): void {
    this.cleanallerrors();
    if(this._passdata.password!=this._passdata.repeatpass){
      this._passdata.errorrepeat = 'Passwords not matching';
      this._passdata.errorcurrent = "";
      return;
    }

    this.dataService.updatepassword(this._passdata.current,this._passdata.password).subscribe(
      response => {
        this.toastr.success("","Password updated");
        this.onDismiss();
      },
      error => {
        this._passdata.errorrepeat = "";
        this._passdata.errorcurrent =  error.error.error;
      }
    );
  }


  public cleanallerrors(){
    this._passdata.errorrepeat = "";
    this._passdata.errorcurrent =  "";
  }
}
