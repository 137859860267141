<div class='modal-wrap laliga'>
    <div #step1>
        <div class='modal-header'>
            <h3>{{'newAllocationModal.newAllocationTitle' | translate}}</h3>
            <button type="button" class="btn-modal-header" (click)="onClose()" data-dismiss="modal">
                <span>&times;</span>
            </button>
        </div>
        <div class='modal-content'>
            <form #formSimulation="ngForm" (ngSubmit)="onSubmit(formSimulation)" (keydown.enter)="$event.preventDefault()">
                <div *ngIf='error'>
                    <div class='warning-alert'>{{errorMsg}}.</div>
                </div>
                <div class='step'>
                    <div class='row'>
                        <div class='col-6 pl-0'>
                            <span class='data-content'>{{'editSimulationModal.venue' | translate}}: <b>{{venueName}}</b></span>
                            <span class='data-content'>{{'editSimulationModal.configuration' | translate}}: <b>{{simulation?.configuration?.name}}</b></span>
                        </div>
                        <div class='col-6 pr-0'>
                            <span class='data-content'>{{'editSimulationModal.simulation' | translate}}: <b>{{simulation?.name}}</b></span>
                            <span class='data-content'>{{'editSimulationModal.venuecapacity' | translate}}: <b>{{formatNumber(simulation?.configuration?.capacity)}}</b></span>
                            <span class='data-content'>
                            {{'editSimulationModal.simresult' | translate}}:
                            <b class='color-blue'>{{formatNumber(simulation?.capacity)}} </b>
                            <small class='color-blue'> ({{availabilityPercentage}}%)</small>
                        </span>
                        </div>
                    </div>

                    <div class='row align-items-center' *ngIf="!toCurrent">
                        <div class='col-6 pr-0'>
                            {{'editSimulationModal.chooseSocialDistancing' | translate}}
                        </div>
                        <div class='col-6 pr-0'>
                            <div class='sd-btns text-center'>
                                <button id='btn-less-distance' type='button' class='btn-round d-inline' (click)="onSetDistancing('minus')">
                                    <div class='icon-container-round'>
                                        <div class='icon minus'></div>
                                    </div>
                                </button>
                                <span class='sd-value d-inline-block'>{{formatSocialDistancing(socialDistancing)}}</span>
                                <span class='sd-unit d-inline-block'>{{units}}</span>
                                <button id='btn-more-distance' type='button' class=' d-inline btn-round' (click)="onSetDistancing('sum')">
                                    <div class='icon-container-round'>
                                        <div class='icon plus'></div>
                                    </div>
                                </button>
                            </div>
                            <input id="securityDistance" type="hidden" name="socialDistancing" [(ngModel)]="socialDistancing" />

                        </div>
                    </div>

                    <div class='row align-items-center' *ngIf="!toCurrent">
                        <div class='col pr-0'>
                            {{'editSimulationModal.aisle_note' | translate}}
                        </div>
                        <div class='col pr-0 opt-col'>
                            <div class='sd-btns text-center'>
                                <button id='btn-less-aisle' type='button' class='btn-round d-inline' (click)="onChangeAisleSeat('minus')">
                                    <div class='icon-container-round'>
                                        <div class='icon minus'></div>
                                    </div>
                                </button>
                                <span class='sd-value d-inline-block'>{{aisleSeats}}</span>
                                <span class='sd-unit d-inline-block'>{{'newSimulationModal.seats' | translate}}</span>
                                <button id='btn-more-aisle' type='button' class=' d-inline btn-round' (click)="onChangeAisleSeat('sum')">
                                    <div class='icon-container-round'>
                                        <div class='icon plus'></div>
                                    </div>
                                </button>
                            </div>

                        </div>
                    </div>

                    <div class='row'>
                        <div class='col-6 pr-0'>
                            {{'newAllocationModal.laliga.uploadSubscribersFile' | translate}}
                        </div>
                        <div class='col-6 pr-0'>
                            <app-uploadbutton #uploadClientsCsv [disabled]="false" name="{{'newAllocationModal.uploadCsv' | translate}}"></app-uploadbutton>
                        </div>
                    </div>
                    <div class='row'>
                        <div class='col-6 pr-0'>
                            {{'newAllocationModal.laliga.uploadVenueFile' | translate}}
                        </div>
                        <div class='col-6 pr-0'>
                            <app-uploadbutton #uploadCategoriesCsv [disabled]="false" name="{{'newAllocationModal.uploadCsv' | translate}}"></app-uploadbutton>
                        </div>
                    </div>

                    <div class='row'>
                        <div class='col-6 pr-0'>
                            <label for='maxClientsEnabledCheckbox'>Maximum allocated subscribers</label>
                        </div>
                        <div class='col-6 pr-0'>
                            <input id="maxClientsEnabledCheckbox" #maxClientsEnabledCheckbox class="css-checkbox maxClientsEnabledCheckbox" type='checkbox' name="maxClientsEnabledCheckbox" [(ngModel)]="maxClientsEnabled">
                            <input #maxClientsAllowedInput class="maxClientsAllowedInput" type="number" min="0" value="none" name="maxClientsAllowedInput" [disabled]="!maxClientsEnabled" [(ngModel)]="maxClientsAllowed"/>
                        </div>
                    </div>
                    <!-- Upgrade/Downgrade CHECKBOX -->
                    <div class='row'>
                        <div class='col-6 pr-0'>
                            <label for='maxClientsEnabledCheckbox'>Upgrade/Downgrade</label>
                        </div>
                        <div class='col-6 pr-0'>
                            <input id="upgradeOrDowngradeCheckbox" #upgradeOrDowngradeCheckbox class="css-checkbox maxClientsEnabledCheckbox" type='checkbox' name="upgradeOrDowngradeCheckbox" [(ngModel)]="upgradeOrDowngrade">
                        </div>
                    </div>

<!--                    <div class='row'>-->
<!--                        <div class='col-6 pr-0'>-->
<!--                            <input type="checkbox" id="allocClientsLimit" value="allowClientsLimit"><label for="allocClientsLimit">Max allocations allowed</label>-->
<!--                        </div>-->
<!--                        <div class='col-6 pr-0'>-->
<!--                            <input id="maxClientsAllowed" type="number" min="0" value="none" />-->
<!--                        </div>-->
<!--                    </div>-->



                </div>
                <div class='modal-footer'>
                    <button class='btn' type="button" (click)="onClose()">{{'newAllocationModal.buttonCancel' | translate}}</button>
                    <button class='btn' type="submit" [disabled]="isDisabled()">{{'newAllocationModal.buttonConfirm' | translate}}</button>
                </div>
            </form>
        </div >
    </div>

    <div class="d-none" #step2>
        <div class='modal-header'>
            <h3>{{'newAllocationModal.loadingAllocationTitle' | translate}}</h3>

            <button type="button" class="btn-modal-header" (click)="onClose()" data-dismiss="modal">
                <span>&times;</span>
            </button>
        </div>
        <div class='modal-content'>
            <div class='step'>
                <div class='row'>
                    <div class='col-12 pr-0'>
                        {{'newAllocationModal.loadingAllocationContent1' | translate}}<br>
                        {{'newAllocationModal.loadingAllocationContent2' | translate}}
                        <div style="text-align: center" class="loadingDiv">
                            <img src="../../../../../../assets/img/interface/loading.gif" alt="loading">
                        </div>
                    </div>
                </div>
            </div>
            <div class='modal-footer'>
                <button class='btn' type="button" (click)="onClose()">{{'newAllocationModal.buttonCancel' | translate}}</button>
            </div>
        </div>
    </div>
    <div class="d-none" #step3>
        <div class='modal-header'>
            <h3>{{'newAllocationModal.allocationCompletedTitle' | translate}}</h3>
            <button type="button" class="btn-modal-header" (click)="onClose()" data-dismiss="modal">
                <span>&times;</span>
            </button>
        </div>
        <div class='modal-content'>
            <div class='step'>
                <div class='row'>
                    <div class='col-12 pr-0'>
                        {{'newAllocationModal.allocationCompletedContent1' | translate}}
                        <b class="color-blue">{{this.result?.computedPercentage.toFixed(1) || '??'}}%</b>.<br>
                        <b class="color-blue">{{this.result?.membersAssigned || '0'}}</b>
                        {{'newAllocationModal.allocationCompletedContent2' | translate}}
                        <b class="color-blue">{{this.result?.membersLeft || '0'}}</b>
                        {{'newAllocationModal.allocationCompletedContent3' | translate}}
                    </div>
                </div>
            </div>
            <div class='modal-footer'>
                <button class='btn' type="button" (click)="onClose()">{{'newAllocationModal.buttonOk' | translate}}</button>
                <!--
                    <button class='btn' [ngClass]="{'d-none' : hasAllocation()}" type="button" (click)="onViewAllocation()">{{'newAllocationModal.goallocation' | translate}}</button>
                -->
            </div>
        </div>
    </div>
    <div class="d-none" #stepError>
        <div class='modal-header'>
            <h3>{{'newAllocationModal.allocationErrorTitle' | translate}}</h3>
            <button type="button" class="btn-modal-header" (click)="onClose()" data-dismiss="modal">
                <span>&times;</span>
            </button>
        </div>
        <div class='modal-content'>
            <div class='step'>
                <div class='row'>
                    <div class='col-12 pr-0'>
                        <div class='data-content'>
                            <p>{{errorMsg}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class='modal-footer'>
            <button class='btn' type="button" (click)="onClose()">{{'newAllocationModal.buttonOk' | translate}}</button>
        </div>
    </div>
    <div class="d-none" #stepErrorRepeated>
        <div class='modal-header'>
            <h3>{{'newAllocationModal.allocationErrorTitle' | translate}}</h3>
            <button type="button" class="btn-modal-header" (click)="onClose()" data-dismiss="modal">
                <span>&times;</span>
            </button>
        </div>
        <div class='modal-content'>
            <div class='step'>
                <div class='row'>
                    <div class='col-12 pr-0'>
                        <div class='data-content'>
                            <p>{{errorMsg}} <a #repeatedFile>test</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class='modal-footer'>
            <button class='btn' type="button" (click)="onClose()">{{'newAllocationModal.buttonOk' | translate}}</button>
        </div>
    </div>
</div>
