<div id='newsimulation-modal' class='modal-wrap'>
    <div class='modal-header'>
        <h3>{{'computeResultModal.titlesectionsims' | translate}}</h3>
        <button type="button" class="btn-modal-header" (click)="onDismiss()" data-dismiss="modal">
            <span>&times;</span>
        </button>
    </div>
    <div class='modal-content'>
        <div id='loading-modal'>
            <div>
                <img src="../../../../assets/img/interface/loading.gif" alt="loading" height="50">
                <span class='d-block mt-4'>Calculating results...</span>
            </div>
        </div>
        <div id='step-content' class='d-none'>
            <div class='step'>
                <div class='row'>
                    
                    <div class='col-6 p-0'>
                        
                        <span class='data-content'>{{'computeResultModal.venue' | translate}}: <b>{{venueName}}</b></span>
                        <span class='data-content'>{{'computeResultModal.configuration' | translate}}: <b>{{simulation?.configuration?.name}}</b></span>
                        <span class='data-content'>{{'computeResultModal.simulationname' | translate}}: <b class='color-blue'>{{simulation?.name}}</b></span>
                        <span class='data-content'>
                            <span *ngIf="sdParentNodes.length>1 else singleselection"> Sections selected:</span>
                            <b class='color-blue'>{{sections_selected_value}}</b>
                            <ng-template #singleselection>
                                Section selected:
                            </ng-template>
                        </span>
                    </div>
                    <div class='col-6 p-0'>
                        <span class='data-content'>{{'computeResultModal.venuecapacity' | translate}}: <b>{{formatNumber(simulation?.configuration.capacity)}}</b></span>
                        <span class='data-content'>
                            {{'computeResultModal.configseats' | translate}}: <b style='margin-right:10px;'>{{formatNumber(simulation?.configuration.capacity-nlocked)}}</b><small>({{ formatNumber(nlocked) }} locked seats)</small>
                        </span>

                        <span class='data-content'>
                            {{'computeResultModal.simresult' | translate}}: 
                            <b class='color-blue'>{{formatNumber(simulationcapacity)}} </b>
                            <small class='color-blue'> ({{simAvailabilityPercentage}}%)</small>
                        </span>
                        <span id='sectionsselected' class='data-content'>
                            <span *ngIf="sdParentNodes.length>1 else singleselection"> Sections selected new capacity: </span>
                            <b class='color-blue'>{{subsimcapacity}}</b> / <b class='ml-0'>{{selection_capacity}}</b>
                            <ng-template #singleselection>
                                Section selected new capacity: 
                            </ng-template>

                        </span>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-auto p-0'>
                        <label><b>{{'computeResultModal.chooseSocialDistancing' | translate}}</b></label>
                    </div>
                    <div class='col-auto p-0'>
                        <div class='sd-btns text-left'>
                            <div *ngIf='modalData?.social_distancing else multipledata'>
                                <span class='sd-value d-inline-block'>
                                    {{formatSocialDistancing(modalData?.social_distancing)}}
                                </span>
                                <span class='sd-unit d-inline-block'>{{units}}</span>
                            </div>
                            <ng-template #multipledata>
                                <small class="multiplesvalues">{{'computeResultModal.multipledata' | translate}}</small>
                            </ng-template>
                        </div>
                        
                    </div>
                </div>
                <div class='row'>
                    <div class='col-6 p-0'>
                        <div class='asrow'>
                            <div class='col-auto p-0'>
                                <label><b>{{'computeResultModal.aisle_note' | translate}}</b></label>
                            </div>
                            <div class='col-auto p-0 opt-col'>
                                <div class='sd-btns text-left'>
                                    <div *ngIf="modalData?.aisle_seats!='multiple' else multipledata">
                                        <span class='sd-value d-inline-block'>{{modalData?.aisle_seats}}</span>
                                        <span class='sd-unit d-inline-block'>{{'computeResultModal.seats' | translate}}</span>
                                    </div>
                                    <ng-template #multipledata>
                                        <div *ngIf="modalData?.aisle_seats==='multiple'">
                                            <small class="multiplesvalues">{{'computeResultModal.multipledata' | translate}}</small>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='col-6 p-0 opt-col' *ngIf='totalAisleSeats'>
                        <span class="data-content">{{'computeResultModal.totalaisleseats' | translate}}: <b class="color-blue">{{formatNumber(totalAisleSeats)}} </b></span>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-12 p-0'>
                        <label><b>{{'computeResultModal.groupcount' | translate}}</b></label>
                    </div>
                    <div class='parentgroupoption-col asrow p-0'>
                        <!-- HALF 1 -->
                        <div *ngIf="paginatedSimulationParameters[0] && paginatedSimulationParameters[0].length" class='group-options col-6 pl-0'>
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class='text-center'>{{'computeResultModal.ngroups' | translate}}</th>
                                        <th class='text-right'>{{'computeResultModal.availableseats' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let option of paginatedSimulationParameters[0]; let i = index">
                                        <td><span class='opt-lab'>{{option.name}}</span></td>
                                        <td class='text-center'>
                                            <span>{{option.result.num_of_groups}}</span>
                                        </td>
                                         <td class='text-right'>
                                            <span class='color-blue'>
                                                <!-- <b>{{formatNumber(option.computed_nseats)}} </b> <small>({{(option.computed_percentage * 100).toFixed(2)}}%)</small> -->
                                                <b>{{formatNumber(option.result.available_seats)}} </b> <small class='d-none' *ngIf='option.result.availability_percent'>({{(option.result.availability_percent).toFixed(2)}}%)</small>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- HALF 2 -->
                        <div *ngIf="paginatedSimulationParameters[1] && paginatedSimulationParameters[1].length" class='group-options col-6 pr-0'>
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class='text-center'>{{'computeResultModal.ngroups' | translate}}</th>
                                        <th class='text-right'>{{'computeResultModal.availableseats' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let option of paginatedSimulationParameters[1]; let i = index">
                                        <td><span class='opt-lab'>{{option.name}}</span></td>
                                        <td class='text-center'>
                                            <span>{{option.result.num_of_groups}}</span>
                                        </td>
                                        <td class='text-right'>
                                            <span class='color-blue'>
                                                <!-- <b>{{formatNumber(option.computed_nseats)}} </b> <small>({{(option.computed_percentage * 100).toFixed(2)}}%)</small> -->
                                                <b>{{formatNumber(option.result.available_seats)}} </b> <small class='d-none'  *ngIf='option.result.availability_percent'>({{option.result.availability_percent.toFixed(2)}}%)</small>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class='row' *ngIf="modalData?.limit2groups">
                    <div class='col p-0'>
                        <div *ngIf="modalData?.limit2groups==='multiple' else singledata">
                            <b>{{'computeResultModal.limit2groups' | translate}}</b><small class="multiplesvalues">{{'computeResultModal.multipledata' | translate}}</small>
                        </div>
                        <ng-template #singledata>
                            <b>{{'computeResultModal.confirmlimit2groups' | translate}}</b>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class='modal-footer'>
                <button id='cancelsimulation-btn' class='btn' type="button" (click)="onDismiss()">{{'computeResultModal.buttonClose' | translate}}</button>
                <button class='btn' type="button" (click)="closeToEdit()" *ngIf="!page">{{'computeResultModal.buttonEdit' | translate}}</button>


            </div>
        </div>
    </div>
</div>