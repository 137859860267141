<div>
    <div class='modal-header'>
        <h3>{{'login.title' | translate}}</h3>
        <button type="button" class="btn-modal-header" (click)="onDismiss()" data-dismiss="modal">
            <span>&times;</span>
        </button>
    </div>
    
    <form #loginForm="ngForm" >
        <div class='modal-content'>
            <div class='login-wrapper'>
                <div>
                    <label><b>{{'login.enter_username' | translate}}</b></label>
                    <input type="text" name="username" id="username" [(ngModel)]="loginData.username" />
                </div>
                <div>
                    <label><b>{{'login.enter_pass' | translate}}</b></label>
                    <input type="password" name="password" id="password" [(ngModel)]="loginData.password" />
                </div>
                <div class="text-center alert alert-danger" *ngIf="errors" role="alert">
                    {{'login.error' | translate}}
                </div>
                <div id='forgotpass' class='text-center'>
                    <button type='button' class='btn-clear' (click)="onForgotPassword()">{{'login.forgotPassword' | translate}}</button>
                </div>
            </div>
        </div>
        <div class='modal-footer'>
            <button class='btn' type='button' (click)='onDismiss()'>{{'login.cancel' | translate}}</button>
            <button class='btn-reversed' type="submit" (click)='onSubmit()'>{{'login.submit' | translate}}</button>
        </div>
    </form>
</div>
