import { Component, OnInit,ElementRef, ViewChild,HostListener } from '@angular/core';
import { VersionService } from '../social-distance-editor/services/version.service';
import { ModalOptions, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginModalComponent } from '../social-distance-editor/shared/modals/login-modal/login-modal.component';
import {PublicVideosComponent} from '../social-distance-editor/shared/modals/public-videos/public-videos.component'
import { youtubeVideo } from '../social-distance-editor/shared/models/video.model';
import { PdfsubscriberComponent } from '../social-distance-editor/shared/modals/pdfsubscriber/pdfsubscriber.component';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LaligaContactCauModalComponent } from '../social-distance-editor/shared/modals/laliga-contact-cau-modal/laliga-contact-cau-modal.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss','../header/header.component.scss'],
  providers: [

  ]
})
export class HomeComponent implements OnInit {
  @HostListener("scroll", ['$event'])

  bsModalRef: BsModalRef;
  version = "0";
  slides = [];
  slideConfig = {
    slidesToShow: 8, slidesToScroll: 3,
    dots: false, infinite: true, centerMode: true,
    autoplay: true,  speed: 1000, autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };

  constructor(
    private versionService:VersionService,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private sanitizer: DomSanitizer
    ) {

  }

  client;
  documentation_url='/documentation';
  main_video_url = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/rTnsOk8X6hY');

  ngOnInit(): void {
    this.version = this.versionService.getVersion();

    if (this.activatedRoute.snapshot.routeConfig.path.toLowerCase() === 'laliga'){
      this.client = this.activatedRoute.snapshot.routeConfig.path.toLowerCase();
      this.documentation_url ="/documentation/"+this.client;
      this.main_video_url = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/iXgCRnqKMDA");
      this.translate.use('es');
    }


    this.initSlides();

  }

  slickInit(e) {
/*     console.log('slick initialized');
 */  }
  
  breakpoint(e) {
/*     console.log('breakpoint');
 */  }
  
  afterChange(e) {
/*     console.log('afterChange');
 */  }
  
  beforeChange(e) {
/*     console.log('beforeChange');
 */  }

 private initSlides(){
  let nlogos = 51;
  let exclude = [];
  if(this.client==='laliga'){
    nlogos = 11;
  }
  for(let i = 0;i<=nlogos;i++){
    if(exclude.indexOf(i)==-1){
        if(this.client==='laliga'){
          this.slides.push({img:"/assets/img/slider/laliga/logo-min-"+i+".png",desc:""});
          }else{
            this.slides.push({img:"/assets/img/slider/logo-min-"+i+".png",desc:""});
          }

    }
  }
 }
  public onOpenLoginModal(): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered',
      backdrop: false,
      ignoreBackdropClick: false,
      initialState: {}
    };
    this.bsModalRef = this.modalService.show(LoginModalComponent, modalConfig);
  }

  public onOpenPdfSubscriberModal(): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered',
      backdrop: false,
      ignoreBackdropClick: false,
      initialState: {}
    };
    this.bsModalRef = this.modalService.show(PdfsubscriberComponent, modalConfig);
  }


  public openVideo(url, title){
    const videoData : youtubeVideo ={
      raw : url,
      title : title
    }
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered modal-dialog-video',
      backdrop: false,
      ignoreBackdropClick: false,
      initialState: {
        video : videoData
      }
    };
    this.bsModalRef = this.modalService.show(PublicVideosComponent, modalConfig);
  }


  playVideo(event: any) {
    event.target.play();
  }
  stopVideo(event: any) {
    event.target.pause();

  }

  public onOpenContactCau(): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered',
      backdrop: false,
      ignoreBackdropClick: false,
      initialState: {}
    };
    this.bsModalRef = this.modalService.show(LaligaContactCauModalComponent, modalConfig);
  }
}
