import {Injectable} from '@angular/core';
import {InventoryService} from '../../../services/inventory.service';
import {AuthenticationService} from '../../../../auth';
import {Subscription} from 'rxjs';
import {SDEPlugin} from '../../../shared/models/user-data.model';
import {ModalOptions} from 'ngx-bootstrap/modal/modal-options.class';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SportsAllianceModalComponent} from '../components/modals/sports-alliance-modal/sports-alliance-modal.component';
import {LaligaModalComponent} from '../components/modals/laliga-modal/laliga-modal.component';
import {MlbModalComponent} from '../components/modals/mlb-modal/mlb-modal.component';
import {AllocatorRefs} from '../allocator.module';

@Injectable({
    providedIn: 'root'
})
export class AllocatorModalService {

    public allowAllocation = false;
    public ref: AllocatorRefs = '';
    public resultFile: Blob | null = null;
    private userSubscription: Subscription;


    constructor(private inventoryService: InventoryService,
                private authService: AuthenticationService,
                private modalService: BsModalService)
    {
        this.userSubscription = this.authService.getUserLoggedSubject()
            .subscribe((user) => {
                if (user) {
                    // TODO: plugin debería tener un nombre génerico (allocator?) o ser dos plugins (allocator + parser)
                    const plugin = user.user.plugins.find((pl) => pl.type === 'allocation') as SDEPlugin | null;
                    if (plugin) {
                        this.allowAllocation = true;
                        this.ref = (plugin.ref ?? '').toLowerCase() as AllocatorRefs;
                        return;
                    }
                }
                this.allowAllocation = false;
                this.ref = '';
            });
    }

    public showModal(modalConfig?: ModalOptions): BsModalRef {
        switch (this.ref) {
            case 'sportsallianceallocator':
                return this.modalService.show(SportsAllianceModalComponent, modalConfig);
            case 'laligaallocator':
                return this.modalService.show(LaligaModalComponent, modalConfig);
            case 'mlballocator':
                return this.modalService.show(MlbModalComponent, modalConfig);
        }

        throw new Error('Invalid allocator ref');
    }
}
