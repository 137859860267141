import { Injectable} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DataService } from 'src/app/social-distance-editor/services/data.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormatNumberService } from '../../social-distance-editor/services/format-number.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Seatingchartviewer } from '../models/seatingchartviewer-data.model';
import { ApiService} from './api.service';
@Injectable({ providedIn: 'root' })

export class SCVDataService extends DataService {

    private invokeData: Subject<any> = new Subject();
    private invokeSCVView: Subject<any> = new Subject();
    public data: {
        seating: any;
        pricescales: any;
        plans: any;
        seatstatus: any;
        buyertypesgroups: any;
        holdcodes: any;
        buyertypes: any;
    };
    public scvmapId = ['main_tiers_status'];
    public loadFromUpload = false;

/*     public ticketplansDescription= [
        {"desc":"2021 Full Season","color":"red1","code":"21FULL"},
        {"desc":"2021 Full Season Broker","color":"red2","code":"21FULLBRKR"},
        {"desc":"2021 Full Season Comp","color":"red3","code":"21FULLCMP"},
        {"desc":"2021 Half Season A","color":"yellow1","code":"21HALFA"},
        {"desc":"2021 Half Season B","color":"yellow2","code":"21HALFB"},
        {"desc":"2021 Partial Plan 1","color":"blue1","code":"21PLAN1"},
        {"desc":"2021 Partial Plan 2","color":"blue2","code":"21PLAN2"},
        {"desc":"2021 Partial Plan 3","color":"blue3","code":"21PLAN3"},
        {"desc":"2021 Partial Plan 4","color":"blue4","code":"21PLAN4"},
        {"desc":"2021 Pick 10 Pack","color":"purple1","code":"21PICK10"},
    ]; */
/*      public pricescalesDescription = {
        "BATSCC":"CommunityAmerica Crown Club",
        "DGBOXF":"Front Dugout Box",
        "DCLUB":"Konica Minolta Diamond Club",
        "LEXINF":"Loge Infield",
        "DGBOXR":"Dugout Box",
        "DGPLZI":"Inner Dugout Plaza",
        "VWBOX":"View Box",
        "FDPLZ":"Field Plaza",
        "DGPLZO":"Dugout Plaza",
        "FDBOX":"Field Box",
        "VWRES":"View Reserved",
        "TRIPLE":"Triple Crown Suite",
        "DCTBLE":"Konica Minolta Diamond Clb Tbl",
        "DCMVP":"Konica MinoltaDiamond Club MVP",
        "OFBOX":"Outfield Box",
        "DCBOX":"Konica MinoltaDiamond Club Box",
        "SIGSTE":"Signature Suite",
        "PRESS":"Press Level Suites",
        "LEXOUT":"Loge Outfield",
        "CRAFT":"Craft and Draft Seats",
        "OUTPLZ":"Outfield Plaza"
      } */

      public scvView;
      constructor(protected http: HttpClient,
        protected formatMumber: FormatNumberService,
        protected apiService: ApiService) {
        super(http,formatMumber);

        this.data = {
            seating : {},
            pricescales: {},
            plans: {},
            seatstatus: {},
            buyertypesgroups: {},
            buyertypes: {},
            holdcodes: {}
        }

    }

    public getData(): Observable<any>{
        return this.invokeData.asObservable();
    }
    public nextData(data: any): void{
        this.invokeData.next(data);
    }
    public setData(seating: any, pricescales: any, plans: any, seatstatus: any,buyertypesgroups: any, holdcodes: any, buyertypes: any) {
        this.data= {
            seating : seating,
            pricescales: pricescales,
            plans: plans,
            seatstatus: seatstatus,
            buyertypesgroups: buyertypesgroups,
            holdcodes: holdcodes,
            buyertypes: buyertypes
        }
    
        this.nextData(this.data);
        return this.data;
    }

    public setSeating(data){
        this.data.seating = data;
    }

    public setPricescales(data){
        this.data.pricescales = data;
    }

    public getPricescales(){
        return Object.keys(this.data.pricescales);
    }
    public getSeatInfo(seat_id:string){
        return this.data.seating[seat_id];
    }
    public getSeats(){
        return Object.keys(this.data.seating);
    }

    public setPlans(data){
        this.data.plans = data;
    }

    public getSeatsByPackageCode(packageCode){
        if(this.data.plans[packageCode]){
            return this.data.plans[packageCode].seats
        }
        return [];
    }

    public getSeatsByPricescale(pricescale){
        if(this.data.pricescales[pricescale]){
            return this.data.pricescales[pricescale].seats
        }
        return [];
    }
    public getBuyertypesgroups(){
        return Object.keys(this.data.buyertypesgroups);
    }
    public getBuyertypes(){
        return Object.keys(this.data.buyertypes);
    }
    public getHoldcodes(){
        return Object.keys(this.data.holdcodes);
    }
    public getAvailablePlans(){
        return Object.keys(this.data.plans);
    }
    public getSCVView(): Observable<any>{
        return this.invokeSCVView.asObservable();
    }
    public nextSCVView(data: any): void{
        this.invokeSCVView.next(data);
    }
    public setSCVView(SCVView){
        this.scvView = SCVView;
        this.nextSCVView(SCVView);
    }
    public generatePublicKey(id: number): Observable<any>{
        return this.apiService.generatePublicKey(id);
    }

    public stopSharingSCVView(id: number): Observable<any>{
        return this.apiService.deletePublicKey(id);
    }

    public updateSCVView(id:number,data: any){
        return this.apiService.updateView(id,data);
    }
    public loginSharedView(id,key,username,password){
        return this.apiService.loginSharedView(id, key, username,password);
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const id = route.params['id'];
        let result = this.apiService.getView(id);
        if(route.params['key']){
            let key = route.params['key'];
            result = this.apiService.getSharedView(id,key);
        }
        return result;
    }
}