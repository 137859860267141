import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {ButtonComponent} from '../button/button.component';

@Component({
    selector: 'app-uploadbutton',
    templateUrl: './uploadbutton.component.html',
    styleUrls: ['../button/button.component.scss', './uploadbutton.component.scss']
})
export class UploadbuttonComponent extends ButtonComponent implements OnInit, AfterViewInit {

    public readonly observableChange: Observable<Event>;
    protected subscriberChange: Subscriber<Event> | null = null;

    protected inputFile: HTMLInputElement;
    public filename = '';
    public fileExtension = '';

    @ViewChild('uploadFileDiv') uploadFileDiv!: ElementRef<HTMLDivElement>;
    @ViewChild('removeFileDiv') removeFileDiv!: ElementRef<HTMLDivElement>;

    constructor() {
        super();
        this.inputFile = document.createElement('input');
        this.inputFile.type = 'file';
        this.inputFile.style.display = 'none';

        this.observableChange = new Observable<Event>((subscriber) => {
            this.subscriberChange = subscriber;
        });
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.inputFile.addEventListener('change', (event ) => {
            if (this.subscriberChange) {
                this.subscriberChange.next(event);
            }
        });

        this.observableClick.subscribe((event) => {
            this.inputFile.value = '';
            this.inputFile.click();
        });

        this.inputFile.addEventListener('input', (event) => {
            const file = this.getFile();
            if (file) {
                const split = file.name.split('.');
                if (split.length > 1) {
                    const extension = split.splice(split.length - 1, 1)[0];
                    this.filename = split.join('.');
                    this.fileExtension = extension;
                } else {
                    this.filename = file.name;
                    this.fileExtension = '';
                }
                this.showRemoveFile();
            }
        });

        // this.inputFile.addEventListener("change", (event) => {
        //     console.log("CHANGE FILE");
        // });
    }

    reset(): void {
        super.reset();
        this.removeFile();
    }

    removeFile(): void {
        this.showUploadFile();
        this.filename = '';
        this.fileExtension = '';
        this.inputFile.value = '';
    }

    hasFile(): boolean {
        return this.inputFile.files != null && this.inputFile.files.length > 0;
    }

    getFile(index = 0): File | null {
        return this.inputFile.files?.item(index) ?? null;
    }
    getFileRaw(): FileList | null {
        return this.inputFile.files ?? null;
    }
    private showUploadFile() {
        this.uploadFileDiv.nativeElement.classList.remove('d-none');
        this.removeFileDiv.nativeElement.classList.add('d-none');
    }

    private showRemoveFile() {
        this.uploadFileDiv.nativeElement.classList.add('d-none');
        this.removeFileDiv.nativeElement.classList.remove('d-none');
    }

}
