import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
    apiRoot = '/api';
    
    constructor(private http: HttpClient) {
        
    }

    // Este método se tiene que llamar despues de crear la View en BD y así obtener el viewID
    // viewId Creada en BD : De momento pasar un numero fijo
    // data: Objetos de datos JSON que se genera del CSV.
    public postFile(viewId,data: any, type:string): Observable<any> {
        const blob = new Blob([JSON.stringify(data)], {type: 'application/json'});
        const body = new FormData();
        body.append('scvviewfile', blob, type+'.json');
        return this.http.post(`${this.apiRoot}/scv/view/${viewId}/files/${type}`, body);
    }

    public postRawFile(viewId, file: File, ticketplan){
        const body = new FormData();
        body.append('scvrawfile', file);
        return this.http.post(`${this.apiRoot}/scv/view/${viewId}/rawfiles?ticketplan=${ticketplan}`, body);
    }
    public createView(body: any): Observable<any> {
      return this.http.post(`${this.apiRoot}/scv/view/`, body);
    }
    public updateView(viewId:number, body: any): Observable<any> {
      return this.http.patch(`${this.apiRoot}/scv/view/${viewId}`, body);
    }
    public getView(id: number): Observable<any> {
      return this.http.get(`${this.apiRoot}/scv/view/${id}`);
    }
    // type: only allowed ticketplans, pricescales, seating
    public getViewFile(id: number,type:string): Observable<any> {
      return this.http.get(`${this.apiRoot}/scv/view/${id}/files/${type}`);
    }

    public getTestCDNFile(): Observable<any> {
      return this.http.get(`${this.apiRoot}/scv/filefromcdn`);
    }
    public getCDNFile(id: number,type:string,key?:string): Observable<any> {
      if(key){
        return this.http.get(`${this.apiRoot}/scv/view/${id}/share/${key}/cdn/files/${type}`);
      }else{
        return this.http.get(`${this.apiRoot}/scv/cdn/view/${id}/files/${type}`);
      }
    }
    public getList(): Observable<any> {
      return this.http.get(`${this.apiRoot}/scv/view`);
    }

    public deleteView(id: number): Observable<any> {
      return this.http.delete(`${this.apiRoot}/scv/view/${id}`);
    }

    public rawGet(endpoint: string): Observable<any>{
      return this.http.get(`${endpoint}`);
    }

    public generatePublicKey(id: number): Observable<any>{
      return this.http.get(`${this.apiRoot}/scv/view/${id}/share/`);
    }
    public deletePublicKey(id: number): Observable<any>{
      return this.http.delete(`${this.apiRoot}/scv/view/${id}/share/`);
    }
    public getSharedView(id: number, key: string): Observable<any>{
      return this.http.get(`${this.apiRoot}/scv/view/${id}/share/${key}`);
    }
    
    public loginSharedView(id: number, key: string, username: string, password: string){
      const body = {
        username : username,
        password: password
      }
      return this.http.post(`${this.apiRoot}/scv/view/${id}/share/${key}/login`,body);
    }
}