import { Injectable, Inject } from '@angular/core';
import { GeneralHandler } from './general.handler';
import { DVMService } from '../dvm.service';
import { DVM_CONFIG } from 'src/assets/configuration/digitalvenue.configuration';
import { DVMConfiguration } from 'src/app/social-distance-editor/shared/models/dvm-configuration.model';
import { InventoryService } from '../inventory.service';
import { SelectionService } from '../selection.service';

@Injectable()
export class LeaveHandler extends GeneralHandler {
    constructor(protected dvmService: DVMService,
                @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
                private inventoryService: InventoryService,
                private selection: SelectionService) {
        super('leave', dvmService);
    }

    protected handle(obj) {
        // Close popovers
        const tooltip: HTMLElement = document.querySelector('#tooltip');
        tooltip.removeAttribute('data-show');
        // Handle        
        const viewer = obj.instance;
        const nodes = obj.nodes;
        if (nodes.length) {
            viewer.hover(null);
            this.selection.selectionSubscriptionsArray.forEach(
                e => { e.unsubscribe(); }
            );
        }
        
        viewer.removeNodesFromGroup(viewer.getNodesByGroups('seat', 'tomove'), 'tomove');
        viewer.removeNodesFromGroup(viewer.getNodesByGroups('seat', 'warning'), 'warning');
        viewer.removeNodesFromGroup(viewer.getNodesByGroups('seat', 'singleselection'), 'singleselection');
        viewer.removeNodesFromGroup(viewer.getNodesByGroups('seat','regularselection'),"regularselection");
        viewer.removeNodesFromGroup(viewer.getNodesByGroups('seat','vipselection'),"vipselection");
        viewer.removeNodesFromGroup(viewer.getNodesByGroups('seat','disabledselection'),"disabledselection");
        viewer.removeNodesFromGroup(viewer.getNodesByGroups('seat','restrictedselection'),"restrictedselection");
    }
}
